import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, Card, Table, Row, Col, Form } from "react-bootstrap";
import HeaderItem from "components/Table/HeaderItem";
import API from "api";
import { PAGE_LIMIT } from "constant";
import MFPagination from "components/Pagination/MFPagination";
import FilterPopOver from "../components/FilterPopOver";
import { QuestionStatus } from "constant";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import Loader from "components/Loader/Loader";
import { AppContext } from "../context/app-context";
import { calculateTdWidth, formatQuestion } from "utils";
import editIcon from "../assets/img/edit-icon.png";
import trashIcon from "../assets/img/trash-icon.png";
import { formatCreatedDate } from "utils";
import ExportToExcel from "components/ExportToExcel";
import { Link } from "react-router-dom";
import DetailsOfQuestionAssignedCrew from "components/QuestionTestingGroup/Modals/DetailsOfQuestionAssignedCrew";
import QuestionnaireModal from "components/QuestionTestingGroup/Modals/QuestionnaireModal";
import QuestionnaireViewModal from "components/QuestionTestingGroup/Modals/QuestionnaireViewModal";
import { t } from "constants/stringConstants";
import { CONFIRMATION_TYPE } from "constant";
import Icon from "components/Icon";
import { formatNumber } from "utils";

const Questionnaire = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);
  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(width - 900, 2);

  const [isShow, setIsShow] = useState(false);
  const [isQuestionView, setIsQuestionView] = useState(false);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [groups, setGroups] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const searchTerms = ["Group Name"];
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortDescending, setSortDescending] = useState(true);
  const [sortBy, setSortBy] = useState("");
  const [itemToDelete, setItemToDelete] = useState("");
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [empSelectZone, setEmpSelectZone] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const appContext = useContext(AppContext);
  const [newItem, setNewItem] = useState({
    groupName: "",
    question: {
      question: [
        {
          id: (Math.random() * new Date()) % 10,
          name: "",
          status: QuestionStatus.new,
        },
      ],
    },
  });

  let componentLabel = t("questionnaire");

  const selectedItem = [
    { itemKey: "createdAt" },
    { itemKey: "groupName" },
    { itemKey: "question" },
    { itemKey: "totalEmployees" },
    { itemKey: "updatedAt" },
    { itemKey: "createdByName" },
  ];

  const sortGroups = (groups, sortParam) => {
    if (sortParam === "question") {
      if (sortDescending) {
        return [...groups].sort((a, b) =>
          Object.keys(a[sortParam].question).length < Object.keys(b[sortParam].question).length
            ? 1
            : Object.keys(b[sortParam].question).length < Object.keys(a[sortParam].question).length
            ? -1
            : 0
        );
      } else {
        return [...groups].sort((a, b) =>
          Object.keys(b[sortParam].question).length < Object.keys(a[sortParam].question).length
            ? 1
            : Object.keys(a[sortParam].question).length < Object.keys(b[sortParam].question).length
            ? -1
            : 0
        );
      }
    } else {
      if (sortDescending) {
        return [...groups].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
      } else {
        return [...groups].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
      }
    }
  };

  useEffect(() => {
    getQuestions();
    setLoading(false);
  }, [appContext.questions]);

  const getQuestions = () => {
    const questionsData = formatQuestion(appContext.questions);
    const employeeData = appContext.employees
      .filter((f) => f.programID)
      .reduce(
        (obj, employee) => {
          const program = appContext.programs.find((p) => p.id === employee.programID && p.scheduleID);

          const question = appContext.schedules.find((s) => s.id === program?.scheduleID && s.questionID);
          if (question) {
            obj["totalEmployees"][question.questionID] = (obj["totalEmployees"][question.questionID] || 0) + 1;
            if (obj["zoneIds"][question.questionID]) {
              obj["zoneIds"][question.questionID].push(program.id);
            } else {
              obj["zoneIds"][question.questionID] = [program.id];
            }
          }
          return obj;
        },
        { zoneIds: {}, totalEmployees: {} }
      );
    const groupData = questionsData.map((question) => {
      let ttlEmployees = employeeData["totalEmployees"][question.id] || 0;
      const assignedZone = [...new Set(employeeData["zoneIds"][question.id])];
      return {
        ...question,
        totalEmployees: ttlEmployees,
        assignedZone,
      };
    });
    setGroups(groupData);
    setFilteredGroups(nestedFilter(groupData, filter));
  };

  useEffect(() => {
    if (filteredGroups.length > 0) setFilteredGroups(sortGroups(filteredGroups, sortBy));
  }, [sortDescending, sortBy]);

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    if (filteredGroups.length === filteredList.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredGroups.map((t) => t.id) : []);
  };

  const openDeleteConfirmation = (user) => {
    if (user.totalEmployees > 0) {
      appContext.showErrorMessage(` ${user.groupName} Group assigned to the Pre-Screening Schedule`);
      return;
    }
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.DELETE,
      title: "Delete Group",
      message: `Are you sure you want to delete ${user.groupName}?`,
    });

    if (user) setItemToDelete(user);
  };

  const handleBulkDeleteClick = () => {
    let [ids, departmentName, totalDepartments] = getDeleteMessage();
    if (ids.length === 0) {
      appContext.showErrorMessage("Only unassigned Pre-Screening Question Groups can be deleted");
      return;
    }
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.MULTI_DELETE,
      title: `Delete ${componentLabel}`,
      message: departmentName
        ? `Are you sure you want to delete ${departmentName} ${componentLabel}?`
        : `Are you sure you want to delete the ${formatNumber(totalDepartments)} ${componentLabel}s selected?`,
    });
  };

  const getDeleteMessage = () => {
    const departmentToDelete = filteredGroups.filter((d) => checkboxes.indexOf(d.id) !== -1 && d.totalEmployees === 0);
    const ids = departmentToDelete.map((d) => d.id);

    let departmentName = null;
    let totalDepartments = null;

    if (ids.length === 1) {
      const departmentToRemove = filteredGroups.find((d) => d.id === ids[0]);
      if (departmentToRemove) {
        departmentName = departmentToRemove.groupName;
      }
    } else {
      totalDepartments = ids.length;
    }
    return [ids, departmentName, totalDepartments];
  };

  const onHandleConfirmation = async (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.DELETE:
        handleConfirm();
        break;
      case CONFIRMATION_TYPE.MULTI_DELETE:
        deleteBulkQuestionnaire();
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await API.deleteQuestionnaire(itemToDelete.id);
      appContext.showSuccessMessage(`${itemToDelete.groupName} ${componentLabel} has been deleted successfully`);
      setItemToDelete(null);
      appContext.resetQuestions();
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const deleteBulkQuestionnaire = async () => {
    try {
      let [ids, departmentName, totalDepartments] = getDeleteMessage();
      if (ids.length === 0) {
        appContext.showErrorMessage(`No ${componentLabel} to Delete`);
        return;
      }
      setLoading(true);
      for (let i = 0; i < ids.length; i++) {
        await API.deleteQuestionnaire(ids[i]);
      }
      if (departmentName) {
        appContext.showSuccessMessage(`${departmentName} ${componentLabel} has been deleted successfully`);
      } else {
        appContext.showSuccessMessage(
          `${formatNumber(totalDepartments)} ${componentLabel}s have been deleted successfully`
        );
      }
      appContext.resetQuestions();
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage(`Fail to Delete the ${componentLabel}`);
    }
    setLoading(false);
    setCheckboxes([]);
  };

  const currentItems = (grps) => {
    const indexOfLast = currentPage * PAGE_LIMIT;
    const indexOfFirst = indexOfLast - PAGE_LIMIT;
    return grps.slice(indexOfFirst, indexOfLast);
  };

  let itemsToMap = currentItems(filteredGroups);

  const handleSubmit = async (newGroup) => {
    setIsShow(false);
    const data = { ...newGroup, question: JSON.stringify(newGroup.question) };

    setLoading(true);
    if (newGroup.isNew) {
      await API.createQuestionnaire(data, appContext.user);
      appContext.showSuccessMessage(`You have successfully created ${newGroup.groupName} ${componentLabel}`);
    }
    if (!newGroup.isNew) {
      await API.updateQuestionnaire(data);
      appContext.showSuccessMessage(`${componentLabel} updated successfully`);
    }
    setTimeout(() => {
      appContext.resetQuestions();
    }, 1000);
    // } catch (err) {
    //   console.log(`err in ${newGroup.isNew ? "creation" : " updation"}`, err);
    // }
    setLoading(false);
  };

  const getPageNumbers = (groups) => {
    const pageNumbers = groups.length > PAGE_LIMIT ? Math.ceil(groups.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(groups);

  if (filteredGroups.length > 0) {
    pageNumbers = getPageNumbers(filteredGroups);
  }

  const handleClose = () => {
    setIsQuestionView(false);
    setIsShow(false);
    setIsShowDelete(false);
    setItemToDelete(null);
  };

  const handleChangePage = (number) => setCurrentPage(number);

  const handleEdit = (item) => {
    const obj = { ...item, isNew: false };
    // const data = {...obj};
    // const data = Object.assign({},obj);
    const data = JSON.parse(JSON.stringify(obj));
    setNewItem(data);
    setIsShow(true);
  };

  const handleViewQuestion = (item) => {
    const obj = { ...item };
    setNewItem(obj);
    setIsQuestionView(true);
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  useEffect(() => {
    if (groups.length > 0) {
      setFilteredGroups(nestedFilter(groups, filter));
      setCurrentPage(1);
    }
  }, [filter]);

  const TableRow = ({ item }) => {
    return (
      <tr>
        <td>
          {" "}
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={item.id}
              checked={checkboxes.indexOf(item.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, item.id)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td className="icon" style={{ textAlign: "center", textOverflow: "visible" }}>
          <img
            src={editIcon}
            alt="edit icon"
            width="18"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={item.id}
            onClick={(e) => handleEdit({ ...item })}
          />
        </td>
        <td className="ellipsis ">{formatCreatedDate(item.createdAt)}</td>
        <td className="ellipsis" title={item.groupName}>
          {item.groupName}
        </td>
        <td
          style={{
            textAlign: "center",
          }}
        >
          <span
            onClick={() => handleViewQuestion({ ...item })}
            onKeyPress={() => handleViewQuestion(item)}
            style={{
              textDecoration: "underline",
              color: "#A82632",
            }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.textDecoration = "none";
            }}
            onMouseLeave={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            role="button"
            tabIndex={0}
            title={item.groupName}
          >
            {item.question && formatNumber(Object.keys(item.question.question).length)}
          </span>
        </td>
        <td style={{ textAlign: "center", textOverflow: "visible" }} title={item.totalEmployees}>
          {item.totalEmployees > 0 ? (
            <Link
              style={{ color: "#42cef5" }}
              onClick={() => {
                setEmpSelectZone(item.assignedZone);
                setShowDetailsModal(true);
              }}
            >
              {formatNumber(item.totalEmployees)}
            </Link>
          ) : (
            formatNumber(item.totalEmployees)
          )}
        </td>
        <td className="ellipsis centered" style={{ textAlign: "center", textOverflow: "visible" }}>
          {formatCreatedDate(item.updatedAt) || ""}
        </td>
        <td className="ellipsis centered" style={{ textAlign: "center", textOverflow: "visible" }}>
          {item.createdByName || ""}
        </td>
        <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px", textAlign: "center" }}>
          <img
            src={trashIcon}
            alt="trash icon"
            width="15"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={item.id}
            onClick={(e) => {
              // setIsShowDelete(true);
              openDeleteConfirmation(item);
            }}
          />
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  Total {componentLabel}s (
                  {filteredGroups ? formatNumber(filteredGroups.length) : formatNumber(groups.length)})
                </Card.Title>
                <Row>
                  <div className="buttonHeader">
                    {/* <Button
                      className="headerButton btn-fill"
                      variant="primary"
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      Filter
                    </Button> */}
                    <Icon
                      handleClick={() => setShowFilter(!showFilter)}
                      label="Filter"
                      title="Filter"
                      iconType={"filter"}
                    />

                    <Icon
                      handleClick={() => {
                        if (filteredGroups && filteredGroups.length > 0) {
                          setAllSelected(!allSelected);
                          toggleCheckboxes(!allSelected);
                        }
                      }}
                      label={!allSelected ? "Select All" : "Deselect All"}
                      title={!allSelected ? "Select All" : "Deselect All"}
                      iconType={"selectAllIcon"}
                    />

                    <Icon
                      handleClick={() => handleBulkDeleteClick()}
                      label="Delete"
                      title="Delete"
                      disabled={checkboxes.length === 0}
                      iconType={"binIcon"}
                    />
                    <Icon
                      handleClick={() => {
                        setNewItem({
                          groupName: "",
                          isNew: true,
                          question: {
                            question: [
                              {
                                id: (Math.random() * new Date()) % 10,
                                name: "",
                                status: QuestionStatus.new,
                              },
                            ],
                          },
                          isNew: true,
                        });
                        setIsShow(true);
                      }}
                      title={"Create Group"}
                      label="Create"
                      iconType="createIcon"
                    />
                    {filteredGroups.length > 0 && (
                      <ExportToExcel
                        items={filteredGroups}
                        selectedItem={selectedItem}
                        appContext={appContext}
                        filter={filter}
                        title={`${componentLabel}s Data`}
                        sortBy={sortBy}
                      />
                    )}
                  </div>
                </Row>
                {showFilter && (
                  <FilterPopOver
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination
                  totalSelected={checkboxes.length}
                  // showAll
                  showSelectedRecord
                  selectedLabel={`Selected ${componentLabel}s`}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                />
                {/* <MFPagination currentPage={currentPage} handleChangePage={handleChangePage} totalPages={pageNumbers} /> */}
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    filteredGroups.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="table">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>
                        <th></th>
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="createdAt"
                          title="Date Created"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="groupName"
                          title="Group Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="question"
                          title="No of Question"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="totalEmployees"
                          title="No of Crew"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="updatedAt"
                          title="Last Updated"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="createdByName"
                          title="Created By"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsToMap &&
                        itemsToMap.map((item, i) => {
                          return <TableRow key={i} item={item} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
      {isShow && (
        <QuestionnaireModal item={JSON.stringify(newItem)} handleClose={handleClose} handleSubmit={handleSubmit} />
      )}

      {isQuestionView && <QuestionnaireViewModal item={newItem} handleClose={handleClose} />}
      {showDetailsModal && (
        <DetailsOfQuestionAssignedCrew
          handleClose={() => {
            setShowDetailsModal(false), setEmpSelectZone([]);
          }}
          appContext={appContext}
          zoneIds={empSelectZone}
        />
      )}
      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={onHandleConfirmation}
          closeBtn
        />
      )}
    </div>
  );
};

export default Questionnaire;
