import React, { useState, useContext, useRef } from "react";
import { Card, Table, Row, Col, Button, Container } from "react-bootstrap";
import { useEffect } from "react";
import { AppContext } from "context/app-context";
import TestTypeModal from "views/TestTypeSetting/TestTypeModal";
import QuestionSchedules from "views/QuestionSchedule";
import QuestionSchedulesList from "views/QuestionSchedulesList";
import Departments from "views/Departmens";
import API from "api";
import { calculateTdWidth } from "utils";
import Schedules from "views/Schedules";
import Questionnaire from "views/Questionnaire";
import MessageSetting from "views/MessageSetting";
import Location from "views/Location";
import Regions from "views/Regions";
import ProgramSettings from "views/ProgramSettings";
import {
  EMPLOYEE_DEPARTMENTS,
  HEALTH_SAFETY_SCHEDULE,
  LOCATIONS,
  MESSAGE_SETTINGS,
  PROGRAMS,
  QUESTION_SCHEDULE,
  QUESTION_SCHEDULE_LIST,
  REGIONS,
  TESTING_SCHEDULE,
  TIMER_TEST,
  ZONES,
  PROXY_CONTACT_OPTIONS,
} from "constants/tabPillsContants";
import TabPills from "components/Tabs/TabPills";
import Loader from "components/Loader/Loader";
import TimerTest from "./TimerTest";
import ProxyContactOptions from "views/ProxyContactOptions/ProxyContactOptions";

const TestTypeSetting = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const [departments, setDepartment] = useState([]);
  const [tabsOptions, setTabOptions] = useState([]);
  const [loading, setLoading] = useState();
  const appContext = useContext(AppContext);

  const getDepartments = async () => {
    const data = await API.getDepartments();
    setDepartment(data);
  };

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    if (appContext.company?.programLevel === "Zone") {
      if (appContext.user.phone_number === "+19999999999" || appContext.user.phone_number === "+18888888888") {
        setTabOptions(
          tabPillsConstants.filter((f) => f.title !== `${TESTING_SCHEDULE}` && f.title !== `${QUESTION_SCHEDULE}`)
        );
      } else {
        setTabOptions(
          tabPillsConstants.filter(
            (f) =>
              f.title !== `${TESTING_SCHEDULE}` &&
              f.title !== `${QUESTION_SCHEDULE}` &&
              f.title !== PROXY_CONTACT_OPTIONS
          )
        );
      }
    } else {
      setTabOptions(tabPillsConstants.filter((f) => f.title !== `${QUESTION_SCHEDULE_LIST}`));
    }
  }, [
    appContext.company,
    departments,
    appContext.programs,
    appContext.schedules,
    appContext.locations,
    appContext.regions,
    appContext.questions,
    appContext.proxyContacts,
  ]);

  const tabPillsConstants = [
    {
      title: `${appContext.isZoneLevelCompany() ? ZONES : PROGRAMS}`,
      length: `${appContext.programs.length}`,
      component: <ProgramSettings />,
    },
    {
      title: `${EMPLOYEE_DEPARTMENTS}`,
      length: `${departments.length}`,
      component: <Departments setDepartment={setDepartment} />,
    },
    {
      title: `${TESTING_SCHEDULE}`,
      length: `${appContext.schedules.filter((f) => f.scheduleCategory !== "Question").length}`,
      component: <Schedules />,
    },
    {
      title: `${QUESTION_SCHEDULE}`,
      length: `${appContext.schedules.filter((f) => f.scheduleCategory === "Question").length}`,
      component: <QuestionSchedules />,
    },
    {
      title: `${HEALTH_SAFETY_SCHEDULE}`,
      length: `${appContext.questions.length}`,
      component: <Questionnaire />,
    },
    // {
    //   title: `${QUESTION_SCHEDULE_LIST}`,
    //   length: `${appContext.schedules.filter((f) => f.scheduleCategory === "Question").length}`,
    //   // component: <QuestionSchedules />,
    //   component: <QuestionSchedulesList />,
    // },
    {
      title: `${LOCATIONS}`,
      length: `${appContext.locations.length}`,
      component: <Location />,
    },
    {
      title: `${REGIONS}`,
      length: `${appContext.regions.length}`,
      component: <Regions />,
    },
    {
      title: MESSAGE_SETTINGS,
      component: <MessageSetting />,
    },
    {
      title: TIMER_TEST,
      component: <TimerTest />,
    },
    {
      title: `${PROXY_CONTACT_OPTIONS}`,
      length: `${appContext.proxyContacts?.length}`,
      component: <ProxyContactOptions />,
    },
  ];

  return (
    <Container fluid>
      {!loading ? (
        <Row>
          <Col md="12">
            <div className="system-settings-wrapper">
              <TabPills tabs={tabsOptions} />
            </div>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default TestTypeSetting;
