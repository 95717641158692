import React from "react";
import {Button, Form} from "react-bootstrap";
import RadioButton from "../RadioButton/RadioButton";

const GroupQuestion = ({ index, item, handleQuestion, handleAnswer,handleDeleteQuestion, listLength, addQuestion }) => {
  return (
      <>
      <div className="row">
        <div className="col-xl-1">
          <div>
            <label className="modalLineHeaders mt-0">
              Q#{index + 1}
            </label>
          </div>
        </div>
        <div className="col-xl-8">
          <textarea
              style={{ width: "100%", maxHeight: "100px", resize: "none" }}
              id={item.id}
              className="labModalInput textAreaWrapper focus-none"
              placeholder={`Question ${index + 1}`}
              onChange={(e) => handleQuestion(e, item.id)}
          >
            {item.name}
          </textarea>
        </div>
        <div className="col-xl-3">
          <div className="d-flex align-items-center  align-items-start justify-content-start column-gap-10">
            <Form className="d-flex align-items-center column-gap-15">
              <RadioButton
                id={`${Math.random() * 2}`}
                  value="Yes"
                  label="Yes"
                  name="AssignGroupList"
                  checked={item.answer === "Yes"}
                  handleChange={(e) => handleAnswer(e, item.id)}
                  cssClass="expected-result-radio"
              />
              {/*<Form.Check className="d-flex align-items-center">*/}
              {/*  <Form.Check.Input*/}
              {/*      id="1st"*/}
              {/*      value="Yes"*/}
              {/*      name="AssignGroupList"*/}
              {/*      type="radio"*/}
              {/*      className="mt-0 "*/}
              {/*      checked={item.answer === "Yes"}*/}
              {/*      onChange={(e) => handleAnswer(e, item.id)}*/}
              {/*  />*/}
              {/*  <Form.Check.Label htmlFor="1st" className="px-2 scheduleLabel">*/}
              {/*    Yes*/}
              {/*  </Form.Check.Label>*/}
              {/*</Form.Check>*/}
              <RadioButton
                  id={`${Math.random() * 3}`}
                  value="No"
                  label="No"
                  name="AssignGroupList"
                  checked={item.answer === "No"}
                  handleChange={(e) => handleAnswer(e, item.id)}
                  cssClass="expected-result-radio"
              />
              {/*<Form.Check className="d-flex align-items-center pl-4">*/}
              {/*  <Form.Check.Input*/}
              {/*      id="2nd"*/}
              {/*      value="No"*/}
              {/*      name="AssignGroupList"*/}
              {/*      type="radio"*/}
              {/*      className="m-0"*/}
              {/*      checked={item.answer === "No"}*/}
              {/*      onChange={(e) => handleAnswer(e, item.id)}*/}
              {/*  />*/}
              {/*  <Form.Check.Label htmlFor="2nd" className="px-2 scheduleLabel">*/}
              {/*    No*/}
              {/*  </Form.Check.Label>*/}
              {/*</Form.Check>*/}
            </Form>
            <div>
              {listLength > 1 && (
                  <i
                      className="fa fa-trash"
                      onMouseLeave={(e) => (e.target.style.color = "black")}
                      onMouseOver={(e) => {
                        e.target.style.color = "#8B0000";
                        e.target.style.cursor = "pointer";
                      }}
                      aria-hidden="true"
                      id={item.id}
                      onClick={(e) => {
                        listLength > 1 && handleDeleteQuestion(item.id);
                      }}
                  />
              )}
            </div>
            {listLength === index +1 && <Button
                className="modalButtons btn-sm btn-dark btn-fill-dark rounded-0"
                style={{width: "fit-content", marginLeft : "15px"}}
                variant="secondary"
                onClick={() => addQuestion()}
            >
              Add
            </Button>}
          </div>
        </div>
      </div>
      </>
  );
};

export default GroupQuestion;
