import React, { useState, useEffect, useMemo } from "react";
import { Button, Card, Form, Modal, Table } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import { TEST_TYPES, NOTIFICATION_FROM } from "constant";
import moment from "moment";
import ErrorMessage from "components/Message/ErrorMessage";
import TextAreaMessageInput from "components/TextInput/TextAreaMessageInput";
import { v4 as uuidv4 } from "uuid";
import Loader from "components/Loader/Loader";
import api from "api";
import RadioButton from "components/RadioButton/RadioButton";
import { personalizationLocalStorage } from "utils";
import { getValidSpaces } from "utils";

const NotificationInputModal = (props) => {
  const { handleClose, data, appContext, dataType, handleSave, cssClass } = props;
  const [loading, setLoading] = useState(false);
  const [departmentHead, setDepartmentHead] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("M");
  const [subject, setSubject] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [optionOutEmps, setOptionOutEmps] = useState([]);
  const [lsData, setLsData] = useState(personalizationLocalStorage.getStorage() || {});

  const onHandleSave = async () => {
    if (messageType === "E") {
      if (!subject || !subject.trim()) {
        setError("Please add Subject of the email to be sent!");
        return;
      }
    }
    setLoading(true);

    if (optionOutEmps.length > 0) {
      optionOutEmps.forEach((f) => {
        const index = data.findIndex((s) => s.id === f.id);
        if (index !== -1) {
          data.splice(index, 1, f);
        }
      });
    }
    try {
      if (dataType === NOTIFICATION_FROM.department) {
        await api.sendNotificationToDepartment(data, message, appContext.user.name, messageType, subject, fileNames);
      } else if (dataType === NOTIFICATION_FROM.qrCode) {
        await api.sendQRCode(data, message, appContext.user.name, messageType, subject);
      } else {
        await api.sendNotificationToEmployee(data, message, appContext.user.name, messageType, subject, fileNames);
      }
      personalizationLocalStorage.save(JSON.stringify(lsData));
      await api.udpatePersonalization(appContext.user.phone_number, lsData);
      if (handleSave) {
        handleSave();
      } else {
        appContext.showSuccessMessage("Message sent successfully");
      }
      handleClose();
    } catch (err) {
      console.log("Error ", err);
      appContext.showErrorMessage("Request fail");
    }
    setLoading(false);
  };

  useEffect(() => {
    setOptionOutEmps(data.filter((f) => (messageType === "M" ? f.sendSMS === 2 : f.sendEmail === 2)));
  }, [messageType]);

  const onHandleMessageChange = (msg, key, type, sub) => {
    setMessage(getValidSpaces(msg));
    setMessageType(type);
    setSubject(type === "M" ? "" : sub);
  };

  const handleallCheckboxChange = (e, key) => {
    if (e.target.checked) {
      const optOutEmps = optionOutEmps.map((m) => {
        return { ...m, [key]: 1 };
      });
      setOptionOutEmps(optOutEmps);
    } else {
      const optOutEmps = optionOutEmps.map((m) => {
        return { ...m, [key]: 2 };
      });
      setOptionOutEmps(optOutEmps);
    }
  };
  const handleCheckboxChange = (e, item, empKey, index) => {
    const dataIndex = data.findIndex((f) => f.id === item.id);
    const optOutEmps = [...optionOutEmps];
    if (e.target.checked) {
      const emp = { ...item, [empKey]: 1 };
      optOutEmps.splice(index, 1, emp);
      // if (dataIndex !== -1) {
      //   data.splice(dataIndex, 1, emp);
      // }
    } else {
      const emp = { ...item, [empKey]: 2 };
      optOutEmps.splice(index, 1, emp);
      // if (dataIndex !== -1) {
      //   data.splice(dataIndex, 1, emp);
      // }
    }
    setOptionOutEmps(optOutEmps);
  };

  const handleKeyDownEvent = (event) => {
    if (!loading && lsData.useEnter === "1" && event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (messageType === "E") {
        if (!subject || !subject.trim()) {
          setError("Please add Subject of the email to be sent!");
          return;
        }
      }
      if (!message || !message.trim()) {
        setError("Please add message to be sent!");
        return;
      }

      if (!error) onHandleSave();
    }
  };

  const TableRow = ({ item, index, empKey }) => {
    return (
      <tr className="cursor-pointer active">
        <td>
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              className="fs-6"
              checked={item[empKey] !== 2}
              onChange={(e) => handleCheckboxChange(e, item, empKey, index)}
            />
          </Form.Check>
        </td>
        <td className="popOver-centered">{item.firstName}</td>
        <td className="popOver-centered">{item.lastName}</td>
        <td className="popOver-centered">{item.email}</td>
        <td className="popOver-centered">{item.phoneNumber}</td>
      </tr>
    );
  };

  return (
    <>
      <Modal show onHide={handleClose} animation={true} className={cssClass} size={"lg"} keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100">
            <TextAreaMessageInput
              message={message}
              name="testNowVerbiage"
              label="Message"
              type={messageType}
              subject={subject}
              viewAattachment={true}
              setFileNames={setFileNames}
              fileNames={fileNames}
              handleChange={onHandleMessageChange}
              handleKeyDownEvent={handleKeyDownEvent}
            />
            <div className="d-flex justify-content-between w-100 flex-wrap">
              <label>Use Enter To Send Message: </label>
              <RadioButton
                name="useEnter"
                id="yes"
                label="Yes"
                checked={lsData.useEnter === "1" ? true : false}
                handleChange={(e) => setLsData({ ...lsData, useEnter: e.target.checked ? "1" : "0" })}
              />
              <RadioButton
                name="useEnter"
                id="no"
                checked={lsData.useEnter !== "1" ? true : false}
                label="No"
                handleChange={(e) => setLsData({ ...lsData, useEnter: !e.target.checked ? "1" : "0" })}
              />
            </div>
            <div style={{ minHeight: "3vh" }}>
              {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
            </div>
          </div>
          {dataType === NOTIFICATION_FROM.employee && optionOutEmps.length > 0 && (
            <>
              <Card className="strpied-tabled-with-hover m-0">
                <Card.Header>
                  <Card.Title
                    as="h4"
                    style={{
                      marginBottom: 10,
                      fontWeight: "bold",
                      color: "#565656",
                    }}
                  >
                    {`The below ${
                      optionOutEmps.length > 1 ? `(${optionOutEmps.length}) employees have` : "employee has"
                    }  opted out of receiving ${
                      messageType === "M" ? "sms" : "email"
                    }, If you still want to send this ${optionOutEmps.length > 1 ? "employees" : "employee"}  ${
                      messageType === "M" ? "a sms" : "an email"
                    } please select below.`}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table className="table">
                    <thead>
                      <tr>
                        <th>
                          <Form.Check>
                            <Form.Check.Input
                              type="checkbox"
                              checked={optionOutEmps.every((s) =>
                                messageType === "M" ? s["sendSMS"] === 1 : s["sendEmail"] === 1
                              )}
                              onChange={(e) =>
                                handleallCheckboxChange(e, messageType === "M" ? "sendSMS" : "sendEmail")
                              }
                            />
                          </Form.Check>
                        </th>
                        <th className="popOver-centered border-0">First Name</th>
                        <th className="popOver-centered border-0">Last Name</th>
                        <th className="popOver-centered border-0">Email</th>
                        <th className="popOver-centered border-0">Phone Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {optionOutEmps.map((item, i) => (
                        <TableRow
                          key={i}
                          index={i}
                          item={item}
                          empKey={messageType === "M" ? "sendSMS" : "sendEmail"}
                        />
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </>
          )}
          {loading && <Loader />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            disabled={
              !message ||
              !message.trim() ||
              (optionOutEmps.length === data.length &&
                optionOutEmps.every((s) => (messageType === "M" ? s["sendSMS"] === 2 : s["sendEmail"] === 2)))
            }
            className="modalButtons headerButton btn-fill"
            onClick={onHandleSave}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotificationInputModal;
