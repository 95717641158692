import React, { useState, useEffect, useRef } from "react";
import {
  medFlowInLocalStorage,
  formatZip,
  checkValidity,
  getValidName,
  isValidName,
  isValidIdNum,
  isValidPhoneWithCode,
  getPhoneNo,
} from "utils";
import { useHistory, Link } from "react-router-dom";
import logoImage from "assets/img/safecamp_BRAND.1-nobg.png";
import { Auth } from "aws-amplify";
import { newRegisterObj } from "constant";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Default_Profile from "assets/img/default_profile.png";
import { QRCode } from "react-qrcode-logo";
import logo from "assets/img/logo.jpg";
import { Button } from "react-bootstrap";
import API from "api";
import html2canvas from "html2canvas";
import countriesOption from "countries";
import Select from "react-select";
import moment from "moment";
import { countryListAllIsoData, TEST_TYPES } from "constant";
import Loader from "components/Loader/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { toTitleCase, convertToLower, isValidZipCode, getValidSpaces } from "utils";
import SweetAlert from "react-bootstrap-sweetalert";
import SingleDatePicker from "components/Date/SingleDatePicker";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import { isValidEmail, validatePhone } from "utils";
import QrCodeCard from "components/QrCard/QrCodeCard";
import ErrorMessage from "components/Message/ErrorMessage";
import { formatPhoneNumber } from "utils";
import ImageInput from "components/Input/ImageInput";
import RadioButton from "components/RadioButton/RadioButton";
import { COMPANIES } from "constant";
import api from "api";
import ShowAlertMessage from "components/ShowAlertMessage";

let employeeId = "";
const ExternalTestPublic = () => {
  let url = window.location.href;
  const [newUser, setNewUser] = useState(newRegisterObj);
  const [regUser, setRegUser] = useState({});
  const [registrationDone, setRegistrationDone] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [externalTestImage, setExternalTestImage] = useState(null);
  const [testType, setTestType] = useState("");
  const [testError, setTestError] = useState(false);
  const [companyType, setCompanyType] = useState(true);
  const [result, setResult] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [show, setShow] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [companyList, setCompnayList] = useState([]);
  const [message, setMessage] = useState("");

  const history = useHistory();
  const downloadRef = useRef();
  const [company, setCompnay] = useState(COMPANIES.find((f) => url.includes(f.companyWeb)) || {});

  const redirectToAdmin = () => {
    if (history) history.push("/admin");
  };

  useEffect(() => {
    const getUSer = async () => {
      const companyData = await API.getCompnayList();
      setCompnayList(companyData);
      const urlData = companyData.filter((c) => url.includes(c.companyWeb));
      if (urlData.length > 0) {
        setCompnay(urlData[0]);
        setNewUser({ ...newUser, companyID: urlData[0].id });
      } else {
        setNewUser({ ...newUser, companyID: "354ab474-503e-44eb-8b47-dd7fca19a86f" });
      }
    };
    getUSer();
  }, []);

  const onHandleSave = async () => {
    if (!regUser.phoneNumber) {
      setError("Phone number is required");
      return;
    }
    if (!validatePhone(regUser.phoneNumber)) {
      setError("Invalid Phone Number");
      return;
    }
    setLoading(true);
    const cid = company.id;
    const data = { cid, phoneNumber: regUser.phoneNumber };
    try {
      const res = await API.getRegisterData(data);

      if (Object.keys(res).length > 0) {
        setNewUser({ ...newUser, ...res });
        const testsOptions = getOptions(res);
        const testTwo = res?.testTwo || [];

        if (testTwo.length > 0 && testsOptions.length === 0) {
          setMessage("You have already uploaded test");
          setTestError(true);
        } else if (testsOptions.length > 0) {
          setShow(true);
          if (testsOptions.length === 1) setTestType(testsOptions[0]);
        } else {
          setMessage("You are not scheduled to test today. Please contact your H&S team for more information");
          setTestError(true);
        }
      } else {
        setMessageError({ message: "No record found, Please contact health and safety admin", error: true });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  const getOptions = (user) => {
    const options = [...(user.testTwo || [])].filter((t) => !t.isDone);
    if (companyType) return options;
    if (user.testNow) {
      return options.filter((t) => t.testNow);
    }
    return options.filter((t) => !t.testNow);
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn) redirectToAdmin();

  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewUser({ ...newUser, street: val });
    } else {
      console.log("valval", val);
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewUser({ ...newUser, ...addressObj });
    }
  };

  const handleChange = (val) => {
    setResult(val);
  };

  const handleSubmit = async (newUser) => {
    if (!newUser.companyID) {
      showErrorMessage("Your Company Id is not set");
      return;
    }

    if (!testType) {
      setError("Please select test type");
      return;
    }

    let externalImagePath = `${newUser.schrID}/${newUser.schrID}_#datePattern#_${moment()
      .utc()
      .format("YYYY-MM-DD HH:mm")}.jpeg`;

    if (!result) {
      setError("Please select the test result");
      return;
    }

    if (!externalTestImage && result !== "Inconclusive") {
      setError("Please upload the result image");
      return;
    }

    setLoading(true);

    if (newUser.phoneNumber) {
      const [countryCode, phoneNo, phone] = getPhoneNo(newUser.phoneNumber, newUser.countryCode);
      Object.assign(newUser, { phoneNumber: phoneNo });
    }
    // if (externalTestImage instanceof Object) {
    let imageData = "";
    try {
      imageData = await convertToBase64(externalTestImage);
    } catch (err) {
      console.log("error", err.message);
    }

    Object.assign(newUser, {
      ...newUser,
      externalTestImage: result !== "Inconclusive" ? externalTestImage : null,
      externalTestFile: result !== "Inconclusive" ? externalImagePath : null,
      result: result,
      test_type: testType.value,
      testLocation: testType.location,
      ...(imageData && { publicExternalTest: imageData }),
      test: {
        ...testType,
        isDone: true,
        date: moment().utc().toISOString(),
        result,
        externalTestFile: result !== "Inconclusive" ? externalImagePath : null,
        isAcknowledged: false,
        isNewResult: true,
      },
    });
    // }

    console.log("Api", newUser);
    try {
      const res = await api.uploadExternalTest(newUser);
      if (companyList.length > 0) {
        const comp = companyList.find((c) => c.id === company.id);
        if (comp) {
          setCompnay(comp);
        }
      }
      if (res) setRegistrationDone(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error:-", err.message);
    }
  };

  const convertToBase64 = (file) => {
    console.log("file", file);
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };

      console.log(fileInfo);
    });
  };

  const downloadQRCode = async () => {
    const element = downloadRef.current;
    const canvas = await html2canvas(element);
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${newUser.phoneNumber.substring(2)}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (testError) {
    return (
      <div className="registerFormWrapper">
        <div className="preLoginWrapper">
          <div className="text-center">
            <img src={logoImage} alt="logo" className="logo-width" />
          </div>
          <div className="codeScannerWrapper">
            <div className="registered-successful">
              <h3>Upload External Test</h3>
              <p>{message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (registrationDone) {
    return (
      <div className="registerFormWrapper">
        <div className="preLoginWrapper">
          <div className="text-center">
            <img src={logoImage} alt="logo" className="logo-width" />
          </div>
          <div className="codeScannerWrapper">
            <div className="registered-successful">
              <h3>Thank you for Upload External Test</h3>
              <p>
                {result === "Negative"
                  ? "You have successfully completed all the required tests for today."
                  : `Based on the test result, please contact the ${company.testingCordName || "Testing Coordinator"} ${
                      newUser.testingCordNumber || ""
                    } for further instructions.`}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {show ? (
            <div className="registerFormWrapper">
              <div className="registerWrapper pre-register-comp" style={{ maxWidth: "600px" }}>
                <div className="loginInner">
                  <div className="text-center">
                    <img src={logoImage} alt="logo" className="logo-width" />
                  </div>
                  <h4 className="w-100 text-center mt-4 fs-4 fw-bold">Upload External Test</h4>
                  <div className="form-group-wrapper py-1 ">
                    <label className="modalLineHeaders">Name:</label>
                    <span>{`${newUser.firstName} ${newUser.lastName}`}</span>
                  </div>

                  <div className="form-group-wrapper py-1 ">
                    {getOptions(newUser).length === 1 ? (
                      <>
                        <label className="modalLineHeaders">Test Type: </label>
                        <span>{testType.label}</span>
                      </>
                    ) : (
                      <>
                        <label className="modalLineHeaders">Select Test Type: </label>
                        <Select
                          options={getOptions(newUser)}
                          blurInputOnSelect={true}
                          defaultValue={null}
                          menuPlacement="auto"
                          className="w-75"
                          placeholder="Select Testing Type"
                          value={testType ? testType : null}
                          onChange={(e) => {
                            setTestType(e);
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div className="form-group-wrapper py-1">
                    <label className="modalLineHeaders w-25">Result: </label>
                    <div className="d-flex w-75 align-items-center justify-content-between">
                      <RadioButton
                        label="Postive"
                        id="Postive"
                        name="result"
                        checked={result === "Positive"}
                        handleChange={(e) => {
                          handleChange(e.target.checked ? "Positive" : "");
                        }}
                      />

                      <RadioButton
                        label="Negative"
                        id="Negative"
                        name="result"
                        checked={result === "Negative"}
                        handleChange={(e) => {
                          handleChange(e.target.checked ? "Negative" : "");
                        }}
                      />

                      <RadioButton
                        label="Invalid"
                        id="Inconclusive"
                        name="result"
                        checked={result === "Inconclusive"}
                        handleChange={(e) => {
                          handleChange(e.target.checked ? "Inconclusive" : "");
                        }}
                      />
                    </div>
                  </div>
                  {result !== "Inconclusive" && (
                    <div className="form-group-wrapper py-1">
                      <label className="modalLineHeaders"> External Test Image </label>
                      <div className="w-100 mt-3">
                        <ImageInput
                          cssClass="imagePathPublic"
                          setSelectedImage={setExternalTestImage}
                          selectedImage={externalTestImage}
                          type="external"
                          disabled={result === "Inconclusive"}
                        />
                      </div>
                    </div>
                  )}
                  <div style={{ height: "3vh" }}>
                    {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
                  </div>
                  <div className="text-center mt-4">
                    <Button
                      type="submit"
                      variant="secondary"
                      className="headerButton btn-fill"
                      onClick={() => handleSubmit(newUser)}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="registerFormWrapper">
              <div className="preLoginWrapper">
                <div className="text-center">
                  <img src={logoImage} alt="logo" className="logo-width" />
                </div>
                <h4 className="w-100 text-center mt-4">
                  <b>{company.companyName}</b>
                </h4>
                <div className="codeScannerPhoneInput codeScannerWrapper">
                  <div className="registered-successful">
                    <div className="form-group-wrapper my-4">
                      <label className="mb-1">Phone Number:</label>
                      <PhoneNoInput
                        error={setPhoneError}
                        handleChange={(e) =>
                          setRegUser({
                            ...regUser,
                            phoneNumber: e,
                          })
                        }
                        value={regUser.phoneNumber}
                      />
                    </div>
                    <div style={{ height: "3vh" }}>
                      {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
                    </div>
                    <div className="py-2 w-50 mx-auto">
                      <Button type="submit" className="headerButton btn-fill" onClick={onHandleSave}>
                        NEXT
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {messageError && (
            <ShowAlertMessage
              message={messageError}
              handleClose={() => setMessageError("")}
              error={messageError.error}
              success={messageError.success}
            />
          )}
        </>
      )}
    </>
  );
};

export default ExternalTestPublic;
