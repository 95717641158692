import { LOG_POPOVER_HEADER } from "constant";
import React from "react";
import { Card, Table, Overlay, Popover } from "react-bootstrap";
const ExpandableTablePopOverStick = (props) => {
  const { profiles, childNode, handleClose } = props;
  const listKey = Object.keys(profiles[0]).filter((f) => f !== "id");
  const TableCollapse = ({ item, index }) => {
    return (
      <tr>
        <td>{index + 1}</td>
        {listKey.map((m) => (
          <td>{item[m]}</td>
        ))}
      </tr>
    );
  };

  return (
    <Overlay show={true} placement="auto" target={childNode.current} onHide={handleClose} rootClose={true}>
      <Popover id="popover" className="popOverSchedule">
        <Table className="table">
          <thead>
            <tr>
              <th>Sr#</th>
              {listKey.map((m) => (
                <th>{LOG_POPOVER_HEADER[m]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {profiles.length > 0 &&
              profiles.map((item, i) => {
                return <TableCollapse key={i} index={i} item={item} />;
              })}
          </tbody>
        </Table>
      </Popover>
    </Overlay>
  );
};

export default ExpandableTablePopOverStick;
