import React, { useEffect, useState, useContext } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import editIcon from "assets/img/edit-icon.svg";
import profileImageDefault from "assets/img/default_profile.png";
import "bootstrap-daterangepicker/daterangepicker.css";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import GQAPI from "api";
import API from "api";
import SweetAlert from "react-bootstrap-sweetalert";
import { AppContext } from "context/app-context";
import { PDFViewer } from "@react-pdf/renderer";
import ErrorMessage from "components/Message/ErrorMessage";
import { Bounce } from "react-activity";
import { TEST_TYPE_VALUE, countryListAllIsoData, IMAGE_UPLOAD_TYPE, PROFILE_IMAGE_PATH } from "constant";
import {
  getDemoGraphics,
  formatZip,
  formatTest,
  isValidPhone,
  setPhoneNo,
  formatPhoneNumber,
  formatDateOfBirthDOB,
  phoneFormatter,
  formatQuaratineDisplayDate,
  isValidPhoneWithCode,
  isValidIdNum,
  getValidName,
  convertToLower,
  connectedIDByURL,
} from "utils";
import "react-activity/dist/Bounce.css";
import { Storage } from "aws-amplify";
import ImageView from "components/Image/ImageView";
import ReactPDF from "@react-pdf/renderer";
import PDF from "components/PDF";
import moment from "moment";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import SingleDatePicker from "components/Date/SingleDatePicker";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import ImageInput from "components/Input/ImageInput";
import "react-tabs/style/react-tabs.css";
import Loader from "components/Loader/Loader";

import AutoCompleteAddress from "components/AutoCompleteAddress";
import ShowAlertMessage from "components/ShowAlertMessage";

function TestView(props) {
  console.log("props", props);

  const location = useLocation();
  const { state } = location;
  const [test, setTest] = useState();
  const [site, setSite] = useState();
  const [lab, setLab] = useState();
  const [siteContactPhone, setSiteContactPhone] = useState();
  const [demographics, setDemographics] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [releaseTo, setReleaseTo] = useState([]);
  const [showPDF, setShowPDF] = useState();
  const [signature, setSignature] = useState();
  const [loading, setLoading] = useState(false);
  const [hippaConsent, setHippaConsent] = useState(false);
  const [showImages, setShowImages] = useState(null);
  const [showSubmitAlert, setShowSubmitAlert] = useState(false);
  const [editButton, setEditButton] = useState(true);
  const [editInfo, setEditInfo] = useState();
  const [Employee, setEmployee] = useState();
  const [error, setError] = useState("");
  const [openQuarantined, setOpenQuaratined] = useState(false);
  const [allowToEditQuarantine, setAllowToEditQuarantine] = useState(false);
  const [openDataChangeConfirmation, setOpenDataChangeConfirmation] = useState(false);
  const [patternConsent, setPatternConsent] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [vaccinationImage, setVaccinationImage] = useState("");
  const [externalTestImage, setExternalTestImage] = useState("");
  const [changeData, setchangeData] = useState({});
  const [changeDataCheck, setchangeDataCheck] = useState(true);
  const appContext = useContext(AppContext);
  const [profileImage, setProfileImage] = useState(profileImageDefault);
  const [openTestDetail, setOpenTestDetail] = useState(false);
  const [users, setUsers] = useState([]);
  //
  const [openCreator, setOpenCreator] = useState(false);
  const [newTestCreate, setTestCreate] = useState();
  const [selectedTest, setSelectedTest] = useState(test);
  const [labs, setLabs] = useState([]);
  const {
    match: { params },
  } = props;
  const hideAlert = () => {
    props.history.push("/admin/pendingrelease");
    setShowAlert(false);
  };

  const handleCloseTestDetail = () => {
    setOpenTestDetail(false);
  };

  useEffect(async () => {
    if (params.testId) {
      try {
        const res = await API.getTestDetailFromMD(params.testId);
        console.log("params", res);
        setTest(res.tests[0]);
        setEmployee(getDemoGraphics(res.tests[0]));
      } catch (err) {
        console.log("Error:-", err.message);
      }
    }
  }, []);

  // SETTING UP LISTNER
  useEffect(() => {
    if (changeDataCheck && appContext?.eventObject && appContext.eventObject?.opType === "UPDATE") {
      triggerNewData(appContext.eventObject.element);
    }
  }, [appContext?.eventObject]);

  const handleDataChangeConfirm = async (isConfirm) => {
    if (isConfirm) {
      setOpenDataChangeConfirmation(false);
      setEditInfo(changeData);
      setDemographics(getDemoGraphics(changeData));
      setEmployee(getDemoGraphics(changeData));
      setTest(changeData);
    } else {
      setOpenDataChangeConfirmation(false);
    }
  };

  const getFileFromStorage = async (file, setImage) => {
    if (file) {
      try {
        const path = await Storage.get(file);
        setImage(path);
        console.log("path", path);
        return true;
      } catch {
        console.log("Image loading fail");
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (editInfo && editInfo.employee_demographics) {
      const emp = getDemoGraphics(editInfo);
      const list = countryListAllIsoData.find((f) => f.name === emp.country) || { code: "US" };
      setEmployee(emp);
      setDemographics({ ...emp, isoCode: list.code });
    }
  }, [editInfo]);

  useEffect(() => {
    const getSite = async (id) => {
      const model = await API.findSiteByID(id);
      model.length > 0 && setSite(model[0]);
    };

    // console.log("pendingRelease", appContext.pendingTests);
    const getSignature = async (test) => {
      /* const hippaRecord = await API.findHippaConsent(test.employeeID, test.siteID);
      console.log("Hippa Record", hippaRecord);
      const isHippaConsent = true; */
      const imgObject = [];

      try {
        const siteID = test.baseSiteID || test.siteID;
        const fileName = `${siteID}&&${test.employeeID}&&sig.png`;
        const sigt = await Storage.get(fileName);
        setSignature(sigt);
        imgObject.push({ title: "HIPPA Consent", isHIPPA: true, imgSrc: sigt });

        const sig = await Storage.get(`${test.employeeID}&&${siteID}&&ins-f.jpeg`);
        if (sig) imgObject.push({ title: "Insurance Card Front", isHIPPA: false, imgSrc: sig });
        const sig1 = await Storage.get(`${test.employeeID}&&${siteID}&&ins-b.jpeg`);
        if (sig1) imgObject.push({ title: "Insurance Card Back", isHIPPA: false, imgSrc: sig1 });
      } catch (error) {
        console.log("Image Error", error);
      }

      if (imgObject.length > 0) {
        setShowImages(imgObject);
      }
      setHippaConsent(true);
    };

    setLoading(false);
  }, [test]);

  useEffect(() => {
    site && setSiteContactPhone(site.contact_phone);
  }, [site]);

  function calculate_age(dob) {
    const val = formatDateOfBirthDOB(dob);
    const years = moment().diff(val, "years", false);
    return years;
  }

  const captalizeFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatIDNo = () => {
    if (calculate_age(demographics?.dob) < 18) {
      const id = demographics?.idNumber;
      if (id && id.length > 5) {
        return `${id.substring(0, 3)}-${id.substring(3, 5)}-${id.substring(5)}`;
      }
      if (id && id.length > 3) {
        return `${id.substring(0, 3)}-${id.substring(3)}`;
      }
      return demographics?.idNumber;
    }
    return demographics?.idNumber;
  };

  const showSubmitButton = () => {
    if (!test) return true;
    const st = test.status.toLowerCase();
    if (st === "new") return true;
    if (st === "pending" && !test.result) return true;
    return false;
  };
  const ImageUpload = async (fileName, image) => {
    if (image instanceof Object) {
      await Storage.put(fileName, image);
      return fileName;
    } else {
      return "";
    }
  };
  const handleSave = async () => {
    let phoneNo = "";
    let countryCode = "";
    if (!Employee) return;
    if (!Employee.firstName) {
      setError("First name is required");
      return;
    }
    if (!isValidName(Employee.firstName)) {
      setError("First name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!Employee.lastName) {
      setError("Last name is required");
      return;
    }
    if (!isValidName(Employee.lastName)) {
      setError("Last name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!Employee.email) {
      setError("Email name is required");
      return;
    }
    if (!isValidEmail(Employee.email)) {
      setError("Invalid Email");
      return;
    }

    if (!Employee.phoneNumber) {
      setError("Phone name is required");
      return;
    }

    // FOR PHONE CHECK COUTRY CODE FOR INTERNATIONAL RECORD VERIFICATION
    const phone = formatPhoneNumberIntl(Employee.phoneNumber);
    const phoneArr = phone.split(" ");
    // check Country code exist

    if (phoneArr[0].includes("+")) {
      let [cc, pNo] = getPhoneNo(Employee.phoneNumber, phoneArr[0]);
      phoneNo = pNo;
      countryCode = cc;
    } else {
      phoneNo = Employee.phoneNumber;
      countryCode = Employee.countryCode ? Employee.countryCode : "+1";
    }

    if (phoneError || !isValidPhoneWithCode(`${countryCode}${phoneNo}`)) {
      setError("Invalid Phone Number");
      return;
    }
    if (!Employee.dob) {
      setError("DOB is required");
      return;
    }
    if (Employee.dob === "Invalid date") {
      setError("Invalid Date of Birth");
      return;
    }
    // if (!isValidZipCode(demographics.isoCode || "US", Employee.zip)) {
    //   setError("Invalid Postal Code");
    //   return;
    // }
    if (!Employee.idNumber) {
      setError(`${calculate_age(Employee.dob) < 18 ? "SSN" : "ID Number"} is required`);
      return;
    }
    if (!isValidIdNum(Employee.idNumber)) {
      setError(`Invalid ${calculate_age(Employee.dob) < 18 ? "SSN" : "ID Number"} must contains at least 5 characters`);
      return;
    }

    setLoading(true);
    setchangeDataCheck(false);
    const testModal = await API.getRecordById(test.id, "test");
    const testVersionNo = testModal[0]._version;
    const models = await API.getRecordById(test.employeeID, "employee");
    const versionNo = models[0]?._version || null;
    const fileName = `${Employee.mdID ? Employee.mdID : test.employeeID}/${test.employeeID}-${
      Employee.phoneNumber
    }-${moment().toISOString()}`;

    // console.log("vaccinationImage", vaccinationImage);
    // console.log("externalTestImage", externalTestImage);
    // Vacination Image
    let vaccinationFileName = "";
    if (typeof vaccinationImage === "object") {
      vaccinationFileName = await API.saveFileExternalStorage(
        phoneNo,
        test.employeeID,
        IMAGE_UPLOAD_TYPE.VacinationCard,
        vaccinationImage
      );
    }

    // External Test Image
    let externalImageFileName = "";
    if (typeof externalTestImage === "object") {
      externalImageFileName = await API.saveFileExternalStorage(
        phoneNo,
        test.employeeID,
        IMAGE_UPLOAD_TYPE.ExternalTest,
        externalTestImage
      );
    }

    const emp = {
      ...Employee,
      countryCode: countryCode,
      vaccinationCardImage: vaccinationFileName ? vaccinationFileName : Employee.vaccinationCardImage,
      externalTestImage: externalImageFileName ? externalImageFileName : Employee.externalTestImage,
      _version: versionNo,
      updatedBy: appContext.user.sub,
      updatedByName: appContext.user.name,
      phoneNumber: phoneNo,
    };

    if (Employee?.autoRelease) {
      const pendingTestList = appContext.pendingTests?.filter((f) => f.employeeID === test.employeeID);
      // console.log("pendingTestList", pendingTestList);
      const paramsData = [];
      pendingTestList.forEach((element) => {
        paramsData.push({
          id: element.id,
          status: "Processed",
          timerStatus: "Processed",
          sequenceNo: element.sequenceNo,
          invalidResultStatus: 1,
        });
      });
      try {
        await GQAPI.releaseTest(paramsData);
      } catch (err) {
        console.log("Error:-", err.message);
      }
    }
    try {
      const obj = { ...editInfo, employee_demographics: emp, _version: testVersionNo };
      const updateTest = await API.updateCompleteCrewInfo(obj);
      setDemographics(getDemoGraphics(updateTest));
      setEditInfo(updateTest);
      setTest(updateTest);
      setEmployee(getDemoGraphics(updateTest));
      setEditButton(true);
      switch (location?.state?.type) {
        case "pendingRelease":
          appContext.resetPendingTests();
          break;
        case "inconclusiveTest":
          appContext.resetInvalidTests();
          break;
      }
      appContext.showSuccessMessage("Test updated Successfully");
      setLoading(false);
    } catch (err) {
      console.log("Error in crew save", err);
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
    setTimeout(() => {
      setchangeDataCheck(true);
    }, 60000);
  };
  const handelCancelButton = async () => {
    setEditButton(true);
    if (!Employee) return;
    setEmployee(getDemoGraphics(editInfo));
    // const profile = await getFileFromStorage(Employee.profileImage, setProfileImage);

    let getExternalTestImage = await API.getFileExternalStorage(Employee.externalTestImage);
    if (!getExternalTestImage) setExternalTestImage("");
    let getVacinationCardImage = await API.getFileExternalStorage(Employee.vaccinationImage);
    if (!getVacinationCardImage) setVaccinationImage("");
  };
  const emailTest = (t) => {
    setLoading(true);
    const test = formatTest(t);
    appContext.getLab(test).then(async (resp) => {
      ReactPDF.pdf(<PDF test={test} testsLab={resp} parseTestResultForPDF={appContext.parseTestResultForPDF} />)
        .toBlob()
        .then(async (data) => {
          try {
            const fileName = `${test.id}-result.pdf`;
            await Storage.put(fileName, data);
            await API.sendEmailToCrew([{ email: test.email, fileName: `public/${fileName}` }]);
            await API.updateEmailStatus(test.id);
            // send sms as well if email is sending first time
            if (!test.emailSend && test.phoneNumber) {
              let smsMessage = `Hello, your test results are in for your test with ${test?.site_name}, please log into Med Flow to review.`;
              const siteObj = appContext.sites.find((s) => s.id === test.siteID);
              if (siteObj && siteObj.messageResulted && result === "negative") {
                smsMessage = siteObj.messageResulted;
              } else if (siteObj && siteObj.messagePositive && result === "positive") {
                smsMessage = siteObj.messagePositive;
              }
              await API.sendSMSNotification(setPhoneNo(test.phoneNumber), smsMessage);
            }
            setLoading(false);
            appContext.showSuccessMessage("Email has been sent successfully");
          } catch (error) {
            console.log("Error");
            setLoading(false);
          }
        });
    });
  };

  const isPatternTest = () =>
    test && test.test_type === "PCR" && test.patternTestAnswer && test.patternTestAnswer.length > 5;

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };
  const patternConsentFile = async () => {
    if (!test) return "";
    const siteID = test.baseSiteID || test.siteID;
    const fileName = `${siteID}-${test.employeeID}-pattern.pdf`;
    const result = await Storage.get(fileName, { download: true });
    downloadBlob(result.Body, `${test.barcode}-pattern.pdf`);
  };

  const dataOfBirthParse = (dob) => {
    if (dob && dob !== "Invalid date") {
      return formatDateOfBirthDOB(dob);
    }
    return moment().format("MM/DD/YYYY");
  };

  const handleCloseCreateTest = () => {
    setTestCreate(test);
    setOpenCreator(false);
  };

  const updateTest = async (updatedTestData) => {
    updatedTestData.id = test?.id;
    updatedTestData._version = test?._version;
    let testData = {
      ...updatedTestData,
      submittedByName: appContext?.user?.name,
      createdBy: appContext?.user?.sub,
    };

    const updateTest = await API.updateTestDataV1(testData);
    setLoading(false);

    if (updateTest) {
      appContext.showSuccessMessage("Test updated successfully");
    } else {
      appContext.showErrorMessage("Test can't be updated");
    }
  };

  const handleOpenCreateTest = () => {
    if (test) {
      let selectedUser = test;
      const emptyObjectKeys = Object.keys(TEST_EMPTY_OBJECT);
      let newObjValue = { ...TEST_EMPTY_OBJECT };
      for (const objKey of emptyObjectKeys) {
        if (objKey == "employee_demographics") {
          let demographicObj =
            typeof selectedUser[objKey] === "string" ? JSON.parse(selectedUser[objKey]) : selectedUser[objKey];
          newObjValue[objKey] = { ...demographicObj, phoneNumber: selectedUser["phoneNumber"] };
          if (selectedUser["dob"]) {
            newObjValue[objKey] = { ...demographicObj, dob: moment(selectedUser["dob"]).format("MMDDYYYY") };
          }
        } else {
          newObjValue[objKey] = selectedUser[objKey];
        }
      }
      setTestCreate(newObjValue);
    }
    setOpenCreator(true);
  };

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setEmployee({ ...Employee, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zipcode: val.zipcode,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setEmployee({ ...Employee, ...addressObj });
    }
  };

  return showPDF ? (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card style={{ padding: 20, marginLeft: "auto", marginRight: "auto" }} className="strpied-tabled-with-hover">
            <Card.Header>
              <Row style={{ alignItems: "center" }}>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bolder",
                    color: "#565656",
                  }}
                >
                  Requisition Form - {demographics?.firstName} {demographics?.lastName}
                </Card.Title>
                <Button style={{ marginLeft: "auto", marginRight: "auto" }} onClick={() => setShowPDF(false)}>
                  Close
                </Button>
              </Row>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <PDFViewer className="pdfWrapper">
                {/* <RequisitionPDF test={test} lab={lab} signature={signature} demographics={demographics} /> */}
                {/* <SitePDF site={pdf} client={client} /> */}
              </PDFViewer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : !loading ? (
    <>
      <Container fluid>
        {showAlert && <ShowAlertMessage message={"Test Released"} handleClose={() => hideAlert()} success />}
        {showSubmitAlert && (
          <ShowAlertMessage
            message={"Test Submitted Successfully."}
            handleClose={() => {
              window.history.back();
              setShowSubmitAlert(false);
            }}
            success
          />
        )}
        <Row className="crew-member-profile">
          <Col md="8">
            <Card className="member-profile-card">
              <Card.Header className="d-flex align-items-center justify-content-between">
                <Card.Title as="h4" className="mb-0">
                  Crew Member Profile
                  {calculate_age(demographics?.dob) < 18 ? <span className="crew-minor-text">- Minor</span> : null}
                </Card.Title>
              </Card.Header>
              <Card.Body className="pt-0 pb-3">
                <Form className="crew-member-profile">
                  {/* <Row>
                    <Col className="pr-1" md="5">
                      <Form.Group>
                        <label>Company (disabled)</label>
                        <Form.Control
                          defaultValue="Creative Code Inc."
                          disabled
                          placeholder="Company"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="3">
                      <Form.Group>
                        <label>Username</label>
                        <Form.Control
                          defaultValue="michael23"
                          placeholder="Username"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Form.Control
                          placeholder="Email"
                          type="email"
                        />
                      </Form.Group>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col md="6">
                      <figure className="profile-image-wrapper">
                        <div className="">
                          <img src={profileImage} alt="profile-image" />
                        </div>
                        <figcaption>
                          <h6 className="mb-1">{`${Employee?.firstName} ${Employee?.lastName}`}</h6>
                          <p className="mb-0">ID Number: {Employee?.idNumber}</p>
                          <small className="mb-0">{Employee?.email}</small>
                        </figcaption>
                      </figure>
                      <div className="testAdmin createClientsWrapper test-view-profile">
                        <div className="first-last-name-wrapper">
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">First Name</label>
                            <Form.Control
                              readOnly={editButton}
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setEmployee({ ...Employee, firstName: e.target.value })}
                              value={Employee?.firstName}
                              defaultValue={demographics?.firstName}
                              placeholder={`${!editButton ? "Enter First Name " : ""}`}
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Last Name</label>
                            <Form.Control
                              readOnly={editButton}
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setEmployee({ ...Employee, lastName: e.target.value })}
                              value={Employee?.lastName}
                              defaultValue={demographics?.lastName}
                              placeholder={`${!editButton ? "Enter Last Name " : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </div>
                        <div className="first-last-name-wrapper">
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Date of Birth</label>
                            {editButton ? (
                              <Form.Control
                                readOnly={editButton}
                                value={formatDateOfBirthDOB(Employee?.dob)}
                                defaultValue={formatDateOfBirthDOB(demographics?.dob)}
                                placeholder="Date of Birth"
                                type="text"
                              />
                            ) : (
                              <div>
                                <SingleDatePicker
                                  onApply={(event, picker) => {
                                    setEmployee({ ...Employee, dob: moment(picker.startDate).format("YYYY-MM-DD") });
                                  }}
                                  value={dataOfBirthParse(Employee?.dob)}
                                  startDate={dataOfBirthParse(Employee?.dob)}
                                />
                              </div>
                            )}
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Phone Number</label>
                            {editButton ? (
                              <Form.Control
                                className={`${!editButton ? "modalInput underlinedText" : "underlinedText"}`}
                                onMouseOver={(e) => {
                                  editButton ? (e.target.style.cursor = "pointer") : (e.target.style.cursor = "auto");
                                  e.target.style.textDecoration = "underline";
                                }}
                                onClick={() =>
                                  editButton
                                    ? Employee?.phoneNumber && window.open(`tel:+1${Employee.phoneNumber}`)
                                    : false
                                }
                                onChange={(e) => setEmployee({ ...Employee, phoneNumber: setPhoneNo(e.target.value) })}
                                readOnly
                                maxLength={15}
                                value={formatPhoneNumber(Employee?.phoneNumber, Employee?.countryCode || "+1")}
                                defaultValue={formatPhoneNumber(Employee?.phoneNumber, Employee?.countryCode || "+1")}
                                placeholder={`${!editButton ? "Enter Phone Number " : ""}`}
                                type="text"
                              />
                            ) : (
                              <PhoneNoInput
                                error={setPhoneError}
                                handleChange={(e) => {
                                  setEmployee({ ...Employee, phoneNumber: e });
                                }}
                                value={formatPhoneNumber(Employee?.phoneNumber, Employee?.countryCode || "+1")}
                                cssCode={"crewPhoneInput w-100"}
                              />
                            )}
                          </Form.Group>
                        </div>
                        <Form.Group className="form-group-wrapper">
                          <label className="modalLineHeaders mt-0 text-capitalize">Email Address</label>
                          <Form.Control
                            className={`${!editButton ? "modalInput underlinedText" : "underlinedText"}`}
                            onMouseOver={(e) => {
                              editButton ? (e.target.style.cursor = "pointer") : (e.target.style.cursor = "auto");
                              e.target.style.textDecoration = "underline";
                            }}
                            onClick={() =>
                              editButton ? Employee?.email && window.open(`mailto:${Employee.email}`) : false
                            }
                            onChange={(e) => setEmployee({ ...Employee, email: e.target.value })}
                            value={Employee?.email}
                            readOnly={editButton}
                            defaultValue={test?.email}
                            placeholder={`${!editButton ? "Enter Email " : ""}`}
                            type="email"
                          />
                        </Form.Group>
                        <Form.Group className="form-group-wrapper">
                          <label className="mt-0 modalLineHeaders text-capitalize">Address</label>
                          {/* <Form.Control
                            className={`${!editButton ? "modalInput" : ""}`}
                            onChange={(e) => setEmployee({ ...Employee, street: e.target.value })}
                            readOnly={editButton}
                            value={Employee?.street}
                            defaultValue={demographics?.street}
                            placeholder={`${!editButton ? "Enter Address  " : ""}`}
                            type="text"
                          /> */}
                          <AutoCompleteAddress
                            readOnly={editButton}
                            value={Employee?.street}
                            handleChange={handleUpdateAddress}
                          />
                        </Form.Group>
                        <Form.Group className="form-group-wrapper">
                          <label className="mt-0 modalLineHeaders text-capitalize">Address 2</label>
                          <Form.Control
                            className={`${!editButton ? "modalInput" : ""}`}
                            readOnly={editButton}
                            onChange={(e) => setEmployee({ ...Employee, street2: e.target.value })}
                            value={Employee?.street2}
                            defaultValue={demographics?.street2}
                            placeholder={`${!editButton ? "Enter Address 2 " : ""}`}
                            type="text"
                          />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md="6" className="second-column-pt">
                      <div className="testAdmin createClientsWrapper test-view-profile">
                        <div className="first-last-name-wrapper">
                          <div className="form-group-wrapper">
                            <label className="text-capitalize mt-0 modalLineHeaders">Auto Release</label> <br />
                            {editButton ? (
                              <span className="vaccination-status pl-3">{Employee?.autoRelease ? "Yes" : "No"}</span>
                            ) : (
                              <div className="form-group-wrapper d-flex flex-basis-1 max-w-100 pl-3">
                                <Form.Check>
                                  <Form.Check.Input
                                    type="checkbox"
                                    value="1"
                                    checked={Employee?.autoRelease}
                                    onChange={(e) =>
                                      setEmployee({ ...Employee, autoRelease: e.target.checked && true })
                                    }
                                  ></Form.Check.Input>
                                </Form.Check>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="first-last-name-wrapper mt-4">
                          <Form.Group className="form-group-wrapper">
                            <label className="mt-0 modalLineHeaders text-capitalize">City</label>
                            <Form.Control
                              readOnly={editButton}
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setEmployee({ ...Employee, city: e.target.value })}
                              value={Employee?.city}
                              defaultValue={demographics?.city}
                              placeholder={`${!editButton ? "Enter City" : ""}`}
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">State</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              onChange={(e) => setEmployee({ ...Employee, state: e.target.value })}
                              value={Employee?.state}
                              defaultValue={demographics?.state}
                              maxLength={35}
                              placeholder={`${!editButton ? "Enter Country" : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </div>
                        <div className="first-last-name-wrapper">
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Postal Code</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setEmployee({ ...Employee, zip: e.target.value })}
                              readOnly={editButton}
                              value={Employee?.isoCode === "US" ? formatZip(Employee?.zip) : Employee?.zip}
                              defaultValue={Employee?.isoCode === "US" ? formatZip(Employee?.zip) : Employee?.zip}
                              placeholder={`${!editButton ? "Enter Postal Code " : ""}`}
                              maxLength={10}
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Insurance Carrier</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              onChange={(e) => setEmployee({ ...Employee, insurance_name: e.target.value })}
                              value={Employee?.insurance_name}
                              defaultValue={demographics?.insurance_name}
                              placeholder={`${!editButton ? "Enter Insurance Carrier" : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </div>
                        <div className="first-last-name-wrapper">
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Insurance ID</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              onChange={(e) => setEmployee({ ...Employee, insurance_number: e.target.value })}
                              value={Employee?.insurance_number}
                              defaultValue={demographics?.insurance_number}
                              placeholder={`${!editButton ? "Enter Insurance Id" : ""}`}
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper" readOnly={editButton} plaintext>
                            {calculate_age(demographics?.dob) > 18 ? (
                              <label className="modalLineHeaders mt-0 text-capitalize">ID Number</label>
                            ) : (
                              <label>SSN</label>
                            )}
                            <Form.Control
                              readOnly={editButton}
                              className={`${!editButton ? "modalInput" : ""}`}
                              value={Employee?.idNumber}
                              onChange={(e) => setEmployee({ ...Employee, idNumber: e.target.value })}
                              defaultValue={formatIDNo()}
                              placeholder="ID Number"
                              type="text"
                            />
                          </Form.Group>
                        </div>
                        <div className="first-last-name-wrapper">
                          <div className="form-group-wrapper">
                            <label className="text-capitalize mt-0 modalLineHeaders">Vaccination Status</label> <br />
                            {editButton ? (
                              <span className="vaccination-status pl-3">{Employee?.isVaccinated ? "Yes" : "No"}</span>
                            ) : (
                              <div className="form-group-wrapper d-flex flex-basis-1 max-w-100 pl-3">
                                <div className="radioBox-wrapper">
                                  <input
                                    className="radioButton"
                                    type="radio"
                                    defaultChecked={Employee?.isVaccinated}
                                    name="isVaccinated"
                                    onChange={(e) =>
                                      setEmployee({ ...Employee, isVaccinated: e.target.checked && true })
                                    }
                                  />
                                  <span className="radioButton">Yes</span>
                                </div>
                                <div className="radioBox-wrapper">
                                  <input
                                    className="radioButton"
                                    type="radio"
                                    defaultChecked={!Employee?.isVaccinated}
                                    name="isVaccinated"
                                    onChange={(e) =>
                                      setEmployee({ ...Employee, isVaccinated: e.target.checked && false })
                                    }
                                  />
                                  <span className="radioButton">No</span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="form-group-wrapper">
                            <label className="text-capitalize mt-0 modalLineHeaders">HIPAA Consent</label> <br />
                            <span className="vaccination-status pl-3">{hippaConsent ? "True" : "False"}</span>
                          </div>
                        </div>
                        <div className="first-last-name-wrapper">
                          <div className="form-group-wrapper">
                            <label className="text-capitalize mt-0 modalLineHeaders">White Glove</label> <br />
                            <span className="vaccination-status pl-3">{Employee?.whiteGlove ? "True" : "False"}</span>
                          </div>
                          <div className="form-group-wrapper">
                            <label className="text-capitalize mt-0 modalLineHeaders">Tester Designation</label> <br />
                            <span className="vaccination-status pl-3">
                              {Employee?.employeeType ? Employee?.employeeType : ""}
                            </span>
                          </div>
                        </div>
                        <div className="first-last-name-wrapper">
                          {allowToEditQuarantine && (
                            <>
                              <div className="form-group-wrapper">
                                <label className="text-capitalize mt-0 modalLineHeaders">Quarantined Start</label>{" "}
                                <br />
                                <span className="vaccination-status pl-3">
                                  {formatQuaratineDisplayDate(test?.quarantinedStart)}
                                </span>
                              </div>
                              <div className="form-group-wrapper">
                                <label className="text-capitalize mt-0 modalLineHeaders">Quarantined End</label> <br />
                                <span className="vaccination-status pl-3">
                                  {formatQuaratineDisplayDate(test?.quarantinedEnd)}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                        {appContext.user["custom:role"] === "Admins" && (
                          <>
                            <div className="first-last-name-wrapper">
                              <div className="form-group-wrapper">
                                <label className="text-capitalize mt-0 modalLineHeaders">Pattern Testing</label> <br />
                                <span className="vaccination-status pl-3">{isPatternTest() ? "True" : "False"}</span>
                              </div>
                            </div>
                            {isPatternTest() && (
                              <div className="form-group-wrapper">
                                <label className="vaccination-status-name">Pattern Consent</label> <br />
                                <span
                                  className="vaccination-status linkedText"
                                  onClick={() => {
                                    patternConsentFile();
                                  }}
                                >
                                  Download
                                </span>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="testAdmin createClientsWrapper">
                      <Form.Group className="form-group-wrapper">
                        <label className="mt-0 modalLineHeaders text-capitalize">Notes</label>
                        <textarea
                          defaultValue=""
                          placeholder=""
                          className="w-100 textAreaWrapper"
                          rows="5"
                          readOnly={editButton}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="text-right w-100">
                    {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
                    {location.state?.pendingRelease && test?.result !== "negative" && (
                      <Button
                        className="profileButton btn-fill createButtons"
                        variant="primary"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        onClick={async () => {
                          // const result = await API.releaseTest(test);
                          // if (result) {
                          //   setShowAlert(true);
                          // }
                          setModalShow(true);
                        }}
                      >
                        Release Test Results
                      </Button>
                    )}
                    {/* {location?.state?.pendingRelease && (
                      <>
                        <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="secondary"
                          onClick={async () => {
                            setLoading(true);
                            if (test?.result.toLowerCase() === "positive") {
                              const result = await API.sendSMSNotification(
                                test.phoneNumber,
                                "Hello, can you please report back to your testing site for further information regarding your test."
                              );
                              if (result) {
                                appContext.showSuccessMessage(
                                  "The crew member has been sent a text message to be retested."
                                );
                              }
                              setLoading(false);
                            } else {
                              const params = [
                                {
                                  id: test.id,
                                  phoneNumber: test.phoneNumber,
                                  email: test.email,
                                  test_type: TEST_TYPE_VALUE[test.test_type],
                                  site_name: test.site_name,
                                  firstName: Employee.firstName,
                                  siteID: test.siteID,
                                  lastName: Employee.lastName,
                                  userName: appContext.user.name,
                                  type: "invalid",
                                },
                              ];
                              try {
                                await GQAPI.sendSmsToCrews(params);
                                appContext.showSuccessMessage(
                                  "The crew member has been sent a message to be retested."
                                );
                                setLoading(false);
                              } catch (err) {
                                console.log(err.message);
                                appContext.showErrorMessage(err.message);
                                setLoading(false);
                              }
                            }
                          }}
                        >
                          Retest
                        </Button>
                        <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="primary"
                          onClick={async () => setShowPDF(true)}
                        >
                          Requisition Form
                        </Button>
                      </>
                    )} */}
                    {location?.state?.unprocessed && (
                      <>
                        {showSubmitButton() && (
                          <Button
                            className="profileButton btn-fill createButtons"
                            style={{
                              marginRight: 10,
                              marginTop: 10,
                            }}
                            variant="primary"
                            onClick={async () => {
                              await API.submitUnprocessedTests(
                                [test],
                                appContext.user,
                                window.location.href.includes("portal.safecamp") ||
                                  window.location.href.includes("staging.safecamp") ||
                                  window.location.href.includes("medflow")
                                  ? "LIVE"
                                  : "DEV"
                              );
                              setShowSubmitAlert(true);
                              await appContext.resetUnprocessedTests();
                            }}
                          >
                            Submit Test
                          </Button>
                        )}

                        {/* <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="secondary"
                          onClick={async () => setShowPDF(true)}
                        >
                          Requisition Form
                        </Button> */}
                      </>
                    )}
                    {/* {location.state?.labPortal && (
                      <Button
                        className="profileButton btn-fill createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        variant="primary"
                        onClick={async () => setShowPDF(true)}
                      >
                        Requisition Form
                      </Button>
                    )} */}
                    {/* {location?.state?.admin && (
                      <Button
                        className="profileButton btn-fill createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        variant="secondary"
                        onClick={async () => setShowPDF(true)}
                      >
                        Requisition Form
                      </Button>
                    )} */}
                    {/* {test?.result && (
                      <Button
                        className="profileButton btn-fill createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        variant="primary"
                        onClick={async () => emailTest(test)}
                      >
                        Resend Results
                      </Button>
                    )} */}

                    {(test?.result === "Positive" || test?.result === "Fail") && allowToEditQuarantine && (
                      <Button
                        className="profileButton btn-fill createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        variant="secondary"
                        onClick={() => setOpenQuaratined(true)}
                      >
                        Quarantined
                      </Button>
                    )}
                    {/* 
                    {editButton && (
                      <Button
                        className="profileButton btn-fill createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        variant="primary"
                        onClick={() => setEditButton(false)}
                      >
                        Edit Info
                      </Button>
                    )} */}
                    {!editButton && (
                      <Button
                        className="profileButton btn-fill createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        variant="secondary"
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    )}
                    {!editButton && (
                      <Button
                        className="profileButton btn-fill createButtons"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                        }}
                        variant="primary"
                        onClick={handelCancelButton}
                      >
                        Cancel
                      </Button>
                    )}
                    {/* <Button
                      className="profileButton btn-fill createButtons"
                      style={{
                        marginRight: 10,
                        marginTop: 10,
                      }}
                      variant="primary"
                      onClick={() => handleClose(false)}
                    >
                      Back
                    </Button> */}
                  </div>
                  <div className="clearfix" />
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md="4">
            <Card className="test-details-wrapper">
              <Card.Header
                className="test-details-header"
                as="h5"
                style={{
                  fontWeight: "bolder",
                  color: "#565656",
                }}
              >
                Test Details
                {(appContext?.user?.phone_number === "+18888888888" ||
                  appContext?.user?.phone_number === "+12222222222") &&
                  window.location.pathname === "/admin" && (
                    <span style={{ float: "right" }}>
                      {test?.createdBy && (
                        <div
                          className="edit-Button"
                          style={{ cursor: "pointer" }}
                          // onClick={() => setOpenTestDetail(true)}
                          onClick={() => handleOpenCreateTest()}
                        >
                          <img src={editIcon} alt="edit-icon" className="w-75" style={{ color: "red" }} />
                        </div>
                      )}
                    </span>
                  )}
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="12">
                    <Card.Text>
                      <span>Barcode:</span> {test?.barcode}
                    </Card.Text>
                  </Col>
                  <Col md="12">
                    <Card.Text>
                      <span>Type:</span> {test?.test_type}
                    </Card.Text>
                  </Col>
                  <Col md="12">
                    <Card.Text>
                      <span>Date Collected:</span> {moment(test?.createdAt).format("llll")}
                    </Card.Text>
                  </Col>
                  <Col md="12">
                    {test?.result && (
                      <Card.Text>
                        <span>Date Processed:</span> {moment(test?.updatedAt).format("llll")}
                      </Card.Text>
                    )}
                  </Col>
                  <Col md="12">
                    <Card.Text>
                      <span>Result Date:</span>{" "}
                      {test?.result
                        ? test?.resultDateTime
                          ? moment(test?.resultDateTime).format("llll")
                          : test?.resultDate
                          ? moment(test?.resultDate).format("llll")
                          : moment(test?.updatedAt).format("llll")
                        : ""}
                    </Card.Text>
                  </Col>
                  <Col md="12">
                    <Card.Text>
                      <span>Resulted By:</span> {test?.StampByName || "N/A"}
                    </Card.Text>
                  </Col>
                  <Col md="12">
                    <Card.Text>
                      <span>Tester:</span> {test?.tester_name}
                    </Card.Text>
                  </Col>
                  {test?.scanByTesterName && (
                    <Col md="12">
                      <Card.Text>
                        <span>Scan by Tester:</span> {test?.scanByTesterName}
                      </Card.Text>
                    </Col>
                  )}
                  {test?.scanByLabUserName && (
                    <Col md="12">
                      <Card.Text>
                        <span>Scan by Lab:</span> {test?.scanByLabUserName}
                      </Card.Text>
                    </Col>
                  )}
                </Row>
              </Card.Body>
              <Card.Footer className="test-details-footer">
                <ul>
                  <li>
                    <Card.Text>
                      <span className="d-block">Show:</span> {test?.site_name}
                    </Card.Text>
                  </li>
                  <li>
                    <Card.Text>
                      <span className="d-block">Result:</span> {test?.result}
                    </Card.Text>
                  </li>
                </ul>
              </Card.Footer>
            </Card>
            {showImages && (
              <Card className="test-details-wrapper">
                <Card.Header
                  className="test-details-header"
                  as="h5"
                  style={{
                    fontWeight: "bolder",
                    color: "#565656",
                  }}
                >
                  <label>HIPPA</label> Signature
                </Card.Header>
                <Card.Body className="imageWrapper">
                  {showImages
                    .filter((i) => i.isHIPPA === true)
                    .map((showImg, index) => {
                      return <ImageView key={index} imgSrc={showImg.imgSrc} />;
                    })}
                </Card.Body>
              </Card>
            )}
            {showImages && (
              <Card className="test-details-wrapper">
                <Card.Header
                  className="test-details-header"
                  as="h5"
                  style={{
                    fontWeight: "bolder",
                    color: "#565656",
                  }}
                >
                  <label>Insurance</label> Card Images
                </Card.Header>
                <Card.Body className="imageWrapper">
                  {showImages
                    .filter((i) => i.isHIPPA === false)
                    .map((showImg, index) => {
                      return (
                        <ImageView
                          key={index}
                          imgSrc={showImg.imgSrc}
                          onClick={() => handleImageClick(showImg.imgSrc)}
                        />
                      );
                    })}
                </Card.Body>
              </Card>
            )}

            {/* <Card className="test-details-wrapper">
              <Card.Header
                className="test-details-header"
                as="h5"
                style={{
                  fontWeight: "bolder",
                  color: "#565656",
                }}
              >
                <label>Vaccination</label> Card Image
              </Card.Header>
              <Card.Body className="imageWrapper">
                {!editButton ? (
                  <ImageInput
                    cssClass="vaccinationField"
                    setSelectedImage={setVaccinationImage}
                    selectedImage={vaccinationImage}
                  />
                ) : (
                  <div className="form-group-wrapper">
                    {vaccinationImage && <ImageView imgSrc={vaccinationImage} crewProfile={true} />}
                  </div>
                )}
              </Card.Body>
            </Card> */}

            {/* <Card className="test-details-wrapper">
              <Card.Header
                className="test-details-header"
                as="h5"
                style={{
                  fontWeight: "bolder",
                  color: "#565656",
                }}
              >
                <label>External</label> Test Result
              </Card.Header>
              <Card.Body className="imageWrapper">
                {!editButton ? (
                  <ImageInput
                    cssClass="vaccinationField"
                    setSelectedImage={setExternalTestImage}
                    selectedImage={externalTestImage}
                  />
                ) : (
                  <div className="form-group-wrapper">
                    {externalTestImage && <ImageView imgSrc={externalTestImage} crewProfile={true} />}
                  </div>
                )}
              </Card.Body>
            </Card> */}
          </Col>
        </Row>
      </Container>
      <Modal
        show={modalShow}
        // style={{ transform: "translate(0, -30%)" }}
        animation={true}
        onHide={() => setModalShow(false)}
        centered
        size={"md"}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Release Test Results</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
          <p>Select all that apply: </p>
          <div className="checkboxRow">
            <label className="checkboxLabel">
              <input
                disabled={!siteContactPhone}
                value="site"
                onChange={(e) => {
                  if (e.target.checked) {
                    setReleaseTo({
                      ...releaseTo,
                      [e.target.value]: siteContactPhone,
                    });
                  } else {
                    const newReleaseTo = releaseTo;
                    delete newReleaseTo[e.target.value];
                    setReleaseTo(newReleaseTo);
                  }
                }}
                className="releaseCheckbox"
                type="checkbox"
              />
              Health and Safety Officer
            </label>
            <label>
              <input
                value="crew"
                onChange={(e) => {
                  if (e.target.checked) {
                    setReleaseTo({
                      ...releaseTo,
                      [e.target.value]: setPhoneNo(test?.phoneNumber),
                    });
                  } else {
                    const newReleaseTo = releaseTo;
                    delete newReleaseTo[e.target.value];
                    setReleaseTo(newReleaseTo);
                  }
                }}
                className="releaseCheckbox"
                type="checkbox"
              />
              Crew Member
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button className="modalButtons" variant="secondary" onClick={() => setModalShow(false)}>
            Cancel
          </Button>
          <Button
            className="modalButtons"
            variant="primary"
            onClick={async () => {
              if (releaseTo.length === 0) return;
              setLoading(true);
              const params = [];
              params.push({
                id: test.id,
                status: "Processed",
                timerStatus: "Processed",
                sequenceNo: test.sequenceNo,
                invalidResultStatus: 1,
              });
              const ids = [{ id: test.id, sequenceNo: test.sequenceNo }];
              await GQAPI.releaseTest(params);
              if (releaseTo.crew) {
                await GQAPI.sendEmailToPendingRelease(params);
              }

              const logs = {
                ids: ids,
                userID: appContext.user?.sub,
                userName: appContext.user?.name,
                slug: test.result.toLowerCase() !== "positive" ? "Inconclusive Release" : "Pending Release",
              };

              await GQAPI.addTestLogs(logs);

              releaseTo.site &&
                (await API.sendSMSNotification(
                  releaseTo.site,
                  `Hello, there was a positive (${TEST_TYPE_VALUE[test.test_type]}) test result for ${
                    site.name
                  }. please login to your Health & Safety Portal to review.`
                ));

              await appContext.resetPendingTests([test.id]);
              props.history.push("/admin/pendingrelease");
              appContext.showSuccessMessage(`Success! You have released test`);
              setLoading(false);
            }}
          >
            Release Results
          </Button>
        </Modal.Footer>
      </Modal>

      {/* {openTestDetail && (
        // <EditTestDetailsModal
        //   testDetails={{
        //     ...test,
        //     createdAt: moment(test.createdAt).format("YYYY-MM-DDTHH:mm"),
        //     employee_demographics: getDemoGraphics(test),
        //   }}
        //   users={users}
        //   handleSave={handleSaveTestDetail}
        //   handleClose={() => handleCloseTestDetail(false)}
        // />
      )} */}
      {/* 
      {openCreator && (
        <TestModal
          newTestCreate={newTestCreate}
          handleCloseCreateTest={handleCloseCreateTest}
          createTest={updateTest}
          setTestCreate={setTestCreate}
          clients={appContext.clients}
          sites={appContext.sites}
          labs={appContext.labs}
          isNew={false}
          users={users}
        />
      )} */}

      {openDataChangeConfirmation && (
        <ConfirmationModal
          show={openDataChangeConfirmation}
          title="Test Update Alert"
          message={`New Changes occures, are you want to update your edit info!`}
          handleConfirm={handleDataChangeConfirm}
        />
      )}
    </>
  ) : (
    <Loader />
  );
}

export default TestView;
