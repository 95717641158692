import React, { useState } from "react";
import logoImage from "../../assets/img/safecamp_BRAND.1-nobg.png";
import { Modal, Button, Card, Table } from "react-bootstrap";
import { formatDateMDY } from "utils";
const LoginProfileSelection = (props) => {
  const { employees, handleSelection } = props;
  const [error, setError] = useState("");
  const [selectedEmp, setSelectedEmp] = useState(null);

  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };

  const handleSubmit = () => {
    if (!selectedEmp) {
      showErrorMessage("Select the user");
      return;
    }
    handleSelection(selectedEmp);
  };

  const onChangeSelection = (e) => {
    setSelectedEmp(e);
  };

  const TableRow = (props) => {
    const { emp, index } = props;
    return (
      <tr>
        <td className="ellipsis" style={{ textAlign: "left" }}>
          <input
            id={index}
            className="radio-custom"
            name="radio-group"
            type="radio"
            checked={emp.id === selectedEmp?.id}
            value={emp.id}
            onChange={() => onChangeSelection(emp)}
          />
        </td>
        <td>{emp.firstName}</td>
        <td>{emp.lastName}</td>
        <td>{emp.email}</td>
        <td>{formatDateMDY(emp.dob)}</td>
      </tr>
    );
  };

  return (
    <Modal show animation={true} size="lg" style={{ paddingLeft: "0" }} centered keyboard={true}>
      <Modal.Header>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Profile Selection
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          paddingTop: 0,
        }}
      >
        <div className="profileFormWrapper">
          <div className="registerWrapper">
            <div className="text-center">
              <img src={logoImage} alt="logo" className="logo-width " />
            </div>
          </div>
        </div>
        <Card className="strpied-tabled-with-hover">
          <Card.Body className="table-full-width desktop-noScroll">
            <div
              className={
                employees.length > 0
                  ? "table-responsive pendingReleaseTable"
                  : "table-responsive pendingReleaseTable overFlow-y-hidden"
              }
            >
              <Table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>DOB</th>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((emp, index) => (
                    <TableRow key={index} emp={emp} />
                  ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>

        {/* {employees.map((emp, index) => {
            return (
              <div key={index} className="d-flex w-100 align-items-center justify-content-between">
                <input
                  id={index}
                  className="radio-custom"
                  name="radio-group"
                  type="radio"
                  value={emp.id}
                  onChange={() => onChangeSelection(emp)}
                />
                <label htmlFor={index} className="radio-custom-label">
                  <div className="d-inline-flex flex-column align-items-start">{emp.firstName}</div>
                </label>
                <label htmlFor={index} className="radio-custom-label">
                  <div className="d-inline-flex flex-column align-items-start">{emp.lastName}</div>
                </label>
                <label htmlFor={index} className="radio-custom-label">
                  <div className="d-inline-flex flex-column align-items-start">{emp.email}</div>
                </label>
                <label htmlFor={index} className="radio-custom-label">
                  <div className="d-inline-flex flex-column align-items-start">{emp.dob}</div>
                </label>
              </div>
            );
          })} */}

        {error && (
          <div className="error-message">
            <h6>{error}</h6>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="headerButton btn-fill w-100" onClick={handleSubmit}>
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginProfileSelection;
