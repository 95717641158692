import React, { useState, useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { AppContext } from "context/app-context";
import { phoneFormatter, isValidPhone } from "../../utils";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import api from "api";

const UserPasswordModal = (props) => {
  const { user, handleClose } = props;
  const [newUser, setNewUser] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setNewUser(user);
  }, [user]);

  const appContext = useContext(AppContext);

  const isValidPasswordLength = (value) => {
    const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(value);
    return isValidPassword;
  };

  const onHandleSave = async () => {
    if (!password) {
      setError("Password is requried");
      return;
    }

    if (!isValidPasswordLength(password)) {
      setError(
        "Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
      );
      return;
    }

    if (!newPassword) {
      setError("Password is requried");
      return;
    }

    if (!isValidPasswordLength(newPassword)) {
      setError(
        "New Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
      );
      return;
    }

    if (password !== newPassword) {
      setError("Password did not match");
      return;
    }

    setLoading(true);
    console.log("New User", newUser);
    try {
      await api.updateUserPassword(newUser.username, password);
      setLoading(false);
      appContext.showSuccessMessage("Password reset successfully");
      handleClose();
    } catch (error) {
      console.log("ERROR:User-", error);
      appContext.showErrorMessage(error.message);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => {
          setNewUser("");
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Set Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
           <div className="row">
            <div className="col-sm-12 createClientsWrapper">
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">First Name: </label>
            <input
              value={newUser.firstName}
              className="modalInput w-100"
              placeholder="First Name"
              readOnly
            />
          </div>
          </div>
            <div className="col-sm-12 createClientsWrapper">
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Last Name: </label>
            <input value={newUser.lastName} className="modalInput w-100" placeholder="Last Name" readOnly />
          </div>
          </div>
          <div className="col-sm-12 createClientsWrapper">
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Password: </label>

            <input
              type="password"
              className="modalInput w-100"
              value={newUser.password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          </div>
          <div className="col-sm-12 createClientsWrapper">
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Confirm Password: </label>

            <input
              type="password"
              className="modalInput w-100"
              value={newUser.password}
              placeholder="New Password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          </div>
          </div>
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button style={{ marginBottom: 10 }} variant="secondary" className="modalButtons headerButton btn-fill" onClick={onHandleSave}>
            Reset Password
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserPasswordModal;
