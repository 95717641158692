import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const Message = (props) => {
  const { showAlert, title, type, message, handleConfirm, handleClose } = props;

  const handleOnConfirm = () => {
    handleClose(false);
    if (handleConfirm) handleConfirm();
  };

  return (
    <SweetAlert show={showAlert} type={type} title={title ?? "Info"} onConfirm={handleOnConfirm}>
      {message}
    </SweetAlert>
  );
};

export default Message;
