import api from "api";
import Status from "components/Status/Status";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { formatCallTime } from "utils";

const CallTimeCalendarView = (props) => {
  const { setError, editable, handleUpdateDates, updated } = props;
  const [events, setEvents] = useState([]);
  const [prevEvents, setPrevEvents] = useState([]);
  const localizer = momentLocalizer(moment);

  useEffect(() => {
    if (updated) setEvents([]);
    fetchCustomCallTime();
  }, [updated]);

  useEffect(() => {
    if (events.length > 0) handleSaveDates();
  }, [events]);

  const fetchCustomCallTime = async () => {
    const customeCallTimeData = await api.fetchAllCustomCallTime();
    const previousData = [];
    customeCallTimeData.forEach((data) => {
      previousData.push({ gctDate: data.gctDate, gctTime: data.gctTime });
    });
    setPrevEvents(previousData);
  };

  const handleSaveDates = () => {
    const datesToSave = events.map((d) => d.start) || [];
    const customCallTimeExist = prevEvents.some((e) => datesToSave.includes(e.gctDate));
    handleUpdateDates(datesToSave, customCallTimeExist);
  };

  const handleSelectSlot = (event) => {
    if (editable) {
      const startDate = moment(event.start).format("YYYY-MM-DD");
      const endDate = moment(event.end).format("YYYY-MM-DD");

      if (event.slots?.length > 1) {
        if (moment().startOf("day").isAfter(endDate)) {
          setError("Past date is not acceptable!");
          return;
        }

        const dates = event.slots.map((d) => moment(d).format("YYYY-MM-DD"));
        let temp = [...events];
        dates.forEach((date) => {
          if (moment().startOf("day").isSameOrBefore(date)) {
            const findVal = temp.findIndex((f) => f.start === moment(date).format("YYYY-MM-DD"));

            if (findVal === -1) {
              temp.push({
                start: moment(date).format("YYYY-MM-DD"),
                end: moment(date).add(1, "day").format("YYYY-MM-DD"),
              });
            }
          }
        });

        // if (temp.length > 0 && JSON.stringify(temp) !== JSON.stringify(events))
        setEvents(temp);
      } else {
        if (moment().startOf("day").isAfter(startDate)) {
          setError("Past date is not acceptable!");
          return;
        }
        const findVal = events.findIndex((f) => moment(f.start).format("YYYY-MM-DD") === startDate);
        if (findVal === -1) {
          setEvents((prev) => [
            ...prev,
            {
              start: startDate,
              end: moment(event.end).format("YYYY-MM-DD"),
            },
          ]);
        } else {
          const arrObj = [...events];
          arrObj.splice(findVal, 1);
          setEvents(arrObj);
        }
      }
    }
  };

  const customDateHeader = (props) => {
    const dateFormat = moment(props.date).format("YYYY-MM-DD");
    const selected = prevEvents.find((event) => event.gctDate === dateFormat);
    return (
      <div className={`custom-date-header d-flex text-center flex-column`}>
        <div className="mx-1 fw-bold">{props.label}</div>
        {selected?.gctTime && <div className="mx-1 fw-light">{formatCallTime(selected.gctTime)}</div>}
      </div>
    );
  };

  return (
    <>
      <div className="my-4 custom-schedule-calendar">
        <Calendar
          events={events.map((m) => {
            return {
              ...m,
              title: (
                <div className="star-icon-wrapper mt-0" title={m.start}>
                  <Status type="circle" size="md" color="green" crossIcon />
                </div>
              ),
            };
          })}
          startAccessor="start"
          endAccessor="end"
          defaultDate={moment().toDate()}
          localizer={localizer}
          views={["month"]}
          selectable
          components={{
            dateHeader: customDateHeader,
          }}
          //   dayPropGetter={calendarStyle}
          // onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          popup={true}
          eventPropGetter={(event, start, end, isSelected) => ({
            event,
            start,
            end,
            isSelected,
            style: { backgroundColor: `transparent`, width: "fit-content" },
          })}
          onShowMore={(event, date) => console.log("onShowMore", event, date)}
        />
      </div>
    </>
  );
};

export default CallTimeCalendarView;
