import { CUSTOM_CALL_TIME } from "constant";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const CallTimeModifyModal = (props) => {
  const { cssClass, dept, handleClose, handleSave, removable } = props;
  const [customCallTime, setCustomCallTime] = useState(dept.defaultCallTime || "");
  return (
    <>
      <Modal show onHide={handleClose} animation={true} size={"lg"} keyboard={true} className={`${cssClass}`}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Set Custom Call Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12">
              <div className="createClientsWrapper flex-wrap">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders mt-0">Custom Call Time:</label>
                  <input
                    type="time"
                    id="callTime"
                    step="900"
                    style={{ lineHeight: "normal" }}
                    className="modalInput"
                    value={customCallTime}
                    onChange={(e) => setCustomCallTime(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" className="headerButton btn-fill" onClick={handleClose}>
            Close
          </Button>
          {removable && (
            <Button
              variant="primary"
              className="headerButton btn-fill"
              onClick={() => handleSave(customCallTime, CUSTOM_CALL_TIME.remove)}
            >
              Remove
            </Button>
          )}
          <Button
            variant="secondary"
            className="headerButton btn-fill"
            onClick={() => handleSave(customCallTime, CUSTOM_CALL_TIME.update)}
          >
            {removable ? "Update" : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CallTimeModifyModal;
