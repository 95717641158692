import React, { useEffect, useState } from "react";
import { parseMinutesIntoHours, checkValidReminderFormat, parseMinutesIntoDHM, parseDHMToMins } from "utils";
const CallTimeReminder = (props) => {
  const { title, value, placeholder, handleChange, disabled, setError } = props;

  const [time, setTime] = useState("");

  const handleAddCounter = (val) => {
    // const defaultMinutes = 15;
    // const newValue = parseInt(val || 0);
    // const minutes = newValue < 15 ? newValue * 60 : newValue;
    // handleChange(minutes + defaultMinutes);
    // if (!checkForErrors()) {
    const totalMins = parseDHMToMins(val) + 15;
    setTime(parseMinutesIntoDHM(totalMins));
    // }
  };

  const handleSubCounter = (val) => {
    // const defaultMinutes = 15;
    // const newValue = parseInt(value || 0);
    // const minutes = newValue < 15 ? newValue * 60 : newValue;
    // if (minutes > 0) handleChange(minutes - defaultMinutes);

    // if (!checkForErrors()) {
    const totalMins = parseDHMToMins(val) - 15;
    if (totalMins <= 0) {
      setTime("");
    } else {
      setTime(parseMinutesIntoDHM(totalMins));
    }
    // }
  };

  useEffect(() => {
    if (value) setTime(parseMinutesIntoDHM(value.replace("m", "")));
  }, [value]);

  useEffect(() => {
    if (time) {
      if (!checkForErrors()) {
        handleChange(parseDHMToMins(time), true);
      } else {
        handleChange(0, false);
      }
    } else {
      handleChange(0, true);
    }
  }, [time]);

  const checkForErrors = () => {
    if (time) {
      let errorMsg = "";
      if (checkValidReminderFormat(time)) {
        const totalMins = parseDHMToMins(time);
        if (totalMins % 15 !== 0) {
          errorMsg = `${title.replace(":", "")} should have 15 mins gap!`;
        }
      } else {
        errorMsg = `Invalid ${title.replace(":", "")} format`;
      }
      setError(errorMsg);
      return errorMsg;
    }
  };

  return (
    <div className="form-group-wrapper">
      <label className="modalLineHeaders mt-0">{title}</label>
      <div className="modalInput d-flex align-items-center justify-content-between">
        <input
          type="text"
          placeholder={placeholder}
          id="remainderOne"
          className="border-0 reminderInputField"
          // value={parseMinutesIntoHours(value)}
          value={time}
          // readOnly
          // onChange={(e) => handleChange(e.target.value)}
          onChange={(e) => setTime(e.target.value)}
          disabled={disabled}
          onBlurCapture={checkForErrors}
        />
        <div>
          <i
            className="fas fa-sort-up"
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.color = "#A82632";
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "";
            }}
            onClick={() => !disabled && handleAddCounter(time)}
          />
          <i
            className="fas fa-sort-down"
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.color = "#A82632";
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "";
            }}
            onClick={() => !disabled && handleSubCounter(time)}
          />
        </div>
      </div>
    </div>
  );
};

export default CallTimeReminder;
