import React from "react"

const SortIcon = ({sortName, sortBy, sortDescending}) => {
    return (
        <>
            {sortBy !== sortName ? 
                <i className="fas fa-sort"></i> : 
                (sortDescending ? 
                    <i className="fas fa-sort-down"></i> : 
                    <i className="fas fa-sort-up"></i>
                )
            }
        </>
    )
}

export default SortIcon;