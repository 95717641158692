import React from "react";
import { formatNumber } from "utils";

const Stats = (props) => {
  const { title, totalCounts, respCss, handelClick, filterKey, value, color } = props;

  return (
    <div
      className="card total-test mb-xl-0 mb-md-3 bg-transparent cursor-pointer"
      onClick={() => handelClick && handelClick({ [filterKey]: value })}
    >
      <div className="card-body custom-card-body py-1">
        <div className="detail d-flex align-items-center">
          <p className="title mb-0 fw-bold">{title}</p>
        </div>
        <span className="number total-number" style={{ color: color }}>
          {formatNumber(totalCounts) || 0}
        </span>
      </div>
    </div>
  );
};

export default Stats;
