// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Employee, Schedule, Question, EmployeeQuestion, EmployeeDailyLog, Department, TestTypes, AppSetting, Test, Locations, Regions, Programs, BlockOutDays, GeneralCallTime } = initSchema(schema);

export {
  Employee,
  Schedule,
  Question,
  EmployeeQuestion,
  EmployeeDailyLog,
  Department,
  TestTypes,
  AppSetting,
  Test,
  Locations,
  Regions,
  Programs,
  BlockOutDays,
  GeneralCallTime
};