import React, { useState } from "react";

const ImageView = (props) => {
  const { imgSrc } = props;
  const [showImage, setShowImage] = useState(true);
  const handleOnError = () => {
    console.log("image Error");
    setShowImage(false);
  };
  if (showImage) {
    return <img style={{ width: "120px", height: "120px" }} src={imgSrc} onError={handleOnError} />;
  }
  return <></>;
};

export default ImageView;
