export default [
  { value: "Local 1", label: "Local 1" },
  { value: "Local 2", label: "Local 2" },
  { value: "Local 3", label: "Local 3" },
  { value: "Local 4", label: "Local 4" },
  { value: "Local 5", label: "Local 5" },
  { value: "Local 6", label: "Local 6" },
  { value: "Local 7", label: "Local 7" },
  { value: "Local 8", label: "Local 8" },
  { value: "Local 9", label: "Local 9" },
  { value: "Local 10", label: "Local 10" },
  { value: "Local 11", label: "Local 11" },
  { value: "Local 12", label: "Local 12" },
  { value: "Local 13", label: "Local 13" },
  { value: "Local 14", label: "Local 14" },
  { value: "Local 15", label: "Local 15" },
  { value: "Local 16", label: "Local 16" },
  { value: "Local 17", label: "Local 17" },
  { value: "Local 18", label: "Local 18" },
  { value: "Local 19", label: "Local 19" },
  { value: "Local 21", label: "Local 21" },
  { value: "Local 22", label: "Local 22" },
  { value: "Local 23", label: "Local 23" },
  { value: "Local 24", label: "Local 24" },
  { value: "Local 25", label: "Local 25" },
  { value: "Local 26", label: "Local 26" },
  { value: "Local 27", label: "Local 27" },
  { value: "Local 28", label: "Local 28" },
  { value: "Local 30", label: "Local 30" },
  { value: "Local 31", label: "Local 31" },
  { value: "Local 33", label: "Local 33" },
  { value: "Local 38", label: "Local 38" },
  { value: "Local 39", label: "Local 39" },
  { value: "Local 42", label: "Local 42" },
  { value: "Local 44", label: "Local 44" },
  { value: "Local 48", label: "Local 48" },
  { value: "Local 50", label: "Local 50" },
  { value: "Local 51", label: "Local 51" },
  { value: "Local 52", label: "Local 52" },
  { value: "Local 53", label: "Local 53" },
  { value: "Local 55", label: "Local 55" },
  { value: "Local 58", label: "Local 58" },
  { value: "Local 59", label: "Local 59" },
  { value: "Local 62", label: "Local 62" },
  { value: "Local 64", label: "Local 64" },
  { value: "Local 66", label: "Local 66" },
  { value: "Local 67", label: "Local 67" },
  { value: "Local 74", label: "Local 74" },
  { value: "Local 76", label: "Local 76" },
  { value: "Local 77", label: "Local 77" },
  { value: "Local 78", label: "Local 78" },
  { value: "Local 80", label: "Local 80" },
  { value: "Local 82", label: "Local 82" },
  { value: "Local 84", label: "Local 84" },
  { value: "Local 85", label: "Local 85" },
  { value: "Local 93", label: "Local 93" },
  { value: "Local 96", label: "Local 96" },
  { value: "Local 97", label: "Local 97" },
  { value: "Local 99", label: "Local 99" },
  { value: "Local 101", label: "Local 101" },
  { value: "Local 107", label: "Local 107" },
  { value: "Local 110", label: "Local 110" },
  { value: "Local 113", label: "Local 113" },
  { value: "Local 114", label: "Local 114" },
  { value: "Local 115", label: "Local 115" },
  { value: "Local 119", label: "Local 119" },
  { value: "Local 121", label: "Local 121" },
  { value: "Local 122", label: "Local 122" },
  { value: "Local 124", label: "Local 124" },
  { value: "Local 126", label: "Local 126" },
  { value: "Local 127", label: "Local 127" },
  { value: "Local 134", label: "Local 134" },
  { value: "Local 138", label: "Local 138" },
  { value: "Local 158", label: "Local 158" },
  { value: "Local 160", label: "Local 160" },
  { value: "Local 161", label: "Local 161" },
  { value: "Local 166", label: "Local 166" },
  { value: "Local 169", label: "Local 169" },
  { value: "Local 182", label: "Local 182" },
  { value: "Local 183", label: "Local 183" },
  { value: "Local 191", label: "Local 191" },
  { value: "Local 193", label: "Local 193" },
  { value: "Local 195", label: "Local 195" },
  { value: "Local 200", label: "Local 200" },
  { value: "Local 204", label: "Local 204" },
  { value: "Local 205", label: "Local 205" },
  { value: "Local 209", label: "Local 209" },
  { value: "Local 210", label: "Local 210" },
  { value: "Local 212", label: "Local 212" },
  { value: "Local 215", label: "Local 215" },
  { value: "Local 217", label: "Local 217" },
  { value: "Local 229", label: "Local 229" },
  { value: "Local 232", label: "Local 232" },
  { value: "Local 251", label: "Local 251" },
  { value: "Local 262", label: "Local 262" },
  { value: "Local 278", label: "Local 278" },
  { value: "Local 280", label: "Local 280" },
  { value: "Local 285", label: "Local 285" },
  { value: "Local 289", label: "Local 289" },
  { value: "Local 295", label: "Local 295" },
  { value: "Local 298", label: "Local 298" },
  { value: "Local 300", label: "Local 300" },
  { value: "Local 306", label: "Local 306" },
  { value: "Local 330", label: "Local 330" },
  { value: "Local 321", label: "Local 321" },
  { value: "Local 322", label: "Local 322" },
  { value: "Local 329", label: "Local 329" },
  { value: "Local 333", label: "Local 333" },
  { value: "Local 336", label: "Local 336" },
  { value: "Local 346", label: "Local 346" },
  { value: "Local 347", label: "Local 347" },
  { value: "Local 353", label: "Local 353" },
  { value: "Local 354", label: "Local 354" },
  { value: "Local 363", label: "Local 363" },
  { value: "Local 370", label: "Local 370" },
  { value: "Local 374", label: "Local 374" },
  { value: "Local 399", label: "Local 399" },
  { value: "Local 411", label: "Local 411" },
  { value: "Local 415", label: "Local 415" },
  { value: "Local 417", label: "Local 417" },
  { value: "Local 421", label: "Local 421" },
  { value: "Local 461", label: "Local 461" },
  { value: "Local 470", label: "Local 470" },
  { value: "Local 471", label: "Local 471" },
  { value: "Local 476", label: "Local 476" },
  { value: "Local 477", label: "Local 477" },
  { value: "Local 478", label: "Local 478" },
  { value: "Local 479", label: "Local 479" },
  { value: "Local 480", label: "Local 480" },
  { value: "Local 481", label: "Local 481" },
  { value: "Local 484", label: "Local 484" },
  { value: "Local 487", label: "Local 487" },
  { value: "Local 488", label: "Local 488" },
  { value: "Local 489", label: "Local 489" },
  { value: "Local 490", label: "Local 490" },
  { value: "Local 491", label: "Local 491" },
  { value: "Local 492", label: "Local 492" },
  { value: "Local 500", label: "Local 500" },
  { value: "Local 504", label: "Local 504" },
  { value: "Local 514", label: "Local 514" },
  { value: "Local 578", label: "Local 578" },
  { value: "Local 592", label: "Local 592" },
  { value: "Local 600", label: "Local 600" },
  { value: "Local 604", label: "Local 604" },
  { value: "Local 611", label: "Local 611" },
  { value: "Local 614", label: "Local 614" },
  { value: "Local 631", label: "Local 631" },
  { value: "Local 632", label: "Local 632" },
  { value: "Local 635", label: "Local 635" },
  { value: "Local 636", label: "Local 636" },
  { value: "Local 647", label: "Local 647" },
  { value: "Local 665", label: "Local 665" },
  { value: "Local 667", label: "Local 667" },
  { value: "Local 669", label: "Local 669" },
  { value: "Local 675", label: "Local 675" },
  { value: "Local 680", label: "Local 680" },
  { value: "Local 683", label: "Local 683" },
  { value: "Local 690", label: "Local 690" },
  { value: "Local 695", label: "Local 695" },
  { value: "Local 700", label: "Local 700" },
  { value: "Local 702", label: "Local 702" },
  { value: "Local 705", label: "Local 705" },
  { value: "Local 706", label: "Local 706" },
  { value: "Local 720", label: "Local 720" },
  { value: "Local 728", label: "Local 728" },
  { value: "Local 729", label: "Local 729" },
  { value: "Local 745", label: "Local 745" },
  { value: "Local 750", label: "Local 750" },
  { value: "Local 751", label: "Local 751" },
  { value: "Local 762", label: "Local 762" },
  { value: "Local 764", label: "Local 764" },
  { value: "Local 767", label: "Local 767" },
  { value: "Local 769", label: "Local 769" },
  { value: "Local 780", label: "Local 780" },
  { value: "Local 783", label: "Local 783" },
  { value: "Local 784", label: "Local 784" },
  { value: "Local 798", label: "Local 798" },
  { value: "Local 800", label: "Local 800" },
  { value: "Local 805", label: "Local 805" },
  { value: "Local 812", label: "Local 812" },
  { value: "Local 815", label: "Local 815" },
  { value: "Local 825", label: "Local 825" },
  { value: "Local 828", label: "Local 828" },
  { value: "Local 829", label: "Local 829" },
  { value: "Local 831", label: "Local 831" },
  { value: "Local 834", label: "Local 834" },
  { value: "Local 835", label: "Local 835" },
  { value: "Local 836", label: "Local 836" },
  { value: "Local 838", label: "Local 838" },
  { value: "Local 839", label: "Local 839" },
  { value: "Local 843", label: "Local 843" },
  { value: "Local 844", label: "Local 844" },
  { value: "Local 849", label: "Local 849" },
  { value: "Local 856", label: "Local 856" },
  { value: "Local 857", label: "Local 857" },
  { value: "Local 868", label: "Local 868" },
  { value: "Local 871", label: "Local 871" },
  { value: "Local 873", label: "Local 873" },
  { value: "Local 884", label: "Local 884" },
  { value: "Local 891", label: "Local 891" },
  { value: "Local 892", label: "Local 892" },
  { value: "Local 900", label: "Local 900" },
  { value: "Local 902", label: "Local 902" },
  { value: "Local 916", label: "Local 916" },
  { value: "Local 917", label: "Local 917" },
  { value: "Local 919", label: "Local 919" },
  { value: "Local 923", label: "Local 923" },
  { value: "Local 926", label: "Local 926" },
  { value: "Local 927", label: "Local 927" },  
  { value: "Local 18032", label: "Local 18032" },  
  { value: "Local 399C", label: "Local 399C" },  
  { value: "Local 868B", label: "Local 868B" },  
  { value: "Local AE936", label: "Local AE936" },
  { value: "Local AE937", label: "Local AE937" },
  { value: "Local AE938", label: "Local AE938" },
  { value: "Local B-18", label: "Local B-18" },
  { value: "Local B192", label: "Local B192" },
  { value: "Local B-32", label: "Local B-32" },
  { value: "Local B4", label: "Local B4" },
  { value: "Local B751", label: "Local B751" },
  { value: "Local B754", label: "Local B754" },
  { value: "Local F72", label: "Local F72" },
  { value: "Local USA829", label: "Local USA829" },
];
