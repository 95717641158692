import React, { useRef, useState, useContext, useEffect } from "react";
import { MESSAGE_SEND_TYPE } from "constant";
import { AppContext } from "context/app-context";
import moment from "moment";
import { Storage } from "aws-amplify";
import TextAreaFormContainer from "./TextAreaFormContainer";
import { isValidEmailAttachment } from "utils";
import { BUCKET_MESSAGE_ATTACHMENT } from "constant";
import RadioButton from "components/RadioButton/RadioButton";
import CallTimeReminder from "components/CallTimeReminder";

const TEXT_FILED_OPTIONS = [
  { label: "First Name", value: "{firstName}" },
  { label: "Last Name", value: "{lastName}" },
  { label: "Test Type", value: "{testType}" },
  { label: "Call Time", value: "{callTime}" },
  { label: "Zone Name", value: "{zone}" },
  { label: "Dept Name", value: "{deptName}" },
  { label: "Show Name", value: "{showName}" },
  { label: "Todays Date", value: "{todaysDate}" },
  { label: "Tomorrows Date", value: "{tomorrowsDate}" },
  { label: "Contact Name", value: "{mainContactName}" },
  { label: "Contact Number", value: "{mainContactNumber}" },
  { label: "Contact Email", value: "{mainContactEmail}" },
  { label: "Testing Coordinator Name", value: "{testingCordinatorName}" },
  { label: "Testing Coordinator Email", value: "{testingCordinatorEmail}" },
  { label: "Testing Coordinator Number", value: "{testingCordinatorNumber}" },
];

const TextAreaEditor = (props) => {
  const {
    isSelected,
    message,
    name,
    handleChange,
    label,
    type,
    subject,
    optionTestType,
    title,
    attachments,
    loading,
    setLoading,
    viewAattachment,
    callTimeReminder,
    callTimeDirection,
    handleCallTimeDirection,
    setError,
  } = props;

  const textAreaRef = useRef();
  const [files, setFiles] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const inputFile = useRef();
  const childNode = useRef();
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (attachments && files.length === 0) setFiles(attachments);
  }, [attachments]);

  const insertAtCursor = (dropDownValue) => {
    textAreaRef.current.focus;
    if (textAreaRef.current) {
      let startPos = textAreaRef.current.selectionStart;
      let endPos = textAreaRef.current.selectionEnd;
      textAreaRef.current.value =
        textAreaRef.current.value.substring(0, startPos) +
        dropDownValue +
        textAreaRef.current.value.substring(endPos, textAreaRef.current.value.length);
      const newPos = endPos + dropDownValue.length;
      textAreaRef.current.setSelectionRange(newPos, newPos);
      textAreaRef.current.focus();
      handleChange(isSelected, textAreaRef.current.value, name, type, subject, files);
    } else {
      handleChange(isSelected, textAreaRef.current.value, name, type, subject, files);
    }
  };

  const getFileName = (fname) => {
    const companyCode = appContext.company.companyCode;
    return `${companyCode}/attachment/${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_${fname}`;
  };

  const onChangeFile = async (event) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      try {
        const errMessage = isValidEmailAttachment(file);

        if (errMessage.length > 0) {
          appContext.showErrorMessage(errMessage);
          return;
        }
        setLoading(true);
        const fname = getFileName(file.name);

        // await Storage.put(fname, file, { bucket: "employee-external-tests" });
        await Storage.put(fname, file, { bucket: BUCKET_MESSAGE_ATTACHMENT });

        const updatedFiles = [
          ...files,
          {
            name: fname,
            // bucket: "message-attachements",
            fileName: file.name,
          },
        ];
        setFiles(updatedFiles);
        handleChange(isSelected, message, name, type, subject, updatedFiles);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        appContext.showErrorMessage(error.message);
      }
    }
  };

  const handleUploadClick = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleRemoveFile = async (fname) => {
    try {
      setLoading(true);

      const filePath = files.find(({ name }) => name === fname);
      console.log("file to be removed: ", { fname, files });

      await Storage.remove(filePath.name, { bucket: BUCKET_MESSAGE_ATTACHMENT });

      const updatedFiles = files.filter(({ name }) => name !== filePath.name);
      setFiles(updatedFiles);

      handleChange(isSelected, message, name, type, subject, updatedFiles);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      appContext.showErrorMessage(error.message);
    }
  };

  const handleCallTimeRadioChange = (selectedValue) => {
    if (!callTimeDirection?.callTime) {
      appContext.showErrorMessage("Please select reminder Time first in a correct format with a time gap of 15 mins!");
    } else {
      handleCallTimeDirection(callTimeDirection?.callTime, selectedValue);
    }
  };

  return (
    <div className="row">
      <div
        className="col-lg-2 col-12 mb-3"
        title={title}
        style={{
          display: `${callTimeReminder && "flex"}`,
          flexDirection: `${callTimeReminder && "column"}`,
          rowGap: `${callTimeReminder && "55px"}`,
        }}
      >
        <label className="modalLineHeaders mt-0 d-inline-flex align-items-start message-label">
          <input
            className="position-relative"
            style={{ top: "3px" }}
            checked={isSelected}
            type="checkBox"
            onChange={(e) => handleChange(e.target.checked, message, name, type, subject, files)}
          ></input>
          <span className="showDisclaimerText">{`${label}:`}</span>
        </label>
      </div>
      <div className="col-lg-5 col-12 mb-3" style={{ position: "relative" }} title={title}>
        <TextAreaFormContainer
          viewAattachment={viewAattachment}
          TEXT_FILED_OPTIONS={TEXT_FILED_OPTIONS}
          isSelected={isSelected}
          insertAtCursor={insertAtCursor}
          MESSAGE_SEND_TYPE={MESSAGE_SEND_TYPE}
          handleChange={handleChange}
          message={message}
          name={name}
          subject={subject}
          type={type}
          handleUploadClick={handleUploadClick}
          loading={loading}
          files={files}
          childNode={childNode}
          setShowPopover={setShowPopover}
          inputFile={inputFile}
          onChangeFile={onChangeFile}
          showPopover={showPopover}
          handleRemoveFile={handleRemoveFile}
          label={label}
          textAreaRef={textAreaRef}
        />
      </div>
      {callTimeReminder && (
        <>
          <div className="col-lg-5 col-12 form-group-wrapper callTimeReminderWrapper">
            <CallTimeReminder
              placeholder="Reminder Time"
              title="Reminder Time:"
              value={callTimeDirection?.callTime}
              setError={setError}
              handleChange={(e) => handleCallTimeDirection(`${e}`.replace(/^0/, ""), callTimeDirection?.schedule)}
              disabled={isSelected !== undefined && !isSelected}
            />
            <div className="w-100 flex-wrap callTimeRemRadioGroup">
              <label>Before: </label>
              <div className="d-flex" style={{ columnGap: "25px" }}>
                <RadioButton
                  name="timeSchedule"
                  id="crew"
                  label="Crew Call Time"
                  checked={callTimeDirection?.schedule === "beforeCrew" ? true : false}
                  handleChange={(e) => e.target.checked && handleCallTimeRadioChange("beforeCrew")}
                  disabled={isSelected !== undefined && !isSelected}
                />
                <RadioButton
                  name="timeSchedule"
                  id="generalCrew"
                  checked={callTimeDirection?.schedule === "beforeGeneralCrew" ? true : false}
                  label="General Crew Call Time"
                  handleChange={(e) => e.target.checked && handleCallTimeRadioChange("beforeGeneralCrew")}
                  disabled={isSelected !== undefined && !isSelected}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default TextAreaEditor;
