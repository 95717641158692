import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import ErrorMessage from "components/Message/ErrorMessage";
import { TIMEZONE_OPT, TIMEZONE_OPT_LIVE, UPDATE_CALL_TIME_CHOICE, CUSTOM_CALL_TIME } from "constant";
import { CONFIG } from "utils";
import ConfirmationModal from "./ConfirmationModal";
import { formatCallTime } from "utils";
import RadioButton from "components/RadioButton/RadioButton";
import { t } from "constants/stringConstants";
import CallTimeCalendarView from "components/CustomCallTime/CallTimeCalendarView";
import CallTimeModifyModal from "components/CustomCallTime/CallTimeModifyModal";
import { AppContext } from "context/app-context";
import Loader from "components/Loader/Loader";
import api from "api";

const GeneralDeptTime = (props) => {
  const { handleClose, handleSave, client, sendType, setSendType } = props;
  const [dept, setDept] = useState(client);
  const [error, setError] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [modifyCustomCallTimeData, setModifyCustomCallTimeData] = useState({ showModifyModal: false });
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);

  const onhandleSave = () => {
    if (dept.customCallTimeUpdated) {
      setOpenConfirmation(false);
      handleClose();
    }
    if (!dept.defaultCallTime) {
      setError("please Set Call Time");
      return;
    }
    if (!dept.defaultTimeZone) {
      setError("Please Set Time Zone");
      return;
    }
    if (!sendType) {
      setError("Please Select notification group");
      return;
    }
    setOpenConfirmation(true);
  };

  const handleConfirm = (isConfirm) => {
    if (!isConfirm) {
      setOpenConfirmation(false);
      handleClose();
      return;
    }

    handleSave(dept);
  };

  const handleUpdateCallTime = (dates, anyContainCallTime) => {
    // if (removeCallTime && !anyContainCallTime) return;
    // return appContext.showErrorMessage("Following Dates does not have any custom call time in it.");
    setModifyCustomCallTimeData({
      dates,
      showModifyModal: true,
      updated: false,
      removable: anyContainCallTime,
    });
  };

  const updateCustomCallTime = async (callTime, action) => {
    setLoading(true);
    try {
      const dataToModify = {
        dates: modifyCustomCallTimeData.dates,
        callTime: callTime || "",
        action,
      };

      if (dataToModify.action === CUSTOM_CALL_TIME.update) {
        await api.updateCustomCallTime(dataToModify.dates, dataToModify.callTime);
        appContext.showSuccessMessage("Custom Call Time has been assigned successfully on the selected dates");
      } else if (dataToModify.action === CUSTOM_CALL_TIME.remove) {
        await api.deleteCustomCallTimeDates(dataToModify.dates);
        appContext.showSuccessMessage("Custom Call Time has been removed successfully on the selected dates");
      }
      setDept({ ...dept, customCallTimeUpdated: true });
      setLoading(false);
      setModifyCustomCallTimeData({ showModifyModal: false, updated: true });
    } catch (error) {
      setLoading(false);
      appContext.showErrorMessage(error.message);
      console.log(error.message);
    }
  };

  return (
    <>
      {!openConfirmation ? (
        <Modal show onHide={handleClose} animation={true} size={"lg"} keyboard={true}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Set General Call Time</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-12">
                <div className="createClientsWrapper flex-wrap">
                  <div className="first-last-name-wrapper">
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders mt-0">General Call Time:</label>
                      <input
                        type="time"
                        id="callTime"
                        step="900"
                        style={{ lineHeight: "normal" }}
                        className="modalInput"
                        value={dept.defaultCallTime || ""}
                        onChange={(e) => setDept({ ...dept, defaultCallTime: e.target.value })}
                      />
                    </div>
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders mt-0">Time Zone:</label>
                      <Select
                        options={CONFIG.isLive ? TIMEZONE_OPT_LIVE : TIMEZONE_OPT}
                        blurInputOnSelect={true}
                        defaultValue={null}
                        menuPlacement="auto"
                        placeholder="Select Time zone"
                        value={
                          dept.defaultTimeZone ? { label: dept.defaultTimeZone, value: dept.defaultTimeZone } : null
                        }
                        onChange={(e) =>
                          setDept({
                            ...dept,
                            defaultTimeZone: e.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="first-last-name-wrapper justify-content-between">
                    <div className="form-group-wrapper">
                      <div>
                        <label className="modalLineHeaders emp-gender-mb">Notify To: </label>
                        <div className="d-flex align-items-center justify-content-between">
                          <RadioButton
                            name="sendEmailMsg"
                            id="sendEmailMsg-yes"
                            checked={sendType === UPDATE_CALL_TIME_CHOICE.FOR_ALL}
                            label="All Employees"
                            cssClass="me-2"
                            handleChange={() => setSendType(UPDATE_CALL_TIME_CHOICE.FOR_ALL)}
                          />
                          <RadioButton
                            label={CONFIG.expectedOnSet}
                            name="sendEmailMsg"
                            id="sendEmailMsg-no"
                            checked={sendType === UPDATE_CALL_TIME_CHOICE.EXPECTED_ON_SET}
                            handleChange={() => setSendType(UPDATE_CALL_TIME_CHOICE.EXPECTED_ON_SET)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    <CallTimeCalendarView
                      setError={setError}
                      editable={true}
                      selectedTime={dept.defaultCallTime}
                      updated={modifyCustomCallTimeData.updated}
                      handleUpdateDates={handleUpdateCallTime}
                    />
                  </div>
                </div>
              </div>
              <div style={{ minHeight: "3vh" }}>
                {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
              Close
            </Button>
            <Button variant="secondary" className="headerButton btn-fill" onClick={onhandleSave}>
              Update
            </Button>
          </Modal.Footer>
          {modifyCustomCallTimeData.showModifyModal && (
            <CallTimeModifyModal
              cssClass={"eos-employeeModal"}
              dept={dept}
              handleClose={() => setModifyCustomCallTimeData({ showModifyModal: false, updated: true })}
              handleSave={updateCustomCallTime}
              removable={modifyCustomCallTimeData.removable}
            />
          )}
          {loading && <Loader />}
        </Modal>
      ) : (
        <ConfirmationModal
          show={openConfirmation}
          title="Update General Time Confirmation"
          message={`Are you sure you want to set the General Call Time to ${formatCallTime(dept.defaultCallTime)}?`}
          handleConfirm={handleConfirm}
          handleClose={() => setOpenConfirmation(false)}
          closeBtn={true}
        />
      )}
    </>
  );
};

export default GeneralDeptTime;
