import React, { useState, useEffect, useMemo, useContext } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { toTitleCase, onBoardingTest } from "utils";
import Select from "react-select";
import ErrorMessage from "components/Message/ErrorMessage";
import { AppContext } from "context/app-context";
import { Storage } from "aws-amplify";
import ImageInput from "components/Input/ImageInput";
import api from "api";
import moment from "moment";
import RadioButton from "components/RadioButton/RadioButton";
import { getPhoneNo } from "utils";
const ExternalTestProgramModal = (props) => {
  const { handleConfirm, user, selectedType, companyType } = props;
  // console.log(user, selectedType);
  const [error, setError] = useState("");
  const [externalTestImage, setExternalTestImage] = useState(null);
  const [newUser, setNewUser] = useState("");
  const [testType, setTestType] = useState("");
  const [result, setResult] = useState("");
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (selectedType === "test") {
      const tests = [...(user.testTwo || [])];
      if (tests.length === 1) {
        setTestType(tests[0]);
      }
    }
  }, [selectedType]);
  // console.log("testType", testType);
  const onHandleConfirm = async (newUser) => {
    let logObject = null;

    if (!testType) {
      setError("Please select test type");
      return;
    }
    if (selectedType === "test") {
      let test = user.testTwo?.find((f) => f.id === testType.id);
      if (test.isDone && !test.externalTestFile) {
        setError("This test has already done");
        return;
      }
    }

    let externalImagePath = `${newUser.schrID}/${newUser.schrID}_#datePattern#_${moment()
      .utc()
      .format("YYYY-MM-DD HH:mm")}.jpeg`;

    if (!result) {
      setError("Please select the test result");
      return;
    }

    if (!externalTestImage) {
      setError("Please upload the result image");
      return;
    }

    if (newUser.phoneNumber) {
      const [countryCode, phoneNo, phone] = getPhoneNo(newUser.phoneNumber, newUser.countryCode);
      Object.assign(newUser, { phoneNumber: phoneNo });
    }

    if (externalTestImage instanceof Object) {
      Object.assign(newUser, {
        ...newUser,
        externalTestImage: externalTestImage,
        externalTestFile: externalImagePath,
        result: result,
        test_type: testType.value,
        testLocation: testType.location,
        userName: appContext.user.name,
        test: {
          ...testType,
          isDone: true,
          date: moment().utc().toISOString(),
          result,
          externalTestFile: externalImagePath,
          isAcknowledged: false,
          isNewResult: true,
        },
      });
    }

    // if (externalTestImage instanceof Object && selectedType === "test") {
    //   const OnLoactionTest = [...newUser.testTwo, ...newUser.testOne];
    //   const updateTest = OnLoactionTest.map((m) => {
    //     if (m.id && m.id === testType.id && m.testNow && testType.testNow && !companyType) {
    //       return { ...m, isDone: true, externalTestFile: externalImagePath, result };
    //     } else if (m.id && m.id === testType.id && !companyType) {
    //       return { ...m, isDone: true, externalTestFile: externalImagePath, result };
    //     } else if (m.id && m.id === testType.id && m.testNow && testType.testNow && companyType) {
    //       return {
    //         ...m,
    //         isAcknowledged: false,
    //         isDone: true,
    //         externalTestFile: externalImagePath,
    //         result,
    //         date: moment().utc().toISOString(),
    //         isNewResult: true,
    //       };
    //     } else if (companyType && m.value === testType.value) {
    //       return { ...m, isDone: true, externalTestFile: externalImagePath, result, testNow: false, isNewResult: true };
    //     }
    //     return { ...m };
    //   });
    //   newUser.testTwo = updateTest.filter((f) => f.location === "On Location");
    //   if (companyType && testType.testNow) {
    //     newUser.onBoardingTesting = [...updateTest].map((m) => {
    //       return { ...m, testNow: true };
    //     });
    //   }
    //   logObject = {
    //     schrID: user.id.replace("%testNow", ""),
    //     result,
    //     auditType: "ExternalTest",
    //     companyID: newUser.companyID,
    //     userName: appContext.user.name,
    //     testDate: moment().utc().toISOString(),
    //     filePath: externalImagePath,
    //     test_type: testType.value,
    //     testNow: testType.testNow ? true : false,
    //   };
    // }
    handleConfirm(newUser, logObject);
  };
  useEffect(() => {
    setNewUser(user);
  }, [user]);

  useEffect(() => {
    if (newUser) {
      if (getOptions(newUser).length === 1) {
        setTestType(getOptions(newUser)[0]);
      }
    }
  }, [newUser]);

  useEffect(() => {
    let filePath = "";
    let testResult = "";
    if (selectedType === "test") {
      const obj = user.testTwo?.find((f) => f.value === testType.value && f.testNow === testType.testNow);
      if (obj) {
        filePath = obj.externalTestFile;
        testResult = obj.result;
      }
    }
    if (selectedType === "onBoarding") {
      if (Array.isArray(user.onBoardingTesting)) {
        const obj = user.onBoardingTesting.find((f) => f.value === testType.value);
        if (obj) {
          filePath = obj.externalTestFile;
          testResult = obj.result;
        }
      } else {
        filePath = user.onBoardingTesting.externalTestFile;
        testResult = user.onBoardingTesting.result || "";
      }
    }
    setResult(testResult || result);
    if (filePath) {
      getFileFromStorage(filePath, setExternalTestImage);
    } else {
      setExternalTestImage("");
    }
  }, [testType]);

  const getFileFromStorage = async (file, setImage) => {
    if (file) {
      console.log("file", file);
      try {
        const path = await Storage.get(file, { bucket: "employee-external-tests" });
        setImage(path);
        console.log("path", path);
        return true;
      } catch {
        console.log("Image loading fail");
      }
    } else {
      return false;
    }
  };

  const getOptions = () => {
    const options = [...(user.testTwo || [])].filter((t) => !t.isDone);
    if (companyType) return options;
    if (user.testNow) {
      return options.filter((t) => t.testNow);
    }
    return options.filter((t) => !t.testNow);
  };

  const handleChange = (val) => {
    setResult(val);
  };
  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleConfirm(false, null);
      }}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          External Test
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="form-group-wrapper">
          <label className="modalLineHeaders">Name:</label>
          <span>{`${newUser.firstName} ${newUser.lastName}`}</span>
        </div>
        <div className="createClientsWrapper">
          <div className="first-last-name-wrapper">
            {getOptions(newUser).length === 1 ? (
              <div className="form-group-wrapper d-flex">
                <label className="modalLineHeaders mb-0" style={{ maxWidth: "fit-content" }}>
                  Test Type:
                </label>
                <p className="mb-0">{testType.label}</p>
              </div>
            ) : (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Select Test Type: </label>
                <Select
                  options={getOptions()}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  className="assignSchedule"
                  placeholder="Select Testing Type"
                  value={testType ? testType : null}
                  onChange={(e) => setTestType(e)}
                />
              </div>
            )}
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Result: </label>
              <div className="d-flex assignSchedule align-items-center justify-content-between">
                <RadioButton
                  label="Postive"
                  id="Postive"
                  name="result"
                  checked={result === "Positive"}
                  handleChange={(e) => {
                    handleChange(e.target.checked ? "Positive" : "");
                  }}
                />
                {/* <div className="d-flex align-items-center">
              <Form.Check.Input
                type="radio"
                className="m-2"
                checked={result === "Positive"}
                name="Positive"
                onChange={(e) => {
                  handleChange(e.target.checked ? "Positive" : "");
                }}
              />
              <label className="dayLable px-1">Positive</label>
            </div> */}
                <RadioButton
                  label="Negative"
                  id="Negative"
                  name="result"
                  checked={result === "Negative"}
                  handleChange={(e) => {
                    handleChange(e.target.checked ? "Negative" : "");
                  }}
                />
                {/* <div className="d-flex align-items-center">
              <Form.Check.Input
                type="radio"
                className="m-2"
                checked={result === "Negative"}
                name="Negative"
                onChange={(e) => {
                  handleChange(e.target.checked ? "Negative" : "");
                }}
              />
              <label className="dayLable px-1">Negative</label>
            </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="createClientsWrapper">
          <div className="form-group-wrapper">
            <label className="modalLineHeaders"> External Test Image </label>
            <div className="w-100 mt-3">
              <ImageInput
                cssClass="imagePathField"
                setSelectedImage={setExternalTestImage}
                selectedImage={externalTestImage}
                type="external"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="px-4" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button
          className="modalButtons headerButton btn-fill"
          variant="primary"
          onClick={() => {
            handleConfirm(false, null);
          }}
        >
          Go back
        </Button>
        <Button
          variant="secondary"
          className="modalButtons headerButton btn-fill"
          onClick={() => onHandleConfirm(newUser)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExternalTestProgramModal;
