import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const IdleModal = ({ showIdleModal, handleLogOut, handleCloseModal, showChildModal }) => {
  return (
    <>
      <Modal
        className={showChildModal}
        show={showIdleModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header closeButton={handleCloseModal}>
          <Modal.Title className="my-0">You Have Been Idle!</Modal.Title>
        </Modal.Header>
        <Modal.Body>You will be signed out. Do you want to stay?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="headerButton btn-fill" onClick={handleLogOut}>
            Logout
          </Button>
          <Button variant="secondary" className="headerButton btn-fill" onClick={handleCloseModal}>
            Stay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IdleModal;
