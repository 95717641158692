import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Card, Table, Row, Col } from "react-bootstrap";
import Select from "react-select";
import ErrorMessage from "components/Message/ErrorMessage";

const DepartmentOptionsModal = (props) => {
  const { handleSave, handleClose, departments } = props;
  const [selectedDpt, setSelectedDpt] = useState([]);
  const [error, setError] = useState("");

  const onHandleSave = () => {
    console.log("selectedDPt", selectedDpt);
    if (selectedDpt.length === 0) {
      setError("Please Select at least one department");
      return;
    }
    handleSave(selectedDpt);
  };
  const TableRow = (props) => {
    const { item, handleCheck } = props;
    return (
      <tr>
        <td className="ellipsis" style={{ textAlign: "left" }}>
          <Form.Check>
            <Form.Check.Input
              type="checkBox"
              id={item.id}
              onChange={(e) => handleCheck(e, item.id)}
              value={item.id}
              checked={selectedDpt.includes(item.id)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td>{item.name}</td>
        <td>{item.callTime}</td>
      </tr>
    );
  };
  const handleCheckBox = (e, id) => {
    if (e.target.checked) {
      setSelectedDpt((prevID) => [...prevID, id]);
    } else {
      console.log("not Checked");
      const filterDpts = selectedDpt.filter((f) => f !== id);
      setSelectedDpt(filterDpts);
    }
  };
  return (
    <Modal show onHide={handleClose} animation={true} size={"lg"} scrollable keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0">Select Department</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="strpied-tabled-with-hover">
          <Card.Body className="table-full-width desktop-noScroll">
            <div
              className={
                departments.length > 0
                  ? "table-responsive pendingReleaseTable"
                  : "table-responsive pendingReleaseTable overFlow-y-hidden"
              }
            >
              <Table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>CallTime</th>
                  </tr>
                </thead>
                <tbody>
                  {departments.map((dpt, index) => (
                    <TableRow key={index} handleCheck={handleCheckBox} item={dpt} />
                  ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
      <div className="px-4 pt-2" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="secondary" className="headerButton btn-fill" onClick={onHandleSave}>
          Select
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DepartmentOptionsModal;
