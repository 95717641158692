import React, { useEffect, useRef, useState } from "react";
import ResizeObserver from "react-resize-observer";

const ResizeableHeader = (props) => {
  const { style, cssClass, width, title, label, handleResize } = props;

  const resizeableRef = useRef(null);
  // const [resizeWidth, setResizeWidth] = useState();

  // useEffect(() => {
  //   // if (resizeWidth) handleResize();
  // }, [resizeWidth]);

  return (
    <>
      <div style={style} className={cssClass} ref={resizeableRef}>
        {/* <ResizeObserver onResize={(e) => setResizeWidth(e.width)} /> */}
        <div
          className="text-left resizeHorizontal"
          style={{
            width: `${width || `${width}px`}`,
          }}
        >
          <span title={title} className="custom-ellipsis d-block">
            {label}
          </span>
        </div>
      </div>
    </>
  );
};

export default ResizeableHeader;
