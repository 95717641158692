import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import { AppContext } from "context/app-context";
import { Card, Table, Row, Col, Button, Form } from "react-bootstrap";
import { Bounce } from "react-activity";
import API from "api";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { calculateTdWidth, differenceBtwDays } from "utils";
import { DAYS, FUTURE_INITAIL_DATE, PAST_INITIAL_DATE } from "constant";
import moment from "moment";
import { removeSpaceIns, formatDateMatrix, getDaysArray } from "utils";
import Loader from "components/Loader/Loader";
import MatrixPopOver from "components/Matrix/MatrixPopOver";
import MatrixView from "components/MatrixView";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { formatDateMDY, sortList } from "utils";
import Filter from "components/Filter";
import ViewCheckBox from "../components/CheckBox/ViewCheckBox";

const TcMatrix = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [programData, setProgramData] = useState({});
  const [filterPrograms, setFilterPrograms] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [userProgramData, setUserProgramData] = useState({});
  const [filter, setFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [dateList, setDateList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [showLeveMtarix, setShowLevelMatrix] = useState(false);
  const [date, setDate] = useState(!showData ? FUTURE_INITAIL_DATE : PAST_INITIAL_DATE);
  const [logDateRange, setLogDateRange] = useState(!showData ? FUTURE_INITAIL_DATE : PAST_INITIAL_DATE);
  const [triggerFilter, setTriggerFilter] = useState();

  useEffect(() => {
    setWidth(componentRef.current?.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current?.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const tdWidth = calculateTdWidth(width);

  const searchTerms = ["First Name", "Last Name", "Email", "Phone"];

  const getDepartments = async () => {
    const data = await API.getDepartments();
    setDepartments(data);
  };

  useEffect(async () => {
    setLoading(true);
    setDateList(getDaysArray(date.startDate, date.endDate));
    getDepartments();
    // await API.deleteDailyLog();
  }, []);

  useEffect(() => {
    if (showData) {
      setDate(PAST_INITIAL_DATE);
      setLogDateRange(PAST_INITIAL_DATE);
    } else {
      setDate(FUTURE_INITAIL_DATE);
      setLogDateRange(FUTURE_INITAIL_DATE);
    }
  }, [showData]);

  useEffect(() => {
    if (Object.keys(userProgramData).length > 0) {
      const filterData = nestedFilter(userProgramData, filter);
      setFilterPrograms(Object.keys(filterData));
      setProgramData(filterData);
    }
  }, [filter]);

  useEffect(async () => {
    setLoading(true);
    if (differenceBtwDays(date.startDate, date.endDate) < 14) {
      setLogDateRange(date);
      if (!showData) {
        setDateList(getDaysArray(date.startDate, date.endDate));
        await uniquePrograms();
      } else {
        setDateList(getDaysArray(date.startDate, date.endDate).reverse());
        await getLogData();
      }
    } else {
      appContext.showErrorMessage("Date Range should be 2 weeks");
      setDate(logDateRange);
    }
    setLoading(false);
  }, [appContext.employees, appContext.schedules, showData, showLeveMtarix, date]);

  const handleApply = (event, picker) => {
    picker.element.val(picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY"));
    console.log("DateRange", picker.startDate, picker.endDate);
    setDate({
      startDate: moment(picker.startDate).format("YYYY-MM-DD"),
      endDate: moment(picker.endDate).format("YYYY-MM-DD"),
    });
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
    setDate({});
  };

  const UpdateValue = () => {
    if (date.endDate)
      return {
        startDate: formatDateMDY(date.startDate),
        endDate: formatDateMDY(date.endDate),
        minDate: !showData ? moment() : false,
        maxDate: showData ? moment().subtract(1, "day") : false,
      };
    return {
      autoUpdateInput: false,
      minDate: !showData ? moment() : false,
      maxDate: showData ? moment().subtract(1, "day") : false,
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Clear",
      },
    };
  };

  const nestedFilter = (programData, filters) => {
    if (Object.keys(filter).length === 0) return programData;
    const filterKeys = Object.keys(filters);
    const targetArray = Object.keys(programData);
    //filters main array of objects
    console.log("Filters", filters);
    const targetProgramData = {};
    targetArray.forEach((prog) => {
      const models = programData[prog].filter((obj) => {
        //goes through each key being filtered for
        return filterKeys.every((key) => {
          if (!filters[key].length && !Object.keys(filters[key]).length) {
            return true;
          }

          if (key === "First Name") {
            return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
          }

          if (key === "Last Name") {
            return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
          }
          return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
        });
      });

      if (models.length > 0) {
        Object.assign(targetProgramData, { [prog]: models });
      }
    });
    return targetProgramData;
  };

  const getLogData = async () => {
    let rows = [];
    let dataLogs = [];
    try {
      dataLogs = await API.getAllEmployeeDailyLogs();
    } catch (err) {
      console.log("Error:- dailyLogs", err.message);
    }
    console.log("dataLogs", dataLogs);
    const empTtl = appContext.employees?.length;
    const logsTtl = dataLogs.length;
    const startDate = moment(date.startDate).startOf("day").toDate();
    const endDate = moment(date.endDate).endOf("day").toDate();
    for (let i = 0; i < empTtl; i++) {
      const emp = appContext.employees[i];
      let dataFilterlist = [];
      for (let j = 0; j < logsTtl; j++) {
        const f = dataLogs[j];
        const isDateRange =
          new Date(f.logDate?.substring(0, 10)) > startDate && new Date(f.logDate?.substring(0, 10)) < endDate;
        const testType = [...(f.testOne || []), ...(f.testTwo || [])];
        const isDone = testType.filter((s) => s.isDone);
        if (f.employeeID === emp.id && isDateRange && isDone.length > 0) {
          dataFilterlist.push({ ...f, date: f.logDate?.substring(0, 10), testType: isDone });
        }
      }
      console.log("dataFilter", dataFilterlist);
      if (dataFilterlist.length > 0) {
        rows.push({ ...emp, scheduleData: dataFilterlist });
      }
    }

    console.log("rows", rows);

    const prevListData = rows.reduce((obj, user) => {
      const ID = !showLeveMtarix ? user.programID || "-" : user.department || "-";
      if (obj[ID] && user) {
        console.log("LOGs Future");
        obj[ID].push(user);
      } else if (user) {
        obj[ID] = [user];
      }
      return obj;
    }, {});
    console.log("prevListData", prevListData);
    setUserProgramData(prevListData);
    setProgramData(nestedFilter(prevListData, filter));
    setFilterPrograms(Object.keys(nestedFilter(prevListData, filter)));
  };

  const getEmployeeScheduleDetails = (emp) => {
    const clone = { ...emp };
    if (appContext.schedules && clone.scheduleLinked) {
      for (let i = 0; i < clone.scheduleLinked.length; i++) {
        const schID = clone.scheduleLinked[i];
        const empSchedule = appContext.schedules.find((s) => s.id === schID);
        if (empSchedule && empSchedule.scheduleCategory === "Testing") {
          console.log("empSch", empSchedule);
          const isEndDate = moment(date.endDate).isSameOrAfter(empSchedule.endDate);
          const DateList = getDaysArray(date.startDate, isEndDate ? empSchedule.endDate : date.endDate);
          const schTestTypes = [];
          DateList.forEach((d) => {
            const day = moment(d).format("dddd");
            const list = empSchedule.dayOptions.find((f) => f.name === day);
            if (list) {
              schTestTypes.push({ ...list, date: d });
            }
          });
          console.log("schTestTypes", schTestTypes);
          Object.assign(clone, {
            scheduleData: schTestTypes,
            endDateSch: empSchedule.endDate,
            startDateSch: empSchedule.startDate,
            ScheduleDays: empSchedule.days,
          });
          return clone;
        }
      }
    }

    return false;
  };

  console.log("programData", programData);

  const uniquePrograms = async () => {
    const result = appContext.employees
      .filter((f) => f.isSchedule === 1)
      .reduce((obj, user) => {
        const userObj = getEmployeeScheduleDetails(user);
        const ID = !showLeveMtarix ? user.programID || "-" : user.department || "-";
        if (obj[ID] && userObj) {
          console.log("LOGs Future");
          obj[ID].push(userObj);
        } else if (userObj) {
          obj[ID] = [userObj];
        }
        return obj;
      }, {});
    setUserProgramData(result);
    setProgramData(nestedFilter(result, filter));
    setFilterPrograms(Object.keys(nestedFilter(result, filter)));
  };

  const handleConfirmDelete = async (isConfirm) => {
    console.log("[DELETE USER]", itemToDelete);
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      await API.deleteProgramSettings(itemToDelete.id);
      setItemToDelete(null);
      appContext.resetPrograms();
      appContext.showSuccessMessage("program deleted successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const isTooDark = (hexcolor) => {
    if (!hexcolor) return true;
    var r = parseInt(hexcolor.substr(1, 2), 16);
    var g = parseInt(hexcolor.substr(3, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    // Return new color if to dark, else return the original
    return yiq < 90 ? true : false;
  };

  const TableRow = ({ prog }) => {
    const list = sortList(!showLeveMtarix ? "firstName" : "programName", true, programData[prog] || []);
    let program = null;
    if (!showLeveMtarix) {
      program = appContext.programs?.find((f) => f.id === prog);
    } else {
      program = departments.find((f) => f.id === prog);
    }
    const isDarkProg = isTooDark(program?.color);
    return (
      <>
        <tr style={{ backgroundColor: `${program?.color || "black"}` }}>
          <td
            colSpan={!showLeveMtarix ? "6" : "7"}
            className="py-1 px-3 bg-color-td"
            style={{ color: `${!showLeveMtarix ? `${isDarkProg ? "white" : "black"}` : "white"}` }}
          >
            <Form.Check className="tcMatrixCheckBox programName me-3">
              <Form.Check.Input type="checkbox" value="2" />
            </Form.Check>
            <div className="ms-5">
              {program?.name || "Unassigned"} ({list?.length || 0})
            </div>
          </td>
        </tr>
        {list?.map((emp) => {
          let employee = null;
          if (showLeveMtarix) {
            employee = appContext.programs?.find((f) => f.id === emp.programID);
          } else {
            employee = departments.find((f) => f.id === emp.department);
          }
          const isDarkEmp = isTooDark(employee?.color);
          return (
            <tr>
              <td className="first-col-checkBox border-0">
                <Form.Check className="tcMatrixCheckBox">
                  <Form.Check.Input type="checkbox" value="1" />
                </Form.Check>
                <i className="fas fa-star star-icon" />
              </td>

              {showLeveMtarix && (
                <td
                  className="ellipsis border-0"
                  style={{
                    textAlign: "center",
                    // width: tdWidth,
                    // maxWidth: tdWidth,
                    backgroundColor: `${employee?.color || "black"}`,
                    color: `${isDarkEmp ? "white" : "black"}`,
                  }}
                  title={employee?.name || "Unassigned"}
                >
                  {employee?.name || "Unassigned"}
                </td>
              )}
              {/*<td*/}
              {/*    className="ellipsis border-0"*/}
              {/*  style={{ textAlign: "left",*/}
              {/*    // width: tdWidth, maxWidth: tdWidth*/}
              {/*  }}*/}
              {/*  title={emp.firstName}*/}
              {/*>*/}
              {/*  {emp.firstName} <i className="fas fa-star star-icon" />*/}
              {/*  <span className="exclamation-mark">!</span>*/}
              {/*</td>*/}

              <td
                className="ellipsis nameBlock tcMatrixResizeableCell"
                style={{
                  textAlign: "left",
                  height: "71px",
                  minHeight: "71px",
                  maxHeight: "71px",
                }}
                title={emp.firstName}
              >
                <>{emp.firstName}</>
              </td>
              <td
                className="ellipsis"
                style={{
                  textAlign: "center",
                  // width: tdWidth, maxWidth: tdWidth
                }}
                title={emp.lastName}
              >
                {emp.lastName}
              </td>
              <td
                className="ellipsis"
                style={{
                  textDecoration: "underline",
                  color: "#A82632",
                  textAlign: "center",
                  // width: tdWidth,
                  // maxWidth: tdWidth,
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.style.textDecoration = "none";
                }}
                onMouseLeave={(e) => {
                  e.target.style.textDecoration = "underline";
                }}
                onClick={() => emp.email && window.open(`mailto:${emp.email}`)}
                title={emp.email}
              >
                {emp.email}
              </td>
              <td
                className="ellipsis"
                style={{
                  textDecoration: "underline",
                  color: "#A82632",
                  textAlign: "center",
                  // width: tdWidth,
                  // maxWidth: tdWidth,
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.style.textDecoration = "none";
                }}
                onMouseLeave={(e) => {
                  e.target.style.textDecoration = "underline";
                }}
                onClick={() => emp.phoneNumber && window.open(`tel:+${emp.phoneNumber.replace(/\D/g, "")}`)}
                title={emp.phoneNumber}
              >
                {emp.phoneNumber}
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  const TableRowDate = ({ prog }) => {
    const list = sortList(!showLeveMtarix ? "firstName" : "programName", true, programData[prog] || []);

    return (
      <>
        <tr>
          <td className="py-1 px-3 text-white bg-color-td">.</td>
        </tr>
        {list.map((emp) => {
          return (
            <tr>
              {dateList.map((d) => {
                const dayOpt = emp.scheduleData?.find((f) => f.date === d);
                console.log("dayOPt", dayOpt);
                return (
                  <MatrixPopOver
                    dayOpt={dayOpt?.testType}
                    prevTest={showData}
                    tdWidth={tdWidth}
                    emp={emp}
                    appContext={appContext}
                  />
                );
              })}
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <div className="container-fluid">
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  TC Matrix
                </Card.Title>
                <div className="buttonHeader pe-2 mt-3">
                  <Button className="headerButton btn-fill" onClick={() => setShowFilter(!showFilter)}>
                    Filter
                  </Button>
                  <DateRangePicker onApply={handleApply} onCancel={handleCancel} initialSettings={UpdateValue()}>
                    <input
                      type="text"
                      placeholder="Select Date Range"
                      readOnly
                      className="dateRangeMatrix headerButton"
                      defaultValue=""
                    />
                  </DateRangePicker>
                  <Button variant="secondary" className="headerButton btn-fill" onClick={() => setShowData(!showData)}>
                    {!showData ? "Show Previous Date" : "Show Future Date"}
                  </Button>
                  <Button
                    variant="secondary"
                    className="headerButton btn-fill"
                    onClick={() => setShowLevelMatrix(!showLeveMtarix)}
                  >
                    {!showLeveMtarix ? "Group By Departmetnt" : "Group By Zone"}
                  </Button>
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                    isZoneLevel={appContext.isZoneLevelCompany()}
                    program={"Program"}
                  />
                )}
              </Card.Header>
              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="d-flex">
                  <div className={filterPrograms.length > 0 ? `tcMatrixZone tcMatrixFirstZone` : "overFlow-y-hidden"}>
                    <Table className="table">
                      <thead ref={componentRef}>
                        <tr>
                          <th
                            style={{
                              width: tdWidth,
                              maxWidth: tdWidth,
                            }}
                            className="border-0 centered first-col-checkBox"
                          >
                            <Form.Check className="tcMatrixCheckBox programName headerFirstCheckBox">
                              <Form.Check.Input type="checkbox" value="1" />
                            </Form.Check>
                          </th>
                          {showLeveMtarix && (
                            <th
                              style={{
                                color: "grey",
                                fontWeight: "bold",
                                width: tdWidth,
                                maxWidth: tdWidth,
                              }}
                              className="border-0 centered"
                            >
                              <div className="centered">
                                <span title={!showLeveMtarix ? "Department" : "Zone"}>
                                  {!showLeveMtarix ? "Department" : "Zone"}
                                </span>
                              </div>
                            </th>
                          )}
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                              maxWidth: tdWidth,
                            }}
                            className="border-0 centered"
                          >
                            <div className="d-flex centered">
                              <span title="First Name">First Name</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                              maxWidth: tdWidth,
                            }}
                            className="border-0 centered"
                          >
                            <div className="centered">
                              <span title="Last Name">Last Name</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                              maxWidth: tdWidth,
                            }}
                            className="border-0 centered"
                          >
                            <div className="centered">
                              <span title="Email">Email</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                              maxWidth: tdWidth,
                              textAlign: "center",
                            }}
                            className="border-0 centered"
                          >
                            <div className="centered">
                              <span title="Phone">Phone</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterPrograms.length > 0 &&
                          filterPrograms.map((item, i) => {
                            return <TableRow key={i} prog={item} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                  <div className={filterPrograms.length > 0 ? `tcMatrixZone` : "overFlow-y-hidden"}>
                    <Table className="table">
                      <thead ref={componentRef}>
                        <tr>
                          {dateList &&
                            dateList.map((d) => {
                              return (
                                <th
                                  style={{
                                    color: "grey",
                                    fontWeight: "bold",
                                  }}
                                  className="border-0 centered ellipsis"
                                >
                                  <div className="centered">
                                    <span title={`${formatDateMatrix(d)}`}>{`${formatDateMatrix(d)}`}</span>
                                  </div>
                                </th>
                              );
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {filterPrograms.length > 0 &&
                          filterPrograms.map((item, i) => {
                            return <TableRowDate key={i} prog={item} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Loader />
          )}

          {itemToDelete && (
            <ConfirmationModal
              show={itemToDelete ? true : false}
              title="Delete programSettings"
              message="Are you sure, you want to remove program on settings?"
              handleConfirm={handleConfirmDelete}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TcMatrix;
