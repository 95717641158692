import { EOS_OPT } from "constant";
import { CUSTOM_SCH } from "constant";
import { DROPDOWN_OPT } from "constant";
import React, { useEffect, useState, useCallback } from "react";
import debounce from "debounce";
import { Card, Modal, Table } from "react-bootstrap";
import moment from "moment";
import { formatEmployeesForCustomSch } from "utils";
import { DAY_RANGE } from "constant";
import api from "api";
import ErrorMessage from "components/Message/ErrorMessage";
import { sortList } from "utils";
import { t } from "constants/stringConstants";
import ConfirmationModal from "./ConfirmationModal";
import { CONFIRMATION_TYPE } from "constant";
import { CONFIG } from "utils";

const EOSItemsModal = (props) => {
  const { handleSelectEvent, handleClose, emp, expectedOnSet, date, appContext } = props;
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [employee, setEmployee] = useState(emp);
  const [error, setError] = useState("");
  const [selectItem, setSelectItem] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });

  useEffect(() => {
    if (expectedOnSet) {
      setFilteredEvents([EOS_OPT.removeEos, ...(employee.testTypes || [])]);
    } else {
      setFilteredEvents([EOS_OPT.addEos, ...(employee.testTypes || [])]);
    }
  }, [expectedOnSet, employee]);

  const saveCustomSch = useCallback(
    debounce((emps, date, setting, key) => {
      handleUpdateSch(emps, date, setting, key);
    }, 500),
    []
  );

  const handleUpdateSch = async (emps, date, setting, key) => {
    try {
      if (CUSTOM_SCH.reset === key) {
        await api.customReSchedule(emps, date, setting);
      } else {
        await api.updateCustomSchedule(emps, date, setting);
      }
      appContext.resetEmployees();
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const handleDropDownOptions = (item, e) => {
    // Remove Tests
    const workingList = sortList("date", true, appContext.company.workingDays);
    const workingDayIndex = workingList.findIndex((f) => f.date === date);
    const reSchDate = workingList[workingDayIndex + DAY_RANGE[item.value]];
    if (!reSchDate) {
      setError("No Wroking Day Available");
      return;
    }

    const emp = { ...employee };
    emp.testTypes = emp.testTypes.filter((f) => f.value !== e.value);
    if (
      emp.zoneTests.length === emp.testTypes.length &&
      emp.zoneTests.every(
        (t) => emp.testTypes.findIndex((f) => f.value === t.value && f.location === t.location) !== -1
      )
    ) {
      emp.customTest = false;
    } else {
      emp.customTest = true;
    }

    // Update CustomeSchedule key with selected date from expand

    const data = formatEmployeesForCustomSch(JSON.stringify({ employees: [emp] }), reSchDate.date, appContext.programs);

    const tests = [e].reduce(
      (arr, obj) => {
        const findTest = arr.find((f) => f.value === obj.value);
        if (!findTest) {
          arr.push({ ...obj, date: reSchDate.date, location: obj.location });
        } else if (findTest && findTest.location !== obj.location) {
          const testIndex = arr.findIndex((f) => f.value === findTest.value);
          arr.splice(testIndex, 1, { ...obj, date: reSchDate.date, location: obj.location });
        }
        return arr;
      },
      [...data[0].testTypes]
    );
    const testData = { date: reSchDate.date, testTypes: tests != "" ? tests : null };
    let customSchedule = [];
    if (emp.CustomSchedule && emp.CustomSchedule.length > 0) {
      const customTest = [...emp.CustomSchedule];
      const customTestIndex = customTest.findIndex((f) => f.date === reSchDate.date);
      if (customTestIndex !== -1) {
        customTest.splice(customTestIndex, 1, testData);
      } else {
        customTest.push(testData);
      }
      customSchedule.push(...customTest);
    } else {
      customSchedule.push(testData);
    }
    emp.CustomSchedule = customSchedule;

    setEmployee(emp);
    saveCustomSch([emp], date, appContext.company, CUSTOM_SCH.reset);
  };

  const handleRemoveTestTypes = async (testType) => {
    const emp = { ...employee };
    emp.testTypes = emp.testTypes.filter((f) => f.value !== testType);
    if (
      emp.zoneTests.length === emp.testTypes.length &&
      emp.zoneTests.every(
        (t) => emp.testTypes.findIndex((f) => f.value === t.value && f.location === t.location) !== -1
      )
    ) {
      emp.customTest = false;
    } else {
      emp.customTest = true;
    }
    setEmployee(emp);
    saveCustomSch([emp], date, appContext.company, CUSTOM_SCH.update);
  };

  const handleItemClick = (e) => {
    if (!e.location) {
      setOpenConfirmation({
        isShow: true,
        actionType: CONFIRMATION_TYPE.EXPECTED_ON_SET,
        title: CONFIG.expectedOnSet,
        message:
          e.value === "addEOS"
            ? `Are you sure you want to add ${CONFIG.expectedOnSet}?`
            : `Are you sure you want to remove ${CONFIG.expectedOnSet}?`,
      });
      setSelectItem({ event: e, emp });
    }
  };

  const onHandleConfirmation = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      setSelectItem(null);
      return;
    }

    switch (type) {
      case CONFIRMATION_TYPE.EXPECTED_ON_SET:
        handleSelectEvent(selectItem.event, selectItem.emp);
        break;
    }
  };

  const TableRow = ({ e, i }) => {
    const [expand, setExpand] = useState(false);

    return (
      <>
        <tr style={{ borderBottom: "0" }}>
          <div
            key={i}
            onClick={(f) => {
              f.stopPropagation();
              handleItemClick(e);
              // !e.location && handleSelectEvent(e, emp);
            }}
            className="my-1 py-2 px-3 eosEventBar"
          >
            <div className="d-flex align-items-center">
              {e.location && <i className={`fa ${e.location === "At Home" ? "fa-home" : "fa-building"} me-2`} />}
              <span>{e.label}</span>
            </div>
            {e.location && (
              <div className="eventIcon">
                <div className="d-flex align-items-center">
                  <i
                    className={`fas fa-trash clickableIcon`}
                    title="Delete Test"
                    alt="Delete"
                    onClick={() => handleRemoveTestTypes(e.value)}
                  />
                  <i
                    className={`fas ${expand ? "fa-angle-up" : "fa-angle-down"}`}
                    alt="more icon"
                    onClick={(e) => {
                      e.preventDefault();
                      setExpand(!expand);
                      e.stopPropagation();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          {expand && (
            <>
              <div>
                {DROPDOWN_OPT.map((option, index) => (
                  <div
                    className="eosItemModal-options  cursor-pointer px-3 my-1 py-2  eosEventBar"
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty("background-color", `rgba(163, 164, 164, 0.2)`, "important");
                        el.style.setProperty("border-left", "4px solid rgb(163, 164, 164)", "important");
                      }
                    }}
                    onClick={() => handleDropDownOptions(option, e)}
                  >
                    <span key={index}>{option.title}</span>
                  </div>
                ))}
              </div>
            </>
          )}
        </tr>
      </>
    );
  };

  return (
    <>
      <Modal
        show
        backdrop="static"
        animation={true}
        onHide={handleClose}
        className="eos-employeeModal"
        centered
        scrollable
        size={"md"}
        keyboard={true}
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0 modal-title d-flex flex-column w-90 h4" id="contained-modal-title-vcenter">
            <span>Manage Schedule:</span>
            <div className="d-flex fs-5 flex-row align-items-center  justify-content-between">
              <span>
                Name: {emp.firstName} {emp.lastName}
              </span>{" "}
              <span>Date: {date}</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          <div className="form-group-wrapper d-block">
            <div className="table-responsive overFlow-y-hidden">
              <Table className="table">
                <tbody>
                  {filteredEvents?.map((emp, index) => (
                    <TableRow e={emp} i={index} />
                  ))}
                </tbody>
              </Table>
            </div>
          </div>

          {openConfirmation.isShow && (
            <ConfirmationModal
              cssClass="eos-employeeModal"
              show={openConfirmation.isShow}
              actionType={openConfirmation.actionType}
              title={openConfirmation.title}
              message={openConfirmation.message}
              handleConfirm={onHandleConfirmation}
              closeBtn
            />
          )}
        </Modal.Body>
        <div className="px-4 pb-2" style={{ minHeight: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
      </Modal>
    </>
  );
};

export default EOSItemsModal;
