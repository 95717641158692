import React, { useEffect, useState } from "react";
import { Button, Modal, Card, Table, Row, Col, Form } from "react-bootstrap";
import API from "api";
import moment from "moment";
import ErrorMessage from "components/Message/ErrorMessage";
import { formatCallTime, formatNewCallTime } from "utils";
import Loader from "components/Loader/Loader";

const InconsistentDepartmentCallTimeModal = ({ data, handleClose, appContext, sendType }) => {
  const [users, setUsers] = useState(data.departments);
  const [callTime, setCallTime] = useState(data.callTime);
  const [checkboxes, setCheckboxes] = useState(users.map((t) => t.id));
  const [selectedOption, setSelectedOption] = useState("new");
  const [allSelected, setAllSelected] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setUsers(data.departments);
    setCallTime(data.callTime);
  }, [data.departments]);

  const toggleCheckboxes = (val) => setCheckboxes(val ? users.map((t) => t.id) : []);

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    if (users.length === filteredList.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
    setCheckboxes(filteredList);
  };

  const onChangeType = (e) => setSelectedOption(e.target.checked ? "relative" : "new");

  const handleSaveChange = async () => {
    if (!selectedOption) {
      setError("Please select the option");
      return;
    }
    if (checkboxes.length === 0) {
      setError("Please select the Department");
      return;
    }
    const newList = users.filter((test) => !checkboxes.includes(test.id));
    let res = { employees: [] };
    setLoading(true);
    try {
      if (selectedOption === "new") {
        const deptIds = users
          .filter((test) => checkboxes.includes(test.id))
          .map((e) => {
            return { id: e.id, callTime: e.newCallTime, name: e.name };
          });
        res = await API.updateDepartmentCallTime(deptIds, appContext?.company, appContext.user?.name, sendType);
      } else if (selectedOption === "relative") {
        const deptIds = users
          .filter((test) => checkboxes.includes(test.id))
          .map((e) => {
            const deptTime = moment(`${moment().format("YYYY-MM-DD")} ${e.newCallTime}`);
            return { id: e.id, callTime: deptTime.add(e.diff, "minutes").format("HH:mm"), name: e.name };
          });
        res = await API.updateDepartmentCallTime(deptIds, appContext?.company, appContext.user?.name, sendType);
      }

      Object.assign(res, {
        selectedOption,
      });

      if (newList.length === 0) {
        setLoading(false);
        handleClose(res);
        return;
      }
      if (res.departments?.length === 1) {
        appContext.showSuccessMessage(
          `You have successfully updated the call time of ${res.departments[0].name} department!`
        );
      } else if (res.departments?.length > 0) {
        appContext.showSuccessMessage(
          `You have successfully updated the call time of ${res.departments.length} departments!`
        );
      }
      setUsers(newList);
      setCheckboxes([]);
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      console.log("Error:-", err.message);
      setLoading(false);
    }
  };

  const getTimeOffset = (user) => {
    const deptTime = moment(callTime);
    if (user.callTime && user.diff !== 0) {
      const diff = user.diff;
      const hours = diff > 0 ? Math.floor(diff / 60) : Math.ceil(diff / 60);
      const mins = `${diff > 0 ? "+" : ""}${diff % 60} mins`;
      let response = "";
      if (hours === 0) {
        response = mins;
      } else if (hours > 0) {
        response = hours === 1 ? `+${hours} hour ${mins}` : `+${hours} hours ${mins}`;
      } else {
        response = hours === -1 ? `${hours} hour` : `${hours} hours ${mins}`;
      }

      return <span className={diff < 0 ? "colorRed" : "colorGreen"}>{response}</span>;
    }
    return "-";
  };

  const TableRow = ({ user, index }) => {
    return (
      <tr key={index}>
        <td style={{ width: "10%" }}>
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={user.id}
              checked={checkboxes.indexOf(user.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, user.id)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={user.lastName}>
          {user.name}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={formatCallTime(user.callTime) || "-"}>
          {formatCallTime(user.callTime) || "-"}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={formatCallTime(user.newCallTime) || "-"}>
          {formatCallTime(user.newCallTime) || "-"}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={user.callTime}>
          {getTimeOffset(user)}
        </td>
      </tr>
    );
  };
  return (
    <Modal show animation={true} onHide={() => handleClose({ employees: [] })} centered size={"xl"} keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Department Call Time Offset
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="marginTop0">
        <div style={{ flex: 1 }}>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Row>
                    <Card.Title
                      as="h4"
                      style={{
                        marginBottom: 10,
                        fontWeight: "bold",
                        color: "#565656",
                      }}
                    >
                      We have found the below Departments have unique calls times that are not consistent with the
                      General Call Time setting. What would you like to do?
                    </Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <div className="inconsistant-radio-wrapper justify-content-between">
                    <div className="form-group-wrapper">
                      <Form.Check className="d-flex alin-items-center">
                        <Form.Check.Input
                          id="relative"
                          // value={selectedOption}
                          value="relative"
                          name="resultGroupList"
                          type="checkbox"
                          checked={selectedOption === "relative"}
                          onChange={onChangeType}
                        />
                        <label htmlFor="relative" className="ms-2 programName cursor-pointer">
                          Keep Offset
                        </label>
                      </Form.Check>
                    </div>
                  </div>

                  <div className="buttonHeader">
                    <Button
                      className="headerButton"
                      onClick={() => {
                        if (users && users.length > 0) {
                          setAllSelected(!allSelected);
                          toggleCheckboxes(!allSelected);
                        }
                      }}
                    >
                      {!allSelected ? "Select All" : "Deselect All"}
                    </Button>
                  </div>
                  <div className="form-group-wrapper d-block">
                    <div className="table-responsive" style={{ overflow: "hidden" }}>
                      <Table className="table-hover table-striped">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>#</th>
                            <th className="ellipsis centered" style={{ textAlign: "center" }}>
                              Dept Name
                            </th>
                            <th className="ellipsis centered" style={{ textAlign: "center" }}>
                              Current Call Time
                            </th>
                            <th className="ellipsis centered" style={{ textAlign: "center" }}>
                              New General Call Time
                            </th>
                            <th className="ellipsis centered" style={{ textAlign: "center" }}>
                              Offset
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.length > 0 &&
                            users.map((user, index) => <TableRow user={user} index={index} key={index} />)}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <div className="px-4" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      {!loading ? (
        <Modal.Footer>
          <Button
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => handleClose({ employees: [] })}
          >
            Cancel
          </Button>
          <Button variant="secondary" className="modalButtons headerButton btn-fill" onClick={handleSaveChange}>
            Update
          </Button>
        </Modal.Footer>
      ) : (
        <Loader />
      )}
    </Modal>
  );
};

export default InconsistentDepartmentCallTimeModal;
