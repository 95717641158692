import React, { useEffect } from "react";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";

const Loader = () => {
  return (
    <>
      <div
        style={{ width: "100vw", height: "100vh", position: "fixed", top: "0", left: "0", zIndex: "1000000000000" }}
      />
      <Bounce
        size={35}
        style={{ position: "absolute", top: "50%", left: "50%", right: "50%", zIndex: "100" }}
        color={"#A82632"}
      />
    </>
  );
};
export default Loader;
