import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import ErrorMessage from "components/Message/ErrorMessage";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

const DateRangeModal = (props) => {
  const { handleClose, handleSave } = props;
  const [error, setError] = useState("");
  const [date, setDate] = useState({});

  const onhandleSave = () => {
    if (Object.keys(date).length === 0) {
      setError("Please Select Date Range");
      return;
    }
    handleSave(date);
  };

  const UpdateValue = () => {
    return {
      autoUpdateInput: false,
      minDate: moment(),
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Clear",
      },
    };
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
    setDate({});
  };

  const handleApply = (event, picker) => {
    picker.element.val(picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY"));
    console.log("DateRange", picker.startDate, picker.endDate);
    setDate({
      startDate: moment(picker.startDate).format("YYYY-MM-DD"),
      endDate: moment(picker.endDate).format("YYYY-MM-DD"),
    });
  };

  return (
    <Modal show onHide={handleClose} animation={true} size={"md"} keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0">Date Range Picker</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-12">
            <div className="createClientsWrapper flex-wrap">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders mt-0">Select Date Range:</label>
                <DateRangePicker onApply={handleApply} onCancel={handleCancel} initialSettings={UpdateValue()}>
                  <input
                    type="text"
                    placeholder="Select Date Range"
                    readOnly
                    className="dateRangeMatrix modalInput"
                    defaultValue=""
                  />
                </DateRangePicker>
              </div>
            </div>
          </div>
          <div style={{ minHeight: "3vh" }}>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="secondary" className="headerButton btn-fill" onClick={onhandleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DateRangeModal;
