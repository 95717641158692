import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { Button, Form, Modal, Card, Table, Row, Col } from "react-bootstrap";
import Select from "react-select";
import GlobalSearch from "components/GlobalSearch";
import { TEST_TYPES } from "constant";
import { LOCATIONS_OPTIONS } from "constant";
import ViewportList from "react-viewport-list";
import { formatDateMDY } from "utils";
import moment from "moment";
import DatePicker from "react-date-picker";

import API from "api";
import Loader from "components/Loader/Loader";
import CustomSchDetailItems from "./CustomSchedule/CustomSchDetailItems";
import ProfileListItems from "./profileRelationListItem";
import FilterPopOver from "components/FilterPopOver";
import Icon from "components/Icon";
import { AppContext } from "context/app-context";
import { COMBINE_PROFILE } from "constant";

const ProfileRelationModal = (props) => {
  const { filterEmployees, handleClose, employeesId, getConnectedProfiles, connected } = props;
  const appContext = useContext(AppContext);
  const [users, setUsers] = useState(filterEmployees);
  const [filter, setFilter] = useState({});
  const [relations, setRelations] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [triggerFilter, setTriggerFilter] = useState();
  const [relationOptions, setRelationOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRelations(connected);
    getRelationsData();
  }, []);

  const getRelationsData = async () => {
    const data = appContext.proxyContacts;
    const dataToSave = data.map((d) => {
      return { value: d.name, label: d.name };
    });
    dataToSave.push({ value: COMBINE_PROFILE, label: COMBINE_PROFILE });
    setRelationOptions(dataToSave);
  };

  const handleMakeRelation = useCallback(
    (item, relation) => {
      if (item.id && relation) {
        setRelations([...relations, { ...item, relation: relation }]);
        setFilteredUsers(
          nestedFilter(
            filteredUsers.filter((f) => f.id !== item.id),
            filter
          )
        );
        setUsers(users.filter((f) => f.id !== item.id));
      }
    },
    [relations, filter]
  );

  const handleDeleteRelation = (item) => {
    setRelations(relations.filter((f) => f.id !== item.id));
    const users = [...filteredUsers];
    users.unshift(item);
    setFilteredUsers(nestedFilter(users, filter));
    setUsers((prev) => [item, ...prev]);
  };

  const [checkboxes, setCheckboxes] = useState([]);
  const searchTerms = ["First Name", "Last Name", "Email", "Phone"];
  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }
        if (key === "Lab") {
          return obj.labID && appContext.getLabName(obj.labID).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "Show") {
          const siteIds = appContext.sites
            .filter((s) => s.name.toLowerCase().includes(filters[key].toLowerCase()))
            .map((s) => s.id);
          return siteIds.includes(obj.siteID);
        }
        if (key === "ShowID") {
          return obj.siteID === filters[key];
        }
        if (key === "Client") {
          return (
            obj.clientID && appContext.getClientName(obj.clientID).toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "result") {
          return obj[key] && obj["viewedResult"].toLowerCase().includes(filters[key]);
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }
        if (key === "emailSend") {
          return obj.emailSend !== null && obj.emailSend == filters[key];
        }
        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };
  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(nestedFilter(users, filter));
    }
  }, [filter]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const relationList = relations.filter((f) => f.relation !== COMBINE_PROFILE);
      const combineList = relations.filter((f) => f.relation === COMBINE_PROFILE);
      if (connected.length > 0 || relationList.length > 0) {
        let result = await API.updateRelations(
          employeesId,
          relationList.map((m) => ({ id: m.id, relation: m.relation, relationType: "Child" })),
          connected.filter((f) => relations.findIndex((i) => f.id === i.id) === -1)
        );
        let logData = [];
        logData.push({
          schrID: employeesId,
          auditType: "ProxyProfile",
          action: "View Details",
          relations: JSON.stringify(
            relationList.map((m) => ({
              id: m.id,
              firstName: m.firstName,
              lastName: m.lastName,
              relation: m.relation,
            }))
          ),
          companyID: appContext.company.id,
          userName: appContext.user.name,
        });
        await API.addlogs(logData);
      }

      if (combineList.length > 0) {
        const res = await API.mergeEmployeeLogs(
          employeesId,
          combineList.map((m) => m.id)
        );
        if (res.data) {
          for (const emp of combineList) {
            await API.deleteEmployee(emp.id);
          }

          let logData = [];
          logData.push({
            schrID: employeesId,
            auditType: "CombineProfile",
            action: "View Details",
            deletedIds: JSON.stringify(
              combineList.map((m) => ({
                id: m.id,
                firstName: m.firstName,
                lastName: m.lastName,
                phoneNumber: m.phoneNumber,
              }))
            ),
            companyID: appContext.company.id,
            userName: appContext.user.name,
          });
          await API.addlogs(logData);
        }
      }
      setLoading(false);
      appContext.showSuccessMessage("Proxy relation updated successfully!");
      getConnectedProfiles();
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log("Error:-", err);
    }
  };

  return (
    <Modal show onHide={handleClose} animation={true} size={"xxl"} scrollable keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0">Manage Proxy Profiles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="mt-0 fw-bold">{`Proxy Profiles (${relations.length})`}</h4>
        <div className="">
          <Table className="table">
            <thead>
              <tr>
                <th className="">First Name</th>
                <th className="">Last Name</th>
                <th className="">Email</th>
                <th className="">Phone</th>
                <th className="">Relation</th>
                <th className="">Action</th>
              </tr>
            </thead>
            <tbody>
              {relations?.map((item, i) => {
                return (
                  <tr>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.email}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.relation}</td>
                    <td>
                      <i className="fa fa-trash" onClick={() => handleDeleteRelation(item)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <h4 className="mt-0 fw-bold">{`All Profiles (${filteredUsers.length})`}</h4>
        <Card className="strpied-tabled-with-hover">
          <Card.Body className="table-full-width desktop-noScroll">
            <Icon handleClick={() => setShowFilter(!showFilter)} label="Filter" title="Filter" iconType={"filter"} />
            {showFilter && (
              <FilterPopOver
                filterTerms={searchTerms}
                setFilter={setFilter}
                filter={filter}
                triggerFilter={triggerFilter}
                setTriggerFilter={setTriggerFilter}
              />
            )}
            <div className={"table-responsive "}>
              <Table className="table">
                <thead>
                  <tr>
                    <th className="tdWidth16">First Name</th>
                    <th className="tdWidth16">Last Name</th>
                    <th className="tdWidth16">Email</th>
                    <th className="tdWidth16">Phone</th>
                    <th className="tdWidth16">Zone Name</th>
                    <th className="tdWidth16">Relations</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.length > 0 && (
                    <ViewportList
                      items={filteredUsers}
                      overscan={10}
                      itemMinSize={42}
                      margin={16}
                      overflowAnchor="auto"
                      withCache={false}
                      scrollThreshold={500}
                    >
                      {(emp, index) =>
                        employeesId ? (
                          <ProfileListItems
                            key={emp.id}
                            options={relationOptions}
                            //   handleCheck={handleCheckBox}
                            item={emp}
                            employeeId={employeesId}
                            index={index}
                            checkboxes={checkboxes}
                            handleMakeRelation={handleMakeRelation}
                            relations={relations}
                          />
                        ) : (
                          <></>
                        )
                      }
                    </ViewportList>
                  )}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
      {/* <div className="px-4 pt-2" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div> */}
      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={handleClose}>
          Close
        </Button>
        {
          <Button variant="secondary" className="headerButton btn-fill" onClick={handleSave}>
            Save
          </Button>
        }
      </Modal.Footer>
      {loading && <Loader />}
      {/* {showConfirmationMessage && (
        <ConfirmationModal
          show={showConfirmationMessage ? true : false}
          title="Update Custom Schedule"
          message={showConfirmationMessage}
          handleConfirm={handleConfirm}
          closeBtn
        />
      )} */}
    </Modal>
  );
};

export default ProfileRelationModal;
