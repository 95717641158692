import React, { useState, useRef } from "react";
import { Button, Modal, Card, Table, Row, Col, Collapse } from "react-bootstrap";
import { formatDateMDY, formatDateMDYTime, toTitleCase } from "utils";
import api from "api";
import { Audit_Types, Zone_Audit_Types, TEST_TYPE_VALUE, Zone_PARSE_Audit_Types, Audit_Parse_Types } from "constant";
import { RESULT_COLOR } from "constant";
import { CapsFirstLetter } from "utils";

const AuditTrailItem = (props) => {
  const { log, index, handleClick, handleEmplQues, isReadOnlyUser, open, handleViewPopOver } = props;
  const childNode = useRef();
  const eventView = () => {
    if (log.isDeleted && log.sequenceNo) return "Test Deleted";
    if (log.result) {
      return isReadOnlyUser ? "Test Completed" : CapsFirstLetter(log.result);
    }
    if (log) {
      return (
        (log.auditType === "Message" && log.message?.replace(/<[^>]+>/g, "")) ||
        (log.auditType === "DriveOnAccess" &&
          log.dateRange &&
          `${log.action} (Start Date: ${formatDateMDY(log.dateRange.startDate)} - End Date: ${formatDateMDY(
            log.dateRange.endDate
          )} )`) ||
        log.reason ||
        (log.action === "ChangeType" &&
          `${TEST_TYPE_VALUE[log.prevData] || "Test"} Changed to ${TEST_TYPE_VALUE[log.test_type] || ""}`) ||
        log.action ||
        (log.sequenceNo && "Test Created")
      );
    }
  };

  const eventCSS = () => {
    if (log.result && !isReadOnlyUser && !log.isDeleted) {
      return {
        color: RESULT_COLOR[log.result?.toLowerCase()],
        textAlign: "center",
        cursor: "pointer",
      };
    }
    return {
      textAlign: "center",
    };
  };

  return (
    <tr>
      <td>
        {log.action === "Done" && (
          <i
            onClick={() => handleEmplQues(log)}
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.color = "#8B0000";
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            className={open ? "fas fa-minus" : "fas fa-plus"}
          />
        )}
      </td>
      <td>{index + 1}</td>
      <td>{formatDateMDYTime(log.createdAt)}</td>
      <td style={{ textAlign: "center" }}>
        {log.filePath
          ? `${TEST_TYPE_VALUE[log.test_type] || ""} (ExternalTest)`
          : TEST_TYPE_VALUE[log.test_type] ||
            (log.auditType === "Message" && log.messageType) ||
            Audit_Parse_Types[log.auditType] ||
            Zone_PARSE_Audit_Types[log.auditType]}
      </td>
      {(log.auditType === "CovidTest" ||
        log.auditType === "ExternalTest" ||
        log.auditType === "ExternalTestRemove") && <td style={{ textAlign: "center" }}>{log.sequenceNo || "-"}</td>}
      <td
        className="ellipsis"
        style={eventCSS()}
        ref={childNode}
        onClick={() => {
          if (log.auditType === "CombineProfile" || log.auditType === "ProxyProfile") {
            handleViewPopOver(log, childNode);
          } else if (log.auditType !== "ExternalTestRemove") handleClick(log);
        }}
        title={eventView()}
      >
        {eventView()}
      </td>
      {/* <td style={{ textAlign: "center", textOverflow: "visible"  }}>{log.detail}</td> */}
      <td style={{ textAlign: "center" }}>{log.userName || "System"}</td>
    </tr>
  );
};

export default AuditTrailItem;
