import React, { useRef, useContext } from "react";
import QRCode from "qrcode.react";
import cardLogo from "assets/img/safecamp_BRAND.1-nobg.png";
import Default_Profile from "assets/img/default_profile.png";
import { removeSpaceIns } from "utils";
const QrCodeCard = (props) => {
  const { profileImage, newUser, appContext, preReg } = props;
  const program = appContext?.programs.find((f) => f.name === newUser.programName);

  return (
    <>
      <div className="codeScannerWrapper">
        <div className="registered-successful">
          <div className="mainLogo-wrapper">
            <img src={cardLogo} alt="" className="img-fluid" />
          </div>
          <div className="registered-inner">
            <h4 className="my-0 card-name">{`${newUser.firstName} ${newUser.lastName}`}</h4>
            <div className="justify-content-center align-items-center">
              <div className="qrWrapper">
                <img
                  crossOrigin="true"
                  src={
                    profileImage === Default_Profile
                      ? Default_Profile
                      : profileImage instanceof Object
                      ? URL.createObjectURL(profileImage)
                      : profileImage
                  }
                  onError={(event) => {
                    event.target.src = Default_Profile;
                  }}
                  alt="user icon"
                  className="userIcon"
                />
                <div className="h4">
                  <h4 className="my-0">{appContext?.company?.companyName}</h4>
                  <h4 className="my-0">{newUser.jobTitle || ""}</h4>
                </div>
                <QRCode
                  className="qrCode"
                  id="qr-gen"
                  value={newUser.id}
                  size={500}
                  level={"M"}
                  fgColor={program ? removeSpaceIns(program.color) : "black"}
                  includeMargin={true}
                />
              </div>
            </div>
            {!preReg && (
              <div className="text-right w-100">
                <button className="btn-sm btn btn-primary mt-3 print-btn">Print</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default QrCodeCard;
