import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "context/app-context";
import {
  Button,
  Card,
  Table,
  Row,
  Col,
  Form,
  DropdownButton,
  Dropdown,
  Overlay,
  Popover,
  Modal,
} from "react-bootstrap";
import moment from "moment";
import { MESSAGE_SEND_TO_MAIN_CONTACT, MAIN_CONTACT_MESSAGE } from "constant";
import Loader from "components/Loader/Loader";
import RadioButton from "components/RadioButton/RadioButton";
import Status from "components/Status/Status";
import api from "api";
import Default_Profile from "assets/img/default_profile.png";
import QuestionSubmissionModal from "components/Modal/QuestionSubmissionModal";
import html2canvas from "html2canvas";
import QrCodeCard from "components/QrCard/QrCodeCard";

const QuestionSubmitForm = () => {
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [newQuestions, setNewQuestions] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [isAsnwers, setIsAnswers] = useState(false);
  const downloadRef = useRef();

  const onHandelSubmit = async () => {
    //await api.testFunc(employee);
    if (!newQuestions.every((e) => e.answer)) {
      appContext.showErrorMessage("Please Fill All Answers of Questions");
      return;
    }
    if (employee?.qusAttempted > 2) {
      appContext.showInfoMessage(
        appContext.company?.contactNumber
          ? `${MAIN_CONTACT_MESSAGE.SAFECAMP_ERROR_MESSAGE} ${appContext.company.contactName} at ${appContext.company.contactNumber} for further instructions.`
          : MAIN_CONTACT_MESSAGE.defaultMessage
      );
      return;
    }
    try {
      setLoading(true);
      const isAsnwer = newQuestions.every(
        (quest) => questions.findIndex((f) => f.id === quest.id && f.answer === quest.answer) !== -1
      );
      const updateEmp = await api.addEmployeeQuestion(employee, newQuestions, isAsnwer);
      setIsAnswers(isAsnwer);
      setSubmit(true);
      if (updateEmp) setEmployee(updateEmp);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  const handleSave = async (newQuestions) => {
    setLoading(true);
    setSubmit(false);
    let listUpdate = null;
    if (
      newQuestions.every((quest) => questions.findIndex((f) => f.id === quest.id && f.answer === quest.answer) !== -1)
    ) {
      listUpdate = await api.updateEmployeeQuestionList(employee, appContext.user.name);
    } else if (employee.qusAttempted === 1) {
      const phoneNumber = appContext.company?.contactNumber;
      const message =
        MESSAGE_SEND_TO_MAIN_CONTACT.HELLO +
        employee.firstName +
        " " +
        employee.lastName +
        MESSAGE_SEND_TO_MAIN_CONTACT.WRONG_ANSWER +
        employee.phoneNumber +
        MESSAGE_SEND_TO_MAIN_CONTACT.SAFE_CAMP_LOGIN;
      if (phoneNumber) await api.sendSMSNotification(phoneNumber, message);
    }
    listUpdate
      ? appContext.showSuccessMessage("Submitted Answers Successfully Thank You!")
      : appContext.showInfoMessage(
          appContext.company?.contactNumber
            ? `${MAIN_CONTACT_MESSAGE.SAFECAMP_ERROR_MESSAGE} ${appContext.company.contactName} at ${appContext.company.contactNumber} for further instructions.`
            : MAIN_CONTACT_MESSAGE.defaultMessage
        );
    if (listUpdate) appContext.resetEmployees();
    setLoading(false);
    // } catch (err) {
    //   console.log(err.message);
    // }
  };

  const handleClear = () => {
    setNewQuestions(questions.map((ques) => ({ ...ques, answer: "" })));
  };

  const handleAnswer = (e, quesId) => {
    const questionGroup = [...newQuestions];
    const index = questionGroup.findIndex((f) => f.id === quesId);
    if (index !== -1) {
      questionGroup[index].answer = e.target.value;
    }
    setNewQuestions(questionGroup);
  };

  useEffect(async () => {
    if (appContext.employees.length > 0) {
      let emp = appContext.employees[0];
      if (appContext.isZoneLevelCompany() && emp) {
        emp = await formatEmployee(emp, appContext.programs, appContext.schedules);
      }
      setEmployee(emp);
      if (!questions && emp.questionID && appContext.questions.length > 0 && !emp.qaDone) {
        const findQuestions = appContext.questions.find((f) => f.id === emp.questionID);
        const questionGroup = JSON.parse(findQuestions.question).question;
        setQuestions(questionGroup);
        setNewQuestions(questionGroup.map((ques) => ({ ...ques, answer: "" })));
      }
      if (emp.qaDone) {
        setQuestions(null);
        setNewQuestions([]);
      }
    }
  }, [appContext.employees, appContext.questions, appContext.schedules, appContext.programs, appContext.company]);

  // console.log("appContext User", appContext.employees, appContext.questions);

  const formatEmployee = (employee, programs, schedules) => {
    if (!employee) return;
    let emp = { ...employee };
    const program = programs.find((f) => f.id === emp.programID);
    const quesSch = program.testsData.filter((f) => f.question);
    if (quesSch && quesSch.length > 0) {
      const isTodayQuestions = quesSch.find((f) => f.date === moment().format("YYYY-MM-DD"));
      if (isTodayQuestions) {
        emp.questionID = program.scheduleID;
      }
    }

    return emp;
  };

  const QuestionsGroup = ({ listLength, item, handleAnswer, index }) => {
    const isAsnwer = questions.findIndex((f) => f.id == item.id && f.answer === item.answer) !== -1;
    return (
      <div className="questions-wrapper rounded-3 p-3">
        <div className="row">
          <div className="col-md-1">
            <div>
              <label className="fs-6 mb-0">Q#{index + 1}</label>
            </div>
          </div>
          <div className="col-md-8">
            <p
              style={{ width: "100%", resize: "none" }}
              id={item.id}
              className=" border-0 mb-0"
              placeholder={`Question ${index + 1}`}
            >
              {item.name}
            </p>
          </div>
          <div className="col-md-3">
            <div className="d-flex align-items-center  align-items-start justify-content-start column-gap-10">
              <Form className="d-flex align-items-center column-gap-15">
                <RadioButton
                  id={`${Math.random() * 2}`}
                  value="Yes"
                  label="Yes"
                  cssClass="colorBlack"
                  name="AssignGroupList"
                  checked={item.answer === "Yes"}
                  handleChange={(e) => handleAnswer(e, item.id)}
                />
                <RadioButton
                  id={`${Math.random() * 3}`}
                  value="No"
                  label="No"
                  cssClass="colorBlack"
                  name="AssignGroupList"
                  checked={item.answer === "No"}
                  handleChange={(e) => handleAnswer(e, item.id)}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const downloadQRCode = async () => {
    const element = downloadRef.current;

    const canvas = await html2canvas(element);
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${employee.phoneNumber.substring(2)}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (employee?.qaDone) {
    return (
      <Card>
        <Card.Header>
          <Card.Title
            as="h4"
            style={{
              marginBottom: 10,
              fontWeight: "bold",
            }}
          >
            Health & Wellness Questions and Answers
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="codeScannerWrapper">
            <div className="registered-successful">
              <div className="justify-content-center align-items-center pt-4">
                <div
                  id="section-to-print"
                  className="printSide signUpCodeScannerWrapper"
                  ref={downloadRef}
                  style={{ display: "block" }}
                >
                  <QrCodeCard profileImage={Default_Profile} newUser={employee} preReg appContext={appContext} />
                </div>
                <div className="downloadQrBtn">
                  <div className="py-3 d-flex align-items-center justify-content-evenly">
                    <Button className="w-75" variant="secondary" onClick={downloadQRCode}>
                      Download QR Code
                    </Button>
                    <Button variant="secondary" onClick={() => window.print()}>
                      <i className="fas fa-print fs-4" style={{ color: "black" }} />
                    </Button>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  Health & Wellness Questions and Answers
                </Card.Title>
              </Card.Header>
              {newQuestions.length > 0 ? (
                <Card.Body className="px-3">
                  <div className="col-md-12 d-none d-md-block">
                    <div className="row">
                      <div className="col-md-9">
                        <h5 className="mt-0 fw-bold">Questions:</h5>
                      </div>
                      <div className="col-md-3">
                        <h5 className="mt-0 fw-bold">Answers:</h5>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {newQuestions.map((item, index) => {
                      return (
                        <QuestionsGroup
                          listLength={newQuestions.length}
                          key={item.id}
                          index={index}
                          item={item}
                          handleAnswer={handleAnswer}
                        />
                      );
                    })}
                    <div className="buttonHeader pb-3">
                      <Button
                        variant={"secondary"}
                        className="headerButton btn-fill ms-auto"
                        disabled={submit}
                        onClick={() => handleClear()}
                      >
                        Clear
                      </Button>
                      <Button className="headerButton btn-fill" onClick={() => onHandelSubmit()}>
                        Submit
                      </Button>
                    </div>
                  </div>
                  {submit && (
                    <QuestionSubmissionModal
                      handleClose={() => setSubmit(false)}
                      handleSave={handleSave}
                      newQuestions={newQuestions}
                      questions={questions}
                      isAsnwers={isAsnwers}
                    />
                  )}
                </Card.Body>
              ) : (
                <Card.Body>
                  <strong className="ms-auto"> No Questions Today</strong>
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default QuestionSubmitForm;
