import React, { useState } from "react";
import { Button, Modal, Card, Table, Row, Col, Collapse } from "react-bootstrap";
import { formatDateMDY, formatDateMDYTime, toTitleCase } from "utils";
import api from "api";
import { Audit_Types, Zone_Audit_Types, TEST_TYPE_VALUE, Zone_PARSE_Audit_Types, Audit_Parse_Types } from "constant";
import { RESULT_COLOR } from "constant";
import AuditTrailItem from "components/Modal/AuditTrailItem";
import ExpandableTablePopOverStick from "components/ExpandableTablePopOverStick";

const AuditTrailEntries = (props) => {
  const { item, auditTrails, user, zoneLevel, handleResult, handleExternalTestView, appContext } = props;

  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);

  const handleExpand = () => {
    const sortList = auditTrails.sort((a, b) => (b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0));

    let logs = [];
    if (zoneLevel) {
      logs = sortList.filter((f) => Zone_Audit_Types[f.auditType] === item);
    } else {
      logs = sortList.filter((f) => Audit_Types[f.auditType] === item);
    }

    setItems(logs);
    setShow(!show);
  };

  console.log("items", items);

  const handleClick = (log) => {
    if (appContext.isReadOnly() || log.isDeleted) return;
    if ((log.result && log.filePath) || log.DoAImage) {
      handleExternalTestView(log.filePath);
      return;
    }
    if (log.result) {
      handleResult(log.sequenceNo);
      return;
    }
  };

  const TableCollapse = ({ item, index }) => {
    return (
      <tr className="expended-row">
        <td>{index + 1}</td>
        <td>{item.question}</td>
        <td style={{ textAlign: "center" }}>{item.answer}</td>
      </tr>
    );
  };
  const TableRow = ({ log, index }) => {
    const [quesAns, setQuesAns] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [childRef, setChildRef] = useState();
    const [open, setOpen] = useState(false);

    const handleEmplQues = async (log) => {
      const models = await api.getEmployeeQuestion(user.id.replace("%testNow", ""), log.createdAt);
      setQuesAns(models);
      setOpen(!open);
    };

    const handleViewPopOver = (log, childNode) => {
      console.log("handleViewPopOver", log);
      const list = log.deletedIds || log.relations;
      setProfiles(list ? JSON.parse(list) : []);
      setChildRef(childNode);
      setOpen(!open);
    };
    return (
      <>
        <AuditTrailItem
          index={index}
          log={log}
          handleClick={handleClick}
          handleEmplQues={handleEmplQues}
          handleViewPopOver={handleViewPopOver}
          isReadOnlyUser={appContext.isReadOnly()}
          open={open}
        />
        {quesAns.length > 0 && open && (
          <tr>
            <td colSpan={"12"} className="expended-table-wrapper">
              <Card className="striped-table-card">
                <Card.Body>
                  <div className="form-group-wrapper d-block">
                    <div className="table-responsive overFlow-y-hidden">
                      <Table className="table expandedTable">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>Sr#</th>
                            <th>Question</th>
                            <th className="centered">Answer</th>
                          </tr>
                        </thead>
                        <tbody>
                          {quesAns.length > 0 &&
                            quesAns.map((item, i) => {
                              return <TableCollapse key={i} index={i} item={item} />;
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </td>
          </tr>
        )}
        {profiles.length > 0 && open && (
          <ExpandableTablePopOverStick profiles={profiles} childNode={childRef} handleClose={() => setOpen(false)} />
        )}
      </>
    );
  };
  return (
    <>
      <tr
        onClick={handleExpand}
        onMouseOver={(e) => {
          e.target.style.cursor = "pointer";
        }}
      >
        <td>
          <i
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.color = "#8B0000";
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            className={show ? "fas fa-minus" : "fas fa-plus"}
          />
        </td>
        <td>{item}</td>
      </tr>
      {items.length > 0 && show && (
        <tr className="subTableRow">
          <td colSpan={"12"} className="expended-table-wrapper">
            <Card className="striped-table-card">
              <Card.Body>
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive overFlow-y-hidden">
                    <Table className="table expandedTable">
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ width: "10%" }}>Sr#</th>
                          <th>Date</th>
                          <th className="border-0 centered">Action</th>
                          {item === Audit_Types["CovidTest"] && <th className="border-0 centered">SequenceNo</th>}
                          <th className="border-0 centered w-25">Event</th>
                          <th className="border-0 centered">User</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.length > 0 &&
                          items.map((item, i) => {
                            return <TableRow key={i} index={i} log={item} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </td>
        </tr>
      )}
    </>
  );
};

export default AuditTrailEntries;
