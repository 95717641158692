import React from "react";
import { Bar, Line, Doughnut, Bubble } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import "chartjs-adapter-moment";
import "../../tableStyling.css";
ChartJS.register(...registerables);

const Graph = (props) => {
  const { type, labels, datasets, height, width } = props;
  const data = {
    labels: labels,
    datasets: datasets,
  };

  const doughnutOptions = {
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        align: "end",
        anchor: "end",
        font: { size: "5" },
      },
      legend: {
        labels: { font: { size: 30, weight: "bold", lineHeight: 50 } },
        display: type === "doughnut" ? true : false,
        position: "bottom",
      },
    },
  };

  const options = {
    barPercentage: 0.2,
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        align: "end",
        anchor: "end",
        font: { size: "5" },
      },
      legend: {
        display: type === "doughnut" ? true : false,
        position: "right",
      },
    },
    scales: {
      y: {
        min: 0,
        max:
          datasets && datasets.length > 0 && datasets[0].data
            ? Math.max(...datasets[0].data) + 5
            : 0,
        ticks: {
          stepSize: 5,
        },
      },
    },
  };

  const lineOptions = {
    plugins: {
      datalabels: {
        display: true,
        color: "#7f7f7f",
        align: "end",
        anchor: "end",
        font: { size: "5" },
      },
      legend: {
        display: false,
      },
    },
    tension: 0,
    responsive: true,
    maintainAspectRatio: false,
    pointRadius: 1.2,

    tooltips: {
      mode: "x",
      intersect: false,
    },

    // scales: {
    //   y: {
    //     min: 1,
    //     max: 100,
    //     stepSize: 5,
    //   },
    // },
    scales: {
      x: {
        min: "04:00",
        ticks: {
          stepSize: 150,
        },
        // type: "timeseries",
        time: {
          parser: "hh:mm",
          // round: 'day'
          // tooltipFormat: "hh:mm",
          // displayFormats: {
          //   millisecond: "HH:mm:ss.SSS",
          //   second: "HH:mm:ss",
          //   minute: "HH:mm a",
          //   hour: "HH",
          // },
        },
      },
    },
  };

  const renderGraph = () => {
    if (type === "doughnut")
      return <Doughnut options={doughnutOptions} data={data} />;
    if (type === "bar")
      return (
        <Bar height={height} width={width} options={options} data={data} />
      );
    if (type === "line")
      return (
        <Line height={height} width={width} options={lineOptions} data={data} />
      );
    return <></>;
  };

  return <>{renderGraph()}</>;
};

export default Graph;
