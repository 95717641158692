import React from "react";
import QRCode from "qrcode.react";
import { removeSpaceIns } from "utils";
import cardLogo from "../../assets/img/safecamp_BRAND.1-nobg.png";
const QrPrint = (props) => {
  const { newUser, appContext } = props;
  const program = appContext?.programs.find((f) => f.name === newUser.programName);

  return (
    <>
      <div className="codeScannerWrapper">
        <div className="registered-successful">
          <div className="registered-inner">
            <div className="justify-content-center align-items-center">
              <div className="qrPrintWrapper">
                <div className="mainLogo-wrapper mb-3">
                  <img src={cardLogo} alt="" className="img-fluid" />
                </div>
                <p className="mb-0">{`${newUser.firstName} ${newUser.lastName}`}</p>
                <QRCode
                  className="qrCode"
                  id="qr-gen"
                  value={newUser.id}
                  size={500}
                  level={"M"}
                  fgColor={program ? removeSpaceIns(program.color) : "black"}
                  includeMargin={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default QrPrint;
