/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Users from "views/Users";
import Employees from "views/Employees";
import Schedules from "views/Schedules";
import Questionnaire from "views/Questionnaire";
import Settings from "views/TestTypeSetting/TestTypesSetting";
import Programs from "views/Programs";
import employeeIcon from "../src/assets/img/employee-icon.png";
import activeProgramIcon from "../src/assets/img/active-programs.png";
import QuestionSubmitForm from "views/QuestionSubmitForm";
import copyIcon from "../src/assets/img/Copy-icon.svg";
import garageIcon from "../src/assets/img/settings-garage.png";
import TcMatrix from "views/TcMatrix";
import TestingCalendar from "views/TestingCalendar/TestingCalendar";
const dashboardRoutes = [
  {
    path: "/employees",
    name: "Screening Question",
    src: copyIcon,
    icon: "",
    component: QuestionSubmitForm,
    layout: "/admin",
  },
];

export default dashboardRoutes;
