import { EVENTS_LIMIT_PER_CELL } from "constant";
import { TEST_TYPE_VALUE } from "constant";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { removeSpaceIns } from "utils";

const EventsPopOver = (props) => {
  const { setShowPopover, showMoreDate, events, newProgram, handleSelectEvent, cssClass } = props;
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    const currentEvents = events.filter((e) => e.start === showMoreDate);
    const eventsToShow = currentEvents.filter((e, i) => i >= EVENTS_LIMIT_PER_CELL);
    if (eventsToShow.length === 0) handleClose();
    setFilteredEvents(eventsToShow);
  });

  const handleClose = () => {
    setShowPopover(false);
  };
  return (
    <>
      <Modal
        show
        animation={true}
        onHide={handleClose}
        className={cssClass}
        centered
        scrollable
        size={"sm"}
        keyboard={true}
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
            Show More Events
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          {filteredEvents.map((e, i) => (
            <div
              key={i}
              style={{
                backgroundColor: `${removeSpaceIns(newProgram?.color || "#000")}`,
                border: `1px solid ${removeSpaceIns(newProgram?.color || "#000")}`,
                color: "white",
                borderRadius: "4px",
              }}
              onClick={(f) => {
                f.stopPropagation();
                handleSelectEvent(e);
              }}
              className="d-flex justify-content-between align-items-center my-1"
            >
              <span>{TEST_TYPE_VALUE[e.title]}</span>
              {e.location === "At Home" ? <i className="fa fa-home" /> : <i className="fa fa-building" />}
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EventsPopOver;
