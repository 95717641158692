import React, { useContext } from "react";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import { PDFViewer } from "@react-pdf/renderer";
import { RESULT_PDF } from "constant";
import PDF from "components/PDF";
import { AppContext } from "../../context/app-context";

const PDFLayout = (props) => {
  const { testPdf, testsLab, setShowPdf, employee } = props;
  const appContext = useContext(AppContext);
  console.log("State", appContext);
  const parseTestResultForPDF = (test) => {
    return test.result ? RESULT_PDF[test.result.toLowerCase()] : "-";
  };

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover" style={{ padding: 20, marginLeft: "auto", marginRight: "auto" }}>
            <Card.Header>
              <Row style={{ alignItems: "center" }}>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Test Results - {testPdf ? testPdf.name : ""}
                </Card.Title>
                <Button
                  style={{
                    marginTop: 10,
                  }}
                  className="createButtons"
                  onClick={() => setShowPdf(false)}
                >
                  Close
                </Button>
              </Row>
            </Card.Header>
            <Card.Body className="pdfContainer px-0">
              <PDFViewer className="pdfView">
                <PDF
                  test={testPdf}
                  testsLab={testsLab}
                  parseTestResultForPDF={parseTestResultForPDF}
                  employee={employee}
                />
              </PDFViewer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PDFLayout;
