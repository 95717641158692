import React from "react";
import { Overlay, Popover } from "react-bootstrap";

const EmailAttachmentPopover = (props) => {
  const { showPopover, childNode, setShowPopover, files, handleRemoveFile } = props;

  return (
    <>
      <Overlay show={showPopover} placement="auto" flip target={childNode.current}>
        <Popover
          onMouseEnter={() => setShowPopover(true)}
          onMouseLeave={() => setShowPopover(false)}
          id="popover"
          className="popOverAttachmentsOverlay"
        >
          <Popover.Header className="fw-bold popOverAttachments">Attachments</Popover.Header>
          <div>
            {files.map((file, i) => (
              <div key={i} className="email email-input email-attachment-bar d-flex">
                <span>{file.fileName}</span>
                <div className="star-icon-wrapper delete-attachment" onClick={() => handleRemoveFile(file.name)}>
                  <i className="fa fa-times" />
                </div>
              </div>
            ))}
          </div>
        </Popover>
      </Overlay>
    </>
  );
};

export default EmailAttachmentPopover;
