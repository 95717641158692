import ColorLabel from "components/ColorLabel";
import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";

const ColorDropDown = (props) => {
  const { title, data, selectedOption, onOptionChange } = props;

  const [selectedValue, setSelectedValue] = useState(null);

  const dropDownOptions = useMemo(() => {
    return data.map((p) => {
      return { ...p, value: p.id, label: <ColorLabel color={p.color} label={p.name} /> };
    });
  }, [data]);

  useEffect(() => {
    if (selectedOption) {
      const ViewDrop = dropDownOptions.find((f) => f.id === selectedOption);
      setSelectedValue(ViewDrop);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (dropDownOptions.length === 1 && !selectedOption) {
      const obj = { ...dropDownOptions[0], value: dropDownOptions[0].value, label: dropDownOptions[0].label };
      setSelectedValue(obj);
      onOptionChange(obj);
    }
  }, [dropDownOptions]);

  const handleOnChange = (e) => {
    setSelectedValue(e);
    if (onOptionChange) {
      onOptionChange(e);
    }
  };
  const customFilter = (option, search) => {
    if (option.data.name.toLowerCase().includes(search.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Select
      options={dropDownOptions}
      blurInputOnSelect={true}
      menuPlacement="bottom"
      placeholder={title}
      className="w-100"
      value={selectedValue}
      filterOption={customFilter}
      onChange={handleOnChange}
    />
  );
};

export default ColorDropDown;
