import RadioButton from "components/RadioButton/RadioButton";
import { CONFIRMATION_TYPE, SEND_QR_CODE_VIA } from "constant";
import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = (props) => {
  const { title, message, handleConfirm, closeBtn, actionType, checked, setChecked, handleClose, cssClass } = props;
  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose ? handleClose() : handleConfirm(false, actionType);
      }}
      className={cssClass}
      centered
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>

        {actionType === CONFIRMATION_TYPE.SEND_QR_CODE && (
          <>
            <RadioButton
              label="Email"
              id="email"
              name="sendQrVia"
              checked={checked && checked === SEND_QR_CODE_VIA.EMAIL ? true : false}
              value="email"
              handleChange={(e) => setChecked(e.target.checked ? SEND_QR_CODE_VIA.EMAIL : "")}
            />
            <RadioButton
              label="SMS"
              id="sms"
              name="sendQrVia"
              checked={checked && checked === SEND_QR_CODE_VIA.SMS ? true : false}
              value="sms"
              handleChange={(e) => setChecked(e.target.checked ? SEND_QR_CODE_VIA.SMS : "")}
            />
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="primary"
          onClick={() => {
            handleConfirm(false, actionType);
          }}
        >
          {closeBtn ? "No" : "Go back"}
        </Button>
        <Button
          variant="secondary"
          className="headerButton btn-fill"
          onClick={() => {
            handleConfirm(true, actionType);
          }}
        >
          {closeBtn ? "Yes" : "Confirm"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
