import React from "react";
import { Button, Modal, Card, Table } from "react-bootstrap";

const TestingCalendarModal = (props) => {
  const { data, handleClose } = props;

  console.log("data", data);

  const TableRow = ({ log, index, data }) => {
    return (
      <tr>
        <td></td>
        <td>{index + 1}</td>
        <td style={{ textAlign: "center" }}>{log.firstName}</td>
        <td style={{ textAlign: "center" }}>{log.lastName}</td>
        <td style={{ textAlign: "center" }}>{log.email}</td>
        <td style={{ textAlign: "center" }}>{log.phoneNumber}</td>
        <td style={{ textAlign: "center" }}>{data.testTypes.join()}</td>
      </tr>
    );
  };

  return (
    <Modal
      show
      animation={true}
      size="xl"
      onHide={() => {
        handleClose();
      }}
      style={{ paddingLeft: "0" }}
      className="testDoneZoneModal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Test Done ({data.title})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          paddingTop: 0,
        }}
      >
        <Card className="striped-table-card">
          <Card.Body>
            <div className="form-group-wrapper d-block">
              <div className="table-responsive overFlow-y-hidden">
                <Table className="table expandedTable">
                  <thead>
                    <tr>
                      <th></th>

                      <th style={{ width: "10%" }}>Sr#</th>
                      <th className="border-0 centered">First Name</th>
                      <th className="border-0 centered">Last Name</th>
                      <th className="border-0 centered">Email</th>
                      <th className="border-0 centered">Phone Number</th>
                      <th className="border-0 centered w-25">Test Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.employees.length > 0 &&
                      data.employees.map((item, i) => {
                        return <TableRow key={i} index={i} log={item} data={data} />;
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons headerButton btn-fill ms-auto"
          onClick={() => {
            handleClose();
          }}
        >
          ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestingCalendarModal;
