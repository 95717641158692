import React from "react";
import editIcon from '../../assets/img/edit-icon.png';

const TableRow = ({ item, handleEdit }) => {
  return (
    <tr>
      <td className="icon">
        <img src={editIcon} width="18" alt="edit icon"
          onMouseLeave={(e) => (e.target.style.color = "black")}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
          }}
          aria-hidden="true"
          onClick={() => handleEdit(item)}
        />
      </td>
      <td className="ellipsis">{item.name}</td>
      <td className="ellipsis">{item.totalTime}</td>
      <td className="ellipsis">{item.firstAlert}</td>
      <td className="ellipsis">{item.secondAlert}</td>
    </tr>
  );
};

export default TableRow;
