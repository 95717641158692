import { QUES_RESULT_TITLE } from "constant";
import { QUES_RESULT_STATUS } from "constant";
import React, { useState, useEffect } from "react";
import { Modal, Button, Card, Table } from "react-bootstrap";
import { formatDateMDY } from "utils";

const DetailsOfQuestionAssignedCrew = (props) => {
  const { handleClose, appContext, zoneIds } = props;
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    if (zoneIds.length > 0) {
      const empData = appContext.employees.filter((f) => f.programID && zoneIds.includes(f.programID));
      setEmployees(empData);
    }
  }, [zoneIds]);

  const TableRow = (props) => {
    const { emp, index } = props;
    return (
      <tr>
        <td className="ellipsis" style={{ textAlign: "left" }}>
          {index}
        </td>
        <td className="ellipsis" title={emp.firstName}>
          {emp.firstName}
        </td>
        <td className="ellipsis" title={emp.lastName}>
          {emp.lastName}
        </td>
        <td className="ellipsis" title={emp.email}>
          {emp.email}
        </td>
        <td className="ellipsis" title={formatDateMDY(emp.scheduleData?.zoneAssignDate)}>
          {formatDateMDY(emp.scheduleData?.zoneAssignDate)}
        </td>
        <td className="ellipsis" title={formatDateMDY(emp.scheduleData?.quesDate)}>
          {formatDateMDY(emp.scheduleData?.quesDate)}
        </td>
        <td className="ellipsis" title={QUES_RESULT_TITLE[emp.scheduleData?.questResult] || ""}>
          {QUES_RESULT_STATUS[emp.scheduleData?.questResult] || ""}
        </td>
        <td className="ellipsis" title={emp.scheduleData?.zoneAssignBy}>
          {emp.scheduleData?.zoneAssignBy}
        </td>
      </tr>
    );
  };

  return (
    <Modal
      show
      animation={true}
      size="xl"
      style={{ paddingLeft: "0" }}
      onHide={() => {
        handleClose();
      }}
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Details of Assigned Crew ({employees.length})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          paddingTop: 0,
        }}
      >
        <Card className="strpied-tabled-with-hover">
          <Card.Body className="table-full-width desktop-noScroll">
            <div
              className={
                employees.length > 0
                  ? "table-responsive pendingReleaseTable"
                  : "table-responsive pendingReleaseTable overFlow-y-hidden"
              }
            >
              <Table className="table">
                <thead>
                  <tr>
                    <th>SR#</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Date Assigned</th>
                    <th>Last Date Responded</th>
                    <th>Result of Last Response</th>
                    <th>Assigned By</th>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((emp, index) => (
                    <TableRow key={index} emp={emp} index={index} />
                  ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="headerButton btn-fill ms-auto" onClick={() => handleClose()}>
          ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DetailsOfQuestionAssignedCrew;
