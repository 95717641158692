import ErrorMessage from "components/Message/ErrorMessage";
import Status from "components/Status/Status";
import { t } from "constants/stringConstants";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Button, Modal } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { CONFIG } from "utils";
import { getDaysArray } from "utils";
import DateRangeModal from "./DateRangeModal";

const ExpectedOnSetCalendarModal = (props) => {
  const { handleClose, handleSave, selectedEmps } = props;
  const [events, setEvents] = useState([]);
  const [prevEvents, setPrevEvents] = useState([]);
  const [error, setError] = useState("");
  const localizer = momentLocalizer(moment);
  const [date, setDate] = useState({});
  const [newAssign, setNewAssign] = useState(true);

  useEffect(() => {
    const temp = [];

    if (selectedEmps[0].onSetDates?.dates?.length > 0) {
      selectedEmps[0].onSetDates.dates.forEach((d) =>
        temp.push({
          start: d,
          end: moment(d).add(1, "d").format("YYYY-MM-DD"),
        })
      );
    } else if (selectedEmps[0].onSetDates?.startDate && selectedEmps[0].onSetDates?.endDate) {
      const res = getDaysArray(selectedEmps[0].onSetDates.startDate, selectedEmps[0].onSetDates.endDate);

      res.forEach((d) => {
        temp.push({
          start: d,
          end: moment(d).add(1, "d").format("YYYY-MM-DD"),
        });
      });
    }

    setEvents(temp);
    setPrevEvents(temp);
    setNewAssign(false);
  }, []);

  const onhandleSave = () => {
    if (Object.keys(date).length === 0) {
      setError("Please Select Date Range");
      return;
    }
    // handleSave(date);
  };

  const UpdateValue = () => {
    return {
      autoUpdateInput: false,
      minDate: moment(),
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Clear",
      },
    };
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
    setDate({});
  };

  const handleApply = (event, picker) => {
    picker.element.val(picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY"));
    console.log("DateRange", picker.startDate, picker.endDate);
    setDate({
      startDate: moment(picker.startDate).format("YYYY-MM-DD"),
      endDate: moment(picker.endDate).format("YYYY-MM-DD"),
    });
  };

  const handleSelectSlot = (event) => {
    console.log("handleSelectSlot", { event });

    const startDate = moment(event.start).format("YYYY-MM-DD");
    const endDate = moment(event.end).format("YYYY-MM-DD");

    if (event.slots?.length > 1) {
      if (moment().startOf("day").isAfter(endDate)) {
        setError("Past date is not acceptable!");
        return;
      }

      let temp = [...events];
      event.slots.forEach((date) => {
        if (moment().startOf("day").isSameOrBefore(moment(date))) {
          const findVal = temp.findIndex((f) => f.start === moment(date).format("YYYY-MM-DD"));

          if (findVal === -1) {
            temp.push({
              start: moment(date).format("YYYY-MM-DD"),
              end: moment(date).add(1, "day").format("YYYY-MM-DD"),
              //   value: addTestGroup.value,
            });
          }
        }
      });

      if (temp.length > 0 && JSON.stringify(temp) !== JSON.stringify(events)) setEvents(temp);
    } else {
      if (moment().startOf("day").isAfter(startDate)) {
        setError("Past date is not acceptable!");
        return;
      }

      const findVal = events.findIndex((f) => moment(f.start).format("YYYY-MM-DD") === startDate);

      console.log("events", { events, findVal });

      if (findVal === -1) {
        setEvents((prev) => [
          ...prev,
          {
            start: startDate,
            end: moment(event.end).format("YYYY-MM-DD"),
            //   value: addTestGroup.value,
          },
        ]);
      } else {
        const arrObj = [...events];
        arrObj.splice(findVal, 1);
        setEvents(arrObj);
      }
    }
  };

  const handleSelectEvent = (event) => {
    console.log("handleSelectEvent", event);
    const findVal = events.findIndex((f) => f.start === event.start);
    if (findVal !== -1) {
      const arrObj = [...events];
      arrObj.splice(findVal, 1);
      setEvents(arrObj);
    }
  };

  const handleSaveChange = () => {
    // if (!events || events.length === 0) {
    //   setError("Please select atleast one date");
    //   return;
    // }

    handleSave(events);
  };

  return (
    <>
      <Modal
        show
        // style={{ transform: "translate(0, -30%)" }}
        animation={true}
        onHide={handleClose}
        centered
        scrollable
        size={"xl"}
        // className="showZoneModal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
            {CONFIG.expectedOnSet}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0 }}>
          <div className="row mb-3"></div>
          {/* <div className="row">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders mt-0">Select Date Range:</label>
              <DateRangePicker onApply={handleApply} onCancel={handleCancel} initialSettings={UpdateValue()}>
                <input
                  type="text"
                  placeholder="Select Date Range"
                  readOnly
                  className="dateRangeMatrix modalInput"
                  defaultValue=""
                />
              </DateRangePicker>
            </div>
          </div> */}
          <div className="my-4 question-schedule-calendar" style={{ height: "350pt" }}>
            <Calendar
              events={events.map((m) => {
                return {
                  ...m,
                  title: (
                    <div className="star-icon-wrapper" title={m.start}>
                      <Status type="circle" size="md" color="green" crossIcon />
                    </div>
                  ),
                };
              })}
              startAccessor="start"
              endAccessor="end"
              defaultDate={moment().toDate()}
              localizer={localizer}
              views={["month"]}
              selectable
              //   components={{
              //     dateHeader: customDateHeader,
              //   }}
              //   dayPropGetter={calendarStyle}
              // onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              popup={true}
              eventPropGetter={(event, start, end, isSelected) => ({
                event,
                start,
                end,
                isSelected,
                style: { backgroundColor: `transparent`, width: "fit-content" },
              })}
              // style={{ height: '100%' }}
              onShowMore={(event, date) => console.log("onShowMore", event, date)}
            />
          </div>
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button className="modalButtons headerButton btn-fill" variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button className="modalButtons headerButton btn-fill" variant="secondary" onClick={() => setEvents([])}>
            Clear All
          </Button>
          <Button
            className="modalButtons headerButton btn-fill"
            variant="secondary"
            disabled={JSON.stringify(events) === JSON.stringify(prevEvents)}
            onClick={handleSaveChange}
          >
            {newAssign ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExpectedOnSetCalendarModal;
