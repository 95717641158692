import React from "react";

const EmailAttachmentButton = (props) => {
  const { handleUploadClick, loading, files, childNode, setShowPopover, inputFile, onChangeFile, type, isSelected } =
    props;
  return (
    <>
      <div
        style={{ color: `${!loading && files.length < 5 ? `var(--bs-black)` : `var(--gray-secondary-color)`}` }}
        className="email email-input email-attachment-btn"
      >
        <i onClick={handleUploadClick} className="nc-icon nc-attach-87"></i>
        {files.length > 0 && (
          <span
            ref={childNode}
            onMouseEnter={() => setShowPopover(true)}
            onMouseLeave={() => setShowPopover(false)}
            onClick={() => setShowPopover((prev) => !prev)}
            className="badge-span"
          >
            {files.length}
          </span>
        )}
      </div>
      <input
        disabled={!(!loading && files.length < 5) || type !== "E" || (isSelected !== undefined && !isSelected)}
        ref={inputFile}
        type="file"
        accept="image/*,.pdf,.csv,.xls,.xlsx,.doc,.docx,.txt"
        onChange={(e) => onChangeFile(e)}
        style={{ display: "none" }}
      />
    </>
  );
};

export default EmailAttachmentButton;
