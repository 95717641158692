import React from "react";
import Styles from 'components/CheckBoxButton/CheckBoxButton.module.css';

const CheckBoxButton = (props) => {
    const { id, label, value, name, checked, handleChange, disabled } = props;
    return(
        <div className={Styles.checkBoxButton}>
            <input
                type="checkbox"
                name={name}
                id={id}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={(e) => handleChange(e)}/>
            <label>{label}</label>
        </div>
    )
}
export default CheckBoxButton;