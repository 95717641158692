import EmployeeItem from "components/Matrix/EmployeeItem";
import MatrixPopOver from "components/Matrix/MatrixPopOver";
import MatrixPopOverCell from "components/Matrix/MatrixPopOverCell";
import debounce from "debounce";
import React, { useCallback, useEffect, useMemo, useRef, useState, memo } from "react";
import { Table, Form, Button } from "react-bootstrap";
import ResizeObserver from "react-resize-observer";
import ViewportList from "react-viewport-list";
import { formatNumber } from "utils";
import { personalizationLocalStorage, formatDateMatrix, isTooDark } from "utils";
import ResizeableHeader from "./ResizeableHeader";

const MainTable = (props) => {
  const {
    showLeveMtarix,
    filteredPrograms,
    handleAllCheckBoxes,
    appContext,
    programData,
    handleGroupCheckboxChange,
    departments,
    checkboxes,
    tdWidth,
    setAddSpecificNotesEmp,
    handleCheckboxChange,
    AddNotes,
    selectAll,
    sortList,
    dateList,
    setLoading,
    openClearedForWorkModal,
    showPopover,
    setShowPopover,
    deptLeftStickPosition,
    setDeptLeftStickPosition,
    zoneLeftStickPosition,
    setZoneLeftStickPosition,
    setShowExternalTestNotification,
    updatePersonalization,
    showExternalTestData,
    setShowExternalTestData,
    testDetailsMatrixData,
    setTestDetailsMatrixData,
    viewChanging,
    setViewChanging,
    handleUserProfile,
    secondTableColsWidth,
    scrollSaveDelay,
    listRef,
  } = props;

  const headerRowRef = useRef(null);
  const [LSColsData, setLSColsData] = useState(personalizationLocalStorage.getStorage().tcMatrix);

  useEffect(() => {
    const colsData = personalizationLocalStorage.getStorage().tcMatrix;
    if (JSON.stringify(colsData) !== JSON.stringify(LSColsData)) setLSColsData(colsData);
  }, [showLeveMtarix]);

  useEffect(() => {
    // Stop the resize observer to observe during the time it takes to switch out to a new table, and apply its sizings
    if (viewChanging) {
      const timer = setTimeout(() => {
        setViewChanging(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [viewChanging]);

  const saveToStorage = (data) => {
    const { deptLeftStickPosition, zoneLeftStickPosition, deptInnerDivWidth, zoneInnerDivWidth } = data;
    const objToSave = personalizationLocalStorage.getStorage();

    Object.assign(objToSave, {
      tcMatrix: {
        zoneLeftStickPosition: zoneLeftStickPosition || [],
        deptLeftStickPosition: deptLeftStickPosition || [],
        deptInnerDivWidth: deptInnerDivWidth || [],
        zoneInnerDivWidth: zoneInnerDivWidth || [],
      },
    });
    try {
      personalizationLocalStorage.save(JSON.stringify(objToSave));
      updatePersonalization(objToSave);
    } catch (error) {
      console.log(error);
    }
  };

  const resizeSaveDelay = useCallback(
    debounce((data) => {
      saveToStorage(data);
    }, 500),
    []
  );

  const handleResize = () => {
    if (viewChanging) return;
    if (headerRowRef.current?.childNodes?.length >= 8) {
      const stickPos = [0];
      const colInnerWidth = [];
      for (let i = 1; i < 9; i++) {
        stickPos.push(stickPos[i - 1] + headerRowRef.current.childNodes[i - 1].offsetWidth);
        colInnerWidth.push(headerRowRef.current.childNodes[i - 1].childNodes[0].offsetWidth);
      }

      const data = {
        zoneLeftStickPosition: zoneLeftStickPosition,
        deptLeftStickPosition: deptLeftStickPosition,
        deptInnerDivWidth: LSColsData.deptInnerDivWidth,
        zoneInnerDivWidth: LSColsData.zoneInnerDivWidth,
      };
      if (showLeveMtarix && JSON.stringify(stickPos) !== JSON.stringify(deptLeftStickPosition)) {
        setDeptLeftStickPosition(stickPos);
        data.deptLeftStickPosition = stickPos;
        data.deptInnerDivWidth = colInnerWidth;

        resizeSaveDelay(data);
      } else if (!showLeveMtarix && JSON.stringify(stickPos) !== JSON.stringify(zoneLeftStickPosition)) {
        setZoneLeftStickPosition(stickPos);
        data.zoneLeftStickPosition = stickPos;
        data.zoneInnerDivWidth = colInnerWidth;

        resizeSaveDelay(data);
      }
    }
  };

  const NamedBarRow = ({ emp }) => {
    let program = null;
    const key = !showLeveMtarix ? "programID" : "department";
    if (!showLeveMtarix) {
      program = appContext.programs?.find((f) => f.id === emp.programID);
    } else {
      program = departments.find((f) => f.id === emp.department);
    }
    const isDarkProg = isTooDark(program?.color);

    return (
      <div
        style={{ backgroundColor: `${program?.color || "black"}` }}
        className="zoneRow tcMatrixTableRow tcMatrixEventBar"
      >
        <div
          className="tcMatrixResizeableCell tcMatrixTableCell"
          style={{
            color: `${!showLeveMtarix ? `${isDarkProg ? "white" : "black"}` : "white"}`,
            backgroundColor: `${program?.color || "black"}`,
            height: "42px",
          }}
          title={program?.name || "Unassigned"}
        >
          <div className="top-first-row ellipsis">
            <Form.Check className="tcMatrixCheckBox programName me-3">
              <Form.Check.Input
                type="checkbox"
                checked={checkboxes.findIndex((f) => (program ? f?.id === emp[key] : f === "Unassigned")) !== -1}
                value={emp[key]}
                onChange={(e) =>
                  handleGroupCheckboxChange(
                    e,
                    program,
                    filteredPrograms.filter((f) => (program ? f[key] === program.id : !f[key]))
                  )
                }
              />
            </Form.Check>
            <div className="unassigned-name">
              {program?.name || "Unassigned"}
              {` (${formatNumber(filteredPrograms.filter((f) => (program ? f[key] === program.id : !f[key])).length)})`}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TableRow = ({ emp }) => {
    return (
      <>
        <div key={emp.id} className="zoneHeight tcMatrixTableRow">
          <EmployeeItem
            emp={emp}
            checkboxes={checkboxes}
            showLeveMtarix={showLeveMtarix}
            handleUserProfile={handleUserProfile}
            tdWidth={tdWidth}
            setAddSpecificNotesEmp={setAddSpecificNotesEmp}
            AddNotes={AddNotes}
            handleCheckboxChange={handleCheckboxChange}
            deptLeftStickPosition={deptLeftStickPosition}
            zoneLeftStickPosition={zoneLeftStickPosition}
          />
          {dateList.map((d) => {
            const dayOpt = appContext.isZoneLevelCompany()
              ? emp.scheduleData?.filter((f) => f.date === d)
              : emp.scheduleData?.find((f) => f.date === d);

            return (
              <MatrixPopOverCell
                dayOpt={dayOpt}
                tdWidth={tdWidth}
                emp={emp}
                appContext={appContext}
                date={d}
                setLoading={setLoading}
                openClearedForWorkModal={openClearedForWorkModal}
                showPopover={showPopover}
                setShowPopover={setShowPopover}
                setShowExternalTestNotification={setShowExternalTestNotification}
                showExternalTestData={showExternalTestData}
                setShowExternalTestData={setShowExternalTestData}
                testDetailsMatrixData={testDetailsMatrixData}
                setTestDetailsMatrixData={setTestDetailsMatrixData}
                secondTableColsWidth={secondTableColsWidth}
              />
            );
          })}
        </div>
      </>
    );
  };

  const rowsToShow = [...filteredPrograms];
  const key = !showLeveMtarix ? "programID" : "department";
  let counter = 0;
  filteredPrograms.forEach((emp, index) => {
    if (index === 0 || (filteredPrograms[index] && emp[key] && emp[key] !== filteredPrograms[index - 1][key])) {
      rowsToShow.splice(index + counter, 0, { type: "eventBar", employee: emp });
      counter++;
    }
  });

  return (
    <div
      className={`${filteredPrograms ? `tcMatrixZone tcMatrixFirstZone` : "overFlow-y-hidden"} 
                  ${!showLeveMtarix ? "departmentTable" : "zoneTable"}`}
    >
      <div id="table" className={`${!showLeveMtarix ? "departmentLeftSideTable" : "zoneLeftSideTable"} tcMatrixTable`}>
        <ResizeObserver onResize={(e) => handleResize()} />
        <div ref={headerRowRef} id="table-header" className="tcMatrixTableRow tcMatrixTHead tcMatrixTableHead">
          <div
            className="border-0 first-col-checkBox tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
            style={{
              left: `${showLeveMtarix ? deptLeftStickPosition[0] : zoneLeftStickPosition[0]}px`,
            }}
          >
            <div className="position-relative">
              <Form.Check className="tcMatrixCheckBox text-center">
                <Form.Check.Input
                  type="checkbox"
                  value={selectAll}
                  checked={selectAll}
                  onChange={(e) => handleAllCheckBoxes(e.target.checked)}
                />
              </Form.Check>
            </div>
          </div>
          <div
            title="Qua-P90"
            className="first-col-checkBox ellipsis tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
            style={{
              color: "gray",
              left: `${showLeveMtarix ? deptLeftStickPosition[1] : zoneLeftStickPosition[1]}px`,
            }}
          >
            <div className="custom-ellipsis">Qua-P90</div>
          </div>
          <div
            style={{
              color: "gray",
              left: `${showLeveMtarix ? deptLeftStickPosition[2] : zoneLeftStickPosition[2]}px`,
            }}
            className="border-0 first-col-checkBox ellipsis tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
          >
            <span title="Notes">Notes</span>
          </div>
          {showLeveMtarix && (
            <>
              <ResizeableHeader
                style={{
                  maxWidth: "fit-content",
                  left: `${deptLeftStickPosition[3]}px`,
                }}
                cssClass="zoneCell tcMatrixtableHeader text-left ellipsis assignBlock tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
                width={LSColsData?.deptInnerDivWidth?.length > 0 ? `${LSColsData.deptInnerDivWidth[3]}px` : ""}
                title={!showLeveMtarix ? "Department" : "Zone"}
                label={!showLeveMtarix ? "Department" : "Zone"}
                handleResize={handleResize}
              />
            </>
          )}

          <ResizeableHeader
            style={{
              left: `${showLeveMtarix ? deptLeftStickPosition[4] : zoneLeftStickPosition[3]}px`,
            }}
            cssClass="tcMatrixtableHeader nameBlock tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
            width={
              showLeveMtarix
                ? LSColsData?.deptInnerDivWidth?.length > 0
                  ? `${LSColsData?.deptInnerDivWidth[4]}px`
                  : ""
                : LSColsData?.zoneInnerDivWidth?.length > 0
                ? `${LSColsData?.zoneInnerDivWidth[3]}px`
                : ""
            }
            title="First Name"
            label="First Name"
            handleResize={handleResize}
          />

          <ResizeableHeader
            style={{
              left: `${showLeveMtarix ? deptLeftStickPosition[5] : zoneLeftStickPosition[4]}px`,
            }}
            cssClass="tcMatrixtableHeader text-left nameBlock tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
            width={
              showLeveMtarix
                ? LSColsData?.deptInnerDivWidth?.length > 0
                  ? `${LSColsData?.deptInnerDivWidth[5]}px`
                  : ""
                : LSColsData?.zoneInnerDivWidth?.length > 0
                ? `${LSColsData?.zoneInnerDivWidth[4]}px`
                : ""
            }
            title="Last Name"
            label="Last Name"
            handleResize={handleResize}
          />

          <ResizeableHeader
            style={{
              left: `${showLeveMtarix ? deptLeftStickPosition[6] : zoneLeftStickPosition[5]}px`,
            }}
            cssClass="tcMatrixtableHeader text-left emailPhoneBlock tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
            width={
              showLeveMtarix
                ? LSColsData?.deptInnerDivWidth?.length > 0
                  ? `${LSColsData?.deptInnerDivWidth[6]}px`
                  : ""
                : LSColsData?.zoneInnerDivWidth?.length > 0
                ? `${LSColsData?.zoneInnerDivWidth[5]}px`
                : ""
            }
            title="Email"
            label="Email"
            handleResize={handleResize}
          />

          <ResizeableHeader
            style={{
              left: `${showLeveMtarix ? deptLeftStickPosition[7] : zoneLeftStickPosition[6]}px`,
            }}
            cssClass="tcMatrixtableHeader text-left emailPhoneBlock tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
            width={
              showLeveMtarix
                ? LSColsData?.deptInnerDivWidth?.length > 0
                  ? `${LSColsData?.deptInnerDivWidth[7]}px`
                  : ""
                : LSColsData?.zoneInnerDivWidth?.length > 0
                ? `${LSColsData?.zoneInnerDivWidth[6]}px`
                : ""
            }
            title="Phone"
            label="Phone"
            handleResize={handleResize}
          />

          {dateList &&
            dateList.map((d) => {
              return (
                <div
                  style={{
                    // width: tdWidth,
                    // maxWidth: tdWidth,
                    width: `${secondTableColsWidth && secondTableColsWidth}%`,
                  }}
                  className="tcMatrixtableHeader centered secondTableBlock tcMatrixTableCell"
                >
                  <div className="centered">
                    <span title={`${formatDateMatrix(d)}`}>{`${formatDateMatrix(d)}`}</span>
                  </div>
                </div>
              );
            })}
        </div>
        <>
          {rowsToShow.length > 0 && (
            <ViewportList
              ref={listRef}
              items={rowsToShow}
              overscan={5}
              // margin={105}
              itemMinSize={42}
              overflowAnchor="auto"
              withCache={true}
              // initialIndex={personalizationLocalStorage.getStorage().tcMatrix?.visibleStartIndex || 0}
              // onViewportIndexesChange={(e) => scrollSaveDelay({ startIndex: e[0] + 1, endIndex: e[1] + 1 })}
              axis={"y"}
              // initialOffset={5}
              // initialPrerender={5}
              // scrollThreshold={1}
            >
              {(item, i) =>
                item.type === "eventBar" ? <NamedBarRow key={i} emp={item.employee} /> : <TableRow key={i} emp={item} />
              }
            </ViewportList>
          )}
        </>
      </div>
    </div>
  );
};

export default memo(MainTable);
