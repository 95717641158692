import ErrorMessage from "components/Message/ErrorMessage";
import { t } from "constants/stringConstants";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CONFIG } from "utils";
import TestingCalendar from "views/TestingCalendar/TestingCalendar";

const ExpectedOnSetCalendarDetailsModal = (props) => {
  const { handleClose } = props;
  return (
    <>
      <Modal
        show
        // style={{ transform: "translate(0, -30%)" }}
        animation={true}
        onHide={handleClose}
        centered
        scrollable
        size={"2xl"}
        className="showZoneModal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
            {CONFIG.expectedOnSet} Details
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0 }}>
          <div className="row mb-3"></div>
          <div className="my-4 question-schedule-calendar" style={{ height: "350pt" }}>
            <TestingCalendar expectedOnSet />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="modalButtons headerButton btn-fill" variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExpectedOnSetCalendarDetailsModal;
