import React from "react";
import Select from "react-select";
import EmailAttachmentButton from "./EmailAttachmentButton";
import EmailAttachmentPopover from "./EmailAttachmentPopover";

const TextAreaFormContainer = (props) => {
  const {
    viewAattachment,
    TEXT_FILED_OPTIONS,
    isSelected,
    insertAtCursor,
    MESSAGE_SEND_TYPE,
    handleChange,
    message,
    name,
    subject,
    type,
    handleUploadClick,
    loading,
    files,
    childNode,
    setShowPopover,
    inputFile,
    onChangeFile,
    showPopover,
    handleRemoveFile,
    label,
    textAreaRef,
    handleKeyDownEvent,
  } = props;
  return (
    <>
      <div className="d-flex align-items-center">
        <Select
          value={{ label: "Insert field", value: "none" }}
          options={TEXT_FILED_OPTIONS}
          // blurInputOnSelect={true}
          isDisabled={isSelected !== undefined && !isSelected}
          menuPlacement="auto"
          className="w-30 ml-auto inputFieldMessage"
          placeholder="Insert Field"
          onChange={(e) => insertAtCursor(e.value)}
        />
        <Select
          options={MESSAGE_SEND_TYPE}
          isDisabled={isSelected !== undefined && !isSelected}
          // blurInputOnSelect={true}
          className="w-75 inputFieldMessage"
          menuPlacement="auto"
          placeholder="Select message Type"
          value={MESSAGE_SEND_TYPE.find((f) => f.value === type)}
          onChange={(e) =>
            isSelected !== undefined
              ? handleChange(isSelected, message, name, e.value, subject, files)
              : handleChange(message, name, e.value, subject, files)
          }
        />
      </div>
      {type === "E" && (
        <>
          <div className="d-flex align-items-center">
            <div className="w-30 email d-inline-flex align-items-center">
              <label className="align-items-center mb-0">Subject :</label>
            </div>
            <input
              type="text"
              // disabled={isSelected !== undefined && !isSelected}
              //   blurInputOnSelect={true}
              className={`email email-input`}
              style={{ width: `${viewAattachment ? "65%" : "75%"}` }}
              placeholder="Email Subject"
              value={subject}
              disabled={type !== "E" || (isSelected !== undefined && !isSelected)}
              onChange={(e) =>
                isSelected !== undefined
                  ? handleChange(isSelected, message, name, type, e.target.value, files)
                  : handleChange(message, name, type, e.target.value, files)
              }
            />
            {viewAattachment && (
              <EmailAttachmentButton
                handleUploadClick={handleUploadClick}
                loading={loading}
                files={files}
                childNode={childNode}
                setShowPopover={setShowPopover}
                inputFile={inputFile}
                onChangeFile={onChangeFile}
                showPopover={showPopover}
                handleRemoveFile={handleRemoveFile}
                type={type}
                isSelected={isSelected}
              />
            )}
          </div>
          {viewAattachment && showPopover && files.length > 0 && (
            <EmailAttachmentPopover
              showPopover={showPopover}
              childNode={childNode}
              setShowPopover={setShowPopover}
              files={files}
              handleRemoveFile={handleRemoveFile}
            />
          )}
        </>
      )}
      <textarea
        placeholder={label}
        className="MessageInput"
        readOnly={isSelected !== undefined && !isSelected}
        ref={textAreaRef}
        value={message}
        onKeyDown={handleKeyDownEvent}
        onChange={(e) =>
          isSelected !== undefined
            ? handleChange(isSelected, e.target.value, name, type, subject, files)
            : handleChange(e.target.value, name, type, subject, files)
        }
      />
    </>
  );
};

export default TextAreaFormContainer;
