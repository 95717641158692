import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
const PhoneNoInput = (props) => {
  const { handleChange, value, error, disabled = false, cssClass } = props;
  const [defaultCountryCode, setDefaultCountryCode] = useState("US");
  useEffect(() => {
    if (error) error(validatePhone());
  });
  useEffect(() => {
    if (value) {
      let coutryCode = parsePhoneNumber(value)?.country;
      setDefaultCountryCode(coutryCode);
    }
  }, []);
  const validatePhone = () => {
    if (value) {
      if (isPossiblePhoneNumber(value)) {
        return "";
      }
      return "Invalid phone number";
    }
    return "";
  };

  return (
    <PhoneInput
      international
      countryCallingCodeEditable={false}
      className={`modalPhoneInput ${cssClass}`}
      defaultCountry={defaultCountryCode}
      placeholder="Phone"
      value={value}
      disabled={disabled}
      onChange={handleChange}
      error={validatePhone()}
    />
  );
};
export default PhoneNoInput;
