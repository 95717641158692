import React, { useEffect, useState, useMemo, useContext } from "react";
import { Button, Modal, Card, Table, Row, Col, Collapse } from "react-bootstrap";
import { formatDateMDY, formatDateMDYTime, toTitleCase, downloadDataAsCSV } from "utils";
import "bootstrap/dist/css/bootstrap.min.css";
import ExportToExcel from "components/ExportToExcel";
import { AppContext } from "context/app-context";
import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import { Audit_Entries, Zone_Audit_Entries, Zone_Audit_Types, Audit_Types, TEST_TYPE_VALUE } from "constant";
import { Storage } from "aws-amplify";
import moment from "moment";
import api from "api";
import Loader from "components/Loader/Loader";
import AuditTrailEntries from "./AuditTrailEntries";
import PDFLayout from "components/PDF/PDFLayout";
import FullImageView from "components/Image/FullPageImageView";
import { Zone_PARSE_Audit_Types, Audit_Parse_Types } from "constant";

const EmployeeAuditTrail = ({ user, handleClose }) => {
  const [items, setItems] = useState([]);
  const [auditTrails, setAuditTrails] = useState([]);
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);
  const [showPdf, setShowPdf] = useState(false);
  const [testPdf, setTestPdf] = useState();
  const [testLab, setTestLab] = useState();
  const [employee, setEmployee] = useState(null);
  const [externalTestImage, setExternalTestImage] = useState(null);
  const [auditEntries, setAuditEntries] = useState([]);
  // console.log("testLab", testLab);
  useEffect(() => {
    loadData();
  }, []);

  // console.log("user", items);

  useEffect(() => {
    if (auditTrails.length > 0) {
      let entries = [];
      if (appContext.isZoneLevelCompany()) {
        entries = Zone_Audit_Entries.filter(
          (f) => auditTrails.findIndex((log) => Zone_Audit_Types[log.auditType] === f) !== -1
        );
      } else {
        entries = Audit_Entries.filter((f) => auditTrails.findIndex((log) => Audit_Types[log.auditType] === f) !== -1);
      }

      setAuditEntries(entries);
    }
  }, [auditTrails]);

  const handleExternalTestView = async (filePath) => {
    try {
      const resImage = await Storage.get(filePath, { bucket: "employee-external-tests" });
      console.log("resImage", resImage);
      setExternalTestImage(resImage);
    } catch (err) {
      console.log("Error:- AuditTrail External Image", err.message);
    }
  };

  const actionView = (log) => {
    if (log.result) {
      return appContext.isReadOnly() ? "Test Completed" : log.result;
    }
    if (log) {
      return (
        (log.auditType === "Message" && log.message?.replace(/<[^>]+>/g, "")) ||
        (log.auditType === "DriveOnAccess" &&
          log.dateRange &&
          `${log.action} (Start Date: ${formatDateMDY(log.dateRange.startDate)} - End Date: ${formatDateMDY(
            log.dateRange.endDate
          )} )`) ||
        log.reason ||
        log.action ||
        (log.sequenceNo && "Test Created")
      );
    }
  };

  const downloadLogs = useMemo(() => {
    let logs = [];
    if (appContext.isZoneLevelCompany()) {
      logs = auditTrails.filter((f) => Zone_Audit_Entries.includes(Zone_Audit_Types[f.auditType]));
    } else {
      logs = auditTrails.filter((f) => Audit_Entries.includes(Audit_Types[f.auditType]));
    }

    return logs.map((d) => {
      return {
        logDate: formatDateMDYTime(d.createdAt),
        action: d.filePath
          ? `${TEST_TYPE_VALUE[d.test_type] || ""} (ExternalTest)`
          : TEST_TYPE_VALUE[d.test_type] ||
            (d.auditType === "Message" && d.messageType) ||
            Audit_Parse_Types[d.auditType] ||
            Zone_PARSE_Audit_Types[d.auditType],
        event: actionView(d),
        userName: d.userName || "System",
      };
    });
  }, [auditTrails]);
  // console.log("downloadLogs", downloadLogs);

  const handleResult = async (resultSeq) => {
    setLoading(true);
    try {
      const res = await api.getTestDetailFromMD(resultSeq);

      setTestLab(res.labs?.Item);
      setEmployee(res.employee?.Item);
      setTestPdf(res.tests[0]);
      setShowPdf(true);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  const getTestObject = (tests, date) => {
    return tests
      .filter((t) => !t.externalTestFile && !t.testNow)
      .map((t) => {
        return {
          ...t,
          date,
          event: "Covid Test",
          action: t.isDone ? "Done" : "Fail",
          detail: t.isDone
            ? `${TEST_TYPE_VALUE[t.value]} ${t.location} (${t.result || "Pending"})`
            : `${TEST_TYPE_VALUE[t.value]} ${t.location} `,
        };
      });
  };

  const getExternalTestEnty = (tests, date) => {
    return tests
      .filter((t) => t.externalTestFile && !t.testNow)
      .map((t) => {
        return {
          ...t,
          date,
          event: "Covid Test-External",
          action: t.isDone ? "Done" : "Fail",
          detail: `${TEST_TYPE_VALUE[t.value]} (${t.result})`,
        };
      });
  };

  const getTestNow = (tests, date) => {
    return tests
      .filter((t) => !t.externalTestFile && t.testNow)
      .map((t) => {
        return {
          ...t,
          date,
          event: "Covid Test Now",
          action: t.isDone ? "Done" : "Fail",
          detail: `${TEST_TYPE_VALUE[t.value]} (${t.result})`,
        };
      });
  };

  const getExternalTestNow = (tests, date) => {
    return tests
      .filter((t) => t.externalTestFile && t.testNow)
      .map((t) => {
        return {
          ...t,
          date,
          event: "Covid Test Now-External",
          action: t.isDone ? "Done" : "Fail",
          detail: `${TEST_TYPE_VALUE[t.value]} (${t.result})`,
        };
      });
  };

  const loadData = async () => {
    setLoading(true);
    let auditTrailz = await api.employeeAuditTrail(user.id.replace("%testNow", ""));
    const filterChangeTypes = auditTrailz.filter((f) => f.action === "ChangeType");
    if (filterChangeTypes != "") {
      auditTrailz = auditTrailz.map((m) => {
        const record = filterChangeTypes.find((f) => f.sequenceNo === m.sequenceNo && f.createdAt > m.createdAt);
        if (record && m.result) {
          return { ...m, result: "" };
        } else {
          return { ...m };
        }
      });
    }
    setAuditTrails([...auditTrails, ...auditTrailz]);
    const models = await api.getEmployeeDailyLogs(user.id.replace("%testNow", ""));
    let auditItems = [];
    let preScreening = [];
    if (models.length > 0) {
      models.forEach((f) => {
        let obj = {};
        const date = f.logDate?.substring(0, 10);
        if (f.testOne) {
          auditItems = auditItems.concat(getTestObject(f.testOne, date));
          auditItems = auditItems.concat(getExternalTestEnty(f.testOne, date));
          auditItems = auditItems.concat(getTestNow(f.testOne, date));
          auditItems = auditItems.concat(getExternalTestNow(f.testOne, date));
        }
        if (f.testTwo) {
          auditItems = auditItems.concat(getTestObject(f.testTwo, date));
          auditItems = auditItems.concat(getExternalTestEnty(f.testTwo, date));
          auditItems = auditItems.concat(getTestNow(f.testTwo, date));
          auditItems = auditItems.concat(getExternalTestNow(f.testTwo, date));
        }
        // if (f.onBoardingTesting) {
        //   console.log('onBoardingTesting',f.onBoardingTesting);
        //   const t = f.onBoardingTesting;
        //   auditItems.push({
        //     date: f.onBoardingTesting.date,
        //     event: t.externalTestFile ? "Covid Test Now-External" : "Covid Test Now",
        //     action: t.isDone ? "Done" : "Fail",
        //   });
        // }
        if (f.dailyTask && f.dailyTask.length) {
          auditItems.push({
            ...f,
            date: f.logDate?.substring(0, 10),
            event: "Pre Screening Question",
            action: f.qaDone ? "Done" : "Fail",
          });
          preScreening.push({
            ...f,
            auditType: "PreScreeningQuestion",
            createdAt: f.logDate,
            action: f.qaDone ? "Done" : "Fail",
          });
        }
        if (f.manualCheckIn) {
          auditItems.push({
            date: f.logDate?.substring(0, 10),
            event: "Cleared For Work",
            action: "Done",
            detail: `${f.manualCheckInReason} (Cleared by ${f.manualCheckAdmin})`,
            user: f.manualCheckAdmin,
          });
        }
      });
    }
    setItems(auditItems);
    setAuditTrails((prevTrail) => [...prevTrail, ...preScreening]);
    setLoading(false);
  };
  // console.log("items", items);
  const TableCollapse = ({ item, index }) => {
    return (
      <tr className="expended-row">
        <td>{index + 1}</td>
        <td>{item.question}</td>
        <td style={{ textAlign: "center" }}>{item.answer}</td>
      </tr>
    );
  };
  const CollapseItemTest = ({ item, index }) => {
    return (
      <tr className="expended-row">
        <td>{index + 1}</td>
        <td>{item.label}</td>
        <td>{item.isDone ? "Done" : "Pending"}</td>
        <td style={{ textAlign: "center" }}>{item.result}</td>
      </tr>
    );
  };
  const TableRow = ({ log, index }) => {
    const [quesAns, setQuesAns] = useState([]);
    const [open, setOpen] = useState(false);
    const handleEmplQues = async (log) => {
      const models = await api.getEmployeeQuestion(user.id.replace("%testNow", ""), log.logDate);
      setQuesAns(models);
      setOpen(!open);
    };
    return (
      <>
        <tr>
          <td>
            {log.qaDone && (
              <i
                onClick={() => handleEmplQues(log)}
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.color = "#8B0000";
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                className={open ? "fas fa-minus" : "fas fa-plus"}
              />
            )}
          </td>
          <td>{index + 1}</td>
          <td>{formatDateMDY(log.date)}</td>
          <td style={{ textAlign: "center" }}>{log.event}</td>
          <td style={{ textAlign: "center" }}>{log.action}</td>
          <td style={{ textAlign: "center", textOverflow: "visible" }}>{log.detail}</td>
          <td style={{ textAlign: "center" }}>{log.user || "System"}</td>
        </tr>
        {quesAns.length > 0 && open && (
          <tr className="subTableRow">
            <td colSpan={"12"} className="expended-table-wrapper">
              <Card className="striped-table-card">
                <Card.Body>
                  <div className="form-group-wrapper d-block">
                    <div className="table-responsive overFlow-y-hidden">
                      <Table className="table expandedTable">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>Sr#</th>
                            <th>Question</th>
                            <th className="centered">Answer</th>
                          </tr>
                        </thead>
                        <tbody>
                          {quesAns.length > 0 &&
                            quesAns.map((item, i) => {
                              return <TableCollapse key={i} index={i} item={item} />;
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </td>
          </tr>
        )}
      </>
    );
  };
  return showPdf ? (
    <Modal
      className="historyViewModal p-0"
      show
      animation={true}
      onHide={() => handleClose()}
      size={"fullscreen"}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {`${user.firstName} ${user.lastName} Screening History`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="marginTop0 pdfModalBody">
        <PDFLayout setShowPdf={setShowPdf} testPdf={testPdf} testsLab={testLab} employee={employee} />
      </Modal.Body>
    </Modal>
  ) : (
    <Modal
      className="historyViewModal"
      show
      animation={true}
      onHide={() => handleClose()}
      size={"xl"}
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {`${user.firstName} ${user.lastName} Screening History`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="marginTop0">
        <>
          {!loading ? (
            <div style={{ flex: 1, marginTop: "10px" }}>
              <Row>
                <Col md="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Header>
                      {auditEntries.length > 0 && (
                        <ExportToExcel
                          items={downloadLogs}
                          appContext={appContext}
                          title={`${user.firstName} ${user.lastName} Screening History`}
                        />
                      )}
                    </Card.Header>
                    <Card.Body>
                      <div className="form-group-wrapper d-block">
                        <div className="table-responsive overFlow-y-hidden">
                          <Table className="table">
                            {/* <thead>
                            <tr>
                              <th></th>
                              <th style={{ width: "10%" }}>Sr#</th>
                              <th>Date</th>
                              <th className="border-0 centered">Event</th>
                              <th className="border-0 centered">Action</th>
                              <th className="border-0 centered">Detail</th>
                              <th className="border-0 centered">User</th>
                            </tr>
                          </thead> */}
                            <tbody>
                              {auditEntries.length > 0 ? (
                                auditEntries.map((item, i) => {
                                  return (
                                    <AuditTrailEntries
                                      key={i}
                                      index={i}
                                      item={item}
                                      auditTrails={auditTrails}
                                      user={user}
                                      zoneLevel={appContext.isZoneLevelCompany()}
                                      handleResult={handleResult}
                                      handleExternalTestView={handleExternalTestView}
                                      appContext={appContext}
                                    />
                                  );
                                })
                              ) : (
                                <span>No Records Found!</span>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <Loader />
          )}
          <FullImageView image={externalTestImage} handleClose={() => setExternalTestImage("")} />
        </>
      </Modal.Body>

      <Modal.Footer>
        <Button className="modalButtons headerButton btn-fill" variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeAuditTrail;
