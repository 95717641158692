import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { formatNumber } from "utils";
import ViewCheckBox from "../CheckBox/ViewCheckBox";

const MFPagination = (props) => {
  const {
    currentPage,
    totalPages,
    handleChangePage,
    totalSelected,
    showSelectedRecord,
    nextPageToken,
    showAll,
    selectedLabel,
    showAllBtn,
    handleShowAll,
    currentRecord,
    totalRecord,
  } = props;

  const showLabel = () => {
    if (showSelectedRecord) {
      const label = selectedLabel ? selectedLabel : "Selected Employee";
      return (
        <span>
          {label} : <span className="totalSelectedNumber">{formatNumber(totalSelected)}</span>
        </span>
      );
    }
    return "";
  };

  return (
    <Row className="align-items-center">
      <Col>
        <span>{showLabel()}</span>
      </Col>

      <Col>
        <div className="d-flex align-items-center justify-content-end gap-3">
          {!showAll && (
            <div>
              Page
              <ul className="createButtons pr-0" style={{ display: "inline" }}>
                <i
                  className="fas fa-angle-left"
                  style={{ color: "#585858" }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                    e.target.style.color = "black";
                  }}
                  onMouseLeave={(e) => (e.target.style.color = "#585858")}
                  onClick={() => {
                    currentPage !== 1 && handleChangePage(currentPage - 1, "prev");
                  }}
                />
                {formatNumber(currentPage)} of {formatNumber(totalPages)} {currentRecord < totalRecord && "..."}
                <i
                  className="fas fa-angle-right"
                  style={{ color: "#585858" }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                    e.target.style.color = "black";
                  }}
                  onMouseLeave={(e) => (e.target.style.color = "#585858")}
                  onClick={() => {
                    currentPage !== totalPages && handleChangePage(currentPage + 1, "next");
                  }}
                />
              </ul>
            </div>
          )}
          {showAllBtn && (
            <>
              <span>Show All</span>
              <div
                role="button"
                onClick={() => {
                  handleShowAll(!showAll);
                }}
              >
                <ViewCheckBox id="1" bgColor="blue" isChecked={showAll} />
              </div>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default MFPagination;
