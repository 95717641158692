import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { getValidReg } from "utils";
import api from "api";
import ErrorMessage from "components/Message/ErrorMessage";

const AssigneDepartmentModal = (props) => {
  const { handleClose, handleSave } = props;
  const [departments, setDepartments] = useState([]);
  const [newUser, setNewUser] = useState({});
  const [selectedVal, setSelectedVal] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    if (selectedVal) {
      const calltime = departments.find((dpt) => selectedVal === dpt.id)?.callTime || "";
      const timeZone = departments.find((dpt) => selectedVal === dpt.id)?.deptTimeZone || null;
      setTimeZone(timeZone);
      const obj = { ...newUser, callTime: calltime };
      setNewUser(obj);
    }
  }, [selectedVal, departments]);

  useEffect(() => {
    if (timeZone === null || timeZone) {
      const obj = { ...newUser, empTZ: "" };
      setNewUser(obj);
    }
  }, [timeZone]);

  const onHandleSave = (newUser) => {
    if (!newUser.callTime) {
      setError("please Set Call Time");
      return;
    }
    if (!newUser.department) {
      setError("Please Select Department");
      return;
    }
    handleSave(newUser);
  };

  const getDepartments = async () => {
    const data = await api.getDepartments();
    setDepartments(data);
  };

  const getEmployeeDepartment = () => {
    if (newUser.department) {
      const dept = departments.find((dpt) => newUser.department === dpt.id);
      if (dept) return { value: dept.id, label: dept.name };
      return null;
    }
    return null;
  };
  console.log("department");
  return (
    <Modal
      show
      animation={true}
      size="xl"
      onHide={() => {
        handleClose();
      }}
      style={{ paddingLeft: "0" }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Assign Department
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          paddingTop: 0,
        }}
      >
        <div className="row">
          <div className="col-sm-12">
            <div className="createClientsWrapper flex-wrap">
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Call Time: </label>
                  <input
                    type="time"
                    id="callTime"
                    value={newUser.callTime}
                    className="modalInput align-middle"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, callTime: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Department: </label>

                  <Select
                    options={departments.map((t) => {
                      return { value: t.id, label: getValidReg(t.name) };
                    })}
                    blurInputOnSelect={true}
                    menuPlacement="auto"
                    placeholder="Select Department"
                    className="w-100"
                    value={getEmployeeDepartment()}
                    onChange={(val) => {
                      setSelectedVal(val.value);
                      setNewUser({ ...newUser, department: val.value, departmentName: val.label });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ minHeight: "3vh" }}>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons headerButton btn-fill"
          onClick={() => onHandleSave(newUser)}
        >
          Assign
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssigneDepartmentModal;
