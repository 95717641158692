import React, { useRef, useContext } from "react";
import QRCode from "qrcode.react";
import cardLogo from "assets/img/safecamp_BRAND.1-nobg.png";
import Default_Profile from "assets/img/default_profile.png";
import { removeSpaceIns } from "utils";
import html2canvas from "html2canvas";

const QRLink = (props) => {
  const downloadRef = useRef();
  let qrCode = "";
  let color = "#000000";

  const {
    match: { params },
  } = props;
  if (params && params.id) {
    const value = params.id.split(":");
    qrCode = value[0];
    if (value.length > 1) color = `#${value[1]}`;
  }

  const downloadQRCode = async () => {
    const element = downloadRef.current;
    console.log("Eleme", element);
    const canvas = await html2canvas(element);
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `qrcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
   
  return (
    <>
      <div className="codeScannerWrapper">
        <div className="registered-successful">
          <div className="registered-inner">
            <div className="justify-content-center align-items-center">
              <div className="qrWrapper" ref={downloadRef}>
                <QRCode
                  className="qrCode"
                  id="qr-gen"
                  value={qrCode}
                  size={500}
                  level={"M"}
                  fgColor={color}
                  includeMargin={true}
                />
              </div>
            </div>
            <div className="text-right w-100">
              <button className="btn-sm btn btn-primary mt-3 print-btn" onClick={downloadQRCode}>Download</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default QRLink;
