import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "context/app-context";
import { Card, Table, Row, Col, Form } from "react-bootstrap";
import API from "api";
import editIcon from "../../assets/img/edit-icon.png";
import { calculateTdWidth } from "utils";
import trashIcon from "../../assets/img/trash-icon.png";
import HeaderItem from "components/Table/HeaderItem";
import FilterPopOver from "components/FilterPopOver";
import MFPagination from "components/Pagination/MFPagination";
import { formatCreatedDate } from "utils";
import ExportToExcel from "components/ExportToExcel";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import ProxyOptionModal from "./components/Modal/ProxyOptionModal";
import { CONFIRMATION_TYPE } from "constant";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { formatNumber } from "utils";

const ProxyContactOptions = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [proxyOptions, setProxyOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [filter, setFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filterProxyOpts, setFilterProxyOpts] = useState([]);
  const [triggerFilter, setTriggerFilter] = useState();
  const [allSelected, setAllSelected] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [optToUpdate, setOptToUpdate] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [itemToDelete, setItemToDelete] = useState(null);

  const searchTerms = ["Name"];

  const selectedItem = [
    { itemKey: "createdAt" },
    { itemKey: "name" },
    { itemKey: "isActive" },
    { itemKey: "updatedAt" },
  ];

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(width - 150, 1);

  const sortProxyOpts = (proxyOpts, sortParam) => {
    if (sortDescending) {
      return [...proxyOpts].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...proxyOpts].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  useEffect(() => {
    if (proxyOptions.length > 0) setFilterProxyOpts(sortProxyOpts(proxyOptions, sortBy));
  }, [sortBy, sortDescending]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  useEffect(() => {
    if (appContext.proxyContacts != "") {
      const data = appContext.proxyContacts;
      setProxyOptions(data);
      setFilterProxyOpts(data);
    }
  }, [appContext.proxyContacts]);

  useEffect(() => {
    setFilterProxyOpts(nestedFilter(proxyOptions, filter));
  }, [filter, proxyOptions]);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? proxyOptions.map((t) => t.id) : []);
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    if (filterProxyOpts.length === filteredList.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
    setCheckboxes(filteredList);
  };

  const handleUpdateClick = (option) => {
    setOptToUpdate(option);
    setOpenUpdateModal(true);
  };

  const updateOption = async (opt) => {
    setOpenUpdateModal(false);
    setOptToUpdate(null);
    try {
      setLoading(true);
      if (!opt.isNew) {
        await API.updateProxyContactData(opt);
        appContext.showSuccessMessage("Proxy option updated successfully!");
      } else {
        await API.addProxyContactData(opt);
        appContext.showSuccessMessage("Proxy option added successfully!");
      }
      appContext.resetProxyContacts();
      setLoading(false);
    } catch (error) {
      console.log(error);
      appContext.showErrorMessage(error.message);
    }
  };

  const getDeleteMessage = () => {
    const optsToDelete = proxyOptions.filter((d) => checkboxes.indexOf(d.id) !== -1);
    const ids = optsToDelete.map((d) => d.id);

    let optName = null;
    let totalOpts = null;

    if (ids.length === 1) {
      const optsToRemove = proxyOptions.find((d) => d.id === ids[0]);
      if (optsToRemove) {
        optName = optsToRemove.name;
      }
    } else {
      totalOpts = ids.length;
    }
    return [ids, optName, totalOpts];
  };

  const openDeleteConfirmation = (option) => {
    +setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.DELETE,
      title: "Delete option",
      message: `Are you sure you want to delete the ${option.name} option?`,
    });
    if (option) setItemToDelete(option);
  };

  const handleBulkDeleteClick = () => {
    let [ids, optName, totalopts] = getDeleteMessage();
    if (ids.length === 0) {
      appContext.showErrorMessage("Select at-least one option with no crew members assigned");
      return;
    }
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.MULTI_DELETE,
      title: "Delete Option",
      message: optName
        ? `Are you sure you want to delete the ${optName} option?`
        : `Are you sure you want to delete the ${formatNumber(totalopts)} options selected?`,
    });
  };

  const onHandleConfirmation = async (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      setItemToDelete(null);
      setCheckboxes([]);
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.DELETE:
        handleConfirmDelete();
        break;
      case CONFIRMATION_TYPE.MULTI_DELETE:
        deleteOptions();
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      console.log(itemToDelete);
      await API.deleteProxyContactData(itemToDelete.id);
      setItemToDelete(null);
      appContext.resetProxyContacts();
      appContext.showSuccessMessage("Option deleted successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  const deleteOptions = async () => {
    try {
      let [ids, optName, totalopts] = getDeleteMessage();
      if (ids.length === 0) {
        appContext.showErrorMessage("No Options to Delete");
        return;
      }
      setLoading(true);
      console.log("ids to delete", ids);
      for (let i = 0; i < ids.length; i++) {
        await API.deleteProxyContactData(ids[i]);
      }
      if (optName) {
        appContext.showSuccessMessage(`${optName} Option has been deleted successfully`);
      } else {
        appContext.showSuccessMessage(`${formatNumber(totalopts)} Options have been deleted successfully`);
      }
      appContext.resetProxyContacts();
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage("Fail to Delete the Option");
    }
    setLoading(false);
    setCheckboxes([]);
  };

  const TableRow = ({ item }) => {
    return (
      <tr key={item.id}>
        <td>
          {" "}
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={item.id}
              checked={checkboxes.indexOf(item.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, item.id)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td className="icon">
          <img
            src={editIcon}
            alt="edit icon"
            width="18"
            className="fas fa-edit"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={() => handleUpdateClick(item)}
          />
        </td>
        <td style={{ textAlign: "left", textOverflow: "visible" }} title={formatCreatedDate(item.createdAt)}>
          {formatCreatedDate(item.createdAt)}
        </td>
        <td style={{ textAlign: "left", textOverflow: "visible" }} title={item.name}>
          {item.name}
        </td>
        {/* <td style={{ textAlign: "left", textOverflow: "visible" }} title={item.isActive ? "Yes" : "No"}>
          {item.isActive ? "Yes" : "No"}
        </td> */}
        <td style={{ textAlign: "left", textOverflow: "visible" }} title={formatCreatedDate(item.updatedAt)}>
          {formatCreatedDate(item.updatedAt)}
        </td>
        <td style={{ textAlign: "center", textOverflow: "visible" }}>
          <img
            src={trashIcon}
            alt="trash icon"
            width="15"
            style={{ marginLeft: "10px" }}
            className="fa fa-trash"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={() => openDeleteConfirmation(item)}
          />
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  Total Proxy Conatct options (
                  {filterProxyOpts ? formatNumber(filterProxyOpts.length) : formatNumber(proxyOptions.length)})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    label="Filter"
                    title="Filter"
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={() => {
                      if (proxyOptions && proxyOptions.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />

                  <Icon
                    handleClick={() => handleBulkDeleteClick()}
                    label="Delete"
                    title="Delete"
                    disabled={checkboxes.length === 0}
                    iconType={"binIcon"}
                  />

                  <Icon
                    handleClick={() => setOpenUpdateModal(true)}
                    title={"Create Option"}
                    label="Create"
                    iconType="createIcon"
                  />

                  {filterProxyOpts.length > 0 && (
                    <ExportToExcel
                      items={filterProxyOpts}
                      selectedItem={selectedItem}
                      appContext={appContext}
                      filter={filter}
                      title="Proxy Options Data"
                      sortBy={sortBy}
                    />
                  )}
                </div>

                {showFilter && (
                  <FilterPopOver
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination
                  totalSelected={checkboxes.length}
                  showAll
                  showSelectedRecord
                  selectedLabel="Selected Options"
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    proxyOptions.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="table">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>
                        <th></th>
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="createdAt"
                          title="Date Created"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="name"
                          title="Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        {/* <HeaderItem
                          width={tdWidth}
                          ItemKey="isActive"
                          title="Active"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        /> */}
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="updatedAt"
                          title="Date Updated"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <th className={`border-0 centered`} title="Action">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterProxyOpts.map((item, i) => {
                        return <TableRow key={i} item={item} />;
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Loader />
          )}
          {openUpdateModal && (
            <ProxyOptionModal
              handleClose={() => {
                setOptToUpdate(null);
                setOpenUpdateModal(false);
              }}
              handleSave={updateOption}
              selectItem={optToUpdate}
              proxyOptions={proxyOptions}
            />
          )}

          {openConfirmation.isShow && (
            <ConfirmationModal
              show={openConfirmation.isShow}
              actionType={openConfirmation.actionType}
              title={openConfirmation.title}
              message={openConfirmation.message}
              handleConfirm={onHandleConfirmation}
              closeBtn
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProxyContactOptions;
