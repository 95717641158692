import React, { useState, useRef } from "react";
import TestDetailsMatrixModal from "components/Modal/TestDetailsMatrixModal";
import SchedulesDetails from "components/SchedulesDetails";
import moment from "moment";
import { formatTime, isTooDark } from "utils";
import Status from "components/Status/Status";
import api from "api";
import ExternalTestProgramModal from "components/Modal/ExternalTestProgramModal";
import NotesStatus from "components/NotesStatus";
import { Card, Table, Row, Col, Button, Form } from "react-bootstrap";
import { formatPhoneNumber } from "utils";
import EmployeeName from "components/EmployeeName/EmployeeName";

const EmployeeItem = (props) => {
  const {
    emp,
    checkboxes,
    showLeveMtarix,
    tdWidth,
    setAddSpecificNotesEmp,
    AddNotes,
    handleCheckboxChange,
    deptLeftStickPosition,
    zoneLeftStickPosition,
    handleUserProfile,
  } = props;
  const isDarkEmp = isTooDark(emp?.color);

  const showQuarantine = (emp) => {
    if (emp.isQuarantine) {
      let days = emp.quarantineRemainingDays;
      let message = "remaining days";
      if (days > 0) {
        message = `${days} days remaining`;
      } else if (days === 0 && emp.quarantineColor === "maroon") {
        message = `Quarantine ends today`;
      } else if (days === 0 && emp.quarantineColor === "blue") {
        message = `P90 ends today`;
      }
      return (
        <div className="star-icon-wrapper">
          <span title={message}>
            <Status type="circle" size="lg" color={emp.quarantineColor} crossIcon />
          </span>
        </div>
      );
    }
    return (
      //  style={{ background: emp.quarantineColor, borderColor: emp.quarantineColor }}
      <div className="star-icon-wrapper">
        <Status type="circle" size="lg" color={emp.quarantineColor} crossIcon />
      </div>
    );
  };

  return (
    <>
      <div
        className="first-col-checkBox tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
        style={{
          left: `${showLeveMtarix ? deptLeftStickPosition[0] : zoneLeftStickPosition[0]}px`,
        }}
      >
        <Form.Check className="tcMatrixCheckBox text-center">
          <Form.Check.Input
            type="checkbox"
            checked={checkboxes.findIndex((f) => f?.id === emp.id) !== -1}
            value={emp.id}
            onChange={(e) => handleCheckboxChange(e, emp)}
          />
        </Form.Check>
      </div>
      <div
        className="first-col-checkBox tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
        style={{
          textAlign: "left",
          left: `${showLeveMtarix ? deptLeftStickPosition[1] : zoneLeftStickPosition[1]}px`,
        }}
      >
        <div className="cellWrapper text-center">{showQuarantine(emp)}</div>
      </div>
      <div
        className="first-col-checkBox tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
        style={{
          textAlign: "left",
          left: `${showLeveMtarix ? deptLeftStickPosition[2] : zoneLeftStickPosition[2]}px`,
        }}
      >
        <div className="cellWrapper text-center">
          <NotesStatus
            emp={emp}
            AddNotes={AddNotes}
            setOpenNotesModal={(isOpen) => {
              if (isOpen) {
                setAddSpecificNotesEmp(emp);
              }
            }}
          />
        </div>
      </div>
      {showLeveMtarix && (
        <div
          className="zoneCell ellipsis assignBlock tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
          style={{
            backgroundColor: `${emp?.zoneColor || "black"}`,
            color: `${isDarkEmp ? "white" : "black"}`,
            left: `${deptLeftStickPosition[3]}px`,
          }}
          title={emp?.programName || "Unassigned"}
        >
          {emp?.programName || "Unassigned"}
        </div>
      )}

      <EmployeeName
        deptLeftStickPosition={deptLeftStickPosition}
        emp={emp}
        showLeveMtarix={showLeveMtarix}
        zoneLeftStickPosition={zoneLeftStickPosition}
        handleUserProfile={handleUserProfile}
      />
      <div
        className="ellipsis nameBlock tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
        style={{
          textAlign: "left",
          left: `${showLeveMtarix ? deptLeftStickPosition[5] : zoneLeftStickPosition[4]}px`,
        }}
        title={emp.lastName}
      >
        {emp.lastName}
      </div>
      <div
        className="ellipsis emailPhoneBlock tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
        style={{
          textDecoration: "none",
          color: "var(--btn-danger)",
          textAlign: "left",
          left: `${showLeveMtarix ? deptLeftStickPosition[6] : zoneLeftStickPosition[5]}px`,
        }}
        onMouseOver={(e) => {
          e.target.style.cursor = "pointer";
        }}
        onClick={() => emp.email && window.open(`mailto:${emp.email}`)}
        title={emp.email}
      >
        {emp.email}
      </div>
      <div
        className="ellipsis emailPhoneBlock tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell"
        style={{
          textDecoration: "none",
          color: "#08a003",
          textAlign: "left",
          left: `${showLeveMtarix ? deptLeftStickPosition[7] : zoneLeftStickPosition[6]}px`,
        }}
        onMouseOver={(e) => {
          e.target.style.cursor = "pointer";
        }}
        onClick={() => emp.phoneNumber && window.open(`tel:+${emp.phoneNumber.replace(/\D/g, "")}`)}
        title={emp.phoneNumber}
      >
        {formatPhoneNumber(emp.phoneNumber)}
      </div>
    </>
  );
};

export default EmployeeItem;
