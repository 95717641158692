import React, { useState, useMemo, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { AppContext } from "context/app-context";
import api from "api";
import ErrorMessage from "components/Message/ErrorMessage";
import { getValidReg, checkValidity, isValidEmail, validatePhone } from "utils";
import { TIMEZONE_OPT, TIMEZONE_OPT_LIVE } from "constant";
import UpdateConfirmation from "components/Department/UpdateConfirmation";
import { isValidReminder } from "utils";
import CallTimeReminder from "components/CallTimeReminder";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import RadioButton from "../RadioButton/RadioButton";
import { convertToLower, CONFIG } from "utils";

const DepartmentModal = (props) => {
  const { show, selectedDepartment, handleClose, handleSubmit, departments } = props;
  let obj = {
    name: "",
    callTime: null,
    reminderOne: "",
    reminderTwo: "",
    deptTimeZone: "",
    isNew: true,
    reminderOneDirection: "before",
    reminderTwoDirection: "after",
    contact_name: "",
    phone_number: "",
    contact_email: "",
  };

  const [departmentObj, setDepartmentObj] = useState(selectedDepartment ? selectedDepartment : obj);
  const [error, setError] = useState("");
  const [updateConfirmation, setUpdateConfirmation] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const appContext = useContext(AppContext);

  const isExistName = () => {
    const isExist = departments.filter(
      (d) => departmentObj.id !== d.id && checkValidity(d.name) == checkValidity(departmentObj.name)
    );
    if (isExist && isExist.length > 0) return true;
    return false;
  };

  const handleSave = (Obj) => {
    const department = { ...Obj };
    if (!department.name.trim()) {
      setError("Department Name is required");
      return;
    }
    department.name = getValidReg(department.name);
    if (!department.callTime) {
      setError("Call Time Required");
      return;
    }
    if (department.reminderOne && parseInt(department.reminderOne) % 15 !== 0) {
      setError("Invalid first reminder should be in 15 mins gap!");
      return;
    }
    if (department.reminderOne && parseInt(department.reminderOne) < 1) {
      setError("Reminder must be greater than zero");
      return;
    }

    if (department.reminderTwo && parseInt(department.reminderTwo) % 15 !== 0) {
      setError("Invalid second reminder should be in 15 mins gap!");
      return;
    }
    if (department.reminderTwo && parseInt(department.reminderTwo) < 1) {
      setError("Reminder must be greater than zero");
      return;
    }

    if (!department.deptTimeZone || department.deptTimeZone === "") {
      setError("Select department time zone");
      return;
    }

    //
    // if (!department.contact_name) {
    //   setError("Contact name is required");
    //   return;
    // }
    // if (!department.contact_email.trim()) {
    //   setError("Email  is required");
    //   return;
    // }

    if (department.contact_email && !isValidEmail(department.contact_email)) {
      setError("Invalid Email");
      return;
    }

    // if (!department.phone_number) {
    //   setError("Phone number is required");
    //   return;
    // }
    // if (!validatePhone(department.phone_number)) {
    //   setError("Invalid Phone Number");
    //   return;
    // }

    if (isExistName()) {
      setError("Department name already exists");
      return;
    }
    if (department.reminderOne) {
      Object.assign(department, { reminderOne: `${department.reminderOne.replace(/\D/g, "")}m` });
    }
    if (department.reminderTwo) {
      Object.assign(department, { reminderTwo: `${department.reminderTwo.replace(/\D/g, "")}m` });
    }
    console.log({ department });
    if (department.isNew) {
      handleSubmit(department);
      return;
    }
    if (
      selectedDepartment &&
      selectedDepartment.callTime !== departmentObj.callTime &&
      departmentObj.totalEmployees > 0
    ) {
      setUpdateConfirmation(true);
      return;
    }
    handleSubmit(department);
  };

  const onHandleUpdateConfirmation = (updateOption) => {
    handleSubmit({ ...departmentObj, updateOption });
  };

  return (
    <>
      <Modal show={show} animation={true} onHide={() => handleClose()} centered size={"lg"} keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {selectedDepartment ? "Edit Department" : "Create New Department"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0 }}>
          <div className="row mb-3"></div>
          <div className="row">
            <div className="col-sm-12">
              <div className="createClientsWrapper flex-wrap">
                <div className="first-last-name-wrapper">
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">Department Name:</label>
                    <input
                      id="name"
                      className="modalInput"
                      placeholder="Enter Department Name"
                      value={departmentObj.name}
                      onChange={(e) => setDepartmentObj({ ...departmentObj, name: e.target.value })}
                    />
                  </div>
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">Department Head:</label>
                    <input
                      id="name"
                      className="modalInput"
                      placeholder="Department Head"
                      value={departmentObj.contact_name}
                      onChange={(e) => setDepartmentObj({ ...departmentObj, contact_name: e.target.value })}
                    />
                  </div>
                </div>
                <div className="first-last-name-wrapper">
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">Call Time:</label>
                    <input
                      style={{ lineHeight: "inherit", contain: "inline-size" }}
                      type="time"
                      id="callTime"
                      step="900"
                      onFocus={(e) => console.log("e", e)}
                      className="modalInput d-inline-flex align-items-center"
                      value={departmentObj.callTime || ""}
                      onChange={(e) => setDepartmentObj({ ...departmentObj, callTime: e.target.value })}
                    />
                  </div>
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">Department Head Phone Number:</label>
                    <PhoneNoInput
                      cssClass="pd-right-30"
                      handleChange={(e) => {
                        setDepartmentObj({
                          ...departmentObj,
                          phone_number: e,
                        });
                      }}
                      value={departmentObj.phone_number}
                    />
                  </div>
                </div>
                <div className="first-last-name-wrapper">
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">Time Zone:</label>
                    <Select
                      options={CONFIG.isLive ? TIMEZONE_OPT_LIVE : TIMEZONE_OPT}
                      blurInputOnSelect={true}
                      defaultValue={null}
                      menuPlacement="auto"
                      placeholder="Select Time Zone"
                      value={
                        departmentObj.deptTimeZone
                          ? { label: departmentObj.deptTimeZone, value: departmentObj.deptTimeZone }
                          : null
                      }
                      onChange={(e) =>
                        setDepartmentObj({
                          ...departmentObj,
                          deptTimeZone: e.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">Department Head Email:</label>
                    <input
                      id="name"
                      className="modalInput"
                      placeholder="Department Head Email"
                      value={departmentObj.contact_email}
                      onChange={(e) =>
                        setDepartmentObj({ ...departmentObj, contact_email: convertToLower(e.target.value) })
                      }
                    />
                  </div>
                </div>
                <div className="first-last-name-wrapper">
                  <CallTimeReminder
                    placeholder="First Reminder"
                    title="First Reminder Time:"
                    value={departmentObj.reminderOne}
                    setError={setError}
                    handleChange={(int, valid) => {
                      setDisabled(!valid);
                      if (valid) {
                        setDepartmentObj({
                          ...departmentObj,
                          reminderOne: `${int}`.replace(/^0/, ""),
                        });
                      }
                    }}
                  />
                  <CallTimeReminder
                    placeholder="Second Reminder"
                    title="Second Reminder Time:"
                    value={departmentObj.reminderTwo}
                    setError={setError}
                    handleChange={(e, valid) => {
                      setDisabled(!valid);
                      if (valid) {
                        setDepartmentObj({
                          ...departmentObj,
                          reminderTwo: `${e}`.replace(/^0/, ""),
                        });
                      }
                    }}
                  />
                </div>
                <div className="first-last-name-wrapper">
                  <div className="form-group-wrapper emp-select-area">
                    <div className="d-flex justify-content-between w-100 flex-wrap">
                      <RadioButton
                        name="roBefore"
                        id="roBefore"
                        label="Before Call Time"
                        checked={departmentObj?.reminderOneDirection === "before" ? true : false}
                        handleChange={(e) =>
                          setDepartmentObj({ ...departmentObj, reminderOneDirection: e.target.checked ? "before" : "" })
                        }
                      />
                      <RadioButton
                        name="roAfter"
                        id="roAfter"
                        checked={departmentObj?.reminderOneDirection === "after" ? true : false}
                        label="After Call Time"
                        handleChange={(e) =>
                          setDepartmentObj({ ...departmentObj, reminderOneDirection: e.target.checked ? "after" : "" })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group-wrapper emp-select-area">
                    <div className="d-flex justify-content-between w-100 flex-wrap">
                      <RadioButton
                        name="rtBefore"
                        id="rtBefore"
                        label="Before Call Time"
                        checked={departmentObj?.reminderTwoDirection === "before" ? true : false}
                        handleChange={(e) =>
                          setDepartmentObj({ ...departmentObj, reminderTwoDirection: e.target.checked ? "before" : "" })
                        }
                      />
                      <RadioButton
                        name="rtAfter"
                        id="rtAfter"
                        checked={departmentObj?.reminderTwoDirection === "after" ? true : false}
                        label="After Call Time"
                        handleChange={(e) =>
                          setDepartmentObj({ ...departmentObj, reminderTwoDirection: e.target.checked ? "after" : "" })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
            Close
          </Button>
          <Button
            variant="secondary"
            className="headerButton btn-fill"
            disabled={disabled}
            onClick={() => handleSave(departmentObj)}
          >
            {selectedDepartment ? "Update" : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
      {updateConfirmation && (
        <>
          <div className="secondModal"></div>
          <UpdateConfirmation
            handleSave={onHandleUpdateConfirmation}
            handleClose={() => {
              setUpdateConfirmation(false);
            }}
            settings={appContext.company}
          />
        </>
      )}
    </>
  );
};

export default DepartmentModal;
