import React, { useState, useEffect, useRef } from "react";
import {
  medFlowInLocalStorage,
  formatZip,
  checkValidity,
  getValidName,
  isValidName,
  isValidIdNum,
  isValidPhoneWithCode,
  getPhoneNo,
} from "utils";
import { useHistory, Link } from "react-router-dom";
import logoImage from "assets/img/safecamp_BRAND.1-nobg.png";
import { Auth } from "aws-amplify";
import { newRegisterObj } from "constant";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Default_Profile from "assets/img/default_profile.png";
import { QRCode } from "react-qrcode-logo";
import logo from "assets/img/logo.jpg";
import { Button } from "react-bootstrap";
import API from "api";
import html2canvas from "html2canvas";
import countriesOption from "countries";
import Select from "react-select";
import moment from "moment";
import { countryListAllIsoData } from "constant";
import Loader from "components/Loader/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { toTitleCase, convertToLower, isValidZipCode, getValidSpaces } from "utils";
import SweetAlert from "react-bootstrap-sweetalert";
import SingleDatePicker from "components/Date/SingleDatePicker";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import { isValidEmail, validatePhone } from "utils";
import QrCodeCard from "components/QrCard/QrCodeCard";
import ErrorMessage from "components/Message/ErrorMessage";
import { formatPhoneNumber } from "utils";
import ShowAlertMessage from "components/ShowAlertMessage";

let employeeId = "";
const PreRegister = () => {
  const [newUser, setNewUser] = useState(newRegisterObj);
  const [regUser, setRegUser] = useState({});
  const [registrationDone, setRegistrationDone] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [show, setShow] = useState(false);
  const [messageError, setMessageError] = useState("");
  const history = useHistory();
  const downloadRef = useRef();
  const [company, setCompnay] = useState({
    companyName: "Testing",
    companyWeb: "devtest",
    id: "10725574-1495-458d-a1c4-252becd947d0",
  });

  let url = window.location.href;

  const redirectToAdmin = () => {
    if (history) history.push("/admin");
  };

  useEffect(() => {
    const getUSer = async () => {
      const companyData = await API.getCompnayList();
      console.log("companyData", companyData);
      const urlData = companyData.filter((c) => url.includes(c.companyWeb));
      console.log("urlDataurlData", urlData);
      if (urlData.length > 0) {
        console.log("fsfsfdf");
        setCompnay(urlData[0]);
        setNewUser({ ...newUser, companyID: urlData[0].id });
      } else {
        setNewUser({ ...newUser, companyID: "10725574-1495-458d-a1c4-252becd947d0" });
      }
    };
    getUSer();
  }, []);
  console.log("company", company);
  const onHandleSave = async () => {
    if (!regUser.phoneNumber) {
      setError("Phone number is required");
      return;
    }
    if (!validatePhone(regUser.phoneNumber)) {
      setError("Invalid Phone Number");
      return;
    }
    setLoading(true);
    const cid = company.id;
    console.log("regUser", regUser.phoneNumber);
    const data = { cid, phoneNumber: regUser.phoneNumber };
    try {
      const res = await API.getRegisterData(data);
      console.log("response", res);

      if (Object.keys(res).length > 0) {
        setMessageError("Phone Number Already Registered");
      } else {
        setNewUser({ ...newUser, ...regUser });
        setShow(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn) redirectToAdmin();

  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };
  console.log("newUSer", newUser);
  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewUser({ ...newUser, street: val });
    } else {
      console.log("valval", val);
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewUser({ ...newUser, ...addressObj });
    }
  };
  const handleSubmit = async () => {
    if (!newUser.companyID) {
      showErrorMessage("Your Company Id is not set");
      return;
    }

    if (!newUser.firstName?.trim()) {
      showErrorMessage("First name is required");
      return;
    }

    if (!isValidName(newUser.firstName)) {
      showErrorMessage("First name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.lastName?.trim()) {
      showErrorMessage("Last name is required");
      return;
    }
    if (!isValidName(newUser.lastName)) {
      showErrorMessage("Last name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.email) {
      showErrorMessage("Email name is required");
      return;
    }
    if (!isValidEmail(newUser.email)) {
      showErrorMessage("Invalid Email");
      return;
    }

    // if (!newUser.phoneNumber) {
    //   showErrorMessage("Phone Number is required");
    //   return;
    // }

    // if (!isValidPhoneWithCode(newUser.phoneNumber)) {
    //   showErrorMessage("Invalid Phone Number");
    //   return;
    // }

    if (!newUser.password) {
      showErrorMessage("Password is requried");
      return;
    }

    if (newUser.password) {
      console.log(newUser.password);
      const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(newUser.password);

      if (!isValidPassword) {
        showErrorMessage(
          "Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
        );
        return;
      }
    }

    if (!newUser.dob) {
      showErrorMessage("Date of Birth is required");
      return;
    }

    if (!newUser.street.trim()) {
      showErrorMessage("Address is required");
      return;
    }
    if (!newUser.city.trim()) {
      showErrorMessage("City is required");
      return;
    }

    if (!newUser.state.trim()) {
      showErrorMessage("State is required");
      return;
    }
    if (!newUser.country) {
      showErrorMessage("Country is Required");
      return;
    }
    if (newUser.zip === null || !newUser.zip) {
      showErrorMessage("zip is required");
      return;
    }

    if (!isValidZipCode(newUser.isoCode || "US", newUser.zip)) {
      showErrorMessage("Invalid zipcode");
      return;
    }

    if (!newUser.idNumber) {
      showErrorMessage("DL/Passport Number is required");
      return;
    }
    if (!isValidIdNum(newUser.idNumber)) {
      showErrorMessage("Invalid DL/Passport Number must contains at least 5 characters");
      return;
    }
    if (!newUser.sex) {
      showErrorMessage("Gender is required");
      return;
    }
    if (newUser.isVaccinated === null) {
      showErrorMessage("Vaccinated is required");
      return;
    }

    try {
      let countryCode = "";
      setLoading(true);
      let employeeObj = { ...newUser };
      const phone = formatPhoneNumberIntl(employeeObj.phoneNumber);
      const phoneArr = phone.split(" ");
      // check Country code exist

      if (phoneArr[0].includes("+")) {
        let [cc] = getPhoneNo(employeeObj.phoneNumber, phoneArr[0]);
        countryCode = cc;
      }
      employeeObj = { ...employeeObj, countryCode: countryCode };

      const attributePayload = {
        email: newUser.email,
        phone_number: newUser.phoneNumber,
        preferred_username: newUser.phoneNumber,
        "custom:role": `Employees`,
        "custom:autoConfirm": "true",
        "custom:firstName": `${newUser.firstName}`,
        "custom:lastName": `${newUser.lastName}`,
        "custom:note": company.companyName,
        "custom:labID": newUser.client ? newUser.client.id : "",
      };

      const signUp = await Auth.signUp({
        username: newUser.phoneNumber,
        password: newUser.password,
        attributes: attributePayload,
      });

      const userId = signUp.userSub;

      const response = await API.createHrEmployee(employeeObj);
      console.log("responseresponse", response);
      employeeId = response.data;
      if (!employeeId) throw "Employee don't create";
      setLoading(false);
      setNewUser({ ...newUser, id: employeeId });
      setRegistrationDone(true);
    } catch (err) {
      employeeId = "";
      console.log("Errr", err.response);
      setLoading(false);
      setMessageError(err.response?.status === 422 ? err.response?.data.data : err.message);
    }
  };

  const downloadQRCode = async () => {
    const element = downloadRef.current;
    console.log("Eleme", element);
    const canvas = await html2canvas(element);
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${newUser.phoneNumber.substring(2)}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (registrationDone) {
    return (
      <div className="codeScannerWrapper">
        <div className="registered-successful">
          <h3>Thank you for Registering</h3>
          <p>Please present this QR code at Event Check In</p>
          <div className="justify-content-center align-items-center pt-4">
            <div id="section-to-print" className="printSide" ref={downloadRef} style={{ display: "block" }}>
              <QrCodeCard profileImage={Default_Profile} newUser={newUser} preReg />
            </div>
            <div className="downloadQrBtn">
              <div className="pt-3 d-flex align-items-center justify-content-evenly">
                <Button className="w-75" variant="secondary" onClick={downloadQRCode}>
                  Download QR Code
                </Button>
                <Button variant="secondary" onClick={() => window.print()}>
                  <i className="fas fa-print fs-4" style={{ color: "black" }} />
                </Button>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {show ? (
            <div className="registerFormWrapper">
              <div className="registerWrapper pre-register-comp">
                <div className="loginInner">
                  <div className="text-center">
                    <img src={logoImage} alt="logo" className="logo-width" />
                  </div>
                  <h4 className="w-100 text-center mt-4 fs-4 fw-bold">{company.companyName}</h4>
                  <h4 className="w-100 text-center mt-4 fs-4 fw-bold">Register your account</h4>
                  <div className="form-wrapper">
                    <div className="form-group-wrapper mt-2">
                      <label className="mb-1">First Name:</label>
                      <input
                        type="text"
                        className="modalInput"
                        placeholder="First Name"
                        value={newUser.firstName}
                        onChange={(e) => setNewUser({ ...newUser, firstName: getValidName(e.target.value) })}
                      />
                    </div>
                    <div className="form-group-wrapper ">
                      <label className="mb-1">Last Name:</label>
                      <input
                        type="text"
                        className="modalInput"
                        placeholder="Last Name"
                        value={newUser.lastName}
                        onChange={(e) => setNewUser({ ...newUser, lastName: getValidName(e.target.value) })}
                      />
                    </div>
                    <div className="form-group-wrapper ">
                      <label className="mb-1">Email:</label>
                      <input
                        type="text"
                        maxLength={75}
                        className="modalInput"
                        placeholder="Email"
                        value={newUser.email}
                        onChange={(e) => setNewUser({ ...newUser, email: convertToLower(e.target.value) })}
                      />
                    </div>
                    <div className="form-group-wrapper ">
                      <label className="mb-1">Phone Number:</label>
                      <input
                        type="text"
                        maxLength={75}
                        className="modalInput"
                        placeholder="Phone Number"
                        value={formatPhoneNumber(newUser.phoneNumber)}
                        disabled={true}
                      />
                      {/* <PhoneNoInput
                        error={setPhoneError}
                        handleChange={(e) => {
                          setNewUser({
                            ...newUser,
                            phoneNumber: e,
                          });
                        }}
                        value={newUser.phoneNumber}
                      /> */}
                    </div>
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders">Password: </label>
                      <input
                        type="password"
                        className="modalInput"
                        value={newUser.password}
                        placeholder="Password"
                        style={{
                          width: "100%",
                        }}
                        onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                      />
                    </div>
                    <div className="form-group-wrapper ">
                      <label className="modalLineHeaders">DOB: </label>
                      <SingleDatePicker
                        onApply={(event, picker) => {
                          setNewUser({ ...newUser, dob: moment(picker.startDate).format("YYYY-MM-DD") });
                        }}
                        value={newUser.dob}
                        currentDate={true}
                      />
                    </div>
                    <div className="form-group-wrapper">
                      <label className="mb-1">Address:</label>
                      <AutoCompleteAddress value={newUser.street} handleChange={handleUpdateAddress} />
                    </div>
                    <div className="form-group-wrapper">
                      <label className="mb-1">City:</label>
                      <input
                        type="text"
                        className="modalInput"
                        maxLength={75}
                        value={newUser.city}
                        placeholder="City"
                        onChange={(e) => setNewUser({ ...newUser, city: e.target.value })}
                      />
                    </div>
                    <div className="form-group-wrapper">
                      <label className="mb-1">State:</label>
                      <input
                        type="text"
                        className="modalInput stateField"
                        placeholder="State Abbr: XX"
                        value={newUser.state}
                        maxLength={35}
                        onChange={(e) => {
                          let val = e.target.value;
                          if (val) val = val.toUpperCase();
                          setNewUser({ ...newUser, state: getValidSpaces(val) });
                        }}
                      />
                    </div>
                    <div className="form-group-wrapper ">
                      <label className="mb-1">Country:</label>
                      <Select
                        options={countriesOption}
                        blurInputOnSelect={true}
                        value={{ label: newUser.country, value: newUser.country }}
                        menuPlacement="auto"
                        placeholder="Select Country"
                        className="w-100"
                        onChange={(e) => {
                          const country = countryListAllIsoData.find((f) => f.name === e.value) || { code: "US" };
                          setNewUser({
                            ...newUser,
                            country: e.value,
                            isoCode: country.code,
                          });
                        }}
                      />
                    </div>
                    <div className="form-group-wrapper">
                      <label className="mb-1">Zip Code:</label>
                      <input
                        value={newUser.isoCode === "US" ? formatZip(newUser.zip) : newUser.zip}
                        type="text"
                        className="modalInput"
                        placeholder="Enter Zip Code"
                        maxLength={10}
                        onChange={(e) => {
                          let val = e.target.value;
                          if (val) val = val.toUpperCase();
                          setNewUser({
                            ...newUser,
                            zip: newUser.isoCode === "US" ? formatZip(val) : getValidSpaces(val),
                          });
                        }}
                      />
                    </div>
                    <div className="form-group-wrapper ">
                      <label className="mb-1">DL/Passport Number:</label>
                      <input
                        maxLength={16}
                        type="text"
                        className="modalInput"
                        value={newUser.idNumber}
                        placeholder="DL/Passport Number"
                        onChange={(e) => setNewUser({ ...newUser, idNumber: checkValidity(e.target.value) })}
                      />
                    </div>
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders">Gender: </label>
                      <div>
                        <span className="radioBox-wrapper">
                          <input
                            type="radio"
                            className="radioButton"
                            checked={newUser.sex === "M"}
                            name="sex"
                            onChange={(e) => setNewUser({ ...newUser, sex: e.target.checked ? "M" : "" })}
                          />
                          <span className="radioButton">Male</span>
                        </span>
                        <span className="radioBox-wrapper">
                          <input
                            type="radio"
                            className="radioButton"
                            name="sex"
                            checked={newUser.sex === "F"}
                            onChange={(e) => setNewUser({ ...newUser, sex: e.target.checked ? "F" : "" })}
                          />
                          <span className="radioButton">Female</span>
                        </span>
                        <span className="radioBox-wrapper">
                          <input
                            type="radio"
                            className="radioButton"
                            name="sex"
                            checked={newUser.sex === "X"}
                            onChange={(e) => setNewUser({ ...newUser, sex: e.target.checked ? "X" : "" })}
                          />
                          <span className="radioButton">Not Specified</span>
                        </span>
                      </div>
                    </div>

                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders">Vaccinated: </label>
                      <div>
                        <span className="radioBox-wrapper">
                          <input
                            type="radio"
                            className="radioButton"
                            name="isVaccinated"
                            checked={newUser.isVaccinated}
                            onChange={(e) => setNewUser({ ...newUser, isVaccinated: e.target.checked && true })}
                          />
                          <span className="radioButton">Yes</span>
                        </span>
                        <span className="radioBox-wrapper">
                          <input
                            type="radio"
                            className="radioButton"
                            checked={newUser.isVaccinated === false}
                            name="isVaccinated"
                            onChange={(e) => setNewUser({ ...newUser, isVaccinated: e.target.checked && false })}
                          />
                          <span className="radioButton">No</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  {error && (
                    <div className="error-message">
                      {error.length > 0 && <strong style={{ color: "Red" }}>{error}</strong>}
                    </div>
                  )}
                  <div className="mt-2">
                    <button type="submit" className="text-uppercase w-100 btn signup-btn" onClick={handleSubmit}>
                      Pre-register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="registerFormWrapper">
              <div className="preLoginWrapper">
                <div className="text-center">
                  <img src={logoImage} alt="logo" className="logo-width" />
                </div>
                <h4 className="w-100 text-center mt-4">
                  <b>{company.companyName}</b>
                </h4>
                <div className="codeScannerPhoneInput codeScannerWrapper">
                  <div className="registered-successful">
                    <div className="form-group-wrapper my-4">
                      <label className="mb-1">Phone Number:</label>
                      <PhoneNoInput
                        error={setPhoneError}
                        handleChange={(e) =>
                          setRegUser({
                            ...regUser,
                            phoneNumber: e,
                          })
                        }
                        value={regUser.phoneNumber}
                      />
                    </div>
                    <div style={{ height: "3vh" }}>
                      {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
                    </div>
                    <div className="py-2 w-50 mx-auto">
                      <Button type="submit" className="headerButton btn-fill" onClick={onHandleSave}>
                        NEXT
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {messageError && <ShowAlertMessage message={messageError} handleClose={() => setMessageError("")} error />}
        </>
      )}
    </>
  );
};

export default PreRegister;
