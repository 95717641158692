import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "context/app-context";
import { Card, Table, Row, Col, Button, Form } from "react-bootstrap";
import { Bounce } from "react-activity";
import API from "api";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { calculateTdWidth, checkValidity } from "utils";
import RegionModal from "components/Modal/RegionModal";
import editIcon from "../assets/img/edit-icon.png";
import trashIcon from "../assets/img/trash-icon.png";
import HeaderItem from "components/Table/HeaderItem";
import FilterPopOver from "components/FilterPopOver";
import { CONFIRMATION_TYPE } from "constant";
import MFPagination from "components/Pagination/MFPagination";
import { Link } from "react-router-dom";
import { formatCreatedDate } from "utils";
import ExportToExcel from "components/ExportToExcel";
import { downloadDataAsCSV } from "utils";
import { getValidReg } from "utils";
import DepartmentImportModal from "components/Modal/DepartmentImportModal";
import ImportErrorModal from "components/Modal/ImportErrorModal";
import Icon from "components/Icon";
import { formatNumber } from "utils";

const Regions = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [regions, setRegions] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [filter, setFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filterRegions, setFilterRegions] = useState([]);
  const [triggerFilter, setTriggerFilter] = useState();
  const [allSelected, setAllSelected] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [openImportRegion, setOpenImportRegion] = useState(false);
  const [openErrModal, setOpenErrModal] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [successData, setsuccessData] = useState([]);
  const [title, setTitle] = useState("");

  const searchTerms = ["Name"];

  const selectedItem = [
    { itemKey: "createdAt" },
    { itemKey: "name" },
    { itemKey: "totalEmployees" },
    { itemKey: "createdByName" },
  ];

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(width - 150, 1);

  const sortRegions = (regions, sortParam) => {
    if (sortDescending) {
      return [...regions].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...regions].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  useEffect(() => {
    if (regions.length > 0) setFilterRegions(sortRegions(filterRegions, sortBy));
  }, [sortBy, sortDescending]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  useEffect(() => {
    getRegions();
  }, [appContext.regions, filter]);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const getRegions = () => {
    const regionsOldData = appContext.regions;
    const employeeRegions = appContext.employees
      .filter((f) => f.region)
      .reduce((obj, employee) => {
        let regionId = employee.region;
        obj[regionId] = (obj[regionId] || 0) + 1;
        return obj;
      }, {});
    const regionsNewData = regionsOldData.map((region) => {
      let ttlEmployees = employeeRegions[region.name] || 0;
      return {
        ...region,
        totalEmployees: ttlEmployees,
      };
    });

    setRegions(regionsNewData);
    setFilterRegions(nestedFilter(regionsNewData, filter));
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? regions.map((t) => t.id) : []);
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    if (filterRegions.length === filteredList.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
    setCheckboxes(filteredList);
  };

  const getDeleteMessage = () => {
    const regionsToDelete = regions.filter((d) => checkboxes.indexOf(d.id) !== -1 && d.totalEmployees === 0);
    const ids = regionsToDelete.map((d) => d.id);

    let regionName = null;
    let totalRegions = null;

    if (ids.length === 1) {
      const regionsToRemove = regions.find((d) => d.id === ids[0]);
      if (regionsToRemove) {
        regionName = regionsToRemove.name;
      }
    } else {
      totalRegions = ids.length;
    }
    return [ids, regionName, totalRegions];
  };

  const onHandleConfirmation = async (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      setItemToDelete(null);
      setCheckboxes([]);
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.DELETE:
        handleConfirmDelete();
        break;
      case CONFIRMATION_TYPE.MULTI_DELETE:
        deleteRegions();
    }
  };

  const openDeleteConfirmation = (region) => {
    if (region.totalEmployees > 0) {
      appContext.showErrorMessage("Employees are assigned to the specified region");
    } else {
      setOpenConfirmation({
        isShow: true,
        actionType: CONFIRMATION_TYPE.DELETE,
        title: "Delete Region",
        message: `Are you sure you want to delete the ${region.name} region?`,
      });
      if (region) setItemToDelete(region);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      await API.deleteRegion(itemToDelete.id);
      setItemToDelete(null);
      appContext.resetRegions();
      appContext.showSuccessMessage("Region deleted successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleBulkDeleteClick = () => {
    let [ids, regionName, totalRegions] = getDeleteMessage();
    if (ids.length === 0) {
      appContext.showErrorMessage("Select at-least one Region with no crew members assigned");
      return;
    }
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.MULTI_DELETE,
      title: "Delete Region",
      message: regionName
        ? `Are you sure you want to delete the ${regionName} region?`
        : `Are you sure you want to delete the ${formatNumber(totalRegions)} regions selected?`,
    });
  };

  const deleteRegions = async () => {
    try {
      let [ids, regionName, totalRegions] = getDeleteMessage();
      if (ids.length === 0) {
        appContext.showErrorMessage("No Regions to Delete");
        return;
      }
      setLoading(true);
      for (let i = 0; i < ids.length; i++) {
        await API.deleteRegion(ids[i]);
      }
      if (regionName) {
        appContext.showSuccessMessage(`${regionName} Region has been deleted successfully`);
      } else {
        appContext.showSuccessMessage(`${formatNumber(totalRegions)} Regions have been deleted successfully`);
      }
      appContext.resetRegions();
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage("Fail to Delete the Region");
    }
    setLoading(false);
    setCheckboxes([]);
  };

  const OnHandleRegion = async (reg) => {
    setOpenCreateModal(false);
    try {
      setLoading(true);
      if (reg.isNew) {
        (await API.newRegions(reg.name, appContext.user))
          ? appContext.showSuccessMessage(`You have successfully created the ${reg.name} region`)
          : appContext.showErrorMessage("Region already exist");
      } else {
        await API.updateRegion(reg);
        appContext.showSuccessMessage("Region Updated Successfully");
      }
      appContext.resetRegions();
      setSelectItem(null);
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
    }
  };

  const handleEdit = (item) => {
    setSelectItem(item);
    setOpenCreateModal(true);
  };

  const handleDownloadRegion = async () => {
    await downloadDataAsCSV(
      filterRegions.map((region) => {
        return {
          createdAt: formatCreatedDate(region.createdAt),
          name: region.name,
          totalEmployees: region.totalEmployees,
          createdByName: region.createdByName,
        };
      }),
      "region"
    );
  };

  const handleImportRegion = async (regionData) => {
    if (regionData.length === 0) return;
    const arrErrorData = [];
    const arrSuccessData = [];
    if (Object.keys(regionData[0].data)[0] !== "name") {
      appContext.showErrorMessage("First column Header must be name");
      setOpenImportRegion(false);
      return;
    }
    setLoading(true);
    const ttlLength = regionData.length;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = regionData[index];
      const region = { ...data };

      if (!region.name) continue;
      region.name = getValidReg(region.name);

      const findVal = regions.find((f) => checkValidity(f.name) == checkValidity(region.name));
      if (findVal) {
        arrErrorData.push({ message: `${region.name} Already Exist` });
        continue;
      } else {
        try {
          (await API.newRegions(region.name, appContext.user)) && arrSuccessData.push(region.name);
        } catch (error) {
          console.log("Import Error: ", error);
          appContext.showErrorMessage(error);
        }
      }
    }
    setOpenImportRegion(false);
    appContext.resetRegions();
    getRegions();
    setLoading(false);

    if (arrErrorData.length > 0 || arrSuccessData.length > 0) {
      setErrorData(arrErrorData);
      setTitle("Region");
      setsuccessData(arrSuccessData);
      setOpenErrModal(true);
    }
  };

  const TableRow = ({ item }) => {
    return (
      <tr key={item.id}>
        <td>
          {" "}
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={item.id}
              checked={checkboxes.indexOf(item.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, item.id)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td className="icon">
          <img
            src={editIcon}
            alt="edit icon"
            width="18"
            className="fas fa-edit"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={() => handleEdit(item)}
          />
        </td>
        <td style={{ textAlign: "left", textOverflow: "visible" }}>{formatCreatedDate(item.createdAt)}</td>
        <td style={{ textAlign: "left", textOverflow: "visible" }}>{item.name}</td>
        <td style={{ textAlign: "center", textOverflow: "visible" }} title={item.totalEmployees}>
          {item.totalEmployees > 0 ? (
            <Link
              style={{ color: "#42cef5" }}
              to={{
                pathname: "/admin/employees",
                state: { name: item.name, term: "region" },
              }}
            >
              {formatNumber(item.totalEmployees)}
            </Link>
          ) : (
            formatNumber(item.totalEmployees)
          )}
        </td>
        <td style={{ textAlign: "center", textOverflow: "visible" }}>{item.createdByName}</td>
        <td style={{ textAlign: "center", textOverflow: "visible" }}>
          <img
            src={trashIcon}
            alt="trash icon"
            width="15"
            style={{ marginLeft: "10px" }}
            className="fa fa-trash"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={(e) => openDeleteConfirmation(item)}
          />
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  Total Regions ({filterRegions ? formatNumber(filterRegions.length) : formatNumber(regions.length)})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    label="Filter"
                    title="Filter"
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={() => {
                      if (regions && regions.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />

                  <Icon
                    handleClick={() => handleBulkDeleteClick()}
                    label="Delete"
                    title="Delete"
                    disabled={checkboxes.length === 0}
                    iconType={"binIcon"}
                  />
                  <Icon
                    handleClick={() => setOpenCreateModal(true)}
                    title={"Create Region"}
                    label="Create"
                    iconType="createIcon"
                  />

                  {filterRegions.length > 0 && (
                    <ExportToExcel
                      items={filterRegions}
                      selectedItem={selectedItem}
                      appContext={appContext}
                      filter={filter}
                      title="Regions Data"
                      sortBy={sortBy}
                    />
                  )}

                  <Icon
                    handleClick={handleDownloadRegion}
                    title={"Download Regions"}
                    label="Download"
                    iconType="downloadIcon"
                  />

                  <Icon
                    handleClick={() => setOpenImportRegion(true)}
                    title={`Import Regions`}
                    label="Import"
                    iconType="importIcon"
                  />
                </div>

                {showFilter && (
                  <FilterPopOver
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination
                  totalSelected={checkboxes.length}
                  showAll
                  showSelectedRecord
                  selectedLabel="Selected Regions"
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    regions.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="table">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>
                        <th></th>
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="createdAt"
                          title="Date Created"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="name"
                          title="Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="totalEmployees"
                          title="No of Crew"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="createdByName"
                          title="Created By"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <th className={`border-0 centered`} title="Action">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterRegions.map((item, i) => {
                        return <TableRow key={i} item={item} />;
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Bounce
              style={{
                position: "fixed",
                left: "50%",
                right: "50%",
                top: "50%",
              }}
              size={35}
              color={"#A82632"}
            />
          )}

          {openConfirmation.isShow && (
            <ConfirmationModal
              show={openConfirmation.isShow}
              actionType={openConfirmation.actionType}
              title={openConfirmation.title}
              message={openConfirmation.message}
              handleConfirm={onHandleConfirmation}
              closeBtn
            />
          )}

          {openImportRegion && (
            <DepartmentImportModal handleClose={() => setOpenImportRegion(false)} handleImport={handleImportRegion} />
          )}

          {openErrModal && (
            <ImportErrorModal
              title={title}
              errData={errorData}
              successData={successData}
              handleClose={() => {
                setOpenErrModal(false);
                setErrorData([]);
                setsuccessData([]);
                setTitle("");
              }}
            />
          )}

          {openCreateModal && (
            <RegionModal
              handleClose={() => {
                setSelectItem(null), setOpenCreateModal(false);
              }}
              selectItem={selectItem}
              handleSave={OnHandleRegion}
              regions={regions}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Regions;
