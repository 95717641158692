import React, { useState, useEffect, useCallback, useRef } from "react";
import notesOnIcon from "../../src/assets/img/file-icon.png";
import notesOffIcon from "../../src/assets/img/file-icon-gray.png";

const NotesStatus = (props) => {
  const { emp, AddNotes, setOpenNotesModal } = props;
  let timer = 0;
  let delay = 250;
  let prevent = false;
  const handleClick = () => {
    setOpenNotesModal(true);
  };

  const handleDoubleClick = () => {
    AddNotes(null, null, emp);
  };

  const onSingleClickHandler = () => {
    timer = setTimeout(() => {
      if (!prevent) {
        setOpenNotesModal(true);
      }
    }, delay);
  };

  const onDoubleClickHandler = () => {
    clearTimeout(timer);
    prevent = true;
    emp.notes && AddNotes(null, null, emp);
    console.log("double Click");
    setTimeout(() => {
      prevent = false;
    }, delay);
  };
  const useSingleAndDoubleClick = (onClick, onDoubleClick) => {
    const clicks = useRef(0);

    const callFunction = useCallback(
      debounce(() => {
        clicks.current === 3 ? onDoubleClick() : onClick();
        clicks.current = 0;
      }, DELAY),
      []
    );

    const handleClick = () => {
      clicks.current++;
      callFunction();
    };

    const handleDoubleClick = () => {
      clicks.current++;
    };

    return { handleClick, handleDoubleClick };
  };

  // const onClick = useCallback(() => {
  //   console.log("onClick");
  //   setOpenNotesModal(true);
  // }, []);

  return (
    <div className="action-exclamation" onClick={onSingleClickHandler} onDoubleClick={onDoubleClickHandler}>
      <span
        className="cursor-pointer"
        title={emp.notes}
        style={{ borderColor: emp.notes && emp.notes.length > 0 ? "rgb(83 83 232)" : "rgb(186 184 184)" }}
      >
        {/* <i
          className="fa fa-scroll"
          style={{ color: emp.notes && emp.notes.length > 0 ? "rgb(83 83 232)" : "rgb(186 184 184)" }}
        /> */}
        {emp.notes && emp.notes.length > 0 ? (
          <img className="notesIcon" src={notesOnIcon} alt="notes-on-img" />
        ) : (
          <img className="notesIcon" src={notesOffIcon} alt="notes-off-img" />
        )}
      </span>
    </div>
  );
};

export default NotesStatus;
