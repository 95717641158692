import React, { useState, useContext, useMemo, useRef } from "react";
import { Button, Card, Table, Row, Col, Form, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import { useEffect } from "react";
import api from "api";
import { Auth } from "aws-amplify";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import { AppContext } from "../context/app-context";
import SweetAlert from "react-bootstrap-sweetalert";
import Filter from "../components/Filter";
import TimeFilter from "../components/TimeFilter";
import HeaderItem from "components/Table/HeaderItem";
import EmployeeModal from "components/Employee/EmployeeModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import AssignGroupModal from "components/Modal/AssignGroupModal";
import ExportToExcel from "components/ExportToExcel";
import EmployeeDailyLogView from "components/Modal/EmployeeDailyLogView";
import { EmptyUserObj, PAGE_LIMIT, GROUP_TYPES, TEST_TYPE_VALUE } from "../constant";
import TestNowModal from "components/Modal/TestNowModal";
import {
  formatDOB,
  setPhoneNo,
  parseBooleanValue,
  formatPhoneNumber,
  formatDateMDYTime,
  personalizationLocalStorage,
  tdEmail,
  tdPhone,
  tdCheckBox,
  tdProgramStatus,
  isValidPhone,
  isValidDob,
  isValidEmail,
  tdZoneColor,
  formatDateMDY,
  calculateTdWidth,
  sortingFilterInLC,
  sortList,
  changeSort,
  formatEmployeesData,
  formatTimeZone,
  formatDate,
  tdCallTime,
} from "utils";
import MFPagination from "components/Pagination/MFPagination";
import "../../src/tableStyling.css";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import { NEW_PERSONALIZE } from "constant";
import moment from "moment";
import ClearedProgramModal from "components/Modal/ClearedProgramModal";
import PopoverStick from "components/PopoverStick";
import editIcon from "../assets/img/edit-icon.png";
import EmployeeAuditTrail from "components/Modal/EmployeeAuditTrail";
import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import ExternalTestProgramModal from "components/Modal/ExternalTestProgramModal";
import Status from "components/Status/Status";
import ShowAlertMessage from "components/ShowAlertMessage";

const Programs = (props) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [assignProgram, setAssignProgram] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const searchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Job Title",
    "Department",
    "Program Name",
    "Screening Type",
    "Group",
    "Schedule",
    "Test_Type",
    "PSQ",
    "Test",
    "Check In",
    "Updated At",
  ];
  const [triggerFilter, setTriggerFilter] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [checkboxes, setCheckboxes] = useState([]);

  const [allSelected, setAllSelected] = useState(false);
  const [programData, setProgramData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [openTestNowModal, setOpenTestNowModal] = useState(false);
  const [showClearedProgram, setShowClearedProgram] = useState(false);
  const [showExternalTest, setShowExternalTest] = useState(false);
  const headerAlignKeys =
    "location,region,isVaccinated,schrID,gender,updatedAt,departmentName,tests,dob,jobTitle,empTZ,testingGroup,programName,zoneColor,scheduleName,qaDone,testDone,checkIn,isSchedule,callTime,localNo";

  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);
  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const getDepartments = async () => {
    const data = await api.getDepartments();
    setDepartments(data);
  };
  useEffect(() => {
    getDepartments();
    const sortLS = sortingFilterInLC.get();
    if (sortLS.programs && sortLS.programs.sortBy) {
      setSortBy(sortLS.programs.sortBy);
      setSortDescending(sortLS.programs.sortDescending);
    }
    setPersonalize(personalizationLocalStorage.get(appContext.user, 1));
  }, []);

  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(
    width - 180,
    personalize.length > 0 ? personalize.filter((p) => p.isCheck).length : 25
  );

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const filterUsers = (searchTerm, searchInput) => {
    return setFilteredUsers(users.filter((user) => user[searchTerm].includes(searchInput)));
  };
  const handleEditUser = (user) => {
    const obj = { ...user };
    setAssignProgram(obj);
    setOpenAssignModal(true);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }
        if (key === "updatedAt") {
          return (
            new Date(obj.updatedAt) > filters[key].startDate._d && new Date(obj.updatedAt) < filters[key].endDate._d
          );
        }
        if (key === "First Name") {
          return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Last Name") {
          return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "qaDone") {
          return obj.qaDone === filter[key];
        }
        if (key === "testDone") {
          return obj.testDone === filter[key];
        }
        if (key === "empTZ") {
          return formatTimeZone(obj[key]).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "checkIn") {
          return obj.checkIn === filter[key];
          // return new Date(obj.checkIn) > filters[key].startDate._d && new Date(obj.checkIn) < filters[key].endDate._d;
        }
        if ((key === "programName" || key === "zoneColor") && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };
  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredUsers.map((t) => t.id) : []);

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const currentUsers = (users) => {
    const indexOfLastUser = currentPage * PAGE_LIMIT;
    const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(filteredUsers);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(users);

  useEffect(() => {
    formatEmployees();
  }, [appContext.employees]);

  useEffect(() => {
    formatEmployees();
  }, [appContext.schedules, departments, appContext.programs, appContext.company]);

  useEffect(() => {
    formatEmployees();
    setLoading(false);
  }, [appContext.questions]);

  useEffect(() => {
    handleSaveFilter();
    if (filteredUsers.length === 0) return;
    changeSort(sortBy, sortDescending, filteredUsers, setFilteredUsers);
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(sortList(sortBy, sortDescending, nestedFilter(users, filter)));
      setCurrentPage(1);
    }
  }, [filter]);
  const getGroupTypeUsers = () => {
    const filterCheckData = users.filter((i) => checkboxes.includes(i.id));
    setProgramData(filterCheckData);
    return filterCheckData;
  };

  const handleProgram = async () => {
    const data = getGroupTypeUsers();
    if (Object.keys(data).length === 0) {
      setShowAlert(true);
      return;
    }
    try {
      setLoading(true);
      await api.endProgram(data);
      const activeProgramEmployees = data.filter((e) => !e.id.includes("testNow"));
      if (activeProgramEmployees.length > 0) {
        const data = activeProgramEmployees.map((e) => {
          return {
            id: e.id,
            slug: "EndProgram",
            companyID: e.companyID,
            userName: appContext.user.name,
            programName: e.programName,
            phone_number: e.phoneNumber,
          };
        });
        await api.addAuditLogs(data);
      }
      setCheckboxes([]);
      setAllSelected(false);
      setLoading(false);
      await appContext.resetEmployees();
      appContext.showSuccessMessage(
        `${checkboxes.length} ${checkboxes.length > 1 ? "employees" : "employee"} program ended succesfully`
      );
    } catch (err) {
      console.log("Error Program", err);
      setLoading(false);
    }
  };
  const handleTestNow = async (assignTest) => {
    setOpenTestNowModal(false);
    setLoading(true);
    try {
      await api.updateEmployeeTest([...new Set(checkboxes.map((ch) => ch.replace("%testNow", "")))], assignTest);
      appContext.showSuccessMessage("Assign Test Successfully");
      setCheckboxes([]);
      setAllSelected(false);
      appContext.resetEmployees();
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
  };

  const formatEmployees = () => {
    const emps = formatEmployeesData(
      appContext.employees.filter((e) => e.isSchedule === 1 || e.onBoardingTesting),
      appContext.schedules,
      departments,
      appContext.programs,
      appContext.company
    );
    const empData = [];
    emps.forEach((f) => {
      if (f.isSchedule === 1) {
        empData.push(f);
      }
      const testNow = [...(f.testOne || []), ...(f.testTwo || [])]
        .filter((f) => f.testNow)
        .map((m) => TEST_TYPE_VALUE[m.value]);
      if (testNow && testNow.length > 0) {
        const testTypes = [...testNow];
        let tests = "";
        if (testTypes.length === 1) tests = testTypes[0];
        if (testTypes.length > 1) tests = "Multi";
        empData.push({ ...f, testNow: true, tests, id: `${f.id}%testNow` });
      }
    });

    setUsers(empData);
    if (sortBy) {
      setFilteredUsers(sortList(sortBy, sortDescending, nestedFilter(empData, filter)));
    } else {
      setFilteredUsers(nestedFilter(empData, filter));
    }
  };

  if (filteredUsers.length > 0) {
    pageNumbers = getPageNumbers(filteredUsers);
  }

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };
  const handleAssignGroupSave = async (obj) => {
    try {
      setLoading(true);
      await api.updateEmployeeLogDate(obj, appContext.schedules, departments);
      appContext.showSuccessMessage("Employee program has been changed successfully");
      appContext.resetEmployees();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleAssignGroupClose = () => {
    setOpenAssignModal(false);
  };

  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.programs = { sortBy, sortDescending, filter };
    sortingFilterInLC.save(pervSortLS);
  };

  const exportUsers = useMemo(() => {
    return filteredUsers.map((u) => {
      return {
        schrID: u.schrID,
        firstName: u.firstName,
        lastName: u.lastName,
        email: u.email,
        phoneNumber: formatPhoneNumber(u.phoneNumber),
        department: u.department,
        programName: u.programName,
        testingGroup: u.testingGroup,
        scheduleName: u.scheduleName,
        qaDone: u.qaDone,
        testDone: u.testDone,
        CheckIn: u.CheckIn,
        isSchedule: u.isSchedule ? "✔" : "InActive",
      };
    });
  }, [filteredUsers]);

  const handleHistoryView = (user) => {
    setSelectedEmployee(user);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = personalizationLocalStorage.saveAs(data, 1);
    setOpenPersonalizationModal(false);
    personalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(appContext.user.phone_number, personalisationData);
  };

  const openClearedForWorkModal = () => {
    const filterCheckData = users.filter(
      (i) => [...new Set(checkboxes.map((ch) => ch.replace("%testNow", "")))].includes(i.id) && i.qaDone === "X"
    );
    if (filterCheckData.length === 0) {
      appContext.showErrorMessage("No Active Employee to Process");
      return;
    }
    setProgramData(filterCheckData);
    setShowClearedProgram(true);
  };
  const openExternalTest = (user, type) => {
    setProgramData(user);
    setSelectedType(type);
    setShowExternalTest(true);
  };
  const handleClearedProgram = async (isConfirm, reason) => {
    setShowClearedProgram(false);
    setProgramData([]);
    if (!isConfirm) return;

    try {
      setLoading(true);
      appContext.showSuccessMessage("Successfully cleared the employee");
      appContext.resetEmployees();
      setCheckboxes([]);
      setLoading(false);
    } catch (err) {
      console.log("Error", err.message);
      setLoading(false);
      appContext.showErrorMessage("Fail to cleared the employee program");
    }
  };

  const handleExternaltest = async (newUser, logObject) => {
    setShowExternalTest(false);
    setProgramData([]);
    setSelectedType("");
    if (!newUser) return;
    try {
      setLoading(true);
      await api.externalTestState(newUser);
      if (logObject) {
        await api.addlogs([logObject]);
      }
      appContext.showSuccessMessage("Successfully added external test");
      appContext.resetEmployees();
      setCheckboxes([]);
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const tdFormat = (item, user) => {
    if (item === "updatedAt") return formatDate(user.updatedAt);
    if (item === "dob") return formatDateMDY(user.dob);
    if (item === "isVaccinated") return parseBooleanValue(user.isVaccinated);
    if (item === "departmentName") return user.departmentName ? user.departmentName : "";
    if (item === "jobTitle") return user.jobTitle ? user.jobTitle : "";
    if (item === "testingGroup") return user.testingGroup ? user.testingGroup : "-";
    if (item === "localNo") return user.localNo ? user.localNo : "-";
    return user[item];
  };
  const popOverFn = (item, user, itemKey) => {
    return <PopoverStick item={item} user={user} itemKey={itemKey} appContext={appContext} />;
  };

  const renderTd = (item, user) => {
    if (item.itemKey === "email") return tdEmail(user.email);
    if (item.itemKey === "phoneNumber") return tdPhone(user.phoneNumber);
    if (item.itemKey === "qaDone") return tdCheckBox(item, user);
    if (item.itemKey === "testDone") return tdCheckBox(item, user, openExternalTest);
    if (item.itemKey === "checkIn") return tdCheckBox(item, user);
    if (item.itemKey === "onBoardingTesting") return tdCheckBox(item, user, openExternalTest);
    if (item.itemKey === "callTime") return tdCallTime(user.callTime);
    if (item.itemKey === "isSchedule")
      return (
        <td style={{ textAlign: "center", textOverflow: "visible" }} title={"Program Started"}>
          <div className="star-icon-wrapper">
            <Status type="circle" size="md" color="green" crossIcon />
          </div>
        </td>
      );
    if (item.itemKey === "zoneColor") return tdZoneColor(item, user);
    if (item.itemKey === "scheduleName") return popOverFn(item, user);
    if (item.itemKey === "tests") return popOverFn(item, user, item.itemKey);
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormat(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };
  const headerAlign = (item) => (headerAlignKeys.includes(item) ? "centered" : undefined);

  const isMainAdmin = () => appContext.user?.isAdmin() && !appContext.isReadOnly();

  const TableRow = ({ user }) => {
    return (
      <tr>
        <td className="ellipsis" style={{ textAlign: "left" }}>
          <div className="d-flex align-items-center w-100 column-gap-10">
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                checked={checkboxes.indexOf(user.id) !== -1}
                value={user.id}
                onChange={(e) => handleCheckboxChange(e, user.id)}
              />
            </Form.Check>
            <img
              src={editIcon}
              alt="edit icon"
              width="18"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              id={user.id}
              onClick={(e) => handleEditUser(user)}
            />
            {user.testNow && <i className="fa fa-star fa-2xs" />}
          </div>
        </td>
        {personalize.map((item) => item.isCheck && renderTd(item, user))}
        <td
          className="ellipsis"
          style={{ textAlign: "center", color: "#42cef5", textAlign: "center", cursor: "Pointer" }}
          onClick={() => handleHistoryView(user)}
        >
          View
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  Active Programs ({filteredUsers ? filteredUsers.length : users.length})
                </Card.Title>
                <div className="buttonHeader">
                  <Button
                    variant="primary"
                    className="headerButton btn-fill"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    Filter
                  </Button>
                  <Button
                    variant="secondary"
                    className="headerButton btn-fill"
                    onClick={() => {
                      if (filteredUsers && filteredUsers.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                  >
                    {!allSelected ? "Select All" : "Deselect All"}
                  </Button>
                  <Button
                    variant="secondary"
                    className="headerButton btn-fill"
                    onClick={(e) => handleProgram()}
                    disabled={checkboxes.length === 0}
                  >
                    End Program
                  </Button>
                  <Button
                    className="headerButton btn-fill"
                    variant="secondary"
                    disabled={checkboxes.length === 0}
                    onClick={() => setOpenTestNowModal(true)}
                  >
                    Test Now
                  </Button>
                  <ExportToExcel
                    items={filteredUsers}
                    selectedItem={personalize.length > 0 && personalize.filter((m) => m.isCheck && m.itemKey)}
                    appContext={appContext}
                    filter={filter}
                    title="Employee Data"
                    sortBy={sortBy}
                  />
                  {isMainAdmin() && (
                    <Button
                      className="headerButton btn-fill"
                      variant="secondary"
                      onClick={openClearedForWorkModal}
                      disabled={checkboxes.length === 0}
                    >
                      Clear for Work
                    </Button>
                  )}
                  <Button
                    className="headerButton btn-fill"
                    variant="secondary"
                    onClick={() => setOpenPersonalizationModal(true)}
                  >
                    Personalize
                  </Button>
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}

                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    filteredUsers.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="user-table progrmaTableWrapper">
                    <thead ref={componentRef}>
                      <tr>
                        <th />
                        {personalize.map(
                          (item, i) =>
                            item.isCheck && (
                              <HeaderItem
                                key={i}
                                width={tdWidth}
                                ItemKey={item.itemKey}
                                title={item.title}
                                flipSort={flipSort}
                                sortBy={sortBy}
                                sortDescending={sortDescending}
                                aligned={headerAlign(item.itemKey)}
                              />
                            )
                        )}
                        <th
                          className={`border-0 centered ellipsis`}
                          style={{
                            color: "#231f20",
                            fontWeight: "bold",
                          }}
                          title="History"
                        >
                          History
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersToMap &&
                        usersToMap.map((user, i) => {
                          return <TableRow key={i} user={user} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        // <h3
        //   style={{
        //     display: "block",
        //     marginLeft: "auto",
        //     marginRight: "auto",
        //     paddingTop: 50,
        //     paddingBottom: 50,
        //   }}
        // >
        //   Loading...
        // </h3>
        <Bounce
          style={{
            position: "fixed",
            left: "50%",
            right: "50%",
            top: "50%",
          }}
          size={35}
          color={"#A82632"}
        />
      )}
      {showAlert && (
        <ShowAlertMessage
          message={"Schedule Not Assigned"}
          handleClose={() => {
            setShowAlert(false);
          }}
          info
        />
      )}
      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
      {openAssignModal && (
        <AssignGroupModal
          handleClose={handleAssignGroupClose}
          handleSave={handleAssignGroupSave}
          data={assignProgram}
        />
      )}
      {openTestNowModal && (
        <TestNowModal
          handleClose={() => setOpenTestNowModal(false)}
          handleSave={handleTestNow}
          company={appContext.company}
        />
      )}
      {selectedEmployee && <EmployeeAuditTrail user={selectedEmployee} handleClose={() => setSelectedEmployee(null)} />}
      {showClearedProgram && (
        <ClearedProgramModal handleConfirm={handleClearedProgram} employees={programData} appContext={appContext} />
      )}
      {showExternalTest && (
        <ExternalTestProgramModal handleConfirm={handleExternaltest} user={programData} selectedType={selectedType} />
      )}
    </div>
  );
};

export default Programs;
