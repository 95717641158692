import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "context/app-context";
import { Button, Modal, Table, Row, Col, Form, DropdownButton, Dropdown, Overlay, Popover } from "react-bootstrap";
import Status from "components/Status/Status";

const QuestionSubmissionModal = (props) => {
  const { newQuestions, questions, employee, handleSave, handleClose, isAsnwers } = props;

  const QuestionsGroup = ({ listLength, item, index }) => {
    const isAsnwer = questions.findIndex((f) => f.id == item.id && f.answer === item.answer) !== -1;
    return (
      <div className="questions-wrapper rounded-3 p-3">
        <div className="row">
          <div className="col-lg-1">
            <div>
              <label className="fs-6 mb-0">Q#{index + 1}</label>
            </div>
          </div>
          <div className="col-lg-7">
            <p
              style={{ width: "100%", resize: "none" }}
              id={item.id}
              className=" border-0 mb-0"
              placeholder={`Question ${index + 1}`}
            >
              {item.name}
            </p>
          </div>
          <div className="col-lg-2">
            <div className="d-flex align-items-center  align-items-start justify-content-lg-center justify-content-end column-gap-10">
              <div className="px-2 lh-1">{item.answer}</div>
            </div>
          </div>
          <div className="star-icon-wrapper col-lg-2 d-flex align-items-start justify-content-lg-center mt-2 mt-lg-0 justify-content-end">
            {isAsnwer ? (
              <Status type="circle" size="md" color={"green"} tickIcon />
            ) : (
              <Status type="circle" size="md" color={"maroon"} crossIcon />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal show animation={true} centered size="xl" className="strpied-tabled-with-hover" keyboard={true}>
      <Modal.Header>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Health & Screening Question
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12 d-none d-lg-block">
          <div className="row" style={{ paddingInline: "30px" }}>
            <div className="col-lg-8">
              <h6 className="mt-0 fw-bold">Questions:</h6>
            </div>
            <div className="col-lg-2 text-center">
              <h6 className="mt-0 fw-bold">Answers:</h6>
            </div>
            <div className="col-lg-2 text-center">
              <h6 className="mt-0 fw-bold">Expected Answers:</h6>
            </div>
          </div>
        </div>
        <div className="">
          <div
            style={{
              maxHeight: "380px",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {newQuestions.map((item, index) => {
              return <QuestionsGroup listLength={newQuestions.length} key={item.id} index={index} item={item} />;
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!isAsnwers && (
          <Button variant={"secondary"} className="modalButtons headerButton btn-fill" onClick={() => handleClose()}>
            Change
          </Button>
        )}
        <Button className="modalButtons headerButton btn-fill ms-auto" onClick={() => handleSave(newQuestions)}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuestionSubmissionModal;
