import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import React, { useState, useMemo, useEffect } from "react";
import { Card, Table, Row, Col, Button, Modal, ModalBody } from "react-bootstrap";
import { removeSpaceIns } from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
import ColorDropDown from "components/Dropdown/ColorDropDown";
import api from "api";
import { getValidReg } from "utils";
import Select from "react-select";
import RadioButton from "components/RadioButton/RadioButton";

const AssignZoneModal = (props) => {
  const { data, handleSave, handleClose, program, isDepartmentNotExists, checkboxes } = props;
  const [selectedData, setSelectedData] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [newUser, setNewUser] = useState({ isDptAssigned: "N" });
  const [error, setError] = useState("");

  const empIds = useMemo(() => {
    return isDepartmentNotExists();
  }, []);

  const onHandleSave = () => {
    if (!selectedData) {
      setError(`Please Select Any ${program}`);
      return;
    }
    if (empIds.length > 0) {
      if (!newUser.department) {
        setError("Please Select Department");
        return;
      }
      if (!newUser.isDptAssigned) {
        setError(" Would you update department for assigned or unassigned?");
        return;
      }
    }
    handleSave(selectedData, newUser, empIds);
  };

  useEffect(() => {
    if (empIds.length > 0) getDepartments();
  }, [empIds]);

  const handleProgramChange = (obj) => {
    setSelectedData(obj);
  };

  const getDepartments = async () => {
    const data = await api.getDepartments();
    setDepartments(data);
  };

  const getEmployeeDepartment = () => {
    if (newUser.department) {
      const dept = departments.find((dpt) => newUser.department === dpt.id);
      if (dept) return { value: dept.id, label: dept.name };
      return null;
    }
    return null;
  };

  return (
    <Modal
      show
      animation={true}
      size="lg"
      onHide={() => {
        handleClose();
      }}
      style={{ paddingLeft: "0" }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {`${program}s`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <section className="createClientsWrapper">
          <div className="w-100">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Select {program}: </label>
              <ColorDropDown title={`Select ${program}`} data={data} onOptionChange={handleProgramChange} />
            </div>
            {empIds.length > 0 && (
              <>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Department:</label>
                  <Select
                    options={departments.map((t) => {
                      return { value: t.id, label: getValidReg(t.name) };
                    })}
                    blurInputOnSelect={true}
                    menuPlacement="auto"
                    placeholder="Select Department"
                    className="w-100"
                    value={getEmployeeDepartment()}
                    onChange={(val) => {
                      setNewUser({ ...newUser, department: val.value, departmentName: val.label });
                    }}
                  />
                </div>
                {newUser.department && checkboxes.length !== empIds.length && (
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders emp-gender-mb">
                      Update department for assigned or unassigned?
                    </label>
                    <div className="d-flex justify-content-between w-50 flex-wrap">
                      <RadioButton
                        name="department"
                        id="assigned"
                        label="Assigned"
                        checked={newUser?.isDptAssigned === "Y" ? true : false}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            isDptAssigned: e.target.checked ? "Y" : "",
                          })
                        }
                      />
                      <RadioButton
                        name="department"
                        id="unassigned"
                        checked={newUser?.isDptAssigned === "N" ? true : false}
                        label="Unassigned"
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            isDptAssigned: e.target.checked ? "N" : "",
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </section>
      </Modal.Body>
      <div className="px-4 pt-2" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button variant="primary" className="modalButtons headerButton btn-fill" onClick={handleClose}>
          Close
        </Button>
        <Button variant="secondary" className="modalButtons headerButton btn-fill" onClick={onHandleSave}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignZoneModal;
