import React, { useState, useContext, useRef } from "react";
import {
  Button,
  Card,
  Table,
  Row,
  Col,
  Form,
  DropdownButton,
  Dropdown,
  Overlay,
  Popover,
  Modal,
} from "react-bootstrap";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import api from "api";
import Message from "components/Message/Message";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import { AppContext } from "../context/app-context";
import SweetAlert from "react-bootstrap-sweetalert";
import Filter from "../components/Filter";
import HeaderItem from "components/Table/HeaderItem";
import ExportToExcel from "components/ExportToExcel";
import UserModal from "components/Users/UserModal";
import UserPasswordModal from "components/Users/UserPasswordModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import {
  formatDOB,
  setPhoneNo,
  formatPhoneNumber,
  parseBooleanValue,
  formatDateMDYTime,
  isValidPhone,
  validatePhone,
  getPhoneNumber,
  isValidRegion,
  isValidLocation,
  isValidDob,
  isValidEmail,
  getValidGender,
  isValidCallTime,
  capitalizeLetter,
  getVaccinated,
  getValidDep,
  getCallTime,
  formatDateMDY,
  personalizationLocalStorage,
  tdEmail,
  tdPhone,
  tdCheckBox,
  tdProgramStatus,
  tdZoneColor,
  isValidName,
  calculateTdWidth,
  toTitleCase,
  getValidReg,
  sortingFilterInLC,
  changeSort,
  sortList,
  getValidSpaces,
  checkValidity,
  formatEmployeesData,
  formatTimeZone,
  importPhoneNoFormat,
} from "utils";
import { EmptyUserObj, USER_ROLE_TYPE, PAGE_LIMIT, HEALTH_AND_SAFTEY } from "../constant";
import MFPagination from "components/Pagination/MFPagination";
import EditIcon from "../assets/img/edit-icon.png";
import TrashIcon from "../assets/img/trash-icon.png";
import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import CrewModal from "components/Modal/CrewModal";
import ShowAlertMessage from "components/ShowAlertMessage";
import FilterPopOver from "components/FilterPopOver";
import Icon from "components/Icon";

const Crew = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const [errorMessageText, setErrorMessageText] = useState("");
  const [employees, setEmployees] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [openAdditionalRoleModal, setOpenAdditionalRoleModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [newUser, setNewUser] = useState(EmptyUserObj);
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [messagePopup, setMessagePopup] = useState(false);
  const [count, setCount] = useState(0);
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isUnConfirmed, setUnConfirmed] = useState(false);
  const [liveRecord, setLiveRecord] = useState(false);
  const [nextPageToken, setNextPageToken] = useState(null);

  const searchTerms = ["First Name", "Last Name", "Email", "Phone", "Role", "Associated"];
  const selectedItem = [
    { itemKey: "firstName" },
    { itemKey: "lastName" },
    { itemKey: "email" },
    { itemKey: "phone" },
    { itemKey: "role" },
    { itemKey: "associated" },
    { itemKey: "status" },
  ];
  const tdWidth = calculateTdWidth(width - 90, 7);
  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);
  useEffect(() => {
    setTimeout(() => {
      setErrorMessageText("");
    }, 2000);
  }, [errorMessageText]);

  const formatUserObject = (user, role) => {
    const newUser = {};
    const email = user.Attributes.find((attr) => {
      return attr.Name === "email";
    })?.Value;
    newUser.email = email;
    const phone = user.Attributes.find((attr) => {
      return attr.Name === "phone_number";
    })?.Value;
    const firstName = user.Attributes.find((attr) => {
      return attr.Name === "custom:firstName";
    })?.Value;
    const lastName = user.Attributes.find((attr) => {
      return attr.Name === "custom:lastName";
    })?.Value;

    const note = user.Attributes.find((attr) => {
      return attr.Name === "custom:note";
    })?.Value;

    const clientID = user.Attributes.find((attr) => {
      return attr.Name === "custom:clientID";
    })?.Value;

    const customRole = user.Attributes.find((attr) => {
      return attr.Name === "custom:role";
    })?.Value;

    const adRole = user.Attributes.find((attr) => {
      return attr.Name === "custom:adRole";
    })?.Value;

    const connectedID = user.Attributes.find((attr) => {
      return attr.Name === "custom:adRole";
    })?.Value;

    newUser.customRole = customRole;
    newUser.name = firstName && lastName ? `${firstName} ${lastName}` : "-";
    newUser.phone_number = formatPhoneNumber(phone);
    newUser.phone = phone;
    newUser.username = user.Username;
    newUser.firstName = firstName ? `${firstName}` : "-";
    newUser.lastName = lastName ? `${lastName}` : "-";
    newUser.status = user.UserStatus;
    newUser.role = role === "Employee" ? "Crew" : role === "Employer" ? "Health & Safety" : role;
    newUser.associated = note || "N/A";
    newUser.clientID = clientID;
    newUser.connectedID = connectedID;
    newUser.adRole = adRole;
    const obj = Object.assign(
      {
        role: role === "Employee" ? "Crew" : role === "Employer" ? "Health & Safety" : role,
      },
      newUser
    );
    return obj;
  };
  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "First Name") {
          return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Last Name") {
          return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "phone_number") {
          return obj.phone_number && obj.phone_number.replace(/\D/g, "").includes(filters[key].toLowerCase());
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    // console.log("FROM NESTED FILTER", models);
    return models;
  };
  const createUser = async (user) => {
    try {
      setLoading(true);
      let userId = null;
      const phoneNo = setPhoneNo(user.phone);
      console.log(user.phone, phoneNo);
      if (user.isNew) {
        let note = "";

        if (user.show) note = user.show.label;
        else if (user.lab) note = user.lab.label;
        else if (user.client) note = user.client.label;
        else if (user.role === "Admin" || user.role === "Tester") note = "System";
        else note = "";

        const attributePayload = {
          email: user.email,
          "custom:role": `${user.role === HEALTH_AND_SAFTEY ? "Employer" : user.role}s`,
          "custom:autoConfirm": "true",
          "custom:firstName": `${user.firstName}`,
          "custom:lastName": `${user.lastName}`,
          "custom:note": note,
          "custom:clientID": user.client ? user.client.id : "",
        };

        if (user.lab && user.lab.appPackage) {
          Object.assign(attributePayload, {
            "custom:privilege": "whiteLabelUser",
            "custom:labID": user.lab.appPackage,
          });
        }

        const signUp = await Auth.signUp({
          username: phoneNo,
          password: user.password,
          attributes: attributePayload,
        });

        userId = signUp.userSub;
        appContext.showSuccessMessage("User created successfully");
      } else {
        userId = user.username;
        await api.updateCrewStatus(user);
        appContext.showSuccessMessage("User updated successfully");
      }
      if (!user.isNew && user.role !== newUser.role) {
        await api.changeUserRole(newUser.role, user.role, user.username);
      }
      let msg = `A ${user.role} account has been made for you on Med Flow. Use your phone number and the temporary password '${user.password}' to log in`;
      if (newUser.lab) {
        await api.removeUserToLab(newUser.lab, userId);
      }
      if (newUser.show) {
        await api.removeUserToSite(newUser.show, userId);
      }
      if (userId) {
        if (user.role === HEALTH_AND_SAFTEY) {
          msg = `Hello ${user.firstName}, Please see the below link to access your Health & Safety portal for ${user.show.name} \nUser Name: ${user.phone} \nPassword: ${user.password} \n https://medflow.gosafecamp.com/`;
          await api.addUserToSite(user.show, userId);
        }
        if (user.role === USER_ROLE_TYPE.Lab) {
          msg = `Hello ${user.firstName}, Please see the below link to access lab portal for ${user.lab.name} \nUser Name: ${user.phone} \nPassword: ${user.password} \n https://medflow.gosafecamp.com/`;
          await api.addUserToLab(user.lab, userId);
        }

        if (!user.isNew) {
          if (user.role === USER_ROLE_TYPE.Tester) {
            setUsers(users.filter((s) => s.username !== user.username));
            setFilteredUsers(filteredUsers.filter((s) => s.username !== user.username));
          } else {
            let findIndex = users.findIndex((u) => u.username === user.username);
            if (findIndex !== -1) {
              const tmp = [...users];
              tmp.splice(findIndex, 1, user);
              setUsers([...tmp]);
            }
            findIndex = filteredUsers.findIndex((u) => u.username === user.username);
            if (findIndex !== -1) {
              const tmp = [...filteredUsers];
              tmp.splice(findIndex, 1, user);
              setFilteredUsers([...tmp]);
            }
          }
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("ERROR:User-", error);
      setErrorMessageText(error.message);
      setOpenCreator(true);
    }
  };
  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(users);
  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
  };

  const handlePasswordClose = () => {
    setOpenPasswordModal(false);
  };
  const handleChangePage = (number) => {
    console.log(pageNumbers);
    if (number < pageNumbers) {
      setCurrentPage(number);
      return;
    }
    if (nextPageToken) {
      if (isUnConfirmed) {
        loadUnConfirmedUser();
      } else {
        loadUser();
      }
    }
    if (number === pageNumbers) {
      setCurrentPage(number);
    }
  };
  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.employees = { sortBy, sortDescending, filter, timeFilter };
    sortingFilterInLC.save(pervSortLS);
  };
  const currentUsers = (users) => {
    const indexOfLastUser = currentPage * PAGE_LIMIT;
    const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };
  let usersToMap = currentUsers(filteredUsers);
  console.log("filteredUser", filteredUsers);
  useEffect(() => {
    if (isUnConfirmed) {
      loadUnConfirmedUser(true);
    } else {
      loadUser(true);
    }
    setCurrentPage(1);
  }, [isUnConfirmed]);

  useEffect(() => {
    handleSaveFilter();
    if (filteredUsers.length === 0) return;
    changeSort(sortBy, sortDescending, filteredUsers, setFilteredUsers);
  }, [sortBy, sortDescending]);
  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(sortList(sortBy, sortDescending, nestedFilter(users, filter)));
      setCurrentPage(1);
    }
  }, [filter]);
  const loadUser = async (isNewCall = false) => {
    console.log("user");
    try {
      let models = users;
      setLoading(true);
      let employees = await api.fetchAllCrewMembers("Employees", isNewCall ? null : nextPageToken);
      const formattedUser = employees.Users.map((employee) => formatUserObject(employee, "Employee"));
      console.log("FormatedUSer", formattedUser);
      setLoading(false);
      models = isNewCall ? formattedUser : models.concat(formattedUser);
      setNextPageToken(employees.NextToken || null);
      setUsers(models);
      setFilteredUsers(models);
    } catch (error) {
      setLoading(false);
      appContext.showErrorMessage("fail to load the crew");
    }
  };

  const loadUnConfirmedUser = async (isNewCall = false) => {
    console.log("userIsUnconfirm");
    try {
      let models = users;
      setLoading(true);
      const employees = await api.fetchAllUsers('cognito:user_status ="UNCONFIRMED"', isNewCall ? null : nextPageToken);
      const formattedUser = employees.Users.map((employee) => formatUserObject(employee, "Employee"));
      console.log("FormatUnconfirmed", formattedUser);
      setLoading(false);
      models = isNewCall ? formattedUser : models.concat(formattedUser);
      setNextPageToken(employees.NextToken || null);
      setUsers(models);
      setFilteredUsers(models);
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
      appContext.showErrorMessage("fail to load the crew");
    }
  };
  if (filteredUsers.length > 0) {
    pageNumbers = getPageNumbers(filteredUsers);
  }

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };
  const handleEditUser = (user) => {
    const obj = { ...user, isNew: false };
    if (obj.role === HEALTH_AND_SAFTEY) {
      const site = getUserSiteName(user);
      if (site) {
        obj.show = { label: site.name, value: site.id, id: site.id, name: site.name };
      }
    } else if (obj.role === USER_ROLE_TYPE.Lab) {
      const lab = getUserLabName(user);
      if (lab) {
        Object.assign(obj, {
          lab: { label: lab.name, value: lab.id, id: lab.id, name: lab.name, appPackage: lab.whiteLabelPackage },
        });
      }
    } else if (obj.role === USER_ROLE_TYPE.Client) {
      const client = getUserClientName(user);
      if (client) {
        Object.assign(obj, {
          client: { label: client.name, value: client.id, id: client.id, name: client.name },
        });
      }
    }
    setNewUser(obj);
    if (obj.status === "CONFIRMED") {
      setOpenPasswordModal(true);
    } else {
      setOpenCreator(true);
    }
  };

  const handleConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    console.log("[DELETE USER]", userToDelete);
    if (!isConfirm) {
      setUserToDelete(null);
      return;
    }
    try {
      setLoading(true);
      await api.deleteUser(userToDelete.username);
      if (userToDelete.status === "CONFIRMED") {
        await api.deleteUserLinkFromEmployee(userToDelete.username);
      }
      const newUserList = users.filter((u) => u.username !== userToDelete.username);
      setUsers(newUserList);
      setFilteredUsers(nestedFilter(newUserList, filter));
      setUserToDelete(null);
      appContext.showSuccessMessage("User removed successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleConfirmUser = async (confirmUser) => {
    console.log("Confirm User", confirmUser);

    try {
      setLoading(true);
      await api.confirmUserSignUp(confirmUser.username);
      const newUserList = users.filter((u) => u.username !== confirmUser.username);
      setUsers(newUserList);
      setFilteredUsers(nestedFilter(newUserList, filter));
      appContext.showSuccessMessage("User status changed successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const liveSearch = async () => {
    if (!filter.phone_number && !filter.email) {
      setMessagePopup(true);
      return;
    }
    let searchFilter = `phone_number ^=\"+1${filter.phone_number}\"`;
    if (filter.email) {
      searchFilter = `email ^=\"${filter.email}\"`;
    }
    setLiveRecord(true);
    setLoading(true);
    const employees = await api.fetchAllUsers(searchFilter);
    const formattedUser = employees.Users.map((employee) => formatUserObject(employee, "Employee"));
    const crewUsers = formattedUser.filter((f) => f.customRole === "Employees");
    setLoading(false);
    setNextPageToken(employees.NextToken || null);
    setUsers(crewUsers);
    setFilteredUsers(nestedFilter(crewUsers, filter));
  };
  const getEmployees = async (phone) => {
    console.log("phone", phone);
    if (!phone) return;
    const employee = await api.fetchAllEmployees(phone);

    setEmployees(employee);
    setShowModal(true);
  };
  const handleClearFilter = () => {
    if (liveRecord) {
      if (isUnConfirmed) {
        loadUnConfirmedUser(true);
      } else {
        loadUser(true);
      }
      setLiveRecord(false);
    }
  };
  const handleResetPassword = (obj) => {
    console.log("Password Obj", obj);
  };

  const TableRow = ({ user }) => {
    return (
      <tr key={user.username}>
        <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}>
          <img
            src={EditIcon}
            alt="edit icon"
            width="18"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={user.id}
            onClick={(e) => handleEditUser(user)}
          />
        </td>
        <td className="ellipsis" style={{ textAlign: "left" }} title={user.firstName}>
          {user.firstName}
        </td>
        <td className="ellipsis" style={{ textAlign: "left" }} title={user.lastName}>
          {user.lastName}
        </td>
        <td
          className="table-column ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => user?.email && window.open(`mailto:${user.email}`)}
          title={user.email}
        >
          {user.email}
        </td>
        <td
          className="ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => user?.phone_number && window.open(`tel:+${user.phone_number.replace(/\D/g, "")}`)}
          title={user.phone_number}
        >
          {user.phone_number}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={user.role}>
          {user.role}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={user.associated}>
          {user.associated}
        </td>
        <td
          className="ellipsis"
          style={{ textAlign: "center", color: user.status === "CONFIRMED" ? "green" : "red" }}
          title={user.status}
        >
          {user.status}
        </td>
        <td
          className="icon"
          style={{ textAlign: "center", textOverflow: "visible", width: "45px", minWidth: "45px", maxWidth: "45px" }}
        >
          <img
            src={TrashIcon}
            alt="trash icon"
            width="15"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={user.id}
            onClick={(e) => {
              setOpenDeleteConfirmation(true);
              setUserToDelete(user);
            }}
          />
          {/* <i
            className="fas fa-file-import"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.color = "#8B0000";
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={(e) => getEmployees(user.phone_number.replace(/\D/g, ""))}
          /> */}
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }} ref={mainWidth}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Crews ({filteredUsers ? filteredUsers.length : users.length})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    label="Filter"
                    title="Filter"
                    iconType={"filter"}
                  />
                  <Button
                    variant="secondary"
                    className="headerButton btn-fill"
                    onClick={() => setUnConfirmed(!isUnConfirmed)}
                  >
                    <div className="d-flex">
                      <span style={{ marginRight: "10px" }}>Show Unconfirmed Users </span>
                      {isUnConfirmed && <ViewCheckBox id="1" bgColor="blue" checked={true} />}
                    </div>
                  </Button>
                  <Button variant="secondary" className="headerButton btn-fill" onClick={liveSearch}>
                    <div className="d-flex">
                      <span style={{ marginRight: "10px" }}>Live Search </span>
                    </div>
                  </Button>
                  {filteredUsers.length > 0 && (
                    <ExportToExcel
                      items={filteredUsers}
                      selectedItem={selectedItem}
                      appContext={appContext}
                      filter={filter}
                      timeFilter={timeFilter}
                      title="Crew User"
                      sortBy={sortBy}
                    />
                  )}
                </div>
                {showFilter && (
                  <FilterPopOver
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    isUsersTable={true}
                    handleClearFilter={handleClearFilter}
                  />
                )}
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                  nextPageToken={nextPageToken}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    filteredUsers.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="table">
                    <thead ref={componentRef}>
                      <tr>
                        <th />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="firstName"
                          title="First Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="lastName"
                          title="Last Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="email"
                          title="Email"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="phone_number"
                          title="Phone Number"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="role"
                          title="Role"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="associated"
                          title="Associated"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="status"
                          title="Status"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {usersToMap &&
                        usersToMap.map((user, i) => {
                          return <TableRow key={i} user={user} />;
                        })}
                      {/* {usersToMap.map((user, i) => <TableRow user={user} key={i}/>)} */}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        // <h3
        //   style={{
        //     display: "block",
        //     marginLeft: "auto",
        //     marginRight: "auto",
        //     paddingTop: 50,
        //     paddingBottom: 50,
        //   }}
        // >
        //   Loading...
        // </h3>
        <Bounce
          style={{
            position: "fixed",
            left: "50%",
            right: "50%",
            top: "50%",
          }}
          size={35}
          color={"#A82632"}
        />
      )}
      {openCreator && (
        <CrewModal
          show={openCreator}
          user={newUser}
          handleClose={handleClose}
          handleSave={createUser}
          resetCall={setOpenPasswordModal}
          handleConfirmUser={handleConfirmUser}
          message={errorMessageText}
        />
      )}
      {openAdditionalRoleModal && (
        <UserSecondRoleModal
          show={openAdditionalRoleModal}
          user={newUser}
          handleClose={() => {
            setOpenAdditionalRoleModal(false);
          }}
        />
      )}
      {openPasswordModal && (
        <UserPasswordModal user={newUser} handleClose={handlePasswordClose} handleSave={handleResetPassword} />
      )}
      {messagePopup && (
        // <Message
        //   showAlert={true}
        //   message={"Live Search Filter with only Email & Phone Number"}
        //   handleClose={(confirm) => setMessagePopup(confirm)}
        //   type="info"
        //   title="Information"
        // />
        <ShowAlertMessage
          message={"Live Search Filter with only Email & Phone Number"}
          handleClose={() => setMessagePopup(false)}
          info
        />
      )}
      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete User"
          message="Are you sure, you want to remove User?"
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default Crew;
