import React, { useState, useContext, useRef } from "react";
import { Card, Table, Row, Col, Button, Form } from "react-bootstrap";
import { useEffect } from "react";
import { AppContext } from "context/app-context";
import TestTypeModal from "views/TestTypeSetting/TestTypeModal";
import { Link } from "react-router-dom";
import DepartmentImportModal from "components/Modal/DepartmentImportModal";
import ImportErrorModal from "components/Modal/ImportErrorModal";
import DepartmentModal from "components/Modal/DepartmentModal";
import API from "api";
import { Bounce } from "react-activity";
import HeaderItem from "components/Table/HeaderItem";
import {
  isValidCallTime,
  getCallTime,
  calculateTdWidth,
  getValidReg,
  checkValidity,
  formatTimeZone,
  downloadDataAsCSV,
  formatCallTime,
  convertNameIntoParts,
  formatCreatedDate,
} from "utils";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import InconsistentCallTimeModal from "components/Department/InconsistentCallTimeModal";
import editIcon from "../assets/img/edit-icon.png";
import trashIcon from "../assets/img/trash-icon.png";
import { TIMEZONE_VALUE, CONFIRMATION_TYPE, NOTIFICATION_FROM } from "constant";
import GeneralDeptTime from "components/Modal/GeneralDeptTime";
import DepartmentOptionsModal from "components/Modal/DepartmentOptionsModal";
import { isValidReminder, formatCallTimeReminder, tdCallTime } from "utils";
import NotificationInputModal from "components/Modal/NotificationInputModal";
import MFPagination from "components/Pagination/MFPagination";
import FilterPopOver from "components/FilterPopOver";
import ExportToExcel from "components/ExportToExcel";
import InconsistentDepartmentCallTimeModal from "components/Department/InconsistentDepartmentCallTimeModal";
import Icon from "components/Icon";
import { UPDATE_CALL_TIME_CHOICE } from "constant";
import moment from "moment";
import { getDaysArray } from "utils";
import { formatNumber } from "utils";

const Departments = (props) => {
  const { setDepartment } = props;
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [inConsistentCallTimes, setInConsistentCallTimes] = useState({ employees: [] });
  const [openEmpInConsistentModal, setOpenEmpInConsistentModal] = useState(false);
  const [inConsistentDeptCallTimes, setInConsistentDeptCallTimes] = useState({ departments: [] });
  const [showGeneralTime, setShowGeneralTime] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [sendType, setSendType] = useState("");

  const searchTerms = ["Name"];

  const selectedItem = [
    { itemKey: "createdAt" },
    { itemKey: "name" },
    { itemKey: "contact_name" },
    { itemKey: "callTime" },
    // { itemKey: 'deptTimeZone' },
    { itemKey: "reminderOne" },
    { itemKey: "reminderTwo" },
    { itemKey: "totalEmployees" },
    { itemKey: "createdByName" },
  ];

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(width - 50, 4);

  const [loading, setLoading] = useState();
  const [openImportDep, setOpenImportDep] = useState(false);
  const [openErrModal, setOpenErrModal] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [successData, setsuccessData] = useState([]);
  const [title, setTitle] = useState("");
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
  const [client, setClient] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [filterDepartments, setFilterDepartments] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const appContext = useContext(AppContext);
  const [allSelected, setAllSelected] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    getDepartments();
    getClient();
  }, []);
  const getClient = async () => {
    const Client = await API.getCompanySetting();
    setClient(Client);
  };
  const sortDepartments = (departments, sortParam) => {
    if (sortDescending) {
      return [...departments].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...departments].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  useEffect(() => {
    if (departments.length > 0) setFilterDepartments(sortDepartments(filterDepartments, sortBy));
  }, [sortBy, sortDescending]);

  useEffect(() => {
    setFilterDepartments(nestedFilter(departments, filter));
  }, [filter]);

  const getDepartments = async () => {
    const data = await API.getDepartments();
    const employeeDepartments = appContext.employees
      .filter((f) => f.department)
      .reduce((obj, employee) => {
        let dptId = employee.department;
        obj[dptId] = (obj[dptId] || 0) + 1;
        return obj;
      }, {});
    const dptData = data.map((dpt) => {
      let ttlEmployees = employeeDepartments[dpt.id] || 0;
      return {
        ...dpt,
        totalEmployees: ttlEmployees,
      };
    });
    setDepartments(dptData);
    setDepartment(dptData);
    setFilterDepartments(nestedFilter(dptData, filter));
    setLoading(false);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }
        if (key === "days") {
          return obj.days && filters[key].every((e) => filterDays(obj.days).includes(e));
        }
        if (key === "startDate") {
          return (
            new Date(obj.startDate) > filters[key].startDate._d && new Date(obj.startDate) < filters[key].endDate._d
          );
        }
        if (key === "endDate") {
          return new Date(obj.endDate) > filters[key].startDate._d && new Date(obj.endDate) < filters[key].endDate._d;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const createDepartment = async (dpt) => {
    const dept = { ...dpt };
    if (!dept.callTime) {
      dept.callTime = client.defaultCallTime;
    }
    if (!dept.deptTimeZone) {
      dept.deptTimeZone = client.defaultTimeZone;
    }
    setOpenDepartmentModal(false);
    setLoading(true);
    if (dept.isNew) {
      (await API.newDepartment(dept, appContext.user))
        ? appContext.showSuccessMessage("Department created successfully")
        : appContext.showErrorMessage("Department Name Already Exist");
    } else {
      const res = await API.updateDepartment(dept);
      if (res.employees.length === 0) {
        appContext.showSuccessMessage("Department updated successfully");
      } else {
        setInConsistentCallTimes(res);
        setOpenEmpInConsistentModal(true);
      }
    }
    getDepartments();
    setSelectedDepartment(null);
    setLoading(false);
  };
  const updateGeneralTime = async (data) => {
    setLoading(true);
    setShowGeneralTime(false);
    try {
      await API.updateGeneralDptTime(data);
      getClient();
      await UpdateAllDptTime(data);
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
  };
  const handleImportDepartment = async (depData) => {
    if (depData.length === 0) return;
    const arr = [];
    const arrdata = [];
    let res = null;
    let inConsistentTimes = { employees: [] };
    const departmentList = [...departments];
    if (Object.keys(depData[0].data)[0] !== "name") {
      appContext.showErrorMessage("First column Header must be name");
      setOpenImportDep(false);
      return;
    }
    if (Object.keys(depData[0].data)[1] !== "callTime") {
      appContext.showErrorMessage("Second column Header must be callTime");
      setOpenImportDep(false);
      return;
    }
    setLoading(true);
    const ttlLength = depData.length;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = depData[index];
      const dep = { ...data };
      if (Object.values(data).every((c) => c.length == 0)) continue;
      if (!dep.callTime) {
        dep.callTime = client.defaultCallTime;
      }
      if (!dep.deptTimeZone) {
        dep.deptTimeZone = formatTimeZone(client.defaultTimeZone);
      }
      dep.callTime = getCallTime(dep.callTime);
      if (!isValidCallTime(dep.callTime)) {
        arr.push({ message: `${dep.name} department call Time is invalid on row ${index + 2}` });
        continue;
      }
      if (dep.deptTimeZone) {
        const tz = TIMEZONE_VALUE[dep.deptTimeZone.toLowerCase().trim()];
        dep.deptTimeZone = tz;
      }

      if (dep.name) {
        dep.name = getValidReg(dep.name);
        const findVal = departmentList.find((f) => checkValidity(f.name) == checkValidity(dep.name));
        if (findVal) {
          arr.push({ message: `${dep.name} Already Exist` });
          continue;
          // Object.assign(dep, { id: findVal.id });
          // res = await API.updateDepartment(dep, appContext.user.name, true);
          // if (res && res.employees.length > 0) {
          //   inConsistentTimes.employees.push(...res.employees);
          //   arrdata.push(dep.name);
          // } else if (res) {
          //   arrdata.push(dep.name);
          // }
        } else {
          departmentList.push({ name: dep.name });
          (await API.newDepartment(dep, appContext.user)) && arrdata.push(dep.name);
        }
      } else {
        arr.push({ message: `Fill Department name please on row ${index + 2}` });
      }
    }
    setOpenImportDep(false);
    getDepartments();
    setLoading(false);
    if (res && res.employees.length > 0) {
      setInConsistentCallTimes(inConsistentTimes);
      setOpenEmpInConsistentModal(true);
      return;
    }
    if (arr.length > 0 || arrdata.length > 0) {
      setErrorData(arr);
      setTitle("Department");
      setsuccessData(arrdata);
      setOpenErrModal(true);
    }
  };

  const handleEdit = (item) => {
    setSelectedDepartment({ ...item, isNew: false });
    setOpenDepartmentModal(true);
    setSendType("");
  };
  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const onDeleteConfirm = async () => {
    // const filterdata = appContext.employees?.filter((emp) => emp.department === itemToDelete.id);
    // if (filterdata.length > 0) {
    //   appContext.showErrorMessage("This Department is Assign to Employees");
    //   return;
    // }
    try {
      setLoading(true);
      const resError = await API.deleteDepartment(itemToDelete.id);
      if (resError) {
        appContext.showErrorMessage(resError);
        setLoading(false);
        return;
      }
      setItemToDelete(null);
      appContext.showSuccessMessage("Department deleted successfully");
      getDepartments();
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const UpdateAllDptTime = async (data) => {
    setLoading(true);

    const DepartmentIds = departments.map((d) => d.id);
    // const deptArr = ["Pacific Standard Time", "SQA Testing Department"];
    // const DepartmentIds = departments.filter((d) => deptArr.includes(d.name)).map((d) => d.id);

    let Emps = appContext.employees.filter((f) => DepartmentIds.includes(f.department));

    try {
      const response = await API.updateAllDeptTime(DepartmentIds, data, client.defaultCallTime);
      // if (response && response.departments.length === 0)
      if (response)
        if (DepartmentIds.length === 1) {
          appContext.showSuccessMessage(
            `You have successfully updated the call time of ${departments[0].name} department!`
          );
        } else {
          const updatedDeptIds = DepartmentIds.filter(
            (deptId) => response.departments.findIndex((d) => d.id === deptId) === -1
          );
          appContext.showSuccessMessage(
            `You have successfully updated the call time of ${formatNumber(updatedDeptIds.length)} departments!`
          );
        }
      if (response && response.departments.length > 0) {
        setInConsistentDeptCallTimes(response);
        Emps = Emps.filter(
          (f) => f.department && response.departments.findIndex((dpt) => dpt.id === f.department) === -1
        );
      }
      if (Emps.length > 0) {
        const res = await API.updateAllEmployeesCallTime(Emps, data, appContext.user.name, departments, sendType);
        if (res.employees.length > 0) {
          setInConsistentCallTimes(res);
          if (response.departments.length === 0) setOpenEmpInConsistentModal(true);
        }
      }
      await getDepartments();
      await appContext.resetEmployees();
      setLoading(false);
    } catch (err) {
      console.log({ err });
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
  };

  const onHandleConfirmation = async (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      setItemToDelete(null);
      switch (type) {
        case CONFIRMATION_TYPE.UPDATE_TIME:
          setShowOptions(true);
          // appContext.showSuccessMessage("Update General Time Successfully");
          break;
      }
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.DELETE:
        onDeleteConfirm();
        break;
      case CONFIRMATION_TYPE.UPDATE_TIME:
        UpdateAllDptTime();
        break;
      case CONFIRMATION_TYPE.MULTI_DELETE:
        deleteDepartment();
    }
  };

  const deleteDepartment = async () => {
    try {
      let [ids, departmentName, totalDepartments] = getDeleteMessage();
      if (ids.length === 0) {
        appContext.showErrorMessage("No Department to Delete");
        return;
      }
      setLoading(true);
      for (let i = 0; i < ids.length; i++) {
        await API.deleteDepartment(ids[i]);
      }
      if (departmentName) {
        appContext.showSuccessMessage(`${departmentName} Department has been deleted sucessfully`);
      } else {
        appContext.showSuccessMessage(`${formatNumber(totalDepartments)} Departments have been deleted sucessfully`);
      }
      getDepartments();
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage("Fail to Delete the Department");
    }
    setLoading(false);
    setCheckboxes([]);
  };

  const showHoursFormat = (value) => {
    if (!value) return "-";
    if (isValidReminder(value)) {
      return value;
    }
    return `${value} h`;
  };

  // const showHoursFormat = (value) => {
  //   if (!value) return "-";
  //   const val = parseInt(value);
  //   if (val === 1) return "1 hr";
  //   return `${val} hrs`;
  // };

  const handleDownloadDepartment = async () => {
    await downloadDataAsCSV(
      departments.map((d) => {
        return {
          name: d.name,
          callTime: formatCallTime(d.callTime),
          deptTimeZone: formatTimeZone(d.deptTimeZone),
          reminderOne: d.reminderOne,
          reminderTwo: d.reminderTwo,
        };
      }),
      "departments"
    );
  };

  const updateSelectedCallTime = async (selectedDpt) => {
    setShowOptions(false);
    if (selectedDpt.length === 0) return;
    setLoading(true);
    const Emps = appContext.employees.filter((f) => selectedDpt.includes(f.department));
    const dept = departments.filter((f) => selectedDpt.includes(f.id));
    try {
      await API.updateAllDeptTime(selectedDpt, client);
      if (Emps.length > 0) {
        const res = await API.updateAllEmployeesCallTime(Emps, client, appContext.user.name, dept);
        if (res.employees.length > 0) {
          setInConsistentCallTimes(res);
        } else {
          appContext.showSuccessMessage(
            `Update ${formatNumber(selectedDpt.length)} Departments Call Time with General Time`
          );
        }
      }
      await getDepartments();
      await appContext.resetEmployees();
      if (Emps.length === 0)
        appContext.showSuccessMessage(
          `Update ${formatNumber(selectedDpt.length)} Departments Call Time with General Time`
        );

      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
  };

  const openDeleteConfirmation = (user) => {
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.DELETE,
      title: "Delete Department",
      message: `Are you sure, you want to delete the ${user.name} department?`,
    });
    if (user) setItemToDelete(user);
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    if (filterDepartments.length === filteredList.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filterDepartments.map((t) => t.id) : []);
  };

  const getDeleteMessage = () => {
    const departmentToDelete = departments.filter((d) => d.totalEmployees === 0 && checkboxes.indexOf(d.id) !== -1);
    const ids = departmentToDelete.map((d) => d.id);

    let departmentName = null;
    let totalDepartments = null;

    if (ids.length === 1) {
      const departmentToRemove = departments.find((d) => d.id === ids[0]);
      if (departmentToRemove) {
        departmentName = departmentToRemove.name;
      }
    } else {
      totalDepartments = ids.length;
    }
    return [ids, departmentName, totalDepartments];
  };

  const handleBulkDeleteClick = () => {
    let [ids, departmentName, totalDepartments] = getDeleteMessage();
    if (ids.length === 0) {
      appContext.showErrorMessage("Only unassigned department can be deleted");
      return;
    }
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.MULTI_DELETE,
      title: "Delete Department",
      message: departmentName
        ? `Are you sure, you want to delete the ${departmentName} department?`
        : `Are you sure, you want to delete the ${formatNumber(totalDepartments)} departments selected?`,
    });
  };

  const TableRow = ({ item }) => {
    return (
      <tr key={item.id}>
        <td>
          {" "}
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={item.id}
              checked={checkboxes.indexOf(item.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, item.id)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td className="icon">
          <img
            src={editIcon}
            alt="edit icon"
            width="18"
            className="fas fa-edit"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={() => handleEdit(item)}
          />
        </td>
        <td className="ellipsis ">{formatCreatedDate(item.createdAt)}</td>
        <td className="ellipsis ">{item.name}</td>
        <td className="ellipsis">{item.contact_name || "-"}</td>
        {tdCallTime(item.callTime)}
        {/* <td className="ellipsis centered" style={{ textAlign: "center", textOverflow: "visible" }}>
          {formatTimeZone(item.deptTimeZone)}
        </td> */}
        <td className="ellipsis centered" style={{ textAlign: "center", textOverflow: "visible" }}>
          {formatCallTimeReminder(item.callTime, item.reminderOne, item.reminderOneDirection)}
        </td>
        <td className="ellipsis centered" style={{ textAlign: "center", textOverflow: "visible" }}>
          {formatCallTimeReminder(item.callTime, item.reminderTwo, item.reminderTwoDirection)}
        </td>
        <td style={{ textAlign: "center", textOverflow: "visible" }} title={item.totalEmployees}>
          {item.totalEmployees > 0 ? (
            <Link
              style={{ color: "#42cef5" }}
              to={{
                pathname: "/admin/employees",
                state: { name: item.id, term: "department" },
              }}
            >
              {formatNumber(item.totalEmployees)}
            </Link>
          ) : (
            formatNumber(item.totalEmployees)
          )}
        </td>
        <td className="ellipsis centered" style={{ textAlign: "center", textOverflow: "visible" }}>
          {item.createdByName || ""}
        </td>
        <td style={{ textAlign: "center", textOverflow: "visible" }}>
          <img
            src={trashIcon}
            alt="trash icon"
            width="15"
            style={{ marginLeft: "10px" }}
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={(e) => {
              openDeleteConfirmation(item);
            }}
          />
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title
              as="h4"
              style={{
                marginBottom: 10,
                fontWeight: "bold",
              }}
            >
              Total Departments (
              {filterDepartments ? formatNumber(filterDepartments.length) : formatNumber(departments.length)}) - General
              Call Time: ({formatCallTime(client?.defaultCallTime)})
            </Card.Title>
            <div className="buttonHeader">
              {/* <Button className="headerButton btn-fill" variant="primary" onClick={() => setShowFilter(!showFilter)}>
                Filter
              </Button> */}
              <Icon handleClick={() => setShowFilter(!showFilter)} label="Filter" title="Filter" iconType={"filter"} />

              <Icon
                handleClick={() => {
                  if (filterDepartments && filterDepartments.length > 0) {
                    setAllSelected(!allSelected);
                    toggleCheckboxes(!allSelected);
                  }
                }}
                label={!allSelected ? "Select All" : "Deselect All"}
                title={!allSelected ? "Select All" : "Deselect All"}
                iconType={"selectAllIcon"}
              />

              <Icon
                handleClick={() => handleBulkDeleteClick()}
                label="Delete"
                title="Delete"
                disabled={checkboxes.length === 0}
                iconType={"binIcon"}
              />

              <Icon
                handleClick={() => setOpenDepartmentModal(true)}
                title={"Create New Department"}
                label="Create"
                iconType="createIcon"
              />

              {filterDepartments.length > 0 && (
                <ExportToExcel
                  items={filterDepartments}
                  selectedItem={selectedItem}
                  appContext={appContext}
                  filter={filter}
                  title="Departments Data"
                  sortBy={sortBy}
                />
              )}

              <Icon
                handleClick={() => setOpenImportDep(true)}
                title={"Import Departments"}
                label="Import"
                iconType="importIcon"
              />

              <Icon
                handleClick={handleDownloadDepartment}
                title={"Download Departments"}
                label="Download"
                iconType={"downloadIcon"}
              />

              <Icon
                handleClick={() => setShowGeneralTime(true)}
                title={"General Call Time"}
                label="GCT"
                iconType="generalCallTimeIcon"
              />
              <Icon
                handleClick={() => setOpenNotificationModal(true)}
                disabled={checkboxes.length === 0}
                title={"Send Message"}
                label={"Send"}
                iconType="messageIcon"
              />
            </div>
            {showFilter && (
              <FilterPopOver
                filterTerms={searchTerms}
                setFilter={setFilter}
                filter={filter}
                triggerFilter={triggerFilter}
                setTriggerFilter={setTriggerFilter}
              />
            )}
            <MFPagination
              totalSelected={checkboxes.length}
              showAll
              showSelectedRecord
              selectedLabel="Selected Department Setup"
            />
          </Card.Header>

          <Card.Body className="table-full-width desktop-noScroll">
            <div
              className={
                departments.length > 0
                  ? "table-responsive pendingReleaseTable"
                  : "table-responsive pendingReleaseTable overFlow-y-hidden"
              }
            >
              <Table className="table">
                <thead ref={componentRef}>
                  <tr>
                    <th></th>
                    <th></th>

                    <HeaderItem
                      width={tdWidth}
                      ItemKey="createdAt"
                      title="Date Created"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                    />
                    <HeaderItem
                      width={tdWidth}
                      ItemKey="name"
                      title="Name"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                    />
                    <HeaderItem
                      width={tdWidth}
                      ItemKey="contact_name"
                      title="Dept Head"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                    />
                    <HeaderItem
                      width={tdWidth}
                      ItemKey="callTime"
                      title="Call Time"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned="centered"
                    />
                    {/* <HeaderItem
                      width={tdWidth}
                      ItemKey="deptTimeZone"
                      title="Time Zone"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned="centered"
                    /> */}
                    <HeaderItem
                      width={tdWidth}
                      ItemKey="reminderOne"
                      title="Reminder One"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned="centered"
                    />
                    <HeaderItem
                      width={tdWidth}
                      ItemKey="reminderTwo"
                      title="Reminder Two"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned="centered"
                    />
                    <HeaderItem
                      width={tdWidth}
                      ItemKey="totalEmployees"
                      title="No of Crew"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned="centered"
                    />
                    <HeaderItem
                      width={tdWidth}
                      ItemKey="createdByName"
                      title="Created By"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned="centered"
                    />
                    <th className={`border-0 centered`} title="Action">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filterDepartments.map((item, i) => {
                    return <TableRow key={i} item={item} />;
                  })}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <Bounce
          style={{
            position: "fixed",
            left: "50%",
            right: "50%",
            top: "50%",
          }}
          size={35}
          color={"#A82632"}
        />
      )}

      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={onHandleConfirmation}
          closeBtn
        />
      )}

      {openImportDep && (
        <DepartmentImportModal handleClose={() => setOpenImportDep(false)} handleImport={handleImportDepartment} />
      )}
      {openErrModal && (
        <ImportErrorModal
          title={title}
          errData={errorData}
          successData={successData}
          handleClose={() => {
            setOpenErrModal(false);
            setErrorData([]);
            setsuccessData([]);
            setTitle("");
          }}
        />
      )}
      {openDepartmentModal && (
        <DepartmentModal
          handleSubmit={createDepartment}
          show={openDepartmentModal}
          handleClose={() => {
            setOpenDepartmentModal(false);
            setSelectedDepartment(null);
          }}
          selectedDepartment={selectedDepartment}
          departments={departments}
        />
      )}
      {inConsistentCallTimes.employees.length > 0 && openEmpInConsistentModal && (
        <InconsistentCallTimeModal
          data={inConsistentCallTimes}
          handleClose={(action) => {
            if (action) {
              if (inConsistentCallTimes.employees.length === 1) {
                appContext.showSuccessMessage(
                  `You have successfully updated ${inConsistentCallTimes.employees[0].firstName} employee call times`
                );
              } else if (inConsistentCallTimes.employees.length > 0) {
                appContext.showSuccessMessage(
                  `You have successfully updated ${formatNumber(
                    inConsistentCallTimes.employees.length
                  )} employees call times`
                );
              }
            }
            getDepartments();
            appContext.resetEmployees();
            setInConsistentCallTimes({ employees: [] });
            setOpenEmpInConsistentModal(false);
          }}
          appContext={appContext}
          sendType={sendType}
        />
      )}
      {inConsistentDeptCallTimes.departments.length > 0 && (
        <InconsistentDepartmentCallTimeModal
          data={inConsistentDeptCallTimes}
          handleClose={(obj) => {
            // if (obj.employees.length === 0 && inConsistentCallTimes.employees?.length === 0) {
            getDepartments();
            if (obj.departments?.length === 1 && obj.selectedOption === "new") {
              appContext.showSuccessMessage(
                `You have successfully updated the call time of ${obj.departments[0].name} department!`
              );
            } else if (obj.departments?.length > 0 && obj.selectedOption === "new") {
              appContext.showSuccessMessage(
                `You have successfully updated the call time of ${formatNumber(obj.departments.length)} departments!`
              );
            }
            // } else {
            if (obj.employees.length > 0 && inConsistentCallTimes.employees?.length > 0) {
              setInConsistentCallTimes({ employees: [...inConsistentCallTimes.employees, ...obj.employees] });
              setOpenEmpInConsistentModal(true);
            }
            // }
            setInConsistentDeptCallTimes({ departments: [] });
          }}
          appContext={appContext}
          sendType={sendType}
        />
      )}
      {showOptions && (
        <DepartmentOptionsModal
          departments={departments}
          handleSave={updateSelectedCallTime}
          handleClose={() => setShowOptions(false)}
        />
      )}
      {openNotificationModal && (
        <NotificationInputModal
          data={departments
            .filter((i) => checkboxes.indexOf(i.id) !== -1)
            .map((e) => {
              const [firstName, lastName] = convertNameIntoParts(e.contact_name);
              return {
                phoneNumber: e.phone_number,
                email: e.contact_email,
                firstName: firstName || "",
                lastName: lastName || "",
                callTime: e.callTime,
                departmentName: e.name,
              };
            })}
          handleClose={() => (setOpenNotificationModal(false), setCheckboxes([]))}
          appContext={appContext}
          dataType={NOTIFICATION_FROM.department}
        />
      )}
      {showGeneralTime && (
        <GeneralDeptTime
          handleSave={updateGeneralTime}
          handleClose={() => setShowGeneralTime(false)}
          client={client}
          sendType={sendType}
          setSendType={setSendType}
        />
      )}
    </div>
  );
};

export default Departments;
