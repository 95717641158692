import React, { useState, useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { HEALTH_AND_SAFTEY, newRoleOptions } from "constant";
import { AppContext } from "context/app-context";
import { phoneFormatter, isValidPhone, toTitleCase, parseBooleanValue } from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
import PhoneNoInput from "components/Phone/PhoneNoInput";

const CrewModal = (props) => {
  const { show, user, handleSave, handleClose, resetCall, clients, handleConfirmUser } = props;
  const getUserRole = () =>{if (user.role === "Crew") return { value: "Employee", label: "Crew" }};
  const [newUser, setNewUser] = useState(user);
  const [error, setError] = useState("");
  const [selectedRole, setSelectedRole] = useState(getUserRole());

  useEffect(() => {
    setNewUser(user);
    setSelectedRole(getUserRole());
  }, [user]);

  const appContext = useContext(AppContext);
  const sites = appContext.sites;
  const labs = appContext.labs;

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption);
    console.log(`Option selected:`, selectedOption, newUser);
    setNewUser({ ...newUser, role: selectedOption.value });
  };

  const onHandleSave = () => {
    if(user.status === "UNCONFIRMED" && newUser.status === "CONFIRMED"){
    handleConfirmUser(newUser);
    }
    handleClose();
  };
  console.log(newUser);

  return (
    <>
      <Modal
        show={show}
        animation={true}
        onHide={() => {
          setNewUser("");
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newUser.isNew ? "Create User" : "Update User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
            {user.status === "UNCONFIRMED" &&(
            <div className="form-group-wrapper">
                <label className="modalLineHeaders">Status: </label>
                <div className="d-flex align-items-center justify-content-between w-75">
                  <span className="radioBox-wrapper">
                    <input
                      type="radio"
                      className="radioButton"
                      name="status"
                      checked={newUser.status === "CONFIRMED"}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          status: e.target.checked && "CONFIRMED",
                        })
                      }
                    />
                    <span className="radioButton">CONFIRMED</span>
                  </span>
                  <span className="radioBox-wrapper">
                    <input
                      type="radio"
                      className="radioButton"
                      name="status"
                      checked={newUser.status === "UNCONFIRMED"}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          status: e.target.checked && "UNCONFIRMED",
                        })
                      }
                    />
                    <span className="radioButton">UNCONFIRMED</span>
                  </span>
                </div>
              </div>
                )}
            <div className="form-group-wrapper">
              <label className="modalLineHeaders"> </label>
              <label className="modalLineHeaders linkedText" onClick={() => (handleClose(), resetCall(true))}>
                Reset Password
              </label>
            </div>
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
      {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button style={{ marginBottom: 10 }} variant="primary" className="modalButtons" onClick={onHandleSave}>
            {newUser.isNew ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CrewModal;
