import moment from "moment";
import React from "react";
import { formatCallTime } from "utils";

const CalendarSideBarHeader = (props) => {
  const { date, defaultCallTime, setSelectedData } = props;
  return (
    <div className="ps-3 py-2 d-flex justify-content-between" style={{ borderBottom: "1px solid #ddd" }}>
      <span className="fw-bold">{moment(date).format("DD MMM")}</span>
      {defaultCallTime && (
        <span title="General Call Time" className="fw-bold text-red">
          GCT: ({formatCallTime(defaultCallTime)})
        </span>
      )}
      <div className="b">
        <i
          className="fas fa-angle-left arrowKeys mx-1"
          onClick={() =>
            setSelectedData({
              date: moment(moment(date).subtract(1, "days")._d).format("YYYY-MM-DD"),
              navigator: true,
            })
          }
        />
        <i
          className="fas fa-angle-right arrowKeys mx-1"
          onClick={() =>
            setSelectedData({
              date: moment(moment(date).add(1, "days")._d).format("YYYY-MM-DD"),
              navigator: true,
            })
          }
        />
      </div>
    </div>
  );
};

export default CalendarSideBarHeader;
