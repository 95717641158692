import React, { useEffect, useState, useContext, useCallback } from "react";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import api from "api";
import { AppContext } from "context/app-context";
import TextAreaEditor from "components/TextInput/TextAreaEditor";
import debounce from "debounce";
import { formatAppsettings } from "utils";
import Loader from "components/Loader/Loader";

const messageObject = {
  isCallTimeVerbige: false,
  callTimeVerbiage: "",
  callTimeSubject: "",
  callTimeAttachements: null,
  isOnBoardVerbiage: false,
  onBoardVerbiage: "",
  onBoardSubject: "",
  onBoardingAttachements: "",
  isStartProgramVerbiage: false,
  startProgramVerbiage: "",
  startProgramSubject: "",
  startProgramAttachements: null,
  isDailyTextVerbiage: false,
  DailyTextVerbiage: "",
  dailyTextSubject: "",
  dailyTextAttachements: null,
  isDailyReminderTwoVerbiage: false,
  dailyReminderTwoVerbiage: "",
  dailyReminderSubject: "",
  dailyReminderAttachements: null,
  isTestingReminderOneVerbiage: false,
  testingReminderOneVerbiage: "",
  testingReminderSubject: "",
  testingReminderAttachements: null,
  isTestingReminderTwoVerbiage: false,
  testingReminderTwoVerbiage: "",
  testingReminderTwoSubject: "",
  testingReminderTwoAttachements: null,
  isTestNowVerbiage: false,
  testNowVerbiage: "",
  testNowSubject: "",
  isDriveOnAccessVerbiage: false,
  driveOnAccessVerbiage: "",
  driveOnAccessType: "",
  driveOnAccessSubject: "",
  driveOnAccessAttachements: null,
  generalCallTimeReminder: {
    isCallTimeVerbiage: false,
    callTimeVerbiage: "",
    callTimeType: "",
    callTimeSubject: "",
    callTimeAttachements: null,
  },
  callTimeReminder: {
    isCallTimeVerbiage: false,
    callTimeVerbiage: "",
    callTimeType: "",
    callTimeSubject: "",
    callTimeAttachements: null,
    callTimeDirection: null,
  },
};

const MessageSetting = () => {
  const appContext = useContext(AppContext);
  const [message, setMessage] = useState(messageObject);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const options = {
    onBoardVerbiage: {
      name: "onBoardVerbiage",
      label: "OnBoarding Verbiage",
      message: "This message gets sent when someone is added to SafeCamp HR",
    },
    startProgramVerbiage: {
      name: "startProgramVerbiage",
      label: appContext.isZoneLevelCompany() ? "Assign Zone Verbiage" : "Start Program Verbiage",
      message: "This message gets sent when someone is assigned to or changes zones",
    },
    callTimeVerbiage: {
      name: "callTimeVerbiage",
      label: "Department/Crew Specific Call Time Verbiage",
      message:
        "This message gets sent when you change a crew members call time and nightly to remind each crew member of their call time for the next day",
    },
    generalCallTimeReminder: {
      name: "generalCallTimeReminder",
      label: "General Crew Call Time  Verbiage",
      message:
        "This message gets sent when you change a crew members call time and nightly to remind each crew member of their call time for the next day",
    },
    callTimeReminder: {
      name: "callTimeReminder",
      label: "Call Time Reminder Verbiage",
      message:
        "This message gets sent when you change a crew members call time and nightly to remind each crew member of their call time for the next day",
    },
    DailyTextVerbiage: {
      name: "DailyTextVerbiage",
      label: "Question Reminder-I Verbiage",
      message: "This message gets set based on the department setting if pre-screening questions are scheduled",
    },
    dailyReminderTwoVerbiage: {
      name: "dailyReminderTwoVerbiage",
      label: "Question Reminder-II Verbiage",
      message: "This message gets set based on the department setting if pre-screening questions are scheduled",
    },
    testingReminderOneVerbiage: {
      name: "testingReminderOneVerbiage",
      label: "Testing Reminder-I Verbiage",
      message: "This message gets set based on the department setting if testing is scheduled",
    },
    testingReminderTwoVerbiage: {
      name: "testingReminderTwoVerbiage",
      label: "Testing Reminder-II Verbiage",
      message: "This message gets set based on the department setting if testing is scheduled",
    },
    driveOnAccessVerbiage: {
      name: "driveOnAccessVerbiage",
      label: "Drive on Access Veribiage",
    },
  };

  useEffect(() => {
    loadSetting();
  }, []);

  const loadSetting = async () => {
    const setting = await api.getCompanySetting();

    const msgSetting = formatAppsettings(setting);
    setMessage(msgSetting);
  };

  const handleSave = async (obj, flag) => {
    if (errorMsg.length > 0) {
      appContext.showErrorMessage(errorMsg);
    } else {
      try {
        await api.newAppSettings(obj);
        appContext.resetCompanyInfo();
        if (flag) {
          appContext.showSuccessMessage("Message saved successfully");
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const autoSaveFromApi = useCallback(
    debounce((message) => {
      handleSave(message);
    }, 1500),
    []
  );

  const checkAllFields = (isSelected, type, key) => {
    let newErrorMsg = "";
    if (isSelected) {
      if (!type) {
        newErrorMsg = `You were not successful saving your settings. Please add a Message Type to ${options[key].label} and try saving again`;
      } else {
        newErrorMsg = "";
      }
    }
    setErrorMsg(newErrorMsg);
  };

  const onHandleMessageChange = (isSelected, msg, key, type, subject, attachments) => {
    checkAllFields(isSelected, type, key);
    const obj = { ...message };

    switch (key) {
      case "onBoardVerbiage":
        Object.assign(obj, {
          isOnBoardVerbiage: isSelected,
          onBoardVerbiage: msg,
          onBoardType: type,
          onBoardSubject: subject,
          onBoardingAttachements: attachments,
        });
        break;
      case "startProgramVerbiage":
        Object.assign(obj, {
          isStartProgramVerbiage: isSelected,
          startProgramVerbiage: msg,
          startProgramType: type,
          startProgramSubject: subject,
          startProgramAttachements: attachments,
        });
        break;
      case "DailyTextVerbiage":
        Object.assign(obj, {
          isDailyTextVerbiage: isSelected,
          DailyTextVerbiage: msg,
          dailyTextType: type,
          dailyTextSubject: subject,
          dailyTextAttachements: attachments,
        });
        break;
      case "dailyReminderTwoVerbiage":
        Object.assign(obj, {
          isDailyReminderTwoVerbiage: isSelected,
          dailyReminderTwoVerbiage: msg,
          dailyReminderTwoType: type,
          dailyReminderSubject: subject,
          dailyReminderAttachements: attachments,
        });
        break;
      case "testingReminderOneVerbiage":
        Object.assign(obj, {
          isTestingReminderOneVerbiage: isSelected,
          testingReminderOneVerbiage: msg,
          testingReminderOneType: type,
          testingReminderSubject: subject,
          testingReminderAttachements: attachments,
        });
        break;
      case "testingReminderTwoVerbiage":
        Object.assign(obj, {
          isTestingReminderTwoVerbiage: isSelected,
          testingReminderTwoVerbiage: msg,
          testingReminderTwoType: type,
          testingReminderTwoSubject: subject,
          testingReminderTwoAttachements: attachments,
        });
        break;
      case "testNowVerbiage":
        Object.assign(obj, {
          isTestNowVerbiage: isSelected,
          testNowVerbiage: msg,
          testNowType: type,
          testNowSubject: subject,
        });
        break;
      case "callTimeVerbiage":
        Object.assign(obj, {
          isCallTimeVerbige: isSelected,
          callTimeVerbiage: msg,
          callTimeType: type,
          callTimeSubject: subject,
          callTimeAttachements: attachments,
        });
        break;
      case "generalCallTimeReminder":
        Object.assign(obj, {
          generalCallTimeReminder: {
            isCallTimeVerbiage: isSelected,
            callTimeVerbiage: msg,
            callTimeType: type,
            callTimeSubject: subject,
            callTimeAttachements: attachments,
          },
        });
        break;
      case "callTimeReminder":
        Object.assign(obj, {
          callTimeReminder: {
            ...obj.callTimeReminder,
            isCallTimeVerbiage: isSelected,
            callTimeVerbiage: msg,
            callTimeType: type,
            callTimeSubject: subject,
            callTimeAttachements: attachments,
          },
        });
        break;
      case "driveOnAccessVerbiage":
        Object.assign(obj, {
          isDriveOnAccessVerbiage: isSelected,
          driveOnAccessVerbiage: msg,
          driveOnAccessType: type,
          driveOnAccessSubject: subject,
          driveOnAccessAttachements: attachments,
        });
        break;
    }
    setMessage(obj);
    autoSaveFromApi(obj);
  };

  const handleCallTimeDirection = (callTime, schedule) => {
    const obj = { ...message };
    if (parseInt(callTime) % 15 !== 0) {
      return setErrorMsg("Call time direction is not selected!");
    } else {
      Object.assign(obj, {
        callTimeReminder: {
          ...obj.callTimeReminder,
          callTimeDirection:
            parseInt(callTime) % 15 === 0
              ? {
                  callTime: parseInt(callTime) % 15 === 0 ? callTime : null,
                  schedule: parseInt(callTime) % 15 === 0 && schedule ? schedule : null,
                }
              : null,
        },
      });
      setMessage(obj);
      autoSaveFromApi(obj);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <div className="buttonHeader">
                  <Button
                    className="headerButton btn-fill ms-auto mt-3 mx-5"
                    variant="secondary"
                    onClick={() => handleSave(message, true)}
                  >
                    Save
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                <TextAreaEditor
                  isSelected={message.isOnBoardVerbiage}
                  message={message.onBoardVerbiage}
                  subject={message.onBoardSubject}
                  attachments={message.onBoardingAttachements}
                  name={options.onBoardVerbiage.name}
                  label={options.onBoardVerbiage.label}
                  type={message.onBoardType}
                  handleChange={onHandleMessageChange}
                  title={options.onBoardVerbiage.message}
                  loading={loading}
                  setLoading={setLoading}
                  viewAattachment
                />
                <TextAreaEditor
                  isSelected={message.isStartProgramVerbiage}
                  message={message.startProgramVerbiage}
                  subject={message.startProgramSubject}
                  attachments={message.startProgramAttachements}
                  name={options.startProgramVerbiage.name}
                  label={options.startProgramVerbiage.label}
                  type={message.startProgramType}
                  handleChange={onHandleMessageChange}
                  title={options.startProgramVerbiage.message}
                  loading={loading}
                  setLoading={setLoading}
                  viewAattachment
                />
                {/* <TextAreaEditor
                isSelected={message.isTestNowVerbiage}
                message={message.testNowVerbiage}
                subject={message.testNowSubject}
                name="testNowVerbiage"
                label="Test Completed Verbiage"
                type={message.testNowType}
                handleChange={onHandleMessageChange}
              /> */}
                <TextAreaEditor
                  isSelected={message.isCallTimeVerbige}
                  message={message.callTimeVerbiage}
                  subject={message.callTimeSubject}
                  attachments={message.callTimeAttachements}
                  name={options.callTimeVerbiage.name}
                  label={options.callTimeVerbiage.label}
                  type={message.callTimeType}
                  handleChange={onHandleMessageChange}
                  title={options.callTimeVerbiage.message}
                  loading={loading}
                  setLoading={setLoading}
                  viewAattachment
                />
                <TextAreaEditor
                  isSelected={message.generalCallTimeReminder?.isCallTimeVerbiage}
                  message={message.generalCallTimeReminder?.callTimeVerbiage}
                  subject={message.generalCallTimeReminder?.callTimeSubject}
                  attachments={message.generalCallTimeReminder?.callTimeAttachements}
                  name={options.generalCallTimeReminder.name}
                  label={options.generalCallTimeReminder.label}
                  type={message.generalCallTimeReminder?.callTimeType}
                  handleChange={onHandleMessageChange}
                  title={options.generalCallTimeReminder.message}
                  loading={loading}
                  setLoading={setLoading}
                  viewAattachment
                />
                <TextAreaEditor
                  callTimeReminder={true}
                  callTimeDirection={message.callTimeReminder?.callTimeDirection}
                  handleCallTimeDirection={handleCallTimeDirection}
                  setError={setErrorMsg}
                  isSelected={message.callTimeReminder?.isCallTimeVerbiage}
                  message={message.callTimeReminder?.callTimeVerbiage}
                  subject={message.callTimeReminder?.callTimeSubject}
                  attachments={message.callTimeReminder?.callTimeAttachements}
                  name={options.callTimeReminder.name}
                  label={options.callTimeReminder.label}
                  type={message.callTimeReminder?.callTimeType}
                  handleChange={onHandleMessageChange}
                  title={options.callTimeReminder.message}
                  loading={loading}
                  setLoading={setLoading}
                  viewAattachment
                />
                <TextAreaEditor
                  isSelected={message.isDailyTextVerbiage}
                  message={message.DailyTextVerbiage}
                  subject={message.dailyTextSubject}
                  attachments={message.dailyTextAttachements}
                  name={options.DailyTextVerbiage.name}
                  label={options.DailyTextVerbiage.label}
                  type={message.dailyTextType}
                  handleChange={onHandleMessageChange}
                  title={options.DailyTextVerbiage.message}
                  loading={loading}
                  setLoading={setLoading}
                  viewAattachment
                />
                <TextAreaEditor
                  isSelected={message.isDailyReminderTwoVerbiage}
                  message={message.dailyReminderTwoVerbiage}
                  subject={message.dailyReminderSubject}
                  attachments={message.dailyReminderAttachements}
                  name={options.dailyReminderTwoVerbiage.name}
                  label={options.dailyReminderTwoVerbiage.label}
                  type={message.dailyReminderTwoType}
                  handleChange={onHandleMessageChange}
                  title={options.dailyReminderTwoVerbiage.message}
                  loading={loading}
                  setLoading={setLoading}
                  viewAattachment
                />
                <TextAreaEditor
                  isSelected={message.isTestingReminderOneVerbiage}
                  message={message.testingReminderOneVerbiage}
                  subject={message.testingReminderSubject}
                  attachments={message.testingReminderAttachements}
                  name={options.testingReminderOneVerbiage.name}
                  label={options.testingReminderOneVerbiage.label}
                  type={message.testingReminderOneType}
                  handleChange={onHandleMessageChange}
                  title={options.testingReminderOneVerbiage.message}
                  loading={loading}
                  setLoading={setLoading}
                  viewAattachment
                />
                <TextAreaEditor
                  isSelected={message.isTestingReminderTwoVerbiage}
                  message={message.testingReminderTwoVerbiage}
                  subject={message.testingReminderTwoSubject}
                  attachments={message.testingReminderTwoAttachements}
                  name={options.testingReminderTwoVerbiage.name}
                  label={options.testingReminderTwoVerbiage.label}
                  type={message.testingReminderTwoType}
                  handleChange={onHandleMessageChange}
                  title={options.testingReminderTwoVerbiage.message}
                  loading={loading}
                  setLoading={setLoading}
                  viewAattachment
                />
                <TextAreaEditor
                  isSelected={message.isDriveOnAccessVerbiage}
                  message={message.driveOnAccessVerbiage}
                  subject={message.driveOnAccessSubject}
                  attachments={message.driveOnAccessAttachements}
                  name={options.driveOnAccessVerbiage.name}
                  label={options.driveOnAccessVerbiage.label}
                  type={message.driveOnAccessType}
                  handleChange={onHandleMessageChange}
                  loading={loading}
                  setLoading={setLoading}
                  viewAattachment
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default MessageSetting;
