import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "debounce";
const GlobalSearch = (props) => {
  const { handleSave, handleFilterClick, globalSearchText, cssClass, eosModal } = props;
  const [search, setSearch] = useState(globalSearchText);

  useEffect(() => {
    setSearch(globalSearchText);
  }, [globalSearchText]);

  const debounceFire = useCallback(
    debounce((text, handleSave) => handleSave(text), 300),
    []
  );

  const handleChange = (e) => {
    setSearch(e.target.value);
    debounceFire(e.target.value, handleSave);
  };

  return (
    <div className={`globalSearchBar createClientsWrapper col-md-6 col-sm-12 ${cssClass}`}>
      <div className="form-group-wrapper position-relative mb-0">
        <input
          className="modalInput pd-right-90"
          placeholder="Search"
          type="text"
          style={{
            width: "100%",
            flexShrink: "initial",
          }}
          value={search}
          onChange={handleChange}
        />
        {search && (
          <i
            className="fas fa-times cancelSearch"
            onClick={() => {
              setSearch("");
              handleSave("");
            }}
          />
        )}
        <i className="fas fa-search globalSearch" />
        {eosModal && <i className={`fas fa-filter globalFilterIcon`} onClick={() => handleFilterClick()} />}
      </div>
    </div>
  );
};

export default GlobalSearch;
