import React from "react";

const EmployeeName = ({ emp, deptLeftStickPosition, showLeveMtarix, zoneLeftStickPosition, handleUserProfile }) => {
  return (
    <div
      className="ellipsis nameBlock tcMatrixResizeableCell tcMatrixTableCell firstTableBlock stickyCell cursor-pointer"
      style={{
        textAlign: "left",
        left: `${showLeveMtarix ? deptLeftStickPosition[4] : zoneLeftStickPosition[3]}px`,
      }}
      onClick={() => handleUserProfile(emp)}
      title={emp.firstName}
    >
      {emp.firstName}
    </div>
  );
};
export default EmployeeName;
