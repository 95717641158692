import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import TestDetailsMatrixModal from "components/Modal/TestDetailsMatrixModal";
import SchedulesDetails from "components/SchedulesDetails";
import moment from "moment";
import { formatTime } from "utils";
import Status from "components/Status/Status";
import api from "api";
import ExternalTestProgramModal from "components/Modal/ExternalTestProgramModal";
import SweetAlert from "react-bootstrap-sweetalert";
import { TEST_TYPE_VALUE } from "constant";

const MatrixPopOverCell = (props) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const {
    date,
    dayOpt,
    tdWidth,
    emp,
    appContext,
    setLoading,
    openClearedForWorkModal,
    showPopover,
    setShowPopover,
    setShowExternalTestNotification,
    showExternalTestData,
    setShowExternalTestData,
    testDetailsMatrixData,
    setTestDetailsMatrixData,
    secondTableColsWidth,
  } = props;

  let testType = dayOpt?.length > 0 ? dayOpt.map((d) => TEST_TYPE_VALUE[d.test_type || d.value]) : [];
  let positiveDone = dayOpt?.filter((t) => t.result && t.result.toLowerCase() === "positive");

  useEffect(() => {
    if (
      testDetailsMatrixData.visible &&
      !appContext.isReadOnly() &&
      emp.id === testDetailsMatrixData.emp.id &&
      date === testDetailsMatrixData.date
    ) {
      const temp = {
        visible: true,
        emp,
        date,
        dayOpt,
        testType,
      };

      if (JSON.stringify(temp) !== JSON.stringify(testDetailsMatrixData)) setTestDetailsMatrixData(temp);
    }
  }, []);

  const childNode = useRef();
  let setTimeoutConst = null;
  let checkInDateTime = "";

  const TEST_COLOR = {
    POSITIVE: "red",
    NEGATIVE: "green",
    INCONCLUSIVE: "blue",
  };

  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true);
    }, 1000);
  };

  const handleRemoveExternalTest = async (test) => {
    console.log("test", test, emp);
    setLoading(true);

    try {
      await Promise.all([
        api.removeExternalTest(emp.id, test),
        api.addLogsRemoveExternalTest(test, emp, appContext.user),
      ]);
      appContext.showSuccessMessage(`${TEST_TYPE_VALUE[test.test_type || test.value]} successfully deleted`);
      appContext.resetMatrixTestDone();
    } catch (err) {
      console.log("Error:-", err.message);
    }
    setLoading(false);
  };

  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setShowPopover(false);
  };

  const showTestDoneData = () => {
    if (appContext.isReadOnly() || !dayOpt || dayOpt.length === 0) return <></>;
    const testDone = dayOpt.filter((t) => t.isDone);

    return testDone.map((t) => (
      <span
        key={t.id}
        title={`${TEST_TYPE_VALUE[t.test_type || t.value]} Test Created at ${formatTime(t.createdAt)}`}
        className={`badge-${t.result ? TEST_COLOR[t.result.toUpperCase()] : "gray"}`}
      />
    ));
  };

  const isCallTimeOver = () => emp.callTimeOver && testType.length > 0 && date === moment().format("YYYY-MM-DD");
  const isQuestions = () => emp.qaDates.includes(date) && date === moment().format("YYYY-MM-DD");

  const isCheckIn = () => {
    const index = emp.checkInData?.findIndex((f) => moment(f.createdAt).format("YYYY-MM-DD") === date);

    if (emp.checkInData?.length > 0 && index !== -1) {
      checkInDateTime = formatTime(emp.checkInData[index].createdAt);
      return true;
    }
    return false;
  };

  const isQADone = () => {
    const index = emp.qaData?.findIndex(
      (f) => moment(f.createdAt).format("YYYY-MM-DD") === date && f.action === "Done"
    );
    if (index !== -1) {
      return true;
    }
    return false;
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const files = [...e.dataTransfer.files];
    console.log("files", files);
    setIsDragOver(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    console.log("drag over", e);
    setIsDragOver(true);
  };
  const handleDargLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  return (
    <>
      <div
        className={`ellipsis secondTableBlock tcMatrixTableCell position-relative ${
          isDragOver ? "border border-success" : ""
        }`}
        style={{
          textAlign: "center",
          // width: tdWidth,
          width: `${secondTableColsWidth && secondTableColsWidth}%`,
          maxWidth: tdWidth,
          height: "71px",
          minHeight: "71px",
          maxHeight: "71px",
        }}
        title={dayOpt ? [...new Set(testType)].join() : "-"}
        onDrop={handleFileDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDargLeave}
      >
        {isCallTimeOver() ? (
          <div className="star-icon-wrapper">
            <Status
              crossIcon
              cssClass="checked-mark"
              type="circle"
              size="md"
              color="maroon"
              title={emp.callTimeOver ? `(Call Time ${emp.callTimeOver}) Has Not Checked In` : "Has Not Checked In"}
            />
          </div>
        ) : isCheckIn() ? (
          <div className="star-icon-wrapper">
            <Status
              crossIcon
              cssClass="checked-mark"
              type="circle"
              size="md"
              color="green"
              title={`Checked In at ${checkInDateTime || ""}`}
            />
          </div>
        ) : (
          <></>
        )}

        {appContext.isZoneLevelCompany() && isQADone() ? (
          <div className="star-icon-wrapper">
            <Status
              questionIcon
              cssClass="checked-Ques-mark"
              type="circle"
              size="md"
              color="green"
              title="Question Status"
            />
          </div>
        ) : (
          appContext.isZoneLevelCompany() &&
          isQuestions() && (
            <div className="star-icon-wrapper" onDoubleClick={() => openClearedForWorkModal(emp)}>
              <Status
                questionIcon
                cssClass="checked-Ques-mark"
                type="circle"
                size="md"
                color="maroon"
                title="Questions"
              />
            </div>
          )
        )}
        <div
          className="badge-colors-wrapper cursor-pointer"
          onClick={() => {
            if (!appContext.isReadOnly()) {
              const temp = {
                visible: true,
                emp,
                date,
                dayOpt,
                testType,
              };

              if (JSON.stringify(temp) !== JSON.stringify(testDetailsMatrixData)) setTestDetailsMatrixData(temp);
            }
          }}
        >
          {showTestDoneData()}
        </div>
        <span
          className="cursor-pointer"
          onClick={() => {
            if (
              !appContext.isReadOnly() &&
              testType.length > 0 &&
              dayOpt.some((t) => !t.isDone) &&
              (!appContext.company?.onSet || (emp.onSet === 1 && appContext.company?.onSet === 1))
            ) {
              if (date === moment().format("YYYY-MM-DD")) {
                const temp = {
                  visible: true,
                  emp,
                  date,
                };

                if (JSON.stringify(temp) !== JSON.stringify(showExternalTestData)) setShowExternalTestData(temp);
              } else {
                setShowExternalTestNotification("Please Select Today's Date");
              }
            }
          }}
        >
          {testType.length > 1 && dayOpt ? "Multi" : dayOpt ? testType.join() : "-"}
        </span>
      </div>
    </>
  );
};

export default MatrixPopOverCell;
