import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { getValidReg, checkValidity } from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
const ProxyOptionModal = (props) => {
  const { handleClose, selectItem, handleSave, proxyOptions } = props;
  const [error, setError] = useState("");
  const [newOption, setNewOption] = useState(selectItem || {});

  const handleSaveChange = () => {
    let obj = {};
    selectItem ? Object.assign(obj, { ...newOption, isNew: false }) : Object.assign(obj, { ...newOption, isNew: true });
    if (!obj.name || obj.name === " ") {
      setError("Proxy Option name be required");
      return;
    }
    const findVal = proxyOptions.find((f) => obj.id !== f.id && checkValidity(f.name) === checkValidity(obj.name));
    if (findVal) {
      setError("Proxy Option Already Exist");
      return;
    }
    handleSave(obj);
  };
  return (
    <Modal show animation={true} onHide={() => handleClose()} centered size={"md"} keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {selectItem ? "Edit Option" : "Create Option"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="row mb-3">
          <div className="col-lg-12 col-12 mb-3">
            <label className="modalLineHeaders mt-0">Option name:</label>
            <input
              value={newOption.name}
              className="modalInput"
              placeholder="Enter Option Name"
              style={{
                width: "100%",
              }}
              onChange={(e) => setNewOption({ ...newOption, name: getValidReg(e.target.value) })}
            />
          </div>
        </div>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="secondary" className="headerButton btn-fill" onClick={handleSaveChange}>
          {selectItem ? "Update" : "Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProxyOptionModal;
