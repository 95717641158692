import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const ChangeResultModal = (props) => {
  const { handleSave, handleClose } = props;
  const [result, setResult] = useState("");
  const [error, setError] = useState("");
  const [confirmText, setConfirmText] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 4000);
  }, [error]);

  const onHandleSave = () => {
    if (!result) {
      setError("Please select the result type");
      return;
    }
    if (confirmText !== "confirm") {
      setError("Please enter confirm in input");
      return;
    }
    handleSave(result);
    handleClose();
    return;
    //onChangeType()
  };

  const onChangeType = (e) => setResult(e.target.value);

  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => {
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Change Test Result
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Positive </label>
            <Form.Check>
              <Form.Check.Input
                id="positive"
                value="positive"
                className="mt-2"
                name="resultGroupList"
                type="radio"
                checked={result === "positive"}
                onChange={onChangeType}
              />
              <Form.Check.Label htmlFor="positive" className="px-2 scheduleLabel">
                Positive
              </Form.Check.Label>
            </Form.Check>
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Negative: </label>
            <Form.Check>
              <Form.Check.Input
                id="negative"
                value="negative"
                name="resultGroupList"
                className="mt-2"
                type="radio"
                checked={result === "negative"}
                onChange={onChangeType}
              />
              <Form.Check.Label htmlFor="negative" className="px-2 scheduleLabel">
                Negative
              </Form.Check.Label>
            </Form.Check>
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Inconclusive: </label>
            <Form.Check>
              <Form.Check.Input
                id="Inconclusive"
                value="Inconclusive"
                className="mt-2"
                name="resultGroupList"
                type="radio"
                checked={result === "Inconclusive"}
                onChange={onChangeType}
              />
              <Form.Check.Label htmlFor="Inconclusive" className="px-2 scheduleLabel">
                Inconclusive
              </Form.Check.Label>
            </Form.Check>
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Type confirm: </label>
            <input
              value={confirmText}
              className="modalInput"
              placeholder="confirm"
              style={{
                width: "100%",
              }}
              onChange={(e) => setConfirmText(e.target.value)}
            />
          </div>

          {error.length > 0 && <strong style={{ color: "Red" }}>{error}</strong>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            className="modalButtons headerButton btn-fill"
            variant="primary"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            className="modalButtons headerButton btn-fill"
            variant="secondary"
            onClick={onHandleSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeResultModal;
