const oneYearAgo = () => {
  const d = new Date();
  d.setFullYear(d.getFullYear() - 1);
  d.setHours(0, 0, 0, 0);
  return d;
};

const oneMonthAgo = () => {
  const d = new Date();
  const m = d.getMonth();
  d.setMonth(d.getMonth() - 1);

  if (d.getMonth() === m) d.setDate(0);
  d.setHours(0, 0, 0, 0);
  return d;
};

const oneWeekAgo = () => {
  const d = new Date();
  d.setDate(d.getDate() - 7);
  d.setHours(0, 0, 0, 0);
  return d;
};

const today = () => {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  return d;
};

const timeObject = {
  "All Tests": new Date("March 1, 2021"),
  "This Year": oneYearAgo(),
  "This Month": oneMonthAgo(),
  "This Week": oneWeekAgo(),
  Today: today(),
};

const filterDate = (tests, timeFilter) => {
  console.log("FROM FILTER DATe", tests, timeFilter);
  return tests.filter((test) => {
    return new Date(test.createdAt) > timeFilter;
  });
};

const filterDates = (tests, timeFilter) => {
  const { start, end } = timeFilter;
  if (!tests) return [];
  return tests.filter((test) => {
    if (Object.keys(timeFilter).length !== 0) {
      return new Date(test.startDate) > start && new Date(test.startDate) < end;
    } else {
      return new Date(test.startDate);
    }
  });
};

export const filterOptions = [
  { value: "All", label: "All" },
  { value: "This Year", label: "This Year" },
  { value: "This Month", label: "This Month" },
  { value: "This Week", label: "This Week" },
  { value: "Today", label: "Today" },
];

export default {
  oneYearAgo,
  oneMonthAgo,
  oneWeekAgo,
  today,
  filterDate,
  filterDates,
  filterOptions,
};
