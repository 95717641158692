import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import { TEST_TYPES } from "constant";
import moment from "moment";
import ErrorMessage from "components/Message/ErrorMessage";
import TextAreaMessageInput from "components/TextInput/TextAreaMessageInput";

const NotesModal = (props) => {
  const { handleClose, handleSave, data, testingCalendar, employee } = props;
  const [notes, setNotes] = useState(data.note || "");
  const [error, setError] = useState("");
  const onHandleSave = () => {
    handleSave(notes || "", data.date);
  };
  return (
    <>
      <Modal show onHide={handleClose} animation={true} size={"lg"} keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">
            Notes {employee && `for ${employee.firstName} ${employee.lastName}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row textAreaMessageInput">
            <div className="col-12 mb-3">
              <textarea
                placeholder={"Note!"}
                className="MessageInput"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div>
          <div style={{ minHeight: "3vh" }}>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <div className="d-flex">
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={onHandleSave}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotesModal;
