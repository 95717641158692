import React, { useState, useEffect } from "react";
import Login from "components/Login/Login";
import { Bounce } from "react-activity";
import API from "api";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import Register from "components/Register/Register";
import PreRegister from "components/preRegister/PreRegister";
import QRLink from "components/QrCard/QRLink";
import ExternalTestPublic from "components/externalTestPublic/ExternalTestPublic";

export default function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  // console.log = () => {};

  useEffect(() => {
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      if (user) {
        setUser(user);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    getUSer();
  }, []);

  if (loading) {
    return (
      <Bounce
        style={{
          position: "fixed",
          left: "50%",
          right: "50%",
          top: "50%",
        }}
        size={35}
        color={"#A82632"}
      />
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/qrlink/:id" render={(props) => <QRLink {...props} />} />
        <Route path="/pre-register" render={(props) => <PreRegister {...props} />} />
        <Route path="/external-test" render={(props) => <ExternalTestPublic {...props} />} />
        <Route path="/register" render={(props) => <Register {...props} />} />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/login" render={(props) => <Login />} />
        <Redirect from="/" to="/login" />
      </Switch>
    </BrowserRouter>
  );
}
