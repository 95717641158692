import React, { useState } from "react";
import { Overlay, Popover, Modal } from "react-bootstrap";
import { Card, Table, Row, Col, Button, Form } from "react-bootstrap";
import { onBoardingTest, formatDateMDYTime } from "utils";
import SweetAlert from "react-bootstrap-sweetalert";
import { RESULT_COLOR } from "constant";
import api from "api";
import Loader from "components/Loader/Loader";
import PDFLayout from "components/PDF/PDFLayout";
import { Storage } from "aws-amplify";
import moment from "moment";
import FullImageView from "components/Image/FullPageImageView";
import ErrorMessage from "components/Message/ErrorMessage";
import { NOTIFICATION_FROM } from "constant";
import NotificationInputModal from "./NotificationInputModal";
import ZoneTestNowModal from "./ZoneTestNowModal";
import TrashIcon from "assets/img/trash-icon.png";
import ConfirmationModal from "./ConfirmationModal";
import { TEST_TYPE_VALUE } from "constant";
import ShowAlertMessage from "components/ShowAlertMessage";

const TestDetailsMatrixModal = (props) => {
  const { handleClose, handleSave, appContext, emp, dayOpt, date, handleRemoveExternalTest } = props;
  const [showPdf, setShowPdf] = useState(false);
  const [testPdf, setTestPdf] = useState();
  const [testLab, setTestLab] = useState();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(false);
  const [externalTestImage, setExternalTestImage] = useState("");
  const [qurantineCheck, setQurantineCheck] = useState(emp.isQuarantine ? true : false);
  const [empTest, setEmpTest] = useState();
  const [QDays, setQDays] = useState(emp.quarantineDays !== null ? emp.quarantineDays : 14);
  const [PDays, setPDays] = useState(emp.positiveDays !== null ? emp.positiveDays : 90);
  const [error, setError] = useState("");
  const [updateQuarantine, setUpdateQuarantine] = useState(false);
  const [showSuccess, setShowSuccess] = useState("");
  const [zoneTestNow, setZoneTestNow] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [passTest, setPassTest] = useState(false);
  const [objToDelete, setObjToDelete] = useState(null);

  const handleExternalTestView = async (filePath) => {
    try {
      const resImage = await Storage.get(filePath, { bucket: "employee-external-tests" });
      setExternalTestImage(resImage);
    } catch (err) {
      console.log("Error:- AuditTrail External Image", err.message);
    }
  };

  const handleClick = (log) => {
    if (appContext.isReadOnly()) return;
    if (log.result && (log.filePath || log.externalTestFile)) {
      handleExternalTestView(log.filePath || log.externalTestFile);
      return;
    }
    if (log.result) {
      handleResult(log.sequenceNo);
      return;
    }
  };

  const TableRow = ({ item, index }) => {
    return (
      <tr>
        <td className="popOver-centered">{formatDateMDYTime(item.createdAt || item.date)}</td>
        <td className="popOver-centered">
          {item.auditType === "ExternalTest" || item.externalTestFile
            ? `${TEST_TYPE_VALUE[item.test_type || item.value]} (External Test)`
            : TEST_TYPE_VALUE[item.test_type || item.value]}
        </td>
        <td className="popOver-centered">{item.isDone ? "True" : "False"}</td>
        <td className="popOver-centered">{item.sequenceNo || "-"}</td>
        {!appContext.isReadOnly() && (
          <td
            className="popOver-centered"
            style={{ color: RESULT_COLOR[item.result?.toLowerCase()], cursor: "pointer" }}
            onClick={() => handleClick(item)}
          >
            {item.result || "-"}
          </td>
        )}
        {item.sequenceNo && !item.externalTestFile && item.auditType !== "ExternalTest" && item.result && (
          <td title="Send Email">
            <i className="fas fa-envelope linkedText" onClick={() => handleSendEmail(item.sequenceNo)} />
          </td>
        )}
        {(item.auditType === "ExternalTest" || item.externalTestFile) &&
          !item.isDeleted &&
          date === moment().format("YYYY-MM-DD") && (
            <td>
              <img
                src={TrashIcon}
                width="15"
                alt="trash icon"
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                // onClick={() => handleRemoveExternalTest(item)}
                onClick={() => {
                  setObjToDelete(item);
                  setOpenConfirmation(true);
                }}
              />
            </td>
          )}
      </tr>
    );
  };

  const handleResult = async (resultSeq) => {
    setLoading(true);
    try {
      const res = await api.getTestDetailFromMD(resultSeq);
      if (res.employee?.Item) {
        setTestLab(res.labs?.Item);
        setEmployee(res.employee?.Item);
        setTestPdf(res.tests[0]);
        setShowPdf(true);
      }

      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  const handleSendEmail = async (seqNo) => {
    setLoading(true);
    try {
      const res = await api.getTestDetailFromMD(seqNo);
      if (res.employee?.Item) {
        await api.sendEmailToPendingRelease(res.tests.map((m) => ({ id: m.id, sequenceNo: seqNo })));
        appContext.showSuccessMessage("Email has been sent successfully!");
        let logData = [];
        logData.push({
          schrID: emp.id,
          auditType: "Message",
          action: "Sent Result",
          message: "Sent Result",
          messageType: "Email",
          companyID: emp.companyID,
          userName: appContext.user.name,
        });
        await api.addlogs(logData);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      appContext.showErrorMessage(err);
    }
  };

  const isPositiveTests = () =>
    emp.isQuarantine || dayOpt.filter((t) => t.result && t.result.toLowerCase() === "positive").length > 0;

  const handleSaveQuarantined = async () => {
    if (QDays < 1 || !QDays) {
      setError("Please Select Quarantine Days in Positive Number ");
      return;
    }
    if (PDays < 1 || !PDays) {
      setError("Please Select Testing Days in Positive Number");
      return;
    }

    try {
      setLoading(true);
      let testDate = moment().toISOString();

      const positiveTests = dayOpt.filter((t) => t.result && t.result.toLowerCase() === "positive");

      if (positiveTests.length > 0) {
        testDate = moment(positiveTests[0].date, "YYYY-MM-DD").toISOString();
      }
      await api.updateQuarantine(emp.id, QDays, PDays, testDate, appContext.user.name);
      //appContext.showSuccessMessage("Data updated sucessfully");
      //appContext.resetEmployees();
      setUpdateQuarantine(true);
      setShowSuccess("Data Updated Succesfully");
      setLoading(false);
    } catch (err) {
      console.log("Quarantined Error", err);
    }
  };

  console.log("dayOpt", dayOpt);

  return showPdf ? (
    <Modal
      className="historyViewModal p-0"
      show
      animation={true}
      onHide={() => handleClose(updateQuarantine || passTest)}
      size={"fullscreen"}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {`${emp.firstName} ${emp.lastName} Screening History`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="marginTop0 pdfModalBody">
        <PDFLayout setShowPdf={setShowPdf} testPdf={testPdf} testsLab={testLab} employee={employee} />
      </Modal.Body>
    </Modal>
  ) : (
    <Modal
      className="historyViewModal"
      show={true}
      onHide={() => handleClose(updateQuarantine || passTest)}
      animation={true}
      size={"lg"}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0">{`${emp.firstName} ${emp.lastName}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="striped-table-card programPopOverCard">
          <Card.Body>
            <div className="form-group-wrapper d-block">
              <div className="table-responsive overFlow-y-hidden">
                <Table className="table">
                  <thead>
                    <tr>
                      <th className="popOver-centered border-0">Test Date & Time</th>
                      <th className="popOver-centered border-0">Test Type</th>
                      <th className="popOver-centered border-0">Tested</th>
                      <th className="popOver-centered border-0">Sequence No</th>
                      {!appContext.isReadOnly() && <th className="popOver-centered border-0">Result</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {dayOpt &&
                      dayOpt.map((item, i) => {
                        return <TableRow key={i} index={i} item={item} />;
                      })}
                  </tbody>
                </Table>
              </div>
            </div>

            {isPositiveTests() && (
              <>
                <div className="col-sm-12">
                  <div className="form-group-wrapper  my-3 align-items-center">
                    <Form.Check className="mt-1">
                      <Form.Check.Input
                        type="checkbox"
                        checked={qurantineCheck}
                        onChange={(e) => setQurantineCheck(e.target.checked ? true : false)}
                      />
                    </Form.Check>
                    <strong>Quarantine</strong>
                  </div>
                  {qurantineCheck && (
                    <div className="createClientsWrapper flex-wrap w-100">
                      <div className="first-last-name-wrapper">
                        <div className="form-group-wrapper">
                          <label className="modalLineHeaders mt-0">Quarantine Days:</label>
                          <input
                            id="days"
                            type="number"
                            className="modalInput"
                            maxLength="3"
                            placeholder="days"
                            value={QDays}
                            onChange={(e) => setQDays(e.target.value.replace(/^0|\D/, ""))}
                          />
                        </div>
                        <div className="form-group-wrapper">
                          <label className="modalLineHeaders mt-0">No Testing Days:</label>
                          <input
                            id="name"
                            type="number"
                            value={PDays}
                            maxLength="3"
                            className="modalInput"
                            placeholder="Positive Days"
                            onChange={(e) => setPDays(e.target.value.replace(/^0|[^0-9]/, ""))}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
                {qurantineCheck && (
                  <div className="col-sm-12">
                    <div className="form-group-wrapper  my-3 align-items-right">
                      <Button
                        style={{ marginBottom: 10 }}
                        variant="secondary"
                        className="modalButtons headerButton btn-fill"
                        onClick={handleSaveQuarantined}
                      >
                        {emp.isQuarantine || updateQuarantine ? "Update" : "Save"}
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
            <FullImageView image={externalTestImage} handleClose={() => setExternalTestImage("")} />
            {showSuccess && <ShowAlertMessage message={showSuccess} handleClose={() => setShowSuccess("")} success />}
            {openNotificationModal && (
              <NotificationInputModal
                cssClass="eos-employeeModal ps-0"
                data={[emp]}
                handleClose={() => setOpenNotificationModal(false)}
                handleSave={() => setShowSuccess("Message sent successfully")}
                testDetails={true}
                appContext={appContext}
                dataType={NOTIFICATION_FROM.employee}
              />
            )}
            {zoneTestNow && (
              <ZoneTestNowModal
                handleClose={() => setZoneTestNow(false)}
                handleSave={(obj) => {
                  setShowSuccess("Test Now Succesfully"), setPassTest(true);
                }}
                company={appContext.company}
                empIds={[emp.id]}
              />
            )}
            {openConfirmation && (
              <ConfirmationModal
                show={openConfirmation}
                cssClass={"eos-employeeModal"}
                title="Delete External Test"
                message={`Are you sure to delete this external test?`}
                handleConfirm={async (isConfirm) => {
                  if (isConfirm) {
                    setOpenConfirmation(false);
                    setLoading(true);
                    await handleRemoveExternalTest(objToDelete);
                    setLoading(false);
                  } else {
                    setOpenConfirmation(false);
                    setObjToDelete(null);
                  }
                }}
                closeBtn={true}
              />
            )}
          </Card.Body>
        </Card>
        {loading && <Loader />}
      </Modal.Body>

      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose(updateQuarantine || passTest);
          }}
        >
          Close
        </Button>
        {/* <Button className="headerButton btn-fill" variant="secondary" onClick={() => setZoneTestNow(true)}>
          Test Now
        </Button> */}
        <Button variant="secondary" className="headerButton btn-fill" onClick={() => setOpenNotificationModal(true)}>
          Send Message
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestDetailsMatrixModal;
