/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:0c4fa544-46a6-4882-a0fa-dcabef78be94",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_oivgNGbvz",
    "aws_user_pools_web_client_id": "38jrl6m9mmukd6l020cqhgmkq0",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER",
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_appsync_graphqlEndpoint": "https://xgwqm47tnfajhjhfglk5lyvbya.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-vx64an64ofax5fjcdrhwkz6zlu",
    "aws_cloud_logic_custom": [
        {
            "name": "mfaAdminApi",
            "endpoint": "https://0patdqnxi9.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "mfapp-storage-cd1cccac110752-staging",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
