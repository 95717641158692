import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "context/app-context";
import { Card, Table, Row, Col, Button, Form } from "react-bootstrap";
import { Bounce } from "react-activity";
import API from "api";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { calculateTdWidth, checkValidity } from "utils";
import LocationModal from "components/Modal/LocationModal";
import editIcon from "../assets/img/edit-icon.png";
import trashIcon from "../assets/img/trash-icon.png";
import { CONFIRMATION_TYPE } from "constant";
import FilterPopOver from "components/FilterPopOver";
import MFPagination from "components/Pagination/MFPagination";
import { formatCreatedDate } from "utils";
import ExportToExcel from "components/ExportToExcel";
import HeaderItem from "components/Table/HeaderItem";
import { Link } from "react-router-dom";
import { downloadDataAsCSV } from "utils";
import DepartmentImportModal from "components/Modal/DepartmentImportModal";
import ImportErrorModal from "components/Modal/ImportErrorModal";
import { getValidReg } from "utils";
import Icon from "components/Icon";
import { formatNumber } from "utils";

const Location = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [locations, setLocations] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filterLocations, setFilterLocations] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openImportLoc, setOpenImportLoc] = useState(false);
  const [openErrModal, setOpenErrModal] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [successData, setsuccessData] = useState([]);
  const [title, setTitle] = useState("");

  const searchTerms = ["Name"];

  const selectedItem = [
    { itemKey: "createdAt" },
    { itemKey: "name" },
    { itemKey: "totalEmployees" },
    { itemKey: "createdByName" },
  ];

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(width - 50, 1);

  const sortLocations = (locations, sortParam) => {
    if (sortDescending) {
      return [...locations].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...locations].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  useEffect(() => {
    if (locations.length > 0) setFilterLocations(sortLocations(filterLocations, sortBy));
  }, [sortBy, sortDescending]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  useEffect(() => {
    getLocations();
  }, [appContext.locations, filter]);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const getLocations = () => {
    const locOldData = appContext.locations;
    const employeeLocations = appContext.employees
      .filter((f) => f.location)
      .reduce((obj, employee) => {
        let locId = employee.location;
        obj[locId] = (obj[locId] || 0) + 1;
        return obj;
      }, {});
    const locNewData = locOldData.map((loc) => {
      let ttlEmployees = employeeLocations[loc.name] || 0;
      return {
        ...loc,
        totalEmployees: ttlEmployees,
      };
    });
    setLocations(locNewData);
    setFilterLocations(nestedFilter(locNewData, filter));
  };

  const OnHandleLocation = async (loc) => {
    setOpenCreateModal(false);
    try {
      setLoading(true);
      if (loc.isNew) {
        (await API.newLocations(loc.name, appContext.user))
          ? appContext.showSuccessMessage(`You have successfully created the ${loc.name} location`)
          : appContext.showErrorMessage("Location already exist");
      } else {
        await API.updateLocation(loc);
        appContext.showSuccessMessage("Location Updated Successfully");
      }
      appContext.resetLocations();
      setSelectItem(null);
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
    }
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    if (filterLocations.length === filteredList.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? locations.map((t) => t.id) : []);
  };

  const handleEdit = (item) => {
    setSelectItem(item);
    setOpenCreateModal(true);
  };

  const getDeleteMessage = () => {
    const locationToDelete = locations.filter((d) => checkboxes.indexOf(d.id) !== -1 && d.totalEmployees === 0);
    const ids = locationToDelete.map((d) => d.id);

    let locationName = null;
    let totalLocations = null;

    if (ids.length === 1) {
      const locationToRemove = locations.find((d) => d.id === ids[0]);
      if (locationToRemove) {
        locationName = locationToRemove.name;
      }
    } else {
      totalLocations = ids.length;
    }
    return [ids, locationName, totalLocations];
  };

  const onHandleConfirmation = async (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      setItemToDelete(null);
      setCheckboxes([]);
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.DELETE:
        handleConfirmDelete();
        break;
      case CONFIRMATION_TYPE.MULTI_DELETE:
        deleteLocation();
        break;
    }
  };

  const openDeleteConfirmation = (loc) => {
    if (loc.totalEmployees > 0) {
      appContext.showErrorMessage("Employees are assigned to the specified location");
    } else {
      setOpenConfirmation({
        isShow: true,
        actionType: CONFIRMATION_TYPE.DELETE,
        title: "Delete Location",
        message: `Are you sure you want to delete ${loc.name} location?`,
      });
      if (loc) setItemToDelete(loc);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      await API.deleteLocation(itemToDelete.id);
      appContext.showSuccessMessage("Location deleted successfully");
      setItemToDelete(null);
      appContext.resetLocations();
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleBulkDeleteClick = () => {
    let [ids, locationName, totalLocations] = getDeleteMessage();
    if (ids.length === 0) {
      appContext.showErrorMessage("Select at-least one Location with no crew members assigned");
      return;
    }
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.MULTI_DELETE,
      title: "Delete Location",
      message: locationName
        ? `Are you sure you want to remove ${locationName} Location?`
        : `Are you sure you want to remove ${formatNumber(totalLocations)} Locations?`,
    });
  };

  const deleteLocation = async () => {
    try {
      let [ids, locationName, totalLocations] = getDeleteMessage();
      if (ids.length === 0) {
        appContext.showErrorMessage("No Location to Delete");
        return;
      }

      setLoading(true);
      for (let i = 0; i < ids.length; i++) {
        await API.deleteLocation(ids[i]);
      }
      if (locationName) {
        appContext.showSuccessMessage(`${locationName} location has been deleted successfully`);
      } else {
        appContext.showSuccessMessage(`${formatNumber(totalLocations)} locations have been deleted successfully`);
      }
      appContext.resetLocations();
      setSelectItem(null);
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage("Fail to Delete the Location");
    }
    setLoading(false);
    setCheckboxes([]);
  };

  const handleDownloadLocation = async () => {
    await downloadDataAsCSV(
      filterLocations.map((loc) => {
        return {
          createdAt: formatCreatedDate(loc.createdAt),
          name: loc.name,
          totalEmployees: loc.totalEmployees,
          createdByName: loc.createdByName,
        };
      }),
      "location"
    );
  };

  const handleImportLocation = async (locData) => {
    if (locData.length === 0) return;
    const arrErrorData = [];
    const arrSuccessData = [];
    if (Object.keys(locData[0].data)[0] !== "name") {
      appContext.showErrorMessage("First column Header must be name");
      setOpenImportLoc(false);
      return;
    }
    setLoading(true);
    const ttlLength = locData.length;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = locData[index];
      const loc = { ...data };

      if (!loc.name) continue;
      loc.name = getValidReg(loc.name);

      const findVal = locations.find((f) => checkValidity(f.name) == checkValidity(loc.name));
      if (findVal) {
        arrErrorData.push({ message: `${loc.name} Already Exist` });
        continue;
      } else {
        try {
          (await API.newLocations(loc.name, appContext.user)) && arrSuccessData.push(loc.name);
        } catch (error) {
          console.log("Import Error: ", error);
          appContext.showErrorMessage(error);
        }
      }
    }
    setOpenImportLoc(false);
    appContext.resetLocations();
    getLocations();
    setLoading(false);

    if (arrErrorData.length > 0 || arrSuccessData.length > 0) {
      setErrorData(arrErrorData);
      setTitle("Location");
      setsuccessData(arrSuccessData);
      setOpenErrModal(true);
    }
  };

  const TableRow = ({ item }) => {
    return (
      <tr key={item.id}>
        <td>
          {" "}
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={item.id}
              checked={checkboxes.indexOf(item.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, item.id)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td className="icon">
          <img
            src={editIcon}
            alt="edit icon"
            width="18"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={() => handleEdit(item)}
          />
        </td>
        <td style={{ textAlign: "left", textOverflow: "visible" }}>{formatCreatedDate(item.createdAt)}</td>
        <td style={{ textAlign: "left", textOverflow: "visible" }}>{item.name}</td>
        <td style={{ textAlign: "center", textOverflow: "visible" }} title={item.totalEmployees}>
          {item.totalEmployees > 0 ? (
            <Link
              style={{ color: "#42cef5" }}
              to={{
                pathname: "/admin/employees",
                state: { name: item.name, term: "location" },
              }}
            >
              {formatNumber(item.totalEmployees)}
            </Link>
          ) : (
            formatNumber(item.totalEmployees)
          )}
        </td>
        <td style={{ textAlign: "center", textOverflow: "visible" }}>{item.createdByName}</td>
        <td style={{ textAlign: "center", textOverflow: "visible" }}>
          <img
            src={trashIcon}
            alt="trash icon"
            width="15"
            style={{ marginLeft: "10px" }}
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={(e) => openDeleteConfirmation(item)}
          />
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  Total Locations (
                  {filterLocations ? formatNumber(filterLocations.length) : formatNumber(locations.length)})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    label="Filter"
                    title="Filter"
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={() => {
                      if (locations && locations.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />

                  <Icon
                    handleClick={() => handleBulkDeleteClick()}
                    label="Delete"
                    title="Delete"
                    disabled={checkboxes.length === 0}
                    iconType={"binIcon"}
                  />
                  <Icon
                    handleClick={() => setOpenCreateModal(true)}
                    title={"Create Location"}
                    label="Create"
                    iconType="createIcon"
                  />

                  {filterLocations.length > 0 && (
                    <ExportToExcel
                      items={filterLocations}
                      selectedItem={selectedItem}
                      appContext={appContext}
                      filter={filter}
                      title="Locations Data"
                      sortBy={sortBy}
                    />
                  )}

                  <Icon
                    handleClick={handleDownloadLocation}
                    title={"Download Location"}
                    label="Download"
                    iconType="downloadIcon"
                  />

                  <Icon
                    handleClick={() => setOpenImportLoc(true)}
                    title={`Import Locations`}
                    label="Import"
                    iconType="importIcon"
                  />
                </div>

                {showFilter && (
                  <FilterPopOver
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination
                  totalSelected={checkboxes.length}
                  showAll
                  showSelectedRecord
                  selectedLabel="Selected Locations"
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    locations.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="table">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>
                        <th></th>
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="createdAt"
                          title="Date Created"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="name"
                          title="Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="totalEmployees"
                          title="No of Crew"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="createdByName"
                          title="Created By"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <th className={`border-0 centered`} title="Action">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterLocations.map((item, i) => {
                        return <TableRow key={i} item={item} />;
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Bounce
              style={{
                position: "fixed",
                left: "50%",
                right: "50%",
                top: "50%",
              }}
              size={35}
              color={"#A82632"}
            />
          )}

          {openConfirmation.isShow && (
            <ConfirmationModal
              show={openConfirmation.isShow}
              actionType={openConfirmation.actionType}
              title={openConfirmation.title}
              message={openConfirmation.message}
              handleConfirm={onHandleConfirmation}
              closeBtn
            />
          )}

          {openImportLoc && (
            <DepartmentImportModal handleClose={() => setOpenImportLoc(false)} handleImport={handleImportLocation} />
          )}

          {openErrModal && (
            <ImportErrorModal
              title={title}
              errData={errorData}
              successData={successData}
              handleClose={() => {
                setOpenErrModal(false);
                setErrorData([]);
                setsuccessData([]);
                setTitle("");
              }}
            />
          )}

          {openCreateModal && (
            <LocationModal
              handleClose={() => {
                setSelectItem(null), setOpenCreateModal(false);
              }}
              selectItem={selectItem}
              locations={locations}
              handleSave={OnHandleLocation}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Location;
