import React, { useRef, useState, useContext } from "react";
import { MESSAGE_SEND_TYPE } from "constant";
import { AppContext } from "../../context/app-context";
import moment from "moment";
import { Storage } from "aws-amplify";
import TextAreaFormContainer from "./TextAreaFormContainer";
import { BUCKET_MESSAGE_ATTACHMENT } from "constant";
import Loader from "components/Loader/Loader";
import { isValidEmailAttachment } from "utils";

const TEXT_FILED_OPTIONS = [
  { label: "First Name", value: "{firstName}" },
  { label: "Last Name", value: "{lastName}" },
  { label: "Test Type", value: "{testType}" },
  { label: "Call Time", value: "{callTime}" },
  { label: "Zone Name", value: "{zone}" },
];

const TextAreaMessageInput = (props) => {
  const {
    message,
    name,
    handleChange,
    label,
    type,
    subject,
    viewAattachment,
    setFileNames,
    fileNames,
    handleKeyDownEvent,
  } = props;
  const textAreaRef = useRef();
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const inputFile = useRef();
  const childNode = useRef();
  const appContext = useContext(AppContext);
  const insertAtCursor = (dropDownValue) => {
    textAreaRef.current.focus;
    if (textAreaRef.current) {
      let startPos = textAreaRef.current.selectionStart;
      let endPos = textAreaRef.current.selectionEnd;
      textAreaRef.current.value =
        textAreaRef.current.value.substring(0, startPos) +
        dropDownValue +
        textAreaRef.current.value.substring(endPos, textAreaRef.current.value.length);
      const newPos = endPos + dropDownValue.length;
      textAreaRef.current.setSelectionRange(newPos, newPos);
      textAreaRef.current.focus();
      handleChange(textAreaRef.current.value, name, type, subject);
    } else {
      handleChange(textAreaRef.current.value, name, type, subject);
    }
  };

  const getFileName = (fname) => {
    const companyCode = appContext.company.companyCode;
    return `${companyCode}/attachment/${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_${fname}`;
  };

  const onChangeFile = async (event) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      try {
        const errMessage = isValidEmailAttachment(file);

        if (errMessage.length > 0) {
          appContext.showErrorMessage(errMessage);
          return;
        }
        setLoading(true);

        const fname = getFileName(file.name);

        await Storage.put(fname, file, { bucket: BUCKET_MESSAGE_ATTACHMENT });

        setFiles([...files, { fileName: file.name, name: fname }]);
        setFileNames((prevName) => [...prevName, fname]);
        event.target.value = null;
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        appContext.showErrorMessage(error.message);
      }
    }
  };

  const handleUploadClick = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleRemoveFile = async (fname) => {
    try {
      setLoading(true);

      await Storage.remove(fname, { bucket: BUCKET_MESSAGE_ATTACHMENT });
      setLoading(false);

      const updatedFiles = files.filter(({ name }) => name !== fname);
      const updateFilesName = fileNames.filter((f) => f !== fname);

      setFileNames(updateFilesName);
      setFiles(updatedFiles);
    } catch (error) {
      console.log(error);
      setLoading(false);
      appContext.showErrorMessage(error.message);
    }
  };

  return (
    <div className="row textAreaMessageInput">
      <div className="col-12 mb-3" style={{ position: "relative" }}>
        <TextAreaFormContainer
          viewAattachment={viewAattachment}
          TEXT_FILED_OPTIONS={TEXT_FILED_OPTIONS}
          insertAtCursor={insertAtCursor}
          MESSAGE_SEND_TYPE={MESSAGE_SEND_TYPE}
          handleChange={handleChange}
          message={message}
          name={name}
          subject={subject}
          type={type}
          handleUploadClick={handleUploadClick}
          loading={loading}
          files={files}
          childNode={childNode}
          setShowPopover={setShowPopover}
          inputFile={inputFile}
          onChangeFile={onChangeFile}
          showPopover={showPopover}
          handleRemoveFile={handleRemoveFile}
          label={label}
          textAreaRef={textAreaRef}
          handleKeyDownEvent={handleKeyDownEvent}
        />

        {loading && <Loader />}
      </div>
    </div>
  );
};
export default TextAreaMessageInput;
