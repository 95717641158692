import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext } from "../../context/app-context";
import API from "api";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Select from "react-select";
import { TEST_TYPES } from "constant";
import moment from "moment";
import ErrorMessage from "components/Message/ErrorMessage";
import TextAreaMessageInput from "components/TextInput/TextAreaMessageInput";
import RadioButton from "../RadioButton/RadioButton";
import { v4 as uuidv4 } from "uuid";
import Loader from "components/Loader/Loader";
import ConfirmationModal from "./ConfirmationModal";

const ZoneTestNowModal = (props) => {
  const { handleClose, handleSave, company, empIds } = props;
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [testData, setTestData] = useState([]);
  const [assignTest, setAssignTest] = useState({
    testNowVerbiage: company.isTestNowVerbiage ? company.testNowVerbiage : "",
    testNowType: company?.testNowType,
    testNowSubject: company?.testNowSubject || "",
    location: "At Home",
    testNowDate: moment().format("YYYY-MM-DD"),
    attachments: fileNames,
  });
  const onHandleSave = async () => {
    if (!assignTest) {
      setError("empty fields");
      return;
    }
    if (!assignTest.testType || assignTest.testType.length === 0) {
      setError("please select test type");
      return;
    }
    if (!assignTest.testNowDate) {
      setError("please select date");
      return;
    }
    if (!moment(assignTest.testNowDate, "YYYY-MM-DD", true).isValid()) {
      setError("invalid date");
      return;
    }
    if (assignTest.testNowDate < moment().format("YYYY-MM-DD")) {
      setError("Test Date should be Current or Future Date");
      return;
    }
    if (!assignTest.testNowVerbiage && assignTest.testNowSubject) {
      setError("Please add a message to be sent.");
      return;
    }

    Object.assign(assignTest, {
      attachments: fileNames.join(","),
    });

    const dateTime = `${assignTest.testNowDate}T00:00:00`;
    const testNowData = [];
    assignTest.testType.forEach((t, i) => {
      testNowData.push({
        ...assignTest,
        id: uuidv4(),
        testType: t,
        label: assignTest.label[i],
        location: "On Location",
        isDone: false,
        result: "",
        value: t,
        dateTime,
        testNow: true,
        testCreated: moment(dateTime).utc().toISOString(),
      });
    });

    setTestData(testNowData);

    if (!assignTest.testNowVerbiage) {
      setOpenConfirmation(true);
      return;
    }

    handleSaveApi(testNowData);
  };

  const handleSaveApi = async (testNowData) => {
    let logObj = [];
    for (let i = 0; i < empIds.length; i++) {
      const id = empIds[i];
      const emp = appContext.employees.find((f) => f.id === id);
      logObj.push({
        schrID: id,
        auditType: "TestNow",
        companyID: company.id,
        userName: appContext.user.name,
        action: emp.onBoardingTesting && emp.onBoardingTesting.length > 0 ? "Changed" : "Assign",
      });
      console.log("emp", emp);
    }
    console.log("assigntest", testNowData);
    try {
      setLoading(true);
      await API.updateEmployeeZoneTestNow(empIds, testNowData, appContext.user.name);
      await API.addlogs(logObj);
      handleSave(testNowData);
      handleClose();
      setLoading(false);
    } catch (err) {
      console.log("Error Zone Test Now", err.message);
      setLoading(false);
    }
  };

  const onHandleMessageChange = (msg, key, type, subject) => {
    const obj = { ...assignTest };
    Object.assign(obj, { testNowVerbiage: msg, testNowType: type, testNowSubject: subject });
    setAssignTest(obj);
  };

  const handleKeyDownEvent = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      console.log("Enter pressed!", event);
      onHandleSave();
    }
  };

  const handleConfirm = (isConfirm) => {
    if (!isConfirm) {
      setOpenConfirmation(false);
      setTestData([]);
      return;
    } else {
      setOpenConfirmation(false);
      handleSaveApi(testData);
    }
  };

  return (
    <>
      <Modal show onHide={handleClose} animation={true} size={"lg"} keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Test Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading ? (
            <>
              <div className="createClientsWrapper flex-wrap">
                <div className="first-last-name-wrapper">
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">Select Test Type: </label>
                    <Select
                      options={TEST_TYPES}
                      blurInputOnSelect={true}
                      defaultValue={null}
                      menuPlacement="auto"
                      isMulti
                      className="assignSchedule"
                      placeholder="Test Type"
                      onChange={(e) =>
                        setAssignTest({ ...assignTest, testType: e.map((t) => t.value), label: e.map((t) => t.label) })
                      }
                    />
                  </div>
                  <div className="form-group-wrapper selectDateTime">
                    <label className="modalLineHeaders mt-0">Select Date: </label>
                    <input
                      type="date"
                      placeholder="Select Date"
                      className="dateRangeInput"
                      defaultValue=""
                      min={moment().format("YYYY-MM-DD")}
                      value={assignTest.testNowDate}
                      onChange={(e) => setAssignTest({ ...assignTest, testNowDate: e.target.value })}
                    />
                  </div>
                </div>
                {/* <div className="first-last-name-wrapper">
                  <div className="form-group-wrapper selectDateTime">
                    <label className="modalLineHeaders mt-0">Select Time: </label>
                    <input
                      type="time"
                      placeholder="Select Time"
                      className="dateRangeInput"
                      defaultValue=""
                      onChange={(e) => setAssignTest({ ...assignTest, time: e.target.value })}
                    />
                  </div>
                   <div className="form-group-wrapper">
                <label className="modalLineHeaders">Select Location:</label>
                <div className="d-flex assignSchedule align-items-center justify-content-start justify-content-sm-between column-gap-15">
                  <RadioButton
                    value="On Location"
                    label="On Location"
                    checked={assignTest.location === "On Location"}
                    name="on_location"
                    id="location"
                    handleChange={(e) => setAssignTest({ ...assignTest, location: e.target.value })}
                  />
                  <RadioButton
                    value="At Home"
                    label="At Home"
                    checked={assignTest.location === "At Home"}
                    name="at_home"
                    id="atHome"
                    handleChange={(e) => setAssignTest({ ...assignTest, location: e.target.value })}
                  />
                </div>
              </div> 
                </div> */}
                {/* <div className="first-last-name-wrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Replace Scheduled Tests: </label>
                <div className="d-flex assignSchedule align-items-center column-gap-15 justify-content-sm-between justify-content-start">
                  <RadioButton
                    label="Yes"
                    checked={assignTest.overWrite === "Yes"}
                    name="overWrite"
                    id="writing_yes"
                    handleChange={(e) => {
                      setAssignTest({ ...assignTest, overWrite: e.target.checked ? "Yes" : "" });
                    }}
                  />
                  <RadioButton
                    label="No"
                    checked={assignTest.overWrite === "No"}
                    name="overWrite"
                    id="writing_no"
                    handleChange={(e) => {
                      setAssignTest({ ...assignTest, overWrite: e.target.checked ? "No" : "" });
                    }}
                  />
                </div>
              </div>
            </div> */}
              </div>
              <div className="w-100">
                <TextAreaMessageInput
                  message={assignTest.testNowVerbiage}
                  type={assignTest.testNowType}
                  subject={assignTest.testNowSubject}
                  name="testNowVerbiage"
                  label="Message Verbiage"
                  viewAattachment={true}
                  setFileNames={setFileNames}
                  fileNames={fileNames}
                  handleChange={onHandleMessageChange}
                  handleKeyDownEvent={handleKeyDownEvent}
                />
                <div style={{ minHeight: "3vh" }}>
                  {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
                </div>
              </div>
            </>
          ) : (
            <div style={{ minHeight: "30vh" }}>
              <Loader />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={onHandleSave}
            disabled={loading}
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
      {openConfirmation && (
        <ConfirmationModal
          show={openConfirmation}
          title="Message Confirmation"
          message={`Are you sure you want to proceed without message?`}
          handleConfirm={handleConfirm}
          handleClose={() => setOpenConfirmation(false)}
          closeBtn={true}
        />
      )}
    </>
  );
};

export default ZoneTestNowModal;
